import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, Inverter } from './types/InverterTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const createInverter = createAsyncThunk('inverter/create', async (payload: Partial<Inverter>, thunk) => {
  try {
    const res = await axios.post(api + '/inverters', payload);
    toast.success('Inverter Created Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getInverterList = createAsyncThunk('inverter/list', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/inverters');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getInverterDetails = createAsyncThunk('inverter/details', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/inverters/' + payload.id);
    return res.data;
  } catch (err: any) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateInverter = createAsyncThunk('inverter/udpate', async (payload: Inverter | Partial<Inverter>, thunk) => {
  try {
    const res = await axios.put(api + '/inverters/' + payload.id, payload);
    toast.success('Inverter updated Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const deleteInverter = createAsyncThunk('inverter/delete', async (payload: any, thunk) => {
  try {
    const res = await axios.put(api + '/inverters/' + payload.id, {
      ...payload,
      status: 'Deleted',
    });
    toast.success('Inverter Deleted Successfully!');

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getInverterHardwareAccessories = createAsyncThunk('inverter/hardware-accessories/get', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/inverters/hardware-accessories/' + payload.id);
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getInverterElectricalAccessories = createAsyncThunk('inverter/electrical-accessories/get', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/inverters/electrical-accessories/' + payload.id);

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getInverterCables = createAsyncThunk('inverter/cables/get', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/inverters/cables/' + payload.id);

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateInverterHardwareAccessory = createAsyncThunk('inverter/hardware-accessories/update', async (payload: any, thunk) => {
  try {
    const res = await axios.put(api + '/inverters/hardware-accessories/' + payload.id, payload);
    toast.success('Hardware Accessories Updated Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.details);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateInverterElectricalAccessory = createAsyncThunk('inverter/electrical-accessories/update', async (payload: any, thunk) => {
  try {
    const res = await axios.put(api + '/inverters/electrical-accessories/' + payload.id, payload);
    toast.success('Electrical Accessories Updated Successfully!');

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.details);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateInverterCable = createAsyncThunk('inverter/cables/update', async (payload: any, thunk) => {
  try {
    const res = await axios.put(api + '/inverters/cables/' + payload.id, payload);
    toast.success('Cables Updated Successfully!');

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.details);
    return thunk.rejectWithValue(err.response.data);
  }
});

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  details: {
    data: null,
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
    success: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
    success: false,
  },
  delete: {
    data: null,
    error: false,
    pending: false,
  },
  cableData: {
    data: [],
    error: false,
    pending: false,
  },
  hardwareAccessoryData: {
    data: [],
    error: false,
    pending: false,
  },
  electricalAccessoryData: {
    data: [],
    error: false,
    pending: false,
  },
};

const InverterSlice = createSlice({
  name: 'Inverter',
  initialState: initialState,
  reducers: {
    clearInverterDetails: (state) => {
      state.details.data = null;
    },
    clearInverterCreate: (state) => {
      state.create.data = null;
      state.create.success = false;
    },
    clearInverterUpdate: (state) => {
      state.update.data = null;
      state.update.success = false;
    },
    clearInverterList: (state) => {
      state.list.data = [];
    },
    clearInverterDelete: (state) => {
      state.delete.data = null;
    },
  },
  extraReducers: (builder) => {
    // Create Case
    builder.addCase(createInverter.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
      state.create.success = true;
    });
    builder.addCase(createInverter.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
    });
    builder.addCase(createInverter.pending, (state, action) => {
      state.create.pending = true;
      state.create.error = false;
    });
    // List Case
    builder.addCase(getInverterList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getInverterList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getInverterList.pending, (state, action) => {
      state.list.error = false;
      state.list.pending = true;
    });
    // Details Case
    builder.addCase(getInverterDetails.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.error = false;
      state.details.pending = false;
    });
    builder.addCase(getInverterDetails.rejected, (state, action) => {
      state.details.data = null;
      state.details.error = true;
      state.details.pending = false;
    });
    builder.addCase(getInverterDetails.pending, (state, action) => {
      state.details.error = false;
      state.details.pending = true;
    });
    // Update Case
    builder.addCase(updateInverter.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
      state.details.data = action.payload.data;
      state.update.success = true;
    });
    builder.addCase(updateInverter.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updateInverter.pending, (state, action) => {
      state.update.error = false;
      state.update.pending = true;
    });

    // Delete Case
    builder.addCase(deleteInverter.pending, (state, action) => {
      state.delete.error = false;
      state.delete.pending = true;
    });
    builder.addCase(deleteInverter.fulfilled, (state, action) => {
      state.delete.data = action.payload.data;
      state.delete.error = false;
      state.delete.pending = false;
      state.details.data = action.payload.data;
    });
    builder.addCase(deleteInverter.rejected, (state, action) => {
      state.delete.data = null;
      state.delete.error = true;
      state.delete.pending = false;
    });

    // Get Inverter Cables
    builder.addCase(getInverterCables.fulfilled, (state, action) => {
      state.cableData.data = action.payload.data;
      state.cableData.error = false;
      state.cableData.pending = false;
    });
    builder.addCase(getInverterCables.pending, (state, action) => {
      state.cableData.data = null;
      state.cableData.error = false;
      state.cableData.pending = true;
    });
    builder.addCase(getInverterCables.rejected, (state, action) => {
      state.cableData.data = null;
      state.cableData.error = true;
      state.cableData.pending = false;
    });
    // Get Inverter Hardware Accessories
    builder.addCase(getInverterHardwareAccessories.fulfilled, (state, action) => {
      state.hardwareAccessoryData.data = action.payload.data;
      state.hardwareAccessoryData.error = false;
      state.hardwareAccessoryData.pending = false;
    });
    builder.addCase(getInverterHardwareAccessories.pending, (state, action) => {
      state.hardwareAccessoryData.data = null;
      state.hardwareAccessoryData.error = false;
      state.hardwareAccessoryData.pending = true;
    });
    builder.addCase(getInverterHardwareAccessories.rejected, (state, action) => {
      state.hardwareAccessoryData.data = null;
      state.hardwareAccessoryData.error = true;
      state.hardwareAccessoryData.pending = false;
    });

    // Get Inverter Electrical Accessories
    builder.addCase(getInverterElectricalAccessories.fulfilled, (state, action) => {
      state.electricalAccessoryData.data = action.payload.data;
      state.electricalAccessoryData.error = false;
      state.electricalAccessoryData.pending = false;
    });
    builder.addCase(getInverterElectricalAccessories.pending, (state, action) => {
      state.electricalAccessoryData.data = null;
      state.electricalAccessoryData.error = false;
      state.electricalAccessoryData.pending = true;
    });
    builder.addCase(getInverterElectricalAccessories.rejected, (state, action) => {
      state.electricalAccessoryData.data = null;
      state.electricalAccessoryData.error = false;
      state.electricalAccessoryData.pending = true;
    });
  },
});

export const { clearInverterCreate, clearInverterDetails, clearInverterDelete, clearInverterUpdate } = InverterSlice.actions;

export default InverterSlice.reducer;
