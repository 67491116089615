import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, InstallationCost } from './types/InstallationCostTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllInstallationCosts = createAsyncThunk('installationCost/get', async (filter: any): Promise<any> => {
  const res = await axios.get(`${api}/installation-cost`);

  return res.data;
});

export const createInstallationCost = createAsyncThunk('installationCost/create', async (data: InstallationCost): Promise<any> => {
  const res = await axios.post(`${api}/installation-cost`, data);
  toast.success('Installation Cost Added successfully');
  return res.data;
});

export const updateInstallationCost = createAsyncThunk('installationCost/update', async (data: InstallationCost): Promise<any> => {
  const res = await axios.put(`${api}/installation-cost/${data.id}`, data);
  toast.success('Installation Cost Updated successfully');
  return res.data;
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
};

const InstallationCostSlice = createSlice({
  name: 'InstallationCost',
  initialState: initialState,
  reducers: {},

  // GET ALL Installation Costs
  extraReducers: (builder) => {
    builder.addCase(getAllInstallationCosts.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllInstallationCosts.rejected, (state) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
    builder.addCase(getAllInstallationCosts.pending, (state, action) => {
      state.list.pending = true;
      state.list.error = false;
    });

    // CREATE Installation Cost
    builder.addCase(createInstallationCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createInstallationCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(createInstallationCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });

    // UPATE Installation Cost
    builder.addCase(updateInstallationCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(updateInstallationCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(updateInstallationCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });
  },
});

export default InstallationCostSlice.reducer;
