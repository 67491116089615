import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Projects from './components/Projects';
import NewProject from './components/Projects/NewProject';
import { setCurrentUser } from './store/UserSlice';
import { AppDispatch } from './store';
import Users from './components/Users/index';
import NewUser from './components/Users/NewUser';
import CircuitSpecifications from './components/Products/CircuitSpecs/CircuitSpecifications';
import ViewCircuitSpecifications from './components/Products/CircuitSpecs/ViewCircuitSpecifications';
import CreateCircuitSpecifications from './components/Products/CircuitSpecs/CreateCircuitSpecifications';
import Inverters from './components/Products/Inverters';
import InverterElectricalAccessories from './components/Products/Inverters/InverterElectricalAccessories';
import InverterCables from './components/Products/Inverters/InverterCables';
import PVModules from './components/Products/PVModules';
import InstallationCostsTable from './components/Financials/InstallationCost/InstallationCostTable';
import TransportCostsTable from './components/Financials/TransportCost/TransportCostTable';
import HardwareAccessories from './components/Financials/Accessories/HardwareAccessories';
import ElectricalAccessories from './components/Financials/Accessories/ElectricalAccessories';
import Cables from './components/Financials/Accessories/Cables';
import Railings from './components/Financials/Accessories/Railings';
import InverterHardwareAccessories from './components/Products/Inverters/InverterHardwareAccessories';
import PVModuleRailings from './components/Products/PVModules/PVModuleRailings';
import Customers from './components/Customers';
import NewCustomer from './components/Customers/NewCustomer';
import ViewProject from './components/Projects/ViewProject';
import Dashboard from './components/Dashboard';
import ViewCustomer from './components/Customers/ViewCustomer';
import Utilities from './components/Utilities/index';
import NewUtility from './components/Utilities/NewUtility';
import ViewUtility from './components/Utilities/viewUtility';
import ProjectDevices from './components/Projects/Devices/ProjectDevices';
import ViewUser from './components/Users/ViewUser';
import AddInstallationCost from './components/Financials/InstallationCost/AddInstallationCost';
import AddTransportCost from './components/Financials/TransportCost/AddTransportCost';
import AddServiceCost from './components/Financials/ServiceCost/AddServiceCost';
import ServiceCostTable from './components/Financials/ServiceCost/ServiceCostTable';
import AddEditInverter from './components/Products/Inverters/AddEditInverter';
import AddEditPanel from './components/Products/PVModules/AddEditPanel';
import AddEditTariff from './components/TariffTypes/AddEditTariff';
import AddProject from './components/Projects/AddProject';
import TariffTypes from './components/TariffTypes';
import EngineerFeeTable from './components/Financials/EngineerFee/EngineerFeeTable';
import AddEngineerFee from './components/Financials/EngineerFee/AddEngineerFee';
import SalesPipeline from './components/Dashboard/KanbanBoards/SalesPipeline';

const Layout = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token') || '';

    dispatch(setCurrentUser(token));
  }, [dispatch]);
  return (
    <div className='h-screen overflow-hidden'>
      <Header />
      <div className='flex'>
        <NavBar />
        <Routes>
          <Route path='dashboard/analytics' element={<Dashboard />} />
          <Route path='dashboard/sales-pipeline' element={<SalesPipeline />} />
          {/* <Route path='dashboard/project-status' element={<Utilities />} /> */}
          <Route path='customers' element={<Customers />} />
          <Route path='customers/new-customer' element={<NewCustomer />} />
          <Route path='customers/view-customer/:id' element={<ViewCustomer />} />

          <Route path='projects' element={<Projects />} />
          <Route path='users' element={<Users />} />
          <Route path='users/new-user' element={<NewUser />} />
          <Route path='users/view-user/:id' element={<ViewUser />} />

          <Route path='projects/new-project' element={<NewProject />} />
          <Route path='projects/:id' element={<ViewProject />} />
          <Route path='projects/:id/devices' element={<ProjectDevices />} />

          <Route path='/inverters' element={<Inverters />} />
          <Route path='inverters/add' element={<AddEditInverter />} />
          <Route path='inverters/:id' element={<AddEditInverter />} />

          <Route path='projects/add' element={<AddProject />} />
          <Route path='inverter/hardware-accessories/:id' element={<InverterHardwareAccessories />} />
          <Route path='inverter/electrical-accessories/:id' element={<InverterElectricalAccessories />} />
          <Route path='inverter/cables/:id' element={<InverterCables />} />

          <Route path='panels/add' element={<AddEditPanel />} />
          <Route path='panels/:id' element={<AddEditPanel />} />
          <Route path='panels' element={<PVModules />} />
          <Route path='panels/railings/:id' element={<PVModuleRailings />} />

          <Route path='products/circuit-specifications' element={<CircuitSpecifications />} />
          <Route path='products/circuit-specifications/:id' element={<ViewCircuitSpecifications />} />
          <Route path='products/circuit-specifications/create-circuit-specifications' element={<CreateCircuitSpecifications />} />

          <Route path='utilities' element={<Utilities />} />
          <Route path='utilities/view-utility/:id' element={<ViewUtility />} />
          <Route path='utilities/new-utility' element={<NewUtility />} />

          <Route path='tariff-types' element={<TariffTypes />} />
          <Route path='tariff-types/add' element={<AddEditTariff />} />
          <Route path='tariff-types/:id' element={<AddEditTariff />} />

          <Route path='financials/hardware-accessories' element={<HardwareAccessories />} />
          <Route path='financials/electrical-accessories' element={<ElectricalAccessories />} />
          <Route path='financials/cables' element={<Cables />} />
          <Route path='financials/railings' element={<Railings />} />
          <Route path='financials/installation-cost' element={<InstallationCostsTable />} />
          <Route path='financials/add-installation-cost' element={<AddInstallationCost />} />
          <Route path='financials/transport-cost' element={<TransportCostsTable />} />
          <Route path='financials/add-transport-cost' element={<AddTransportCost />} />
          <Route path='financials/engineer-fee' element={<EngineerFeeTable />} />
          <Route path='financials/add-engineer-fee' element={<AddEngineerFee />} />
          <Route path='financials/service-cost' element={<ServiceCostTable />} />
          <Route path='financials/add-service-cost' element={<AddServiceCost />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
