import { Button, Modal, Tooltip } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { ButtonCustom } from '../../Buttons';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { createInvoice } from '../../../store/ProjectsSlice';
import { FaFilePdf } from 'react-icons/fa';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from './Invoice';
import OptionsDropdown from '../../OptionsDropdown';

type InvoiceListProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  invoices: any[];
  projectId: string;
};

const InvoiceListModal: React.FC<InvoiceListProps> = ({ open = true, setOpen, invoices, projectId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const generateInvoice = async () => {
    setLoading(true);
    await dispatch(createInvoice({ id: projectId }));
    setLoading(false);
  };

  const handleOptionClick = (option: string) => {
    if (option === 'New Invoice') {
      generateInvoice();
    }
  };

  return (
    <Modal open={open} className='flex items-center justify-center' onClose={() => setOpen(false)}>
      <div className='bg-white px-8 py-5 rounded-lg w-1/2'>
        <div className='flex justify-between items-center'>
          {invoices && invoices.length > 0 ? (
            <>
              <h3 className='font-semibold text-xl mx-auto text-orange-500'>Invoice List</h3>
              <OptionsDropdown onOptionClick={handleOptionClick} options={['New Invoice']} />
            </>
          ) : (
            <>
              <p className='font-semibold text-base text-red-500'>
                No Invoice found. <br /> Please generate a new Invoice
              </p>
              <ButtonCustom
                py='py-3'
                px='px-3'
                customStyles='bg-green-500 ml-auto text-lg'
                iconStyles='text-lg'
                onClick={() => generateInvoice()}
                disabled={loading}
              >
                Generate New Invoice
              </ButtonCustom>
            </>
          )}
        </div>
        {invoices && invoices.length > 0 && (
          <table className='divide-y my-2 w-full bg-orange-50'>
            <thead className='divide-x text-gray-500'>
              <tr>
                <th className='p-2'>Invoice No</th>
                <th className='p-2'>Date Created</th>
                <th className='p-2'>Download</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr className='divide-x' key={invoice.id}>
                  <td className='p-2 text-center'>{invoice.invoice_number}</td>
                  <td className='p-2 text-center'>{invoice.created_at?.split('T')[0]}</td>
                  <td className='p-2 text-center'>
                    <PDFDownloadLink document={<Invoice invoice={invoice} customer={invoice.customer}/>} fileName={`Invoice`}>
                      {({ loading }) => (
                        <Tooltip title='Invoice'>
                          <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                            Invoice
                          </Button>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default InvoiceListModal;
