import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsList } from "../../store/ProjectsSlice";
import { Skeleton } from "@mui/material";
import { Project } from "../../store/types/ProjectTypes";
import Map from "../Map";
import WidgetTitle from "./WidgetTitle";

const ProjectMap = () => {
    const dispatch: AppDispatch = useDispatch();
    const projectsList = useSelector((state: RootState) => state.project.list);

    const [locations, setLocations] = useState<{ lat: string, lon: string }[]>([]);

    useEffect(() => {
        dispatch(getProjectsList({}));
    }, [dispatch]);

    useEffect(() => {
        if (projectsList.data?.length) {
            const newLocations = projectsList.data.map((proj: Project) =>
                proj.coordinates);
            setLocations(newLocations);
        }
    }, [projectsList]);

    return (
        <div className="rounded p-8">
            <WidgetTitle title="Projects Map" />

            <div className='w-[100%] h-[500px]'>
                {projectsList.pending ? (
                    <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
                ) : locations.length ? (
                    <Map
                        locations={locations}
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                        zoom={7.3}
                        mapTypeId='roadmap'
                        center={{ lat: 7.797527, lng: 80.662000 }}
                    />
                ) : (
                    <div className={`flex justify-center items-center w-[100%] h-[100%] font-semibold text-slate-600`}>
                        No projects to display
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectMap;
