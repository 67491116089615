import React, { Dispatch, SetStateAction } from 'react';
import { Modal, Paper } from '@mui/material';
import { DiscountType } from '../../utils/enums';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select, Input } from '../FormComponents';
import { Button } from '../Buttons';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiscount } from '../../store/ProjectsSlice';
import { Project } from '../../store/types/ProjectTypes';
import { toast } from 'react-toastify';

const modalStyle = {
  display: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
};

const paperStyle = {
  position: 'absolute',
  width: 'auto',
  top: '50vh',
  left: '50vw',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
};

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  project: Project;
  handleDiscountSave: (e: any) => void;
};

interface DiscountUpdatePayload {
  discount_type: DiscountType;
  discount: number;
  project_id: string;
}

const discountSchema = yup.object({
  project_id: yup.string().required('Invalid project ID'),
  discount_type: yup.string().oneOf(Object.values(DiscountType)).required('Discount type is required.'),
  discount: yup.number().required('Discount value is required').moreThan(-1, 'Discount must be positive'),
});

const DiscountModal: React.FC<Props> = ({ showModal, setShowModal, project, handleDiscountSave }) => {
  const discount = useSelector((state: RootState) => state?.project?.details?.data?.project_finance?.discount);
  const discountType = useSelector((state: RootState) => state?.project?.details?.data?.project_finance?.discount_type);

  const dispatch: AppDispatch = useDispatch();

  const options = Object.entries(DiscountType).map(([key, value]) => ({
    label: value,
    value: value,
  }));

  const methods = useForm<DiscountUpdatePayload>({
    resolver: yupResolver(discountSchema),
    defaultValues: {
      discount_type: discountType || DiscountType.PERCENTAGE,
      discount: discount || 0,
      project_id: project?.id,
    },
  });

  const onSave: SubmitHandler<Partial<DiscountUpdatePayload>> = (data) => {
    dispatch(updateDiscount(data))
      .unwrap()
      .then(() => toast.success('Discount Updated Successfully'))
      .catch(() => toast.success('Discount Update failed'));

    handleDiscountSave(data.discount);
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)} style={modalStyle}>
      <Paper sx={paperStyle} className='relative'>
        <FormProvider {...methods}>
          <h2 className='text-lg font-bold'>Edit Discount</h2>
          <form onSubmit={methods.handleSubmit(onSave)}>
            <div className='grid grid-cols-2 gap-4 mt-5'>
              <Select name='discount_type' label='Discount Type' options={options} />
              <Input name='discount' label='Discount Value' type='number' />
            </div>

            <div className='grid grid-cols-2 gap-4 mt-5'>
              <Button text='Cancel' onClick={() => setShowModal(false)} colorType={'danger'} type={'button'} />
              <Button text='Save' type='submit' colorType='success' />
            </div>
          </form>
        </FormProvider>
      </Paper>
    </Modal>
  );
};

export default DiscountModal;
