import axios from 'axios';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';

import { Project } from '../../store/types/ProjectTypes';

import OpenSans from '../../fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../../fonts/OpenSans-Semibold.ttf';
import OpenSansBold from '../../fonts/OpenSans-Bold.ttf';
import OpenSansSemiBoldItalic from '../../fonts/OpenSans-SemiboldItalic.ttf';

import page1 from '../../images/documents/hand-over-doc/page1.png';
import page2 from '../../images/documents/hand-over-doc/page2.png';
import page3 from '../../images/documents/hand-over-doc/page3.png';
import page4 from '../../images/documents/hand-over-doc/page4.png';
import page5 from '../../images/documents/hand-over-doc/page5.png';
import page6 from '../../images/documents/hand-over-doc/page6.png';
import page7 from '../../images/documents/hand-over-doc/page7.png';
import page8 from '../../images/documents/hand-over-doc/page8.png';
import page9 from '../../images/documents/hand-over-doc/page9.png';
import page10 from '../../images/documents/hand-over-doc/page10.png';
import page11 from '../../images/documents/hand-over-doc/page11.png';
import page12 from '../../images/documents/hand-over-doc/page12.png';
import page13 from '../../images/documents/hand-over-doc/page13.png';
import page14 from '../../images/documents/hand-over-doc/page14.png';
import page15 from '../../images/documents/hand-over-doc/page15.png';
import page16 from '../../images/documents/hand-over-doc/page16.png';
import page17 from '../../images/documents/hand-over-doc/page17.png';
import page18 from '../../images/documents/hand-over-doc/page18.png';
import page19 from '../../images/documents/hand-over-doc/page19.png';
import page20 from '../../images/documents/hand-over-doc/page20.png';
import page21 from '../../images/documents/hand-over-doc/page21.png';

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans,
    },
    {
      src: OpenSansSemiBold,
    },
    {
      src: OpenSansBold,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
  },

  page_view: {
    marginLeft: 90,
    marginRight: 50,
    fontFamily: 'OpenSans',
  },
  page_image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
    color: 'black',
    zIndex: -1,
  },

  // page 1
  owner_name: {
    position: 'absolute',
    top: 151,
    left: 250,
    textTransform: 'capitalize',
  },

  owner_address: {
    position: 'absolute',
    top: 172,
    left: 250,
    textTransform: 'capitalize',
  },

  panel_capacity: { position: 'absolute', top: 193, left: 250 },

  inv_capacity: {
    position: 'absolute',
    top: 214,
    left: 250,
  },

  acc_num: {
    position: 'absolute',
    top: 235,
    left: 250,
  },

  utility_connection: { position: 'absolute', top: 256, left: 250 },

  interconnected_date: {
    position: 'absolute',
    top: 277,
    left: 250,
  },

  // page 6
  solar_modules_wattage: {
    position: 'absolute',
    top: 283,
    right: 230,
  },
  solar_modules_voltage: {
    position: 'absolute',
    top: 298,
    right: 230,
  },
  num_of_modules: {
    position: 'absolute',
    top: 313,
    right: 230,
  },
  num_of_strings: {
    position: 'absolute',
    top: 328,
    right: 230,
  },

  inverter_capacity: {
    position: 'absolute',
    top: 343,
    right: 230,
  },

  max_in_power_dc: {
    position: 'absolute',
    top: 358,
    right: 230,
  },

  max_in_voltage_dc: {
    position: 'absolute',
    top: 373,
    right: 230,
  },

  mppt_voltage_range: {
    position: 'absolute',
    top: 387,
    right: 230,
  },

  startup_voltage: {
    position: 'absolute',
    top: 402,
    right: 230,
  },

  max_in_current: {
    position: 'absolute',
    top: 416,
    right: 230,
  },

  mppt_by_max_in_strings: {
    position: 'absolute',
    top: 433,
    right: 230,
  },

  // Installation Images

  installation_image_1: {
    position: 'absolute',
    top: 120,
    left: 90,
    height: 250,
  },

  installation_image_2: {
    position: 'absolute',
    top: 420,
    left: 90,
    height: 250,
  },

  installation_image_3: {
    position: 'absolute',
    top: 100,
    left: 90,
    height: 250,
  },
});

const HandOverDocument = ({ project }: Project) => {
  const installationDate = new Date(project?.actual_installation_date)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  const inverterCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  const panelCapacity = (
    project?.project_panels.length * project?.project_panels[0].panel.peak_power
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page1} style={styles.page_image} />

          <Text style={styles.owner_name}>
            {project?.customer.title} {project?.customer.first_name}{' '}
            {project?.customer.last_name}
          </Text>

          <Text style={styles.owner_address}>
            {project?.customer.house_no} {project?.customer.street}{' '}
            {project?.customer.city}
          </Text>

          <Text style={styles.panel_capacity}>{panelCapacity} W</Text>
          <Text style={styles.inv_capacity}>{inverterCapacity} kW</Text>

          <Text style={styles.acc_num}>{project?.account_no}</Text>

          <Text style={styles.utility_connection}>{project?.solar_scheme}</Text>

          <Text style={styles.interconnected_date}>{installationDate}</Text>
        </View>
      </Page>

      {/* Page 2 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page2} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 3 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page3} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 4 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page4} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 5 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page5} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 6 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page6} style={styles.page_image} />

          <Text style={styles.solar_modules_wattage}>{panelCapacity}</Text>
          <Text style={styles.solar_modules_voltage}>
            {project?.project_panels[0]?.panel.rated_voltage}
          </Text>
          <Text style={styles.num_of_modules}>{project?.no_of_panels}</Text>
          <Text style={styles.num_of_strings}>
            {project?.project_inverters[0]?.inverter.install_strings}
          </Text>
          <Text style={styles.inverter_capacity}>{inverterCapacity}</Text>
          <Text style={styles.max_in_power_dc}>
            {(
              project?.project_inverters[0]?.inverter.max_in_power_dc / 1000
            ).toFixed(1)}
          </Text>
          <Text style={styles.max_in_voltage_dc}>
            {project?.project_inverters[0]?.inverter.max_in_voltage_dc}
          </Text>
          <Text style={styles.mppt_voltage_range}>
            {project?.project_inverters[0]?.inverter.mppt_voltage_range}
          </Text>
          <Text style={styles.startup_voltage}>
            {project?.project_inverters[0]?.inverter.start_up_voltage}
          </Text>
          <Text style={styles.max_in_current}>
            {project?.project_inverters[0]?.inverter.max_in_current_dc}
          </Text>
          <Text style={styles.mppt_by_max_in_strings}>
            {project?.project_inverters[0]?.inverter.mppt_input_count}/
            {project?.project_inverters[0]?.inverter.install_strings}
          </Text>
        </View>
      </Page>

      {/* Page 7 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page7} style={styles.page_image} />

          <View>
            {project?.installation_images?.length > 0 && (
              <Image
                src={project?.installation_images[0]}
                style={styles.installation_image_1}
              />
            )}

            {project?.installation_images?.length > 1 && (
              <Image
                src={project?.installation_images[1]}
                style={styles.installation_image_2}
              />
            )}
          </View>
        </View>
      </Page>

      {/* Page 8 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page8} style={styles.page_image} />

          <View>
            {project?.installation_images?.length > 2 && (
              <Image
                src={project?.installation_images[2]}
                style={styles.installation_image_3}
              />
            )}
          </View>
        </View>
      </Page>

      {/* Page 9 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page9} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 10 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page10} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 11 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page11} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 12 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page12} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 13 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page13} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 14 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page14} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 15 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page15} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 16 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page16} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 17 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page17} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 18 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page18} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 19 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page19} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 20 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page20} style={styles.page_image} />
        </View>
      </Page>

      {/* Page 21 */}
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page21} style={styles.page_image} />
        </View>
      </Page>
    </Document>
  );
};

export default HandOverDocument;
