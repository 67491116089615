import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getOngoingProjectsList } from '../../store/ProjectsSlice';
import { projecProgress } from '../../utils/constants';
import Skeleton from '@mui/material/Skeleton';
import WidgetTitle from './WidgetTitle';

const OngoingProjects = () => {
    const dispatch: AppDispatch = useDispatch();
    const projectList = useSelector((state: RootState) => state.project.ongoingList);

    useEffect(() => {
        dispatch(getOngoingProjectsList());
    }, [dispatch]);

    useEffect(() => {
        if (projectList.data.length > 0 && projectList.data[0].project_task) {
            setChartOptions(prevOptions => ({
                ...prevOptions,
                series: [
                    {
                        name: 'Ongoing Projects',
                        data: projectList.data.map(proj => {
                            const tasksLength = proj.project_task.length;
                            return projecProgress[tasksLength - 1].percent;
                        })
                    },
                ],
                xaxis: {
                    categories: projectList.data.map(proj => {
                        const { first_name, last_name, city } = proj.customer;
                        return `${first_name} ${last_name}, ${city}`;
                    }),
                },
                colors: projectList.data.map(proj => {
                    const lastTaskIdx = proj.project_task.length - 1;
                    const isOverDue = new Date(proj.project_task[lastTaskIdx].due_date) < new Date();
                    return isOverDue ? '#fa4443d9' : '#008ffbd9';
                })
            }))
        };
    }, [projectList]);

    const [chartOptions, setChartOptions] = useState<ApexOptions>({
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
            }
        },
        series: [
            {
                name: 'Ongoing Projects',
                data: []
            },
        ],
        xaxis: {
            categories: [],
        },
        yaxis: { max: 100 },
        legend: { show: false },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                colors: ['black']
            },
            formatter: function (val, opt) {
                return val + '%'
            },
            offsetX: 0,
        },
        tooltip: {
            x: { show: false },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                },
                formatter: function (val, opt) {
                    const task = projecProgress.find(
                        proj => proj.percent === val
                    )?.task
                    return `${task} - ${val}%`;
                }
            }
        }
    });

    return (
        <div className="rounded p-8">
            <WidgetTitle title='Ongoing Projects' />

            <div className='w-[100%] h-[350px]'>
                {projectList.pending ? (
                    <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
                ) : projectList.data.length ? (
                    <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        width={'100%'}
                        height={'100%'}
                    />
                ) : (
                    <div className={`flex justify-center items-center w-[100%] h-[100%] font-semibold text-slate-600`}>
                        No ongoing projects
                    </div>
                )}
            </div>
        </div>
    );
};

export default OngoingProjects;
