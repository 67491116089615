import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAddCircle, MdSearch } from 'react-icons/md';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { getAllUtilities, createUtility, updateUtility, deleteUtility } from '../../store/UtilitySlice';
import { RootState, AppDispatch } from '../../store';
import { Utility } from '../../store/types/UtilityTypes';

import { footerHeight, navbarHeight } from '../../utils/constants';
import Breadcrumbs from '../Breadcrumbs';
import { Link } from 'react-router-dom';
import LoadingSkeleton from '../LoadingSkeleton';

import DeleteModal from '../DeleteModal';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const Utilities = () => {
  const dispatch: AppDispatch = useDispatch();

  // State for managing data and row modes
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState<Utility[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [sortedData, setSortedData] = useState<Utility[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUtilityId, setSelectedUtilityId] = useState(0);

  // Function to fetch utilities list
  useEffect(() => {
    dispatch(getAllUtilities({}));
  }, [dispatch]);

  // Selector to get utilities list from the store
  const utilityList = useSelector((state: RootState) => state.utility.list);

  // Initialize rows when utilityList changes
  useEffect(() => {
    setRows(utilityList.data.filter((utility: any) => utility.status === 'Active'));
  }, [utilityList]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: any) => () => {
    setDeleteModalOpen(true);
    setSelectedUtilityId(id);
  };

  const findUtilityName = (id: GridRowId): string => {
    const utility = rows.find((row) => row.id === id);
    return utility ? utility.name : '';
  };

  const deleteAction = async () => {
    setRows(rows.filter((row) => row.id !== selectedUtilityId));
    await dispatch(deleteUtility(selectedUtilityId));
    setDeleteModalOpen(false);
  };

  const cancelAction = () => {
    setDeleteModalOpen(false);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (updatedRow: GridRowModel) => {
    const updatedUtilityData = {
      id: updatedRow.id,
      cost: updatedRow.cost,
      name: updatedRow.name,
    };

    await dispatch(updateUtility(updatedUtilityData));

    return updatedRow;
  };

  // Define columns for the DataGrid
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      editable: true,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) => {
        return params.row.name.toUpperCase();
      },
    },
    {
      field: 'cost',
      headerName: 'Cost (LKR)',
      flex: 1,
      type: 'number',
      editable: true,
      align: 'right',
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
        ];
      },
    },
  ];

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Function to handle search term change
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle adding a new Utility
  const handleAddNewUtility = async () => {
    const newUtility: Utility = { id: '', name: '', cost: '' };
    await dispatch(createUtility(newUtility));
  };

  useEffect(() => {
    // Sorting logic based on updated_at
    const sorted = [...utilityList.data].sort((a, b) => {
      const dateA: any = a.updated_at ? new Date(a.updated_at) : null;
      const dateB: any = b.updated_at ? new Date(b.updated_at) : null;

      if (dateA && dateB) {
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      // Handle the case where either date is undefined
      return 0;
    });

    // Filtering based on search term
    const filtered = sorted.filter((utility) => utility.name.toLowerCase().includes(searchTerm.toLowerCase()));

    setSortedData(filtered);
  }, [sortOrder, searchTerm, utilityList.data]);

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '',
      name: 'Utilities',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] h-[100vh]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
        <Link to='/utilities/new-utility' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Utility</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>
      <div className='w-full md:w-[95%] h-full border rounded p-5 md:p-10 md:my-10 shadow-lg bg-white overflow-auto'>
        <DataGrid
          processRowUpdate={processRowUpdate}
          rows={rows}
          columns={columns}
          disableColumnSelector
          editMode='row'
          rowModesModel={rowModesModel}
          onRowEditStop={handleRowEditStop}
          onRowModesModelChange={handleRowModesModelChange}
          slotProps={{
            toolbar: { setRowModesModel },
          }}
          hideFooter
          className='font-bold'
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            fontSize: '12px',
            fontWeight: 'bold',
            overflow: 'auto',

            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#CBE6FA',
            },

            '& .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'flex',
              justifyContent: '',
            },

            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: '14px',
              marginX: 'auto',
            },
          }}
        />
        {deleteModalOpen && (
          <DeleteModal deleteAction={deleteAction} cancelAction={cancelAction} type='Utility' name={findUtilityName(selectedUtilityId)} />
        )}

        {!utilityList.error && !utilityList.pending && rows.length === 0 && (
          <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
        )}
        {!utilityList.pending && utilityList.error && (
          <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
        )}

        {utilityList.pending && <LoadingSkeleton numColumns={1} />}
      </div>
    </div>
  );
};

export default Utilities;
