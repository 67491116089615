import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../../utils/constants';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getPanelRailings, updatePanelRailing } from '../../../store/PanelSlice';
import Breadcrumbs from '../../Breadcrumbs';
import LoadingSkeleton from '../../LoadingSkeleton';
import { InputAdornment, TextField } from '@mui/material';
import { MdSearch } from 'react-icons/md';

const PVModuleRailings = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const panelId = params.id;

  const panelData = useSelector((state: RootState) => state.panel.details.data);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [searchTerm, setSearchTerm] = useState('');

  const railingData = useSelector((state: RootState) => state.panel.railingData);

  console.log(panelData);

  // Fetch Inverter hardware accesssories
  useEffect(() => {
    dispatch(getPanelRailings({ id: panelId }));
  }, [dispatch, panelId]);

  function createRows(accessoryData: [any]) {
    if (!railingData) {
      return [];
    }
    const rows = accessoryData.map((acc) => {
      return {
        id: acc.id,
        accessory: acc.railing.name,
        utilization: acc.utilization,
      };
    });

    return rows;
  }

  // Handle search input change
  useEffect(() => {
    if (searchTerm === '') {
      setRows(createRows(railingData.data));
    } else {
      const filteredRows = railingData?.data?.filter((acc: { railing: { name: string } }) =>
        acc.railing.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setRows(createRows(filteredRows));
    }
  }, [searchTerm, railingData.data]);

  // Initialise rows
  useEffect(() => {
    railingData.data.length && setRows(createRows(railingData.data));
  }, [railingData]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (updatedAccessory: GridRowModel) => {
    const updatedAccessoryData = {
      id: updatedAccessory.id,
      utilization: updatedAccessory.utilization,
    };

    const res = await dispatch(updatePanelRailing(updatedAccessoryData)).unwrap();

    const updatedRow = { ...updatedAccessory, isNew: false };
    if (res.data) {
      setRows(rows.map((row) => (row.id === updatedAccessory.id ? updatedRow : row)));
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'accessory',
      headerName: 'Railing/Accessory Name',
      flex: 3,
      editable: false,
      filterable: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'utilization',
      headerName: 'Utilization',
      type: 'number',
      editable: true,
      align: 'right',
      flex: 1,
      sortable: false,
      filterable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [<GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />];
      },
    },
  ];

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/panels',
      name: 'PV Modules',
    },
    {
      link: '',
      name: 'Railings & Accessories',
    },
  ];

  if (panelData) {
    const panelLink = {
      name: `${panelData?.brand} ${panelData?.model} ${panelData?.peak_power}W`,
      link: `/panels/railings/${panelId}`,
    };
    links.splice(1, 0, panelLink);
  }

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] pb-5'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>

      <div className='md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'>
        <div className='flex gap-2 mb-4 justify-between items-end'>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />

          {/* Show number of items */}
          <p className='text-xs text-gray-500'>
            Viewing {rows.length} item{rows.length === 1 ? '' : 's'}
          </p>
        </div>

        {railingData.pending ? (
          <LoadingSkeleton numColumns={1} />
        ) : railingData.error ? (
          <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error fetching Railing Accessories</div>
        ) : (
          railingData.data.length && (
            <DataGrid
              processRowUpdate={processRowUpdate}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnSelector
              editMode='row'
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              slotProps={{
                toolbar: { setRowModesModel },
              }}
              className='font-bold'
              sx={{
                width: '100%',
                display: 'flex',
                fontSize: '12px',
                fontWeight: 'bold',

                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#CBE6FA',
                },

                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  display: 'flex',
                  justifyContent: '',
                },

                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: '14px',
                  marginX: 'auto',
                },
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PVModuleRailings;
