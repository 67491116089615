import React from 'react';
import { Link } from 'react-router-dom';
import { MdArrowRight } from 'react-icons/md';

type LinkItem = {
  link?: string;
  name: string;
};

type Props = {
  links: LinkItem[];
};

export default function Breadcrumbs({ links }: Props) {
  return (
    <div className="flex gap-2 items-center">
      {links.map((link, index) => (
        <div className="flex gap-2 items-center" key={index}>
          {/* Conditional Link */}
          {link.link ? (
            <Link
              to={link.link}
              className="font-bold text-lg py-2 hover:text-orange-500 capitalize"
            >
              {link.name}
            </Link>
          ) : (
            <h1 className="font-bold text-lg py-2 capitalize">{link.name}</h1>
          )}
          {index !== links.length - 1 && (
            <MdArrowRight className="text-gray-200 text-3xl" />
          )}
        </div>
      ))}
    </div>
  );
}
