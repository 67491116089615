import React from 'react';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  const progressPercentage = (currentStep / (totalSteps - 1)) * 100;

  return (
    <div className='w-full h-4 bg-gray-300 rounded-full relative'>
      <div className='h-4 bg-green-500 rounded-full' style={{ width: `${progressPercentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
