import { useState } from 'react';
import SolarProposal from '../Documents/SolarProposal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CEBAnnexure5 from '../Documents/CEBAnnexure5';
import CEBAnnexure6 from '../Documents/CEBAnnexure6';
import CEBNetAccounting from '../Documents/CEBNetAccounting';
import CEBNetMetering from '../Documents/CEBNetMetering';
import CEBNetPlus from '../Documents/CEBNetPlus';
import ClearanceLetterCEB from '../Documents/ClearanceLetterCEB';
import LECOAnnex2 from '../Documents/LECOAnnex2';
import LECOAnnex4 from '../Documents/LECOAnnex4';
import LECOInverterApp from '../Documents/LECOInverterApp';
import LECOAppV2 from '../Documents/LECOAppV2';
import ADBClearanceLetter from './LECOClearanceLetter';
import HandOverDocument from '../Documents/HandOverDocument';
import LECONetMeter from './LecoNetMeter';
import { FaFilePdf } from 'react-icons/fa';
import { Alert, Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import QuotationListModal from './Quotations/QuotationListModal';
import ReceiptListModal from './Receipts/ReceiptListModal';
import InvoiceListModal from './Invoices/InvoiceListModal';
import { ProjectTasks } from '../../utils/enums';
import BOQ from './BOQ';

const Documents = (projectId: any) => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [quotationModalStatus, setQuotationModalStatus] = useState(false);
  const [invoiceModalStatus, setInvoiceModalStatus] = useState(false);
  const [receiptModalStatus, setReceiptModalStatus] = useState(false);

  const project = useSelector((state: RootState) => state?.project?.details?.data);
  const currentUser = useSelector((state: RootState) => state?.user?.login?.logged_user);

  const sendInvoiceAlert = () => {
    setAlert(true);
    setAlertMessage('Please complete the project to download the Invoice!');
  };

  const sendReceiptAlert = () => {
    setAlert(true);
    setAlertMessage('Please complete the project to download the Receipt!');
  };

  const sendHandOverAlert = () => {
    setAlert(true);
    setAlertMessage('Please check the project status ("Handover" or "Complete"), or whether the installation images are added!');
  };

  const removeAlerts = () => {
    setAlert(false);
    setAlertMessage('');
  };

  return (
    <div className='mr-auto'>
      {alert && (
        <Alert sx={{ marginTop: 1, cursor: 'pointer' }} severity='warning' onClick={() => removeAlerts()}>
          {alertMessage}
        </Alert>
      )}
      <div className='w-full flex flex-wrap gap-5 my-5'>
        <PDFDownloadLink document={<SolarProposal project={project} />} fileName={`Solar Proposal`}>
          {({ loading }) => (
            <Tooltip title='Solar Proposal'>
              <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                Solar Prop...
              </Button>
            </Tooltip>
          )}
        </PDFDownloadLink>

        {/* BOQ */}

        <PDFDownloadLink document={<BOQ project={project} currentUser={currentUser} />} fileName={`Bill of Quantity`}>
          {({ loading }) => (
            <Tooltip title='BOQ'>
              <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                BOQ
              </Button>
            </Tooltip>
          )}
        </PDFDownloadLink>

        <Tooltip title='Quotation' onClick={() => setQuotationModalStatus(true)}>
          <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
            Quotation
          </Button>
        </Tooltip>

        {/* {project?.status === 'Invoice' ? ( */}

        <Tooltip title='Invoice' onClick={() => setInvoiceModalStatus(true)}>
          <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
            Invoice
          </Button>
        </Tooltip>
        {/* ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => sendInvoiceAlert()}
            startIcon={<FaFilePdf />}
          >
            Invoice
          </Button>
        )} */}

        {project?.utility.name.toUpperCase().includes('CEB') && (
          <>
            <PDFDownloadLink document={<ClearanceLetterCEB project={project} />} fileName={`Clearance Letter CEB`}>
              {({ loading }) => (
                <Tooltip title='Clearance Letter CEB'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    Clearance Lett...
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            <PDFDownloadLink document={<CEBAnnexure5 project={project} />} fileName={`CEB Annexure 5`}>
              {({ loading }) => (
                <Tooltip title='CEB Annexure 5'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    CEB Annex 5
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            <PDFDownloadLink document={<CEBAnnexure6 project={project} />} fileName={`CEB Annexure 6`}>
              {({ loading }) => (
                <Tooltip title='CEB Annexure 6'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    CEB Annex 6
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            <PDFDownloadLink document={<CEBNetAccounting project={project} />} fileName={`CEB Net Accounting`}>
              {({ loading }) => (
                <Tooltip title='CEB Net Accounting'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    CEB Net Acc,,,
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            <PDFDownloadLink document={<CEBNetMetering project={project} />} fileName={`CEB Net Metering`}>
              {({ loading }) => (
                <Tooltip title='CEB Net Metering'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    CEB Net Met...
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            <PDFDownloadLink document={<CEBNetPlus project={project} />} fileName={`CEB Net Plus`}>
              {({ loading }) => (
                <Tooltip title='CEB Net Plus'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    CEB Net Plus
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>
          </>
        )}

        {project?.utility.name.toUpperCase().includes('LECO') && (
          <>
            {/* LECO Clearance Letter */}
            <PDFDownloadLink document={<ADBClearanceLetter project={project} />} fileName={`LECO Clearance Letter`}>
              {({ loading }) => (
                <Tooltip title='LECO Clearance Letter'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    LECO Clear...
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* LECO Annexure 2 */}
            <PDFDownloadLink document={<LECOAnnex2 project={project} />} fileName={`LECO Annex 2 - IV`}>
              {({ loading }) => (
                <Tooltip title='LECO Annex 2 - IV'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    LECO Annex 2 - IV
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* LECO Annexure 4 */}
            <PDFDownloadLink document={<LECOAnnex4 project={project} />} fileName={`LECO Annexure 4`}>
              {({ loading }) => (
                <Tooltip title='LECO Annexure 4'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    LECO Annex 4
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* Inverter Application */}
            <PDFDownloadLink document={<LECOInverterApp project={project} />} fileName={`Inverter Application`}>
              {({ loading }) => (
                <Tooltip title='Inverter Application'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    Inverter App...
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* LECO App V2 */}
            <PDFDownloadLink document={<LECOAppV2 project={project} />} fileName={`LECO App V2`}>
              {({ loading }) => (
                <Tooltip title='LECO App V2'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    LECO App V2
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* LECO Net Metering */}
            <PDFDownloadLink document={<LECONetMeter project={project} />} fileName={`LECO Net Metering`}>
              {({ loading }) => (
                <Tooltip title='LECO Net Metering'>
                  <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                    LECO Net Met...
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>
          </>
        )}

        {project?.installation_images?.length && (project?.status === ProjectTasks.Handover || project?.status === ProjectTasks.Complete) ? (
          <PDFDownloadLink document={<HandOverDocument project={project} />} fileName={`Hand Over Document`}>
            {({ loading }) => (
              <Tooltip title='Hand Over Document'>
                <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
                  Hand Over Doc...
                </Button>
              </Tooltip>
            )}
          </PDFDownloadLink>
        ) : (
          <Button variant='outlined' size='small' onClick={() => sendHandOverAlert()} startIcon={<FaFilePdf />}>
            Hand Over Doc...
          </Button>
        )}

        <Tooltip title='Receipt' onClick={() => setReceiptModalStatus(true)}>
          <Button variant='contained' size='small' startIcon={<FaFilePdf />}>
            Receipt
          </Button>
        </Tooltip>
      </div>
      <QuotationListModal
        open={quotationModalStatus}
        setOpen={setQuotationModalStatus}
        quotations={
          project?.quotation!.map((q: any) => ({
            ...q,
            customer: project?.customer,
          })) ?? []
        }
        projectId={project?.id}
      />
      <InvoiceListModal
        open={invoiceModalStatus}
        setOpen={setInvoiceModalStatus}
        invoices={
          project?.invoice!.map((q: any) => ({
            ...q,
            customer: project?.customer,
          })) ?? []
        }
        projectId={project?.id}
      />
      <ReceiptListModal
        open={receiptModalStatus}
        setOpen={setReceiptModalStatus}
        invoices={project?.invoice!}
        receipts={project?.receipt ?? []}
        project={project}
      />
    </div>
  );
};

export default Documents;
