import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { footerHeight, navbarHeight } from '../../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { Divider } from '@mui/material';
import { MdAddCircle } from 'react-icons/md';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getCircuitList } from '../../../store/CircuitSlice';
import Breadcrumbs from '../../Breadcrumbs';
import LoadingSkeleton from '../../LoadingSkeleton';

const CircuitSpecifications = () => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getCircuitList({}));
  }, [dispatch]);

  const circuits = useSelector((state: RootState) => state.circuit.list);

  const activeCircuits = circuits.data.filter((circuit: any) => circuit?.status === 'Active');

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '',
      name: 'Circuit Specifications',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] h-[100vh]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
        <Link to='/products/circuit-specifications/create-circuit-specifications' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Circuit Specification</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>

      <Paper
        sx={{
          marginX: 'auto',
          width: { xs: '100%', md: '95%' },
          height: '100vh',
          overflow: 'auto',
          marginY: { xs: '10px', md: '40px' },
          paddingX: { xs: '10px', md: '40px' },
          paddingY: { xs: '10px', md: '30px' },
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            height: '80%',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C7C8CC',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <p className='text-xs text-gray-500 text-right mb-4'>
          Viewing {activeCircuits.length} item
          {circuits.data.length === 1 ? '' : 's'}
        </p>
        <TableContainer
          sx={{
            marginX: 'auto',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-sm font-bold'>Circuit Specification</p>
                </TableCell>
                <TableCell>
                  <p className='text-sm font-bold text-right'></p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeCircuits.map((spec, index) => (
                <TableRow
                  key={index}
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  onClick={() => {
                    navigate(`/products/circuit-specifications/${spec.id}`);
                  }}
                >
                  <TableCell>
                    <p className='text-xs font-bold text-gray-500 capitalize'>{'Spec ' + (index + 1)}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-xs font-bold text-gray-500 capitalize text-right'>View Details</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='my-1 transition-all duration-200'>
          {circuits.pending && <LoadingSkeleton numColumns={1} />}
          {!circuits.error && !circuits.pending && activeCircuits.length === 0 && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
          )}

          {circuits.error && !circuits.pending && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default CircuitSpecifications;
