import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Proposal', 'Clearance', 'Invoice', 'Installation', 'Handover', 'Complete'];

const ProjectProgress = ({ status }: any) => {
  let index = steps.indexOf(status);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={index + 1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProjectProgress;
