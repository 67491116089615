import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState, User } from './types/UserTypes';
import jwt from 'jwt-decode';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const postLogin = createAsyncThunk('login', async (data: User) => {
  const response = await axios.post(`${api}/auth/login`, data);
  const token = response.headers['authorization'];
  return token;
});

export const getAllUsers = createAsyncThunk('users/get', async (): Promise<any> => {
  const response = await axios.get(`${api}/users`);

  return response.data;
});

export const updateUser = createAsyncThunk('users/update', async (data: any, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${api}/auth/update-user/${data.id}`, data, {
      headers: { authorization: localStorage.getItem('authorization') },
    });
    toast.success('User Updated Successfully!');
    return response.data.user;
  } catch (error: any) {
    toast.error(error.response.data.message);
    return rejectWithValue(error.response.data);
  }
});

export const deleteUser = createAsyncThunk('users/delete', async (userId: string | undefined, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${api}/users/${userId}`, {
      headers: { authorization: localStorage.getItem('authorization') },
    });
    toast.success('User Deleted Successfully!');
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
    return rejectWithValue(error.response.data);
  }
});

export const postUser = createAsyncThunk('users/post', async (body: any, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${api}/auth/create-user`, body);
    toast.success('New user added successfully!');
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
    return rejectWithValue(error.response.data);
  }
});

export const getUserTasks = createAsyncThunk('users/tasks/get', async (user_id: string) => {
  const response = await axios.get(`${api}/tasks/${user_id}`);

  return response.data.tasks;
});

export const setCurrentUser = createAsyncThunk('users/set-user', async (token: string) => {
  const user: User = await jwt(token);

  return user;
});

export const getOneUser = createAsyncThunk('users/get-one', async (userId: string | undefined) => {
  const response = await axios.get(`${api}/users/${userId}`);

  return response.data;
});

const initialState: InitialState = {
  delete: {
    success: false,
    error: false,
    message: '',
  },
  post: {
    new_user: {},
    error: false,
    message: '',
    pending: false,
  },
  list: {
    data: [],
    error: false,
    pending: false,
  },
  login: {
    status: false,
    pending: false,
    error: false,
    logged_user: null,
  },
  details: {
    pending: false,
    error: false,
    data: null,
  },
  update: {
    pending: false,
    error: false,
    data: null,
  },
};

const UserSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    resetPostStatus(state) {
      state.post.message = '';
      state.post.error = false;
    },
    resetDelete(state) {
      state.delete.error = false;
      state.delete.message = '';
      state.delete.success = false;
    },
  },
  extraReducers: (builder) => {
    // GET ALL USERS
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.list.pending = true;
      state.list.error = false;
    });

    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });

    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.list.pending = false;
      state.list.error = true;
    });

    // CREATE USERS
    builder.addCase(postUser.pending, (state, action: any) => {
      state.post.error = false;
      state.post.pending = true;
    });
    builder.addCase(postUser.fulfilled, (state, action) => {
      state.post.error = false;
      state.post.new_user = action.payload.new_user;
      state.post.message = action.payload.message;
    });
    builder.addCase(postUser.rejected, (state, action: any) => {
      state.post.error = true;
      state.post.message = action.payload.message;
    });

    // DELETE USERS
    builder.addCase(deleteUser.rejected, (state, action: any) => {
      state.delete.message = action.payload.message;
      state.delete.error = true;
      state.delete.success = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, action: any) => {
      state.delete.message = action.payload.message;
      state.delete.error = false;
      state.delete.success = true;
    });

    // LOGIN USERS
    builder.addCase(postLogin.rejected, (state, action) => {
      state.login.status = false;
      state.login.pending = false;
      state.login.error = true;
    });
    builder.addCase(postLogin.pending, (state) => {
      state.login.status = false;
      state.login.pending = true;
      state.login.error = false;
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      state.login.status = true;
      state.login.pending = false;
      state.login.error = false;
      localStorage.setItem('token', action.payload);
    });

    // GET ONE USER
    builder.addCase(getOneUser.fulfilled, (state, action) => {
      state.details.error = false;
      state.details.pending = false;
      state.details.data = action.payload.data;
    });
    builder.addCase(setCurrentUser.fulfilled, (state, action) => {
      state.login.logged_user = action.payload;
    });

    // Update User
    builder.addCase(updateUser.pending, (state) => {
      state.update.pending = true;
      state.update.error = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.update.pending = false;
      state.update.error = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action: any) => {
      state.update.pending = false;
      state.update.error = true;
    });
  },
});

export const { resetPostStatus, resetDelete } = UserSlice.actions;

export default UserSlice.reducer;
