import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/ElectricalAccessoryTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getElectricalAccessoryList = createAsyncThunk(
  'electrical-accessories/list',
  async (payload: any, thunk) => {
    try {
      const res = await axios.get(api + '/electrical-accessories');

      return res.data;
    } catch (err: any) {
      return thunk.rejectWithValue(err.response.dat);
    }
  }
);

export const createElectricalAccessory = createAsyncThunk(
  'electrical-accessory/create',
  async (payload: any, thunk) => {
    try {
      const res = await axios.post(api + '/electrical-accessories', payload);
      toast.success('Electrical Accessory Cost Created Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateElectricalAccessory = createAsyncThunk(
  'electrical-accessory/update',
  async (payload: any, thunk) => {
    try {
      const res = await axios.put(
        api + '/electrical-accessories/' + payload.id,
        payload
      );
      toast.success('Electrical Accessory Cost Updated Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
};

const ElectricalAccessorySlice = createSlice({
  name: 'ElectricalAccessory',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // List case
    builder.addCase(getElectricalAccessoryList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getElectricalAccessoryList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getElectricalAccessoryList.pending, (state, action) => {
      state.list.data = [];
      state.list.error = false;
      state.list.pending = true;
    });
    // Create case
    builder.addCase(createElectricalAccessory.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
    });
    builder.addCase(createElectricalAccessory.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
    });
    builder.addCase(createElectricalAccessory.pending, (state, action) => {
      state.create.data = null;
      state.create.error = false;
      state.create.pending = true;
    });
    //Update case
    builder.addCase(updateElectricalAccessory.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
    });
    builder.addCase(updateElectricalAccessory.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updateElectricalAccessory.pending, (state, action) => {
      state.update.data = null;
      state.update.error = false;
      state.update.pending = true;
    });
  },
});

export default ElectricalAccessorySlice.reducer;
