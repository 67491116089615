import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '../../../store';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CircuitForm from './CircuitForm';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import {
  getCircuitDetails,
  updateCircuit,
  deleteCircuit,
} from '../../../store/CircuitSlice';
import { Circuit } from '../../../store/types/CircuitTypes';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../Breadcrumbs';
import DeleteModal from '../../DeleteModal';

const filters = {
  search: '',
  page: 0,
  limit: 100,
};

const ViewCircuitSpecifications = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();

  const circuitDetailsState = useSelector(
    (state: RootState) => state.circuit.details
  );
  const circuitUpdateState = useSelector(
    (state: RootState) => state.circuit.update
  );

  const circuitDeleteState = useSelector(
    (state: RootState) => state.circuit.delete
  );

  const [newCircuitData, setNewCircuitData] = useState<
    Circuit | Partial<Circuit> | null
  >(null);

  const circuitId = params.id;

  const circuit = circuitDetailsState.data;

  useEffect(() => {
    fetchAndSetInverter();
  }, []);

  const [editable, setEditable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!editable) {
      return;
    }
    await dispatch(updateCircuit(newCircuitData as Circuit));
    if (!circuitUpdateState.error) {
      setNewCircuitData(null);
      setEditable(false);
      toast.success(circuitUpdateState.message);
      navigate('/products/circuit-specifications');
    }
    toast.error(circuitUpdateState.message);
  };

  const handleInputChange = (data: { fieldName: string; value: string }) => {
    setNewCircuitData({
      ...newCircuitData,
      [data.fieldName]: data.value,
    });
  };

  const fetchAndSetInverter = async () => {
    await dispatch(getCircuitDetails({ id: circuitId }));
  };

  const toggleEditable = () => {
    setEditable(!editable);
    setNewCircuitData({ ...newCircuitData, id: circuitId });
  };

  const navigate = useNavigate();

  const deleteAction = async () => {
    dispatch(deleteCircuit({ id: circuitId }));
    setDeleteModal(false);
    navigate('/products/circuit-specifications');
  };

  const cancelAction = () => {
    setDeleteModal(false);
  };

  const links = [
    {
      link: '/products/circuit-specifications',
      name: 'Circuit Specs',
    },
  ];

  if (circuit) {
    links.push({
      link: '',
      name: editable ? 'Edit Circuit Specs' : 'View Circuit Specs',
    });
  }

  return (
    <div
      className="w-full flex flex-col items-center overflow-y-scroll"
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className="flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm">
        <Breadcrumbs links={links} />
        <div className="flex">
          {editable && (
            <button
              className="flex gap-2 items-center font-bold hover:text-[#FF802F] mr-4 cursor-pointer"
              type="button"
              onClick={() => setDeleteModal(true)}
            >
              <span className="text-sm">Delete</span>{' '}
              <FaTrashAlt className="text-xl" />
            </button>
          )}
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => toggleEditable()}
          >
            {editable ? (
              <div className="flex gap-2 items-center font-bold hover:text-[#FF802F]">
                <span className="text-sm">Cancel</span>{' '}
                <AiOutlineCloseSquare className="text-xl" />
              </div>
            ) : (
              <div className="flex gap-2 items-center font-bold hover:text-[#FF802F]">
                <span className="text-sm">Edit</span>{' '}
                <FaRegEdit className="text-xl" />
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="w-full bg-[#ECECEC]">
        <CircuitForm
          updateSpec={newCircuitData}
          spec={circuit}
          editable={editable}
          onFormSubmit={handleFormSubmit}
          onInputChange={handleInputChange}
        />
        {deleteModal && (
          <DeleteModal
            deleteAction={deleteAction}
            cancelAction={cancelAction}
            type=""
            name={'Circuit Specification'}
          />
        )}
      </div>
    </div>
  );
};

export default ViewCircuitSpecifications;
