import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/CircuitTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getCircuitList = createAsyncThunk(
  'circuit/list',
  async (payload: any, thunk) => {
    try {
      const res = await axios.get(api + '/circuits');

      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getCircuitDetails = createAsyncThunk(
  'circuit/details',
  async (payload: any, thunk) => {
    try {
      const res = await axios.get(api + '/circuits/' + payload.id);

      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createCircuit = createAsyncThunk(
  'circuit/create',
  async (payload: any, thunk) => {
    try {
      const res = await axios.post(api + '/circuits', payload);

      return res.data;
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
        throw new Error(err.response.data.message);
      } else {
        toast.error(err.message);
        throw new Error(err.message);
      }
    }
  }
);

export const updateCircuit = createAsyncThunk(
  'circuit/update',
  async (payload: any, thunk) => {
    try {
      const res = await axios.put(api + '/circuits/' + payload.id, payload);
      toast.success('Circuit updated successfully!');
      return res.data;
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
        throw new Error(err.response.data.message);
      } else {
        toast.error(err.message);
        throw new Error(err.message);
      }
    }
  }
);

export const deleteCircuit = createAsyncThunk(
  'circuit/delete',
  async (payload: any, thunk) => {
    try {
      const res = await axios.put(api + '/circuits/' + payload.id, {
        status: 'Deleted',
      });
      toast.success('Circuit Deleted Successfully!');
      return res.data;
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
        throw new Error(err.response.data.message);
      } else {
        toast.error(err.message);
        throw new Error(err.message);
      }
    }
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
  details: {
    data: null,
    error: false,
    pending: false,
  },
  delete: {
    data: null,
    pending: false,
    error: false,
  },
};

const CircuitSlice = createSlice({
  name: 'Circuit',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // List case
    builder.addCase(getCircuitList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getCircuitList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getCircuitList.pending, (state, action) => {
      state.list.data = [];
      state.list.error = false;
      state.list.pending = true;
    });
    // Details case
    builder.addCase(getCircuitDetails.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.error = false;
      state.details.pending = false;
    });
    builder.addCase(getCircuitDetails.rejected, (state, action) => {
      state.details.data = null;
      state.details.error = true;
      state.details.pending = false;
    });
    builder.addCase(getCircuitDetails.pending, (state, action) => {
      state.details.data = null;
      state.details.error = false;
      state.details.pending = true;
    });
    // Create case
    builder.addCase(createCircuit.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
      state.create.message = action.payload.message;
    });
    builder.addCase(createCircuit.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
      state.create.message = action.error.message;
    });
    builder.addCase(createCircuit.pending, (state, action) => {
      state.create.data = null;
      state.create.error = false;
      state.create.pending = true;
    });
    //Update case
    builder.addCase(updateCircuit.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.details.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
      state.update.message = action.payload.message;
    });
    builder.addCase(updateCircuit.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
      state.update.message = action?.error.message;
    });
    builder.addCase(updateCircuit.pending, (state, action) => {
      state.update.data = null;
      state.update.error = false;
      state.update.pending = true;
    });
    //Delete case
    // builder.addCase(deleteCircuit.fulfilled, (state, action) => {
    //   state.update.data = action.payload.data;
    //   state.details.data = action.payload.data;
    //   state.delete.error = false;
    //   state.delete.pending = false;
    //   state.delete.message = action.payload.message;
    // });
    // builder.addCase(deleteCircuit.rejected, (state, action) => {
    //   state.delete.data = null;
    //   state.delete.error = true;
    //   state.delete.pending = false;
    // });
    // builder.addCase(deleteCircuit.pending, (state, action) => {
    //   state.delete.data = null;
    //   state.delete.error = false;
    //   state.delete.pending = true;
    // });
  },
});

export default CircuitSlice.reducer;
