import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';
import { Link, useParams } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../utils/constants';
import Divider from '@mui/material/Divider';
import AccordionComponent from '../Accordion';
import { getProjectDetails, updateInstallationImage, updateProjectImage } from '../../store/ProjectsSlice';

import Map from '../Map';
import Breadcrumbs from '../Breadcrumbs';
import ServiceRequestModal from './Services/ServiceRequestModal';

import axios from 'axios';
import { ButtonCustom, ButtonOrange } from '../Buttons';

import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { getAllUsers } from '../../store/UserSlice';

import Documents from '../Documents';
import ProjectProgress from './ProjectProgress';
import StatusUpdate from './Modals/StatusUpdate';
import TaskList from './Modals/TaskList';
import { ProjectTasks } from '../../utils/enums';
import DiscountModal from './DiscountModal';

import { toast } from 'react-toastify';

const ViewProject = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();

  const discountValue = useSelector((state: RootState) => state?.project?.details?.data?.project_finance?.discount);
  const discountType = useSelector((state: RootState) => state?.project?.details?.data?.project_finance?.discount_type);

  const [serviceRequestModal, setServiceRequestModal] = useState(false);
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [taskListModal, setTaskListModal] = useState(false);
  const [projectFiles, setProjectFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [discountModal, setDiscountModal] = useState(false);
  const [discount, setDiscount] = useState(discountValue);
  const [uploadLoading, setUploadLoading] = useState({ installationImgs: false, projectImgs: false });

  const handleDiscountSave = (newDiscount: number) => {
    setDiscount(newDiscount);
  };

  useEffect(() => {
    if (discountValue !== undefined) {
      setDiscount(discountValue);
    }
  }, [discountValue]);

  const projectId = params.id;
  const api = process.env.REACT_APP_API;

  const project = useSelector((state: RootState) => state.project?.details.data);

  const projectState = useSelector((state: RootState) => state.project);

  const currentUser = useSelector((state: RootState) => state.user.login.logged_user);

  useEffect(() => {
    const fetchAndSetProject = async () => {
      await dispatch(getProjectDetails({ id: projectId }));
    };
    dispatch(getAllUsers());

    fetchAndSetProject();
  }, [dispatch, projectId]);

  const uploadProjectImages = async () => {
    try {
      setUploadLoading((prev) => ({ ...prev, projectImgs: true }));
      const formData = new FormData();

      for (let i = 0; i < projectFiles.length; i++) {
        formData.append('files', projectFiles[i]);
      }

      const res = await axios.post(`${api}/projects/project-images/${projectId}`, formData);

      dispatch(updateProjectImage(res.data.data.project_images));

      toast.success('Images Uploaded Successfully!');

      setProjectFiles([]);
      setUploadLoading((prev) => ({ ...prev, projectImgs: false }));
    } catch (error) {
      toast.error('Error Uploading Images!');
    }
  };

  const uploadInstallationImages = async () => {
    try {
      setUploadLoading((prev) => ({ ...prev, installationImgs: true }));
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      const res = await axios.post(`${api}/projects/installation-images/${projectId}`, formData);

      dispatch(updateInstallationImage(res.data.data.installation_images));

      toast.success('Images Uploaded Successfully!');

      setFiles([]);
      setUploadLoading((prev) => ({ ...prev, installationImgs: false }));
    } catch (error) {
      toast.error('Error Uploading Images!');
    }
  };

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/projects',
      name: 'Projects',
    },
  ];

  if (project) {
    links.push({
      link: '',
      name: project?.customer.first_name + ' ' + project?.customer.last_name,
    });
  }

  const projectDate = new Date(project?.created_at)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  const ProjectData = () => (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll pb-10'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex  items-center  justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <div className='flex items-center'>
          <div className='flex gap-2 items-center'>
            <Breadcrumbs links={links} />
          </div>
        </div>
        <div className='flex items-center gap-5'>
          <span className='font-bold capitalize'> Type: {project?.project_type.name}</span>{' '}
          <Divider orientation='vertical' sx={{ bgcolor: '', height: '20px', paddingY: '' }} />
          <span className='font-bold'>Date: {projectDate}</span>
        </div>
      </div>
      {/* Project status */}
      <div className='font-bold w-full flex flex-col items-center px-10 py-10'>
        <ProjectProgress status={project?.status} />
      </div>
      {/* Document Download */}
      <div className='font-bold w-full flex flex-col items-center mb-10 px-10 border border-[#CBE6FA]'>
        <p className='mr-auto mt-5'>Downloads</p>
        <Documents project={projectId} />
      </div>
      {/*=========================== Contact Details ===========================*/}
      <div className='w-full flex flex-col md:flex-row px-10 gap-10'>
        <div className='w-full md:w-1/2 flex flex-col gap-10'>
          <AccordionComponent
            title='Contact Details'
            children={
              <>
                <div className='px-5 py-2 flex'>
                  <div className='w-full text-sm font-bold divide-y'>
                    <li className='list-none py-3 w-full flex'>
                      <div className='inline-block w-1/2 text-[#808080]'>Name:</div>
                      <div className='w-1/2'>{`${project?.customer?.title} ${project?.customer?.first_name} ${project?.customer?.last_name}`}</div>
                    </li>
                    <li className='list-none py-3 w-full flex'>
                      <div className='inline-block w-1/2 text-[#808080]'>Address:</div>
                      <div className='w-1/2'>{`${project?.customer?.house_no} ${project?.customer?.street} ${project?.customer?.city}`}</div>
                    </li>
                    <li className='list-none py-3 w-full flex'>
                      <div className='inline-block w-1/2 text-[#808080]'>Phone:</div>
                      <div className='w-1/2'>{project?.customer?.phone}</div>
                    </li>
                    <li className='list-none py-3 w-full flex'>
                      <div className='inline-block w-1/2 text-[#808080]'>Email:</div>
                      <div className='w-1/2'>{project?.customer?.email}</div>
                    </li>
                    <li className='list-none py-3 w-full flex'>
                      <div className='inline-block w-1/2 text-[#808080]'>NIC:</div>
                      <div className='w-1/2'>{project?.customer?.nic}</div>
                    </li>
                  </div>
                </div>
              </>
            }
          />

          {/*=========================== Project Details ===========================*/}

          <AccordionComponent
            title='Project Details'
            children={
              <>
                <div className='px-5 py-2 flex'>
                  <div className='w-full text-sm font-bold divide-y'>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Electricity A/C No:</div>
                      <div className='w-3/5'>{project?.account_no}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Utility:</div>
                      <div className='w-3/5'>{project?.utility?.name}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Planned Installation Date:</div>
                      <div className='w-3/5'>{project?.installation_date?.split('T')[0]}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Actual Installation Date:</div>
                      <div className='w-3/5'>{project?.actual_installation_date?.split('T')[0] ?? 'TBD'}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Utility Branch:</div>
                      <div className='w-3/5'>{project?.utility_branch}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Roof Type:</div>
                      <div className='w-3/5'>{project?.roof_type}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Utility Supply Rating:</div>
                      <div className='w-3/5'>{project?.phase}</div>
                    </li>
                    <li className={`list-none py-2 w-full flex items-center`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Project Status:</div>
                      <div className='w-3/5 flex justify-between gap-2 flex-col lg:flex-row'>
                        <div className='w-full lg:w-1/3'>
                          <p className='w-max py-1 px-2 bg-green-200'>{project?.status}</p>
                        </div>
                        {project?.status !== ProjectTasks.Complete && (
                          <div className='w-full lg:w-2/3 flex items-center gap-2'>
                            <p className='text-gray-600 whitespace-nowrap'>
                              <span>Due: </span>
                              {project?.project_task[0]?.due_date?.split('T')[0]}
                            </p>
                            <ButtonCustom
                              onClick={() => setStatusUpdateModal(true)}
                              py='py-1'
                              customStyles='bg-green-500 ml-auto'
                              px='px-2'
                              customIcon={<FaArrowRight />}
                              iconStyles='text-lg'
                            ></ButtonCustom>
                          </div>
                        )}
                      </div>
                    </li>
                    <li className={`list-none py-2 w-full flex items-center`}>
                      <div className='inline-block w-2/5 font-bold text-[#808080]'>Handled By:</div>
                      <div className='w-3/5 flex justify-between'>
                        <p className='rounded py-1 px-2 bg-blue-200 w-max'>{project?.project_task[0]?.user.username}</p>
                        <ButtonOrange onClick={() => setTaskListModal(true)} py='py-1'>
                          Details
                        </ButtonOrange>
                      </div>
                    </li>
                  </div>
                </div>
              </>
            }
          />
        </div>

        <div className='w-full md:w-1/2 flex flex-col gap-10'>
          {/*=========================== System Summary ===========================*/}

          <AccordionComponent
            title='System Summary'
            children={
              <>
                <div className='px-5 py-2 flex'>
                  <div className='w-full text-sm font-bold divide-y'>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Inverter Models :</div>
                      <div className='w-1/2'>
                        {project?.project_inverters?.map((inverter: any, idx: number) => (
                          <span key={idx}>{`${inverter?.inverter?.model} ${project?.project_inverters.length > 1 ? ',' : ''}`}</span>
                        ))}
                      </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>No. of Inverters :</div>
                      <div className='w-1/2'>{project?.project_inverters.length}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>PV Module :</div>
                      <div className='w-1/2'>{project?.project_panels[0].panel?.model}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>No. of PV Modules :</div>
                      <div className='w-1/2'>{project?.project_panels?.length}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Details :</div>
                      <Link to='devices' className='w-1/2'>
                        <ButtonOrange py='py-1'>View</ButtonOrange>
                      </Link>
                    </li>
                  </div>
                </div>
              </>
            }
          />

          {/*=========================== Location ===========================*/}

          <AccordionComponent
            title='Location'
            children={
              <>
                <div className='px-2 py-2 flex'>
                  <div className='w-full h-full'>
                    {project && (
                      <Map
                        locations={[project.coordinates]}
                        mapContainerStyle={{
                          height: '365px',
                          width: '100%',
                        }}
                        zoom={22}
                        mapTypeId='satellite'
                      />
                    )}

                    <div className='flex gap-5 mt-3'>
                      <span>
                        Latitude :<span className='font-bold text-[#808080]'>{project?.coordinates?.lat}</span>
                      </span>
                      <span>
                        Longitude :<span className='font-bold text-[#808080]'>{project?.coordinates?.lon}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {/*=========================== Images ===========================*/}
      <div className='w-full flex px-10 my-10'>
        <div className='w-1/2 mr-5'>
          <AccordionComponent
            title='Project Images'
            children={
              <>
                {project?.project_images?.length && (
                  <div className='flex mb-2'>
                    {project?.project_images.map((image: string, index: number) => (
                      <img key={index} className='object-cover w-24 mr-2' src={image} alt='x' />
                    ))}
                  </div>
                )}
                {projectFiles.map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
                <div className='flex justify-between items-center'>
                  <input
                    type='file'
                    multiple
                    name='images'
                    className='border p-2 mr-2 w-full'
                    onChange={(e) => {
                      const fileArr = e.target.files;
                      setProjectFiles(fileArr ? Array.from(fileArr) : []);
                    }}
                  />
                  <ButtonCustom
                    disabled={projectFiles.length === 0 || uploadLoading.projectImgs}
                    isLoading={uploadLoading.projectImgs}
                    onClick={uploadProjectImages}
                  >
                    Upload
                  </ButtonCustom>
                </div>
              </>
            }
          />
        </div>
        <div className='w-1/2 ml-5'>
          <AccordionComponent
            title='Installation Images'
            children={
              <>
                {project?.installation_images?.length && (
                  <div className='flex mb-2'>
                    {project?.installation_images.map((image: string, idx: number) => (
                      <img key={idx} className='object-cover w-24 mr-2' src={image} alt='Installation' />
                    ))}
                  </div>
                )}
                {files.map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
                <div className='flex justify-between items-center'>
                  <input
                    type='file'
                    multiple
                    name='images'
                    className='border p-2 mr-2 w-full'
                    onChange={(e) => {
                      const fileArr = e.target.files;
                      setFiles(fileArr ? Array.from(fileArr) : []);
                    }}
                  />
                  <ButtonCustom
                    disabled={files.length === 0 || uploadLoading.installationImgs}
                    isLoading={uploadLoading.installationImgs}
                    onClick={uploadInstallationImages}
                  >
                    Upload
                  </ButtonCustom>
                </div>
              </>
            }
          />
        </div>
      </div>
      {/*=========================== Inverter Specifications ===========================*/}
      <div className='w-full flex flex-col px-10 mb-10'>
        {project?.project_inverters?.map((inv: any, idx: number) => (
          <AccordionComponent
            key={idx}
            title={`Inverter ${project?.project_inverters.length > 1 ? idx + 1 : ''} Specifications`}
            children={
              <>
                <div key={idx} className='w-full overflow-x-auto'>
                  <table className='my-3 w-full whitespace-nowrap font-bold text-sm'>
                    <thead className='text-[#969696]'>
                      <tr className='text-center divide-x'>
                        <td className='px-10'>Inverter Brand</td>
                        <td className='px-10'>Model No.</td>
                        <td className='px-10'>Country of Origin</td>
                        <td className='px-10'>Rated Output Voltage (AC)</td>
                        <td className='px-10'>Rated Input Voltage (DC)</td>
                        <td className='px-10'>Rated Power Output</td>
                        <td className='px-10'>Max Input Voltage (DC)</td>
                        <td className='px-10'>Max Input Current (DC)</td>
                        <td className='px-10'>Max Output Voltage (AC)</td>
                        <td className='px-10'>Max Output Current (AC)</td>
                        <td className='px-10'>Rated Power Frequency</td>
                        <td className='px-10'>Max Efficiency</td>
                        <td className='px-10'>Operating Temperature</td>
                        <td className='px-10'>Degree of Protection</td>
                        <td className='px-10'>Install String Number</td>
                        <td className='px-10'>Recommended AC Cable</td>
                        <td className='px-10'>Inverter Phase</td>
                        <td className='px-10'>Inverter Warranty</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-center divide-x'>
                        <td className='px-10'>{inv?.inverter?.brand}</td>
                        <td className='px-10'>{inv?.inverter?.model}</td>
                        <td className='px-10'>{inv?.inverter?.origin_country}</td>
                        <td className='px-10'>{inv?.inverter?.rated_out_voltage_ac} V</td>
                        <td className='px-10'>{inv?.inverter?.rated_in_voltage_dc} V</td>
                        <td className='px-10'>{inv?.inverter?.rated_power_output} W</td>
                        <td className='px-10'>{inv?.inverter?.max_in_voltage_dc} V</td>
                        <td className='px-10'>{inv?.inverter?.max_in_current_dc} A</td>
                        <td className='px-10'>{inv?.inverter?.max_out_voltage_ac} V</td>
                        <td className='px-10 border-r'>{inv?.inverter?.max_out_current_ac} A</td>
                        <td className='px-10'>{inv?.inverter?.rated_power_frequency} Hz</td>
                        <td className='px-10'>{inv?.inverter?.max_efficiency} %</td>
                        <td className='px-10'>{inv?.inverter?.operating_temperature}</td>
                        <td className='px-10'>{inv?.inverter?.degree_of_protection}</td>
                        <td className='px-10'>{inv?.inverter?.install_strings}</td>
                        <td className='px-10'>{inv?.inverter?.ac_cables} mm</td>
                        <td className='px-10'>{inv?.inverter?.phase}</td>
                        <td className='px-10'>{inv?.inverter?.warranty} Years</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            }
          />
        ))}
      </div>
      {/*=========================== PV Module Specifications ===========================*/}
      <div className='w-full flex flex-col px-10 mb-10'>
        {project?.project_panels.length > 0 && (
          <AccordionComponent
            title='PV Module Specifications'
            children={
              <>
                <div className='w-full overflow-x-scroll'>
                  <table className='my-2 w-full whitespace-nowrap font-bold text-sm'>
                    <thead className='text-[#969696]'>
                      <tr className='text-center divide-x'>
                        <td className='px-10 mb-2'>PV Module Brand</td>
                        <td className='px-10'>Model No.</td>
                        <td className='px-10'>Country of Origin</td>
                        <td className='px-10'>No. of Panels</td>
                        <td className='px-10'>Peak Power</td>
                        <td className='px-10'>PV Efficiency</td>
                        <td className='px-10'>Cell Type</td>
                        <td className='px-10'>Open Circuit Voltage</td>
                        <td className='px-10'>Rated Voltage (Vmpp)</td>
                        <td className='px-10'>Short Circuit Current (DC)</td>
                        <td className='px-10'>Rated Current (Impp)</td>
                        <td className='px-10'>Support Standards</td>
                        <td className='px-10'>Test Reports</td>
                        <td className='px-10'>Average Daily Unit project_production</td>
                        {/* <td className="px-10">
                          First Year Unit project_production
                        </td>
                        <td className="px-10">
                          Forecasted 20 Year project_production
                        </td> */}
                        <td className='px-10'>Product Warranty</td>
                        <td className='px-10'>Panel Performance Guarantee</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-center divide-x'>
                        <td className='px-10'>{project?.project_panels[0].panel?.brand}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.model}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.origin_country}</td>
                        <td className='px-10'>{project?.no_of_panels}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.peak_power} W</td>

                        <td className='px-10'>{project?.project_panels[0].panel?.efficiency + '%'}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.cell_type}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.open_circuit_voltage} V</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.rated_voltage} V</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.short_circuit_current} A</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.rated_current} A</td>

                        <td className='px-10'>{project?.project_panels[0].panel?.support_standards}</td>

                        <td className='px-10'>{project?.project_panels[0].panel?.test_reports}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.avg_daily_project_production} kWh</td>

                        {/* <td className="px-10">
                          {
                            project?.project_panels[0].panel
                              ?.first_year_project_production
                          }{' '}
                          kWh
                        </td>
                        <td className="px-10">
                          {project?.project_panels[0].panel?.forecast_20yr} kWh
                        </td> */}

                        <td className='px-10'>{project?.project_panels[0].panel?.warranty}</td>
                        <td className='px-10'>{project?.project_panels[0].panel?.performance_guarantee}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            }
          />
        )}
      </div>
      {/*=========================== Circuit Specifications ===========================*/}
      <div className='w-full flex flex-col px-10 mb-10'>
        <AccordionComponent
          title='Circuit Specifications'
          children={
            <>
              <div className='w-full overflow-x-scroll'>
                <table className='my-2 w-full whitespace-nowrap font-bold text-sm'>
                  <thead className='text-[#969696]'>
                    <tr className='text-center divide-x'>
                      <td className='px-10'>Make & Type of DC Cable:</td>
                      <td className='px-10'>DC Cable Size:</td>
                      <td className='px-10'>DC Cable Rated Voltage:</td>
                      <td className='px-10'>DC Cable Standards:</td>
                      <td className='px-10'>Make & Type of AC Cable:</td>
                      <td className='px-10'>AC Cable Size:</td>
                      <td className='px-10'>AC Cable Rated Voltage:</td>
                      <td className='px-10'>AC Cable Standards:</td>
                      <td className='px-10'>Grounding Cable Rated Voltage:</td>
                      <td className='px-10'>Grounding Cable Standards:</td>
                      <td className='px-10'>Grounding Cable Cross-Section:</td>
                      <td className='px-10'>Make & Type of DC Switch:</td>
                      <td className='px-10'>DC Switch - Voltage & Current Rating:</td>
                      <td className='px-10'>Make & Type of AC Isolator:</td>
                      <td className='px-10'>AC Isolator Voltage & Current Rating:</td>
                      <td className='px-10'>Make & Type of DC Overcurrent Protection:</td>
                      <td className='px-10'>DC Overcurrent Protection - Voltage & Current Rating:</td>
                      <td className='px-10'>Make & Type of AC Overcurrent Protection:</td>
                      <td className='px-10'>AC Overcurrent Protection - Voltage & Current Rating:</td>
                      <td className='px-10'>AC Overcurrent Protection - Uc:</td>
                      <td className='px-10'>AC Overcurrent Protection - Umax:</td>
                      <td className='px-10'>AC Overcurrent Protection - In:</td>
                      <td className='px-10'>AC Overcurrent Protection - Imax:</td>
                      <td className='px-10'>Make & Type of RCD:</td>
                      <td className='px-10'>RCD Rating:</td>
                      <td className='px-10 border-r'>Make & Type of Earthing</td>
                      <td className='px-10 border-r'>Size of Earth Bonding:</td>
                      <td className='px-10 border-r'>Make & Type of DC SPD:</td>
                      <td className='px-10 border-r'>Make & Type of AC SPD:</td>
                      <td className='px-10 border-r'>Make & Model of MCB:</td>
                      <td className='px-10 border-r'>MCB Voltage & Current Rating:</td>
                      <td className='px-10 border-r'>MCB Short Circuit Current:</td>
                      <td className='px-10 border-r'>MCB IP Class:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (DC Bus) - Icn:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (DC Bus) - Iu:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (DC Bus) - Current Rating:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (AC Bus) - Icn:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (AC Bus) - Iu:</td>
                      <td className='px-10 border-r'>Over Current Protective Device (AC Bus) - Current Rating:</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='text-center divide-x'>
                      <td className='px-10'>{project?.circuit?.dc_cable_type}</td>
                      <td className='px-10'>{project?.circuit?.dc_cable_size} mm</td>
                      <td className='px-10'>{project?.circuit?.dc_cable_rated_voltage} V</td>
                      <td className='px-10'>{project?.circuit?.dc_cable_standards}</td>
                      <td className='px-10'>{project?.circuit?.ac_cable_type}</td>
                      <td className='px-10'>{project?.circuit?.ac_cable_size} mm</td>
                      <td className='px-10'>{project?.circuit?.ac_cable_rated_voltage} V</td>
                      <td className='px-10'>{project?.circuit?.ac_cable_standards}</td>
                      <td className='px-10'>{project?.circuit?.grounding_cable_rated_voltage} V</td>
                      <td className='px-10'>{project?.circuit?.grounding_cable_standards}</td>
                      <td className='px-10'>{project?.circuit?.grounding_cable_cross} mm</td>
                      <td className='px-10'>{project?.circuit?.dc_switch_type}</td>
                      <td className='px-10'>{project?.circuit?.dc_switch_voltage_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.ac_isolator_type}</td>
                      <td className='px-10'>{project?.circuit?.ac_isolator_voltage_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.dc_ocp_type}</td>
                      <td className='px-10'>{project?.circuit?.dc_ocp_voltage_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.ocp_type}</td>
                      <td className='px-10'>{project?.circuit?.ocp_voltage_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.ocp_uc} V</td>
                      <td className='px-10'>{project?.circuit?.ocp_umax} kV</td>
                      <td className='px-10'>{project?.circuit?.ocp_in} kA</td>
                      <td className='px-10'>{project?.circuit?.ocp_imax} kA</td>
                      <td className='px-10'>{project?.circuit?.rcd_type}</td>
                      <td className='px-10'>{project?.circuit?.rcd_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.earthing_type}</td>
                      <td className='px-10'>{project?.circuit?.earthing_size} mm</td>
                      <td className='px-10'>{project?.circuit?.dc_spd_type}</td>
                      <td className='px-10'>{project?.circuit?.ac_spd_type}</td>
                      <td className='px-10'>{project?.circuit?.mcb_type}</td>
                      <td className='px-10'>{project?.circuit?.mcb_voltage_current_rating}</td>
                      <td className='px-10'>{project?.circuit?.mcb_short_circuit_current}</td>
                      <td className='px-10'>{project?.circuit?.mcb_ip_class}</td>
                      <td className='px-10'>{project?.circuit?.ocp_dc_icn} kA</td>
                      <td className='px-10'>{project?.circuit?.ocp_dc_iu} kA</td>
                      <td className='px-10'>{project?.circuit?.ocp_dc_current_rating} A</td>
                      <td className='px-10'>{project?.circuit?.ocp_ac_icn} kA</td>
                      <td className='px-10'>{project?.circuit?.ocp_ac_iu} kA</td>
                      <td className='px-10'>{project?.circuit?.ocp_ac_current_rating} A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          }
        />
      </div>
      {/*=========================== Financials ===========================*/}
      <div className='w-full flex flex-col md:flex-row px-10 gap-10'>
        <div className='w-full md:w-1/2 flex flex-col'>
          <AccordionComponent
            title='Financials'
            children={
              <>
                <div className='px-5 py-2 flex'>
                  <div className='w-full text-sm font-bold'>
                    {currentUser?.type === 'admin' && (
                      <>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Inverter Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.inverter.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>PV Module Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.panel.toLocaleString()}</div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Railings & Accessories Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.railings.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>DC & AC Cables Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.cables.toLocaleString()}</div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Electrical Accessories Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.electrical_accessories.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Hardware Accessories Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.hardware_accessories.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Installation Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.installation.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Utility Registration Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.utility?.cost.toLocaleString()}</div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Engineer Fee:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.engineer_fee.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Transport Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.transport.toLocaleString()} </div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Service Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.service.toLocaleString()}</div>
                        </li>
                        <li className={`list-none py-3 w-full flex`}>
                          <div className='inline-block w-1/2 font-bold text-[#808080]'>Total Cost:</div>
                          <div className='w-1/2 text-right'>Rs. {project?.project_finance?.total.toLocaleString()}</div>
                        </li>
                      </>
                    )}
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-2/3 font-bold text-[#808080]'>Discount:</div>

                      <div className='flex w-1/3 items-center text-right justify-end'>
                        <ButtonOrange onClick={() => setDiscountModal(true)} py='py-1' width='w-1/2'>
                          Edit
                        </ButtonOrange>
                        {discountType === 'PERCENTAGE' ? <p className='w-1/2 pl-2'>{discount} %</p> : <p className='w-1/2 pl-2'>Rs. {discount}</p>}
                      </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Selling Price :</div>
                      <div className='w-1/2 text-right'>Rs. {project?.project_finance?.price.toLocaleString()}</div>
                    </li>
                  </div>
                </div>
              </>
            }
          />
        </div>

        {/*=========================== project_production & ROI ===========================*/}
        <div className='w-full md:w-1/2 flex flex-col'>
          <AccordionComponent
            title='Production & ROI'
            children={
              <>
                <div className='px-5 py-2 flex'>
                  <div className='w-full text-sm font-bold'>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Estimated Monthly Production:</div>
                      <div className='w-1/2 text-right'>{project?.project_production?.est_monthly_production.toLocaleString()} kWh</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Monthly Consumption:</div>
                      <div className='w-1/2 text-right'>{project?.monthly_units.toLocaleString()} kWh</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Monthly Consumption:</div>
                      <div className='w-1/2 text-right'>Rs. {project?.monthly_value.toLocaleString()}</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Monthly Saving - Net Metering:</div>
                      <div className='w-1/2 text-right'>Rs. {project?.project_production?.saving_net_metering.toLocaleString()} </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Monthly Saving - Net Accounting:</div>
                      <div className='w-1/2 text-right'>Rs. {project?.project_production?.saving_net_accounting.toLocaleString()} </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Monthly Earning - Net Plus:</div>
                      <div className='w-1/2 text-right'>Rs. {project?.project_production?.earning_net_plus.toLocaleString()} </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>Break-Even Time:</div>
                      <div className='w-1/2 text-right'>
                        {(project?.project_production?.break_even_months / 12).toFixed()} Years ({project?.project_production?.break_even_months}{' '}
                        months)
                      </div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>ROI - Net Metering:</div>
                      <div className='w-1/2 text-right'>{project?.project_production?.roi_net_metering}%</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>ROI - Net Accounting:</div>
                      <div className='w-1/2 text-right'>{project?.project_production?.roi_net_accounting}%</div>
                    </li>
                    <li className={`list-none py-3 w-full flex`}>
                      <div className='inline-block w-1/2 font-bold text-[#808080]'>ROI - Net Plus:</div>
                      <div className='w-1/2 text-right'>{project?.project_production?.roi_net_plus}%</div>
                    </li>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {serviceRequestModal && <ServiceRequestModal cancelAction={() => setServiceRequestModal(false)} />}
      <StatusUpdate showModal={statusUpdateModal} setShowModal={setStatusUpdateModal} project={project} />
      <TaskList showModal={taskListModal} setShowModal={setTaskListModal} project={project} />
      <DiscountModal showModal={discountModal} setShowModal={setDiscountModal} project={project} handleDiscountSave={handleDiscountSave} />
    </div>
  );

  return (
    <>
      {projectState.details.pending ? (
        <div className='absolute left-1/2 top-1/2 -translate-x-1/3 translate-y-1/2 text-orange-600 font-semibold'>
          <div className='flex text-2xl items-center'>
            <FaSpinner className='animate-spin mr-2' />
            <p>Loading project data. Please wait...</p>
          </div>
        </div>
      ) : (
        <ProjectData />
      )}
    </>
  );
};

export default ViewProject;
