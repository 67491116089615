import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/ceb-net-plus/1.png';
import img2 from '../../images/documents/ceb-net-plus/2.png';
import installerDetails from '../../config/installerDetails';

// import cambria from '../../fonts/cambria.ttf';

// Font.register({ family: 'cambria', src: cambria });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  consumer_acc_num: {
    position: 'absolute',
    top: '212px',
    left: '250px',
  },

  consumer_name: {
    position: 'absolute',
    top: '230px',
    left: '250px',
    textTransform: 'capitalize',
  },

  consumer_NID: {
    position: 'absolute',
    top: '242px',
    left: '250px',
  },

  consumer_address: {
    position: 'absolute',
    top: '257px',
    left: '250px',
    textTransform: 'capitalize',
  },
  phase_and_current: {
    position: 'absolute',
    top: '274px',
    left: '380px',
  },

  bank_acc_num: {
    position: 'absolute',
    top: '289px',
    left: '170px',
  },

  bank_branch: {
    position: 'absolute',
    top: '289px',
    left: '350px',
  },

  installer_phone_1: {
    position: 'absolute',
    top: '358px',
    left: '200px',
    letterSpacing: '10px',
  },

  installer_phone_2: {
    position: 'absolute',
    top: '372px',
    left: '200px',
    letterSpacing: '10px',
  },

  installer_email: {
    position: 'absolute',
    top: '340px',
    left: '375px',
  },

  signing_person: {
    position: 'absolute',
    top: '565px',
    left: '265px',
    textTransform: 'capitalize',
  },

  // page 2
  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  installer_name: {
    position: 'absolute',
    top: '115px',
    left: '240px',
    textTransform: 'capitalize',
  },

  installer_address: {
    position: 'absolute',
    top: '130px',
    left: '240px',
    width: '260px',
    textTransform: 'capitalize',
    wordWrap: true,
  },

  contact_person: {
    position: 'absolute',
    top: '185px',
    left: '240px',
    textTransform: 'capitalize',
  },

  phone_fixed: {
    position: 'absolute',
    top: '200px',
    left: '280px',
  },

  phone_mobile: {
    position: 'absolute',
    top: '220px',
    left: '280px',
  },

  fax: {
    position: 'absolute',
    top: '240px',
    left: '240px',
  },

  email: {
    position: 'absolute',
    top: '260px',
    left: '240px',
  },

  declaration_1: {
    position: 'absolute',
    top: 415,
    left: 420,
  },
  declaration_2: {
    position: 'absolute',
    top: 437,
    left: 420,
  },
  declaration_3: {
    position: 'absolute',
    top: 462,
    left: 420,
  },
  declaration_4: {
    position: 'absolute',
    top: 485,
    left: 420,
    // width: 100,
    // height: 50,
    // backgroundColor: 'blue',
  },
  additional_comment: {
    position: 'absolute',
    top: 500,
    left: 400,
  },

  installer_name_last: {
    position: 'absolute',
    top: 645,
    left: 125,
    textTransform: 'capitalize',
  },
});

const CEBNetPlus = ({ project }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />
          {/* DC Cables */}

          <Text style={styles.consumer_acc_num}>{project?.account_no}</Text>
          <Text style={styles.consumer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.consumer_NID}>{project?.customer.nic}</Text>
          <Text style={styles.consumer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.phase_and_current}>{project?.phase}</Text>
          <Text style={styles.bank_acc_num}></Text>
          <Text style={styles.bank_branch}></Text>

          <Text style={styles.installer_phone_1}>
            {installerDetails.phone_1}
          </Text>
          <Text style={styles.installer_phone_2}>
            {installerDetails.phone_2}
          </Text>
          <Text style={styles.installer_email}>{installerDetails.email}</Text>

          <Text style={styles.signing_person}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
        </View>
      </Page>

      {/* Page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />

          <Text style={styles.installer_name}>{installerDetails.name}</Text>
          <Text style={styles.installer_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.contact_person}>
            {installerDetails.contact_person}
          </Text>
          <Text style={styles.phone_fixed}>N/A</Text>
          <Text style={styles.phone_mobile}>
            {' '}
            {installerDetails.phone_1}/ {installerDetails.phone_2}
          </Text>
          <Text style={styles.fax}>N/A</Text>
          <Text style={styles.email}>{installerDetails.email}</Text>

          <Text style={styles.additional_comment}>N/A</Text>

          <Text style={styles.installer_name_last}>
            {installerDetails.name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default CEBNetPlus;
