import React, { useState } from 'react';
import { Modal, Paper } from '@mui/material';
import Select from 'react-select';
import { CgClose } from 'react-icons/cg';

type Props = {
  confirmAction: (accessory: any, returnQty: number) => void;
  closeModal: any;
  accessories: any[];
};

const AccessorySelection: React.FC<Props> = ({ confirmAction, closeModal, accessories }) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [accessory, setAccessory] = useState<any>();

  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    height: '30%',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: 10,
  };

  const accessoryOptions = accessories.map((a: any) => ({
    label: a.name,
    value: a,
  }));

  const handleQuantityChange = (value: number) => {
    const existQty = accessory.value.quantity ?? accessory.value.utilization;
    if (value <= existQty && value > 0) {
      setQuantity(value);
    }
  };

  return (
    <Modal open={true} style={modalStyle} onClose={closeModal}>
      <Paper sx={paperStyle}>
        <button onClick={() => closeModal()} className='absolute top-5 right-5'>
          <CgClose />
        </button>
        <div className='flex w-full justify-between items-center'>
          <h2 className='font-semibold text-lg'>Select accessory to add into refund list</h2>
        </div>
        <div className='flex justify-between items-center my-4 gap-4'>
          <Select
            options={accessoryOptions}
            className='w-64'
            value={accessory}
            onChange={(newVal) => {
              setAccessory(newVal);
              setQuantity(1);
            }}
          />
          {accessory && <h3>Existing Qty: {accessory.value.quantity ?? accessory.value.utilization}</h3>}
          <input
            type='number'
            className='py-2 px-3 border rounded'
            value={quantity}
            onChange={(e) => handleQuantityChange(parseFloat(e.target.value))}
          />
        </div>
        <button className='bg-green-600 text-white font-semibold w-full py-2 px-3 rounded' onClick={() => confirmAction(accessory.value, quantity)}>
          Add Refund
        </button>
      </Paper>
    </Modal>
  );
};

export default AccessorySelection;
