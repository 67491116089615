import React, { useState } from 'react';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { TransportCost } from '../../../store/types/TransportCostTypes';
import { createTransportCost } from '../../../store/TransportCostSlice';
import TransportCostForm from './TransportCostForm';
import Breadcrumbs from '../../Breadcrumbs';

type Props = {};

const AddTransportCost: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const createTransportCostState = useSelector(
    (state: RootState) => state.transportCost.create
  );

  const [cost, setCost] = useState<TransportCost>({
    min: '',
    max: '',
    cost: 0,
  });

  const handleInputChange = ({ fieldName, value }: any) => {
    setCost({
      ...cost,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(createTransportCost(cost));

    if (!createTransportCostState.error) {
      navigate('/financials/transport-cost');
    }
  };

  const links = [
    {
      link: '/financials/transport-cost',
      name: 'Financials',
    },
    {
      link: '',
      name: 'Add Transport Cost',
    },
  ];

  return (
    <div
      className="w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]"
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className="flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white">
        <Breadcrumbs links={links} />
      </div>
      <TransportCostForm
        transportCost={cost}
        onInputChange={handleInputChange}
        onFormSubmit={handleFormSubmit}
        editable={true}
      />
    </div>
  );
};

export default AddTransportCost;
