import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';
import { ButtonCustom } from './Buttons';

type Props = {
  deleteAction: any;
  cancelAction: any;
  type: string;
  name: string | any;
};

const DeleteModal: React.FC<Props> = ({ deleteAction, cancelAction, type, name }) => {
  const navigate = useNavigate();

  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 5,
  };

  return (
    <Modal open={true} style={modalStyle}>
      <div>
        <Paper sx={paperStyle} className='relative'>
          <div className='flex flex-col items-center rounded'>
            <h3 className='mb-6 font-semibold text-center'>
              Are you sure you want to delete the {type}: <br /> <span className='font-bold'>{name}</span>?
            </h3>
            <div className='flex justify-center items-center w-full gap-5'>
              <ButtonCustom customStyles='w-1/2 bg-green-800' onClick={() => deleteAction()}>
                Confirm
              </ButtonCustom>

              <ButtonCustom customStyles='w-1/2 bg-red-800 mx-auto' onClick={() => cancelAction()}>
                Cancel
              </ButtonCustom>
            </div>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default DeleteModal;
