import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { postUser, resetPostStatus } from '../../store/UserSlice';
import UserForm from './UserForm';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../Breadcrumbs';
import { toast } from 'react-toastify';
import LoaderOverlay from '../LoaderOverlay';

type ButtonProps = {
  error: boolean;
};

const NewUser = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    type: 'admin',
    password: '',
    password_confirm: '',
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleInputChange = ({ fieldName, value }: any) => {
    setUser({
      ...user,
      [fieldName]: value,
    });

    if (fieldName === 'password_confirm') {
      setPasswordsMatch(value === user.password);
    }
  };

  const userPost = useSelector((state: RootState) => state.user.post);
  const postError = userPost.error;
  const postMessage = userPost.message;

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const submitUser = async (e: any) => {
    e.preventDefault();

    if (!passwordsMatch) {
      return toast.warning('Password does not match!');
    }

    const createdUser = {
      username: user.username,
      email: user.email,
      type: user.type,
      password: user.password,
    };
    await dispatch(postUser(createdUser));
    navigate('/users');
  };

  useEffect(() => {
    dispatch(resetPostStatus());
  }, [dispatch]);

  const links = [
    {
      link: '/users',
      name: 'Users',
    },
    {
      link: '',
      name: 'New User',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>

      {userPost?.pending && <LoaderOverlay />}

      <UserForm editable={true} user={user} onInputChange={handleInputChange} onFormSubmit={submitUser} />
    </div>
  );
};

export default NewUser;
