import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, ServiceCost } from './types/ServiceCostTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllServiceCosts = createAsyncThunk('serviceCost/get', async (filter: any): Promise<any> => {
  const res = await axios.get(`${api}/service-cost`);

  return res.data;
});

export const createServiceCost = createAsyncThunk('serviceCost/create', async (data: ServiceCost): Promise<any> => {
  const res = await axios.post(`${api}/service-cost`, data);
  toast.success('Installation Cost Added successfully');
  return res.data;
});

export const updateServiceCost = createAsyncThunk('serviceCost/update', async (data: ServiceCost): Promise<any> => {
  const res = await axios.put(`${api}/service-cost/${data.id}`, data);
  toast.success('Service Cost Updated successfully');
  return res.data;
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
};

const ServiceCostSlice = createSlice({
  name: 'ServiceCost',
  initialState: initialState,
  reducers: {},

  // GET ALL Service Costs
  extraReducers: (builder) => {
    builder.addCase(getAllServiceCosts.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllServiceCosts.rejected, (state) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
    builder.addCase(getAllServiceCosts.pending, (state, action) => {
      state.list.pending = true;
      state.list.error = false;
    });

    // CREATE Service Cost
    builder.addCase(createServiceCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createServiceCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(createServiceCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });

    // UPATE Service Cost
    builder.addCase(updateServiceCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(updateServiceCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(updateServiceCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });
  },
});

export default ServiceCostSlice.reducer;
