export enum ProjectTasks {
  Consultation = 'Consultation',
  Proposal = 'Proposal',
  Clearance = 'Clearance',
  Invoice = 'Invoice',
  Installation = 'Installation',
  Handover = 'Handover',
  Complete = 'Complete',
}

export const taskList: ProjectTasks[] = [
  ProjectTasks.Consultation,
  ProjectTasks.Proposal,
  ProjectTasks.Clearance,
  ProjectTasks.Invoice,
  ProjectTasks.Installation,
  ProjectTasks.Handover,
  ProjectTasks.Complete,
];

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export enum ItemType {
  ElectricalAccessory = 'ElectricalAccessory',
  HardwareAccessory = 'HardwareAccessory',
  Cable = 'Cable',
  Railing = 'Railing',
}
