import React, { useState, useEffect } from 'react';
import { navbarHeight, footerHeight } from '../../../utils/constants';

import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { createCable, getCableList, updateCable } from '../../../store/CableSlice';

import { Button, Paper } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from '@mui/x-data-grid';

import { randomId } from '@mui/x-data-grid-generator';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import NewAccessoryModal from './NewAccessoryModal';
import Breadcrumbs from '../../Breadcrumbs';

import { MdAddCircle } from 'react-icons/md';
import LoadingSkeleton from '../../LoadingSkeleton';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, accessoryName: '', perPanelReq: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Add New Accessory
      </Button>
    </GridToolbarContainer>
  );
}

const Cables = () => {
  const dispatch: AppDispatch = useDispatch();

  // New Cable
  const [newCableData, setNewCableData] = useState({
    name: '',
    cost: '',
  });
  const [newAccessoryModal, setNewAccessoryModal] = useState(false);
  const closeNewAccessoryModal = () => {
    setNewAccessoryModal(false);
  };
  const openNewAccessoryModal = () => {
    setNewAccessoryModal(true);
  };
  const handleInputChange = (params: { field: 'cost' | 'name'; value: string | number }) => {
    const { field, value } = params;
    setNewCableData({ ...newCableData, [field]: value });
  };
  const handleNewCableSubmit = async (e: Event) => {
    e.preventDefault();
    await dispatch(createCable(newCableData));
    dispatch(getCableList({}));
    setNewCableData({ name: '', cost: '' });
    closeNewAccessoryModal();
  };

  const cableList = useSelector((state: RootState) => state.cable.list);

  const [rows, setRows] = React.useState(cableList.data);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    setRows(cableList.data);
  }, [cableList.data]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (updatedAccessory: GridRowModel) => {
    const updatedAccessoryData = {
      id: updatedAccessory.id,
      cost: Number(updatedAccessory.cost),
      name: updatedAccessory.name,
    };

    await dispatch(updateCable(updatedAccessoryData));

    return updatedAccessory;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Cable Name',
      flex: 3,
      editable: true,
      filterable: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'cost',
      headerName: 'Unit Price (LKR)',
      type: 'number | string',
      editable: true,
      align: 'right',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
        ];
      },
    },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // if the submission successfull
    setSubmitSuccess(true);
    // react-confetty
  };

  useEffect(() => {
    dispatch(getCableList({}));
  }, [dispatch]);

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '',
      name: 'Cables',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
        <button onClick={openNewAccessoryModal} className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p>New Cable</p> <MdAddCircle className='text-2xl' />
        </button>
      </div>
      <Paper className='w-full md:w-[95%] h-full border rounded p-5 md:p-10 md:my-10 shadow-lg bg-white overflow-y-auto relative'>
        {cableList.data.length > 0 && (
          <DataGrid
            processRowUpdate={processRowUpdate}
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnSelector
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slotProps={{
              toolbar: { setRowModesModel },
            }}
            className='font-bold'
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              fontSize: '12px',
              fontWeight: 'bold',

              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#CBE6FA',
              },

              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'flex',
                justifyContent: '',
              },

              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '14px',
                marginX: 'auto',
              },
            }}
          />
        )}
        {cableList.pending && <LoadingSkeleton numColumns={1} />}
        {!cableList.pending && !cableList.error && cableList.data.length === 0 && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2  text-red-400 font-semibold'>Data Not Available!</div>
        )}
        {!cableList.pending && cableList.error && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2  text-red-400 font-semibold'>Error occurred!</div>
        )}
      </Paper>

      {newAccessoryModal && (
        <NewAccessoryModal
          title={'New Cable'}
          accessory={newCableData}
          handleChange={handleInputChange}
          handleSubmit={handleNewCableSubmit}
          closeModal={closeNewAccessoryModal}
        />
      )}
    </div>
  );
};

export default Cables;
