import _ from 'lodash';

export const isBrowser = () => typeof window !== 'undefined';

export const emailValid = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const formatNumber = (x: any) => {
  return Number(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Calculate invoice/quotation total
export const getTotal = (products: any) => {
  let total = 0;
  _.forEach(products, (product) => {
    if (product.price && product.quantity) {
      let discount;

      // if (product.newWarranty) {
      //   discount =
      //     product.discountType === 'LKR'
      //       ? parseFloat(product.discount) * parseFloat(product.quantity)
      //       : parseFloat(product.price) * parseFloat(product.quantity);
      // } else {
      //   discount =
      //     product.discountType === 'LKR'
      //       ? parseFloat(product.discount)
      //       : parseFloat(product.price) * parseFloat(product.quantity);
      // }

      // const addAmountForWarranty=product.newWarranty && product.newWarranty == '5 Years' ? (parseFloat(product.price) * 15/100) * parseFloat(product.quantity) : 0

      total +=
        parseFloat(product.price) * parseFloat(product.quantity) -
        (discount || 0);
    }
  });
  return total;
};
