const installerDetails = {
  name: 'Sunterra (Pvt) Ltd',
  address: 'No 06, Ballahenamulla Road, Colombo',
  phone_1: 705008888,
  phone_2: 705008882,
  email: 'info@sunterra.lk',
  sea_reg_num: 'SLSEASBS00394PV002240132020ABT2P2',
  contact_person: 'Clive Shadrach',
  designation: 'Senior Operation Manager',
};

export default installerDetails;
