import React from 'react';
import { Input } from '../CustomInput';
import { Utility } from '../../store/types/UtilityTypes';
import { ButtonOrange } from '../Buttons';

type Props = {
  editable: boolean;
  updateUtility?: Partial<Utility> | Utility | null;
  utility: Utility | null;
  onInputChange: any;
  onFormSubmit: any;
};

const UtilityForm: React.FC<Props> = ({
  editable,
  updateUtility,
  utility,
  onInputChange,
  onFormSubmit,
}) => {
  return (
    <form
      onSubmit={(e) => onFormSubmit(e)}
      className="w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white"
    >
      <div className="flex flex-col md:flex-row justify-start w-full gap-2 md:gap-5">
        <Input
          label="Name"
          type="text"
          disabled={!editable}
          value={updateUtility?.name ?? utility?.name ?? ''}
          onChange={(e) => {
            const fieldName = 'name';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="Cost"
          type="number"
          disabled={!editable}
          value={updateUtility?.cost ?? utility?.cost ?? ''}
          onChange={(e) => {
            const fieldName = 'cost';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          units="LKR"
        />

        {editable && (
          <ButtonOrange type="submit" customStyles="mb-4">
            SAVE
          </ButtonOrange>
        )}
      </div>
    </form>
  );
};

export default UtilityForm;
