import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdSearch } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import Breadcrumbs from '../Breadcrumbs';
import { getProjectsList } from '../../store/ProjectsSlice';
import { Project } from '../../store/types/ProjectTypes';
import LoadingSkeleton from '../LoadingSkeleton';
import { MenuItem, Select } from '@mui/material';
import ProgressBar from '../ProgressBar';

function Projects() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const projectsListState = useSelector((state: RootState) => state.project.list);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortedData, setSortedData] = useState<Project[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');

  useEffect(() => {
    const fetchAndSetProjectsList = async () => {
      dispatch(getProjectsList({}));
    };

    fetchAndSetProjectsList();
  }, []);

  useEffect(() => {
    // Sorting logic based on updated_at
    const sorted = [...projectsListState.data].sort((a, b) => {
      const dateA = a.updated_at ? new Date(a.updated_at) : null;
      const dateB = b.updated_at ? new Date(b.updated_at) : null;

      if (dateA && dateB) {
        return sortOrder === 'desc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      // Handle the case where either date is undefined
      return 0;
    });

    // Filtering based on search term and status
    const filtered = sorted.filter((project) => {
      const matchesSearch = `${project?.customer?.first_name} ${project?.customer?.last_name}`.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = statusFilter ? (statusFilter === 'ongoing' ? project.status !== 'completed' : project.status === statusFilter) : true;
      return matchesSearch && matchesStatus;
    });

    setSortedData(filtered);
  }, [projectsListState.data, sortOrder, searchTerm, statusFilter]);

  const getStatusStep = (status: any) => {
    switch (status) {
      case 'Proposal':
        return 1;
      case 'Clearance':
        return 2;
      case 'Invoice':
        return 3;
      case 'Installation':
        return 4;
      case 'Handover':
        return 5;
      case 'Complete':
        return 6;
      default:
        return 0;
    }
  };

  return (
    <div className='w-full flex flex-col items-center bg-[#ECECEC] h-[100vh]' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Projects',
            },
          ]}
        />

        <Link to='/projects/new-project' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Project</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>
      <Paper
        sx={{
          marginX: 'auto',
          width: { xs: '100%', md: '95%' },
          height: '100vh',
          overflowY: 'auto',
          marginY: { xs: '10px', md: '40px' },
          paddingX: { xs: '10px', md: '40px' },
          paddingY: { xs: '10px', md: '30px' },
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            height: '80%',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C7C8CC',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <div className='flex justify-between'></div>
        <div className='flex gap-2 mb-4 justify-between items-end'>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            aria-label='Search Projects'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            displayEmpty
            size='small'
            variant='outlined'
            sx={{ minWidth: 150 }}
          >
            <MenuItem value=''>All Status</MenuItem>
            <MenuItem value='Completed'>Completed</MenuItem>
            <MenuItem value='ongoing'>Ongoing</MenuItem>
          </Select>
          {/* Show number of items */}
          <p className='text-xs text-gray-500'>
            Viewing {sortedData.length} item{sortedData.length === 1 ? '' : 's'}
          </p>
        </div>
        <TableContainer
          sx={{
            marginX: 'auto',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-center text-sm font-bold'>Customer</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Location</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Phase</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>User</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Status</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Started Date</p>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData.map((project, index) => (
                <TableRow
                  key={index}
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  onClick={() => {
                    navigate(`/projects/${project.id}`);
                  }}
                >
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{project.customer?.first_name}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{project.address[2]}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{project.phase}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{project?.project_task[0]?.user.username}</p>
                  </TableCell>
                  <TableCell>
                    <div className='text-center text-xs font-bold text-gray-500 capitalize'>
                      <ProgressBar currentStep={getStatusStep(project.status)} totalSteps={6} />
                    </div>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500 capitalize'>{project.created_at?.split('T')[0]}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='my-1'>
          {projectsListState.pending && <LoadingSkeleton numColumns={1} />}
          {!projectsListState.error && !projectsListState.pending && sortedData.length === 0 && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
          )}
          {!projectsListState.pending && projectsListState.error && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default Projects;
