import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/leco-inverter-app/1.png';
import img2 from '../../images/documents/leco-inverter-app/2.png';
import img3 from '../../images/documents/leco-inverter-app/3.png';
import installerDetails from '../../config/installerDetails';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  supplier_name: {
    position: 'absolute',
    top: 305,
    left: 250,
  },

  supplier_reg_num: {
    position: 'absolute',
    top: 323,
    left: 250,
  },

  inverter_manufacturer: {
    position: 'absolute',
    top: 338,
    left: 250,
  },

  origin_country: {
    position: 'absolute',
    top: 356,
    left: 250,
  },

  test_reports: {
    position: 'absolute',
    top: 374,
    left: 250,
  },

  is_accredited: { position: 'absolute', top: 397, left: 250 },

  model_num: {
    position: 'absolute',
    top: 420,
    left: 250,
  },

  support_standards: {
    position: 'absolute',
    top: 433,
    left: 250,
    fontSize: 7,
    width: 230,
    wordBreak: 'break-all',
  },

  rated_power_output_ref: {
    position: 'absolute',
    top: 518,
    left: 450,
    width: 40,
    wordBreak: 'break-word',
  },
  rated_power_output_val: {
    position: 'absolute',
    top: 520,
    left: 510,
  },

  synchronization_ref: {
    position: 'absolute',
    top: 535,
    left: 450,
    width: 40,
    wordBreak: 'break-word',
  },

  synchronization_val: {
    position: 'absolute',
    top: 535,
    left: 510,
  },

  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  voltage_tripping_a_1_val: {
    position: 'absolute',
    top: 175,
    left: 460,
  },

  voltage_tripping_a_1_ref: {
    position: 'absolute',
    top: 175,
    left: 510,
    fontSize: 8,
  },

  voltage_tripping_a_2_val: {
    position: 'absolute',
    top: 192,
    left: 460,
  },

  voltage_tripping_a_2_ref: {
    position: 'absolute',
    top: 192,
    left: 510,
    fontSize: 8,
  },

  voltage_tripping_a_3_val: {
    position: 'absolute',
    top: 209,
    left: 460,
  },

  voltage_tripping_a_3_ref: {
    position: 'absolute',
    top: 209,
    left: 510,
    fontSize: 8,
  },

  voltage_tripping_a_4_val: {
    position: 'absolute',
    top: 226,
    left: 460,
  },

  voltage_tripping_a_4_ref: {
    position: 'absolute',
    top: 226,
    left: 510,
    fontSize: 8,
  },

  frequency_tripping_a_1_val: {
    position: 'absolute',
    top: 302,
    left: 460,
  },

  frequency_tripping_a_1_ref: {
    position: 'absolute',
    top: 302,
    left: 510,
    fontSize: 8,
  },

  frequency_tripping_a_2_val: {
    position: 'absolute',
    top: 319,
    left: 460,
  },

  frequency_tripping_a_2_ref: {
    position: 'absolute',
    top: 319,
    left: 510,
    fontSize: 8,
  },

  frequency_tripping_b_1_val: {
    position: 'absolute',
    top: 368,
    left: 460,
  },

  frequency_tripping_b_1_ref: {
    position: 'absolute',
    top: 368,
    left: 510,
    fontSize: 8,
  },
  frequency_tripping_b_2_val: {
    position: 'absolute',
    top: 388,
    left: 460,
  },

  frequency_tripping_b_2_ref: {
    position: 'absolute',
    top: 388,
    left: 510,
    fontSize: 8,
  },

  withstand_capability_val: {
    position: 'absolute',
    top: 418,
    left: 460,
  },
  withstand_capability_ref: {
    position: 'absolute',
    top: 418,
    left: 510,
    fontSize: 8,
  },

  grounding_scheme_val: {
    position: 'absolute',
    top: 445,
    left: 460,
  },
  grounding_scheme_ref: {
    position: 'absolute',
    top: 445,
    left: 510,
    fontSize: 8,
  },

  reconnection_time_val: {
    position: 'absolute',
    top: 470,
    left: 460,
  },
  reconnection_time_ref: {
    position: 'absolute',
    top: 470,
    left: 510,
    fontSize: 8,
  },

  dc_limitation_val: {
    position: 'absolute',
    top: 515,
    left: 458,
  },
  dc_limitation_ref: {
    position: 'absolute',
    top: 515,
    left: 508,
    fontSize: 8,
  },

  voltage_waveform_val: {
    position: 'absolute',
    top: 550,
    left: 458,
    fontSize: 9,
  },
  voltage_waveform_ref: {
    position: 'absolute',
    top: 550,
    left: 508,
    fontSize: 8,
  },

  power_quality_val: {
    position: 'absolute',
    top: 575,
    left: 458,
  },
  power_quality_ref: {
    position: 'absolute',
    top: 575,
    left: 508,
    fontSize: 8,
  },

  view_3: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  hd_a_val: {
    position: 'absolute',
    top: 105,
    left: 460,
  },
  hd_a_ref: {
    position: 'absolute',
    top: 105,
    left: 510,
    fontSize: 8,
  },

  hd_b_val: {
    position: 'absolute',
    top: 122,
    left: 460,
  },
  hd_b_ref: {
    position: 'absolute',
    top: 122,
    left: 510,
    fontSize: 8,
  },

  hd_c_val: {
    position: 'absolute',
    top: 139,
    left: 460,
  },
  hd_c_ref: {
    position: 'absolute',
    top: 139,
    left: 510,
    fontSize: 8,
  },

  hd_d_val: {
    position: 'absolute',
    top: 156,
    left: 460,
  },
  hd_d_ref: {
    position: 'absolute',
    top: 156,
    left: 510,
    fontSize: 8,
  },

  hd_e_val: {
    position: 'absolute',
    top: 173,
    left: 460,
  },
  hd_e_ref: {
    position: 'absolute',
    top: 173,
    left: 510,
    fontSize: 8,
  },

  hd_f_val: {
    position: 'absolute',
    top: 190,
    left: 460,
  },
  hd_f_ref: {
    position: 'absolute',
    top: 190,
    left: 510,
    fontSize: 8,
  },
});

const LECOInverterApp = ({ project }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />

          <Text style={styles.supplier_name}>{installerDetails.name}</Text>
          <Text style={styles.supplier_reg_num}>
            {installerDetails.sea_reg_num}
          </Text>
          <Text style={styles.inverter_manufacturer}>
            {project?.project_inverters[0]?.inverter.brand}
          </Text>
          <Text style={styles.origin_country}>
            {project?.project_inverters[0]?.inverter.origin_country}
          </Text>

          <Text style={styles.test_reports}>
            {project?.project_inverters[0]?.inverter.test_reports}
          </Text>
          <Text style={styles.is_accredited}>YES</Text>
          <Text style={styles.model_num}>
            {project?.project_inverters[0]?.inverter.brand}
            {'-'}
            {project?.project_inverters[0]?.inverter.model}
          </Text>
          <Text style={styles.support_standards}>
            {project?.project_inverters[0]?.inverter.supported_standards}
          </Text>
          <Text style={styles.rated_power_output_ref}></Text>
          <Text style={styles.rated_power_output_val}></Text>

          <Text style={styles.synchronization_ref}>See attached</Text>
          <Text style={styles.synchronization_val}>+/-5%</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />
          <Text style={styles.voltage_tripping_a_1_val}>{'<0.2'}</Text>
          <Text style={styles.voltage_tripping_a_1_ref}>See attached</Text>
          <Text style={styles.voltage_tripping_a_2_val}>{'<2.00'}</Text>
          <Text style={styles.voltage_tripping_a_2_ref}>See attached</Text>
          <Text style={styles.voltage_tripping_a_3_val}>{'<1.00'}</Text>
          <Text style={styles.voltage_tripping_a_3_ref}>See attached</Text>
          <Text style={styles.voltage_tripping_a_4_val}>{'<0.2'}</Text>
          <Text style={styles.voltage_tripping_a_4_ref}>See attached</Text>

          <Text style={styles.frequency_tripping_a_1_val}>{'<0.2'}</Text>
          <Text style={styles.frequency_tripping_a_1_ref}>See attached</Text>
          <Text style={styles.frequency_tripping_a_2_val}>{'<0.2'}</Text>
          <Text style={styles.frequency_tripping_a_2_ref}>See attached</Text>

          <Text style={styles.frequency_tripping_b_1_val}>{'<0.2'}</Text>
          <Text style={styles.frequency_tripping_b_1_ref}>See attached</Text>
          <Text style={styles.frequency_tripping_b_2_val}>{'<0.2'}</Text>
          <Text style={styles.frequency_tripping_b_2_ref}>See attached</Text>

          <Text style={styles.withstand_capability_val}>220%</Text>
          <Text style={styles.withstand_capability_ref}>See attached</Text>

          <Text style={styles.grounding_scheme_val}>TT</Text>
          <Text style={styles.grounding_scheme_ref}>See attached</Text>

          <Text style={styles.reconnection_time_val}>180s</Text>
          <Text style={styles.reconnection_time_ref}>See attached</Text>

          <Text style={styles.dc_limitation_val}>{'<0.5'}</Text>
          <Text style={styles.dc_limitation_ref}>See attached</Text>

          <Text style={styles.voltage_waveform_val}>50Hz - Sin</Text>
          <Text style={styles.voltage_waveform_ref}>See attached</Text>

          <Text style={styles.power_quality_val}>Yes</Text>
          <Text style={styles.power_quality_ref}>See attached</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_3}>
          <Image src={img3} style={styles.image} />

          <Text style={styles.hd_a_val}>{'<4'}</Text>
          <Text style={styles.hd_a_ref}>See attached</Text>

          <Text style={styles.hd_b_val}>{'<2'}</Text>
          <Text style={styles.hd_b_ref}>See attached</Text>

          <Text style={styles.hd_c_val}>{'<1.5'}</Text>
          <Text style={styles.hd_c_ref}>See attached</Text>

          <Text style={styles.hd_d_val}>{'<0.6'}</Text>
          <Text style={styles.hd_d_ref}>See attached</Text>

          <Text style={styles.hd_e_val}>{'<0.3'}</Text>
          <Text style={styles.hd_e_ref}>See attached</Text>

          <Text style={styles.hd_f_val}>{'<5'}</Text>
          <Text style={styles.hd_f_ref}>See attached</Text>
        </View>
      </Page>
    </Document>
  );
};

export default LECOInverterApp;
