import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img from '../../images/documents/leco-net-meter.png';

// import cambria from '../../fonts/cambria.ttf';

// Font.register({ family: 'cambria', src: cambria });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  dcCrossSection: {
    position: 'absolute',
    top: '195px',
    left: '370px',
    lineHeight: '30px',
  },
  dcCrossSectionSup: {
    fontSize: 5,
    lineHeight: 18,
    position: 'absolute',
    top: '195px',
    left: '395px',
  },
  dcRatedVoltage: {
    position: 'absolute',
    top: '210px',
    left: '370px',
    lineHeight: '30px',
  },

  dcStandards: {
    position: 'absolute',
    top: '225px',
    left: '370px',
    lineHeight: '30px',
  },

  acCrossSection: {
    position: 'absolute',
    top: '240px',
    left: '370px',
    lineHeight: '30px',
  },

  acCrossSectionSup: {
    fontSize: 5,
    lineHeight: 18,
    position: 'absolute',
    top: '240px',
    left: '395px',
  },

  acRatedVoltage: {
    position: 'absolute',
    top: '255px',
    left: '370px',
    lineHeight: '30px',
  },

  acStandards: {
    position: 'absolute',
    top: '270px',
    left: '370px',
  },

  groundCrossSection: {
    position: 'absolute',
    top: '295px',
    left: '370px',
    lineHeight: '30px',
  },

  groundCrossSectionSup: {
    fontSize: 5,
    lineHeight: 18,
    position: 'absolute',
    top: '295px',
    left: '395px',
  },

  groundRatedVoltage: {
    position: 'absolute',
    top: '311px',
    left: '370px',
  },

  groundStandards: {
    position: 'absolute',
    top: '325px',
    left: '370px',
  },

  surgeArresterUc: {
    position: 'absolute',
    top: '341px',
    left: '370px',
  },

  surgeArresterUmax: {
    position: 'absolute',
    top: '356px',
    left: '370px',
  },

  surgeArresterIc: {
    position: 'absolute',
    top: '371px',
    left: '370px',
  },

  surgeArresterImax: {
    position: 'absolute',
    top: '386px',
    left: '370px',
  },

  OcpDCIcn: {
    position: 'absolute',
    top: '401px',
    left: '370px',
  },

  OcpDCIcu: {
    position: 'absolute',
    top: '416px',
    left: '370px',
  },

  OcpDCCurrentRating: {
    position: 'absolute',
    top: '431px',
    left: '370px',
  },
  OcpACIcn: {
    position: 'absolute',
    top: '449px',
    left: '370px',
  },

  OcpACIcu: {
    position: 'absolute',
    top: '464px',
    left: '370px',
  },

  OcpACCurrentRating: {
    position: 'absolute',
    top: '479px',
    left: '370px',
  },

  invCap: {
    position: 'absolute',
    top: '493px',
    left: '370px',
  },

  panelCap: {
    position: 'absolute',
    top: '508px',
    left: '370px',
  },

  busCurrent: {
    position: 'absolute',
    top: '523px',
    left: '370px',
  },

  busVoltage: {
    position: 'absolute',
    top: '538px',
    left: '370px',
  },

  invBrndMdl: {
    position: 'absolute',
    top: '562px',
    left: '370px',
  },

  panelBrndMdl: {
    position: 'absolute',
    top: '580px',
    left: '370px',
  },

  customer_name: {
    position: 'absolute',
    top: '625px',
    left: 320,
  },

  customer_address: {
    position: 'absolute',
    top: '650px',
    left: 320,
  },

  customer_phone: {
    position: 'absolute',
    top: '680px',
    left: 320,
  },
  customer_email: {
    position: 'absolute',
    top: '705px',
    left: 320,
  },
});

const LECONetMeter = ({ project }: any) => {
  const inverterCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  const panelCapacity = (
    project?.project_panels.length *
    (project?.project_panels[0].panel.peak_power / 1000)
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          <Image src={img} style={styles.image} />
          {/* DC Cables */}
          <Text style={styles.dcCrossSection}>
            {project?.circuit?.dc_cable_size} mm
          </Text>
          <Text style={styles.dcCrossSectionSup}>2</Text>
          <Text style={styles.dcRatedVoltage}>
            {project?.circuit?.dc_cable_rated_voltage}V
          </Text>
          <Text style={styles.dcStandards}>
            {project?.circuit?.dc_cable_standards}
          </Text>

          {/* AC Cables */}
          <Text style={styles.acCrossSection}>
            {project?.circuit?.ac_cable_size} mm
          </Text>
          <Text style={styles.acCrossSectionSup}>2</Text>
          <Text style={styles.acRatedVoltage}>
            {project?.circuit?.ac_cable_rated_voltage}
          </Text>
          <Text style={styles.acStandards}>
            {project?.circuit?.ac_cable_standards}
          </Text>

          {/* Ground Cables */}
          <Text style={styles.groundCrossSection}>
            {project?.circuit?.grounding_cable_cross} mm
          </Text>
          <Text style={styles.groundCrossSectionSup}>2</Text>
          <Text style={styles.groundRatedVoltage}>
            {project?.circuit?.grounding_cable_rated_voltage}
          </Text>
          <Text style={styles.groundStandards}>
            {project?.circuit?.grounding_cable_standards}
          </Text>

          {/* AC Bus Surge Arrester Cables */}
          <Text style={styles.surgeArresterUc}>
            {project?.circuit?.ocp_uc}V
          </Text>
          <Text style={styles.surgeArresterUmax}>
            {project?.circuit?.ocp_umax}kV
          </Text>
          <Text style={styles.surgeArresterIc}>
            {project?.circuit?.ocp_in}kA
          </Text>
          <Text style={styles.surgeArresterImax}>
            {project?.circuit?.ocp_imax}kA
          </Text>

          {/* Over current protective devices DC */}
          <Text style={styles.OcpDCIcn}>{project?.circuit?.ocp_dc_icn}kA</Text>
          <Text style={styles.OcpDCIcu}>{project?.circuit?.ocp_dc_iu}kA</Text>
          <Text style={styles.OcpDCCurrentRating}>
            {project?.circuit?.ocp_dc_current_rating}A
          </Text>

          {/* Over current protective devices AC */}
          <Text style={styles.OcpACIcn}>{project?.circuit?.ocp_ac_icn}kA</Text>
          <Text style={styles.OcpACIcu}>{project?.circuit?.ocp_ac_iu}kA</Text>
          <Text style={styles.OcpACCurrentRating}>
            {project?.circuit?.ocp_ac_current_rating}A
          </Text>

          {/* Over current protective devices AC */}
          <Text style={styles.invCap}>{inverterCapacity}kW</Text>
          <Text style={styles.panelCap}>{panelCapacity}kW</Text>
          <Text style={styles.busCurrent}>
            {project?.project_inverters[0].inverter.max_in_current_dc}A
          </Text>
          <Text style={styles.busVoltage}>
            {project?.project_inverters[0].inverter.rated_in_voltage_dc}V
          </Text>
          <Text style={styles.invBrndMdl}>
            {project?.project_inverters[0].inverter.brand}{' '}
            {project?.project_inverters[0].inverter.model}
          </Text>
          <Text style={styles.panelBrndMdl}>
            {project?.project_panels[0].panel.brand}{' '}
            {project?.project_panels[0].panel.model}
          </Text>

          {/* Customer Info */}
          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.customer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.customer_phone}>{project?.customer.phone}</Text>
          <Text style={styles.customer_email}>{project?.customer.email}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default LECONetMeter;
