import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/ceb-annexure-6/1.png';
import img2 from '../../images/documents/ceb-annexure-6/2.png';
import img3 from '../../images/documents/ceb-annexure-6/3.png';
import img4 from '../../images/documents/ceb-annexure-6/4.png';

import './../../styles/checkmark.css';
import installerDetails from '../../config/installerDetails';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  customer_name: {
    position: 'absolute',
    top: '100px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },

  customer_address: {
    position: 'absolute',
    top: '142px',
    left: '200px',
    width: '160px',
    wordWrap: true,
    textTransform: 'capitalize',
  },

  customer_electricity_acc_num: {
    position: 'absolute',
    top: '100px',
    left: '480px',
  },

  customer_contact: {
    position: 'absolute',
    top: '142px',
    left: '480px',
    wordWrap: 'break-word',
  },

  customer_email: {
    position: 'absolute',
    top: '152px',
    left: '480px',
    wordWrap: 'break-word',
  },

  customer_fax: {
    position: 'absolute',
    top: '164px',
    left: '480px',
    wordWrap: 'break-word',
  },

  contractor_name: {
    position: 'absolute',
    top: '200px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },

  contractor_address: {
    position: 'absolute',
    top: '220px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },

  contractor_sea_reg_num: {
    position: 'absolute',
    top: '195px',
    left: '475px',
    width: '100px',
    fontSize: '7px',
    wordBreak: 'break-word',
  },

  contractor_contact: {
    position: 'absolute',
    top: '220px',
    left: '480px',
    width: '80px',
  },

  contractor_email: {
    position: 'absolute',
    top: '230px',
    left: '480px',
    width: '80px',
    wordWrap: 'break-word',
  },

  contractor_fax: {
    position: 'absolute',
    top: '242px',
    left: '480px',
    width: '80px',
    wordWrap: 'break-word',
  },

  package: {
    position: 'absolute',
    top: '290px',
    left: '40px',
  },

  continuation_sheet_num: {
    position: 'absolute',
    top: '313px',
    left: '485px',
  },

  inspection_1_ref: {
    position: 'absolute',
    top: '445px',
    left: '430px',
  },

  inspection_1_val: {
    position: 'absolute',
    top: '445px',
    left: '525px',
  },

  inspection_2_ref: {
    position: 'absolute',
    top: '480px',
    left: '430px',
  },

  inspection_2_val: {
    position: 'absolute',
    top: '480px',
    left: '525px',
  },

  inspection_3_ref: {
    position: 'absolute',
    top: '515px',
    left: '430px',
  },

  inspection_3_val: {
    position: 'absolute',
    top: '515px',
    left: '525px',
  },

  inspection_4_ref: {
    position: 'absolute',
    top: '545px',
    left: '430px',
  },

  inspection_4_val: {
    position: 'absolute',
    top: '545px',
    left: '525px',
  },

  inspection_5_ref: {
    position: 'absolute',
    top: '575px',
    left: '430px',
  },

  inspection_5_val: {
    position: 'absolute',
    top: '575px',
    left: '525px',
  },

  inspection_6_ref: {
    position: 'absolute',
    top: '605px',
    left: '430px',
  },

  inspection_6_val: {
    position: 'absolute',
    top: '605px',
    left: '525px',
  },
  inspection_7_ref: {
    position: 'absolute',
    top: '640px',
    left: '430px',
  },

  inspection_7_val: {
    position: 'absolute',
    top: '640px',
    left: '525px',
  },

  inspection_8_ref: {
    position: 'absolute',
    top: '675px',
    left: '430px',
  },

  inspection_8_val: {
    position: 'absolute',
    top: '675px',
    left: '525px',
  },
  inspection_9_ref: {
    position: 'absolute',
    top: '750px',
    left: '430px',
  },

  inspection_9_val: {
    position: 'absolute',
    top: '750px',
    left: '525px',
  },

  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  inspection_10_ref: {
    position: 'absolute',
    top: '33px',
    left: '430px',
  },

  inspection_10_val: {
    position: 'absolute',
    top: '33px',
    left: '525px',
  },

  inspection_11_ref: {
    position: 'absolute',
    top: '62px',
    left: '430px',
  },

  inspection_11_val: {
    position: 'absolute',
    top: '62px',
    left: '525px',
  },

  inspection_12_ref: {
    position: 'absolute',
    top: '88px',
    left: '430px',
  },

  inspection_12_val: {
    position: 'absolute',
    top: '88px',
    left: '525px',
  },
  inspection_13_ref: {
    position: 'absolute',
    top: '114px',
    left: '430px',
  },

  inspection_13_val: {
    position: 'absolute',
    top: '114px',
    left: '525px',
  },
  inspection_14_ref: {
    position: 'absolute',
    top: '140px',
    left: '430px',
  },

  inspection_14_val: {
    position: 'absolute',
    top: '140px',
    left: '525px',
  },
  inspection_15_ref: {
    position: 'absolute',
    top: '167px',
    left: '430px',
  },

  inspection_15_val: {
    position: 'absolute',
    top: '167px',
    left: '525px',
  },
  inspection_16_ref: {
    position: 'absolute',
    top: '194px',
    left: '430px',
  },

  inspection_16_val: {
    position: 'absolute',
    top: '194px',
    left: '525px',
  },
  inspection_17_ref: {
    position: 'absolute',
    top: '221px',
    left: '430px',
  },

  inspection_17_val: {
    position: 'absolute',
    top: '221px',
    left: '525px',
  },

  inspection_18_ref: {
    position: 'absolute',
    top: '248px',
    left: '430px',
  },

  inspection_18_val: {
    position: 'absolute',
    top: '248px',
    left: '525px',
  },

  deviations_inverter: {
    position: 'absolute',
    top: '318px',
    left: '130px',
  },

  deviations_panel: {
    position: 'absolute',
    top: '338px',
    left: '150px',
  },

  view_3: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  view_4: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  item_inspected_1: {
    position: 'absolute',
    top: '190px',
    left: '500px',
  },

  item_inspected_2: {
    position: 'absolute',
    top: '220px',
    left: '500px',
  },
  item_inspected_3: {
    position: 'absolute',
    top: '250px',
    left: '500px',
  },
  item_inspected_4: {
    position: 'absolute',
    top: '275px',
    left: '500px',
  },
  item_inspected_5: {
    position: 'absolute',
    top: '305px',
    left: '500px',
  },

  item_inspected_6: {
    position: 'absolute',
    top: '345px',
    left: '500px',
  },
  item_inspected_7: {
    position: 'absolute',
    top: '390px',
    left: '500px',
  },
  item_inspected_8: {
    position: 'absolute',
    top: '425px',
    left: '500px',
  },

  item_inspected_9: {
    position: 'absolute',
    top: '455px',
    left: '500px',
  },

  item_inspected_10: {
    position: 'absolute',
    top: '485px',
    left: '500px',
  },
  item_inspected_11: {
    position: 'absolute',
    top: '515px',
    left: '500px',
  },
  item_inspected_12: {
    position: 'absolute',
    top: '555px',
    left: '500px',
  },
  item_inspected_13: {
    position: 'absolute',
    top: '580px',
    left: '500px',
  },
  item_inspected_14: {
    position: 'absolute',
    top: '605px',
    left: '500px',
  },
  item_inspected_15: {
    position: 'absolute',
    top: '635px',
    left: '500px',
  },

  item_inspected_16: {
    position: 'absolute',
    top: '665px',
    left: '500px',
  },
  item_inspected_17: {
    position: 'absolute',
    top: '692px',
    left: '500px',
  },

  checkmark: {
    position: 'absolute',
    width: 8,
    height: 15,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 0,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    transform: 'rotate(45deg)',
  },
});

const Checkmark = () => (
  <View>
    <div style={styles.checkmark}></div>
  </View>
);

const CEBAnnexure6 = ({ project }: any) => {
  const systemCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />

          {/* Customer Details */}
          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.customer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}.
          </Text>
          <Text style={styles.customer_electricity_acc_num}>
            {project?.account_no}
          </Text>
          <Text style={styles.customer_contact}>{project?.customer.phone}</Text>
          <Text style={styles.customer_email}>{project?.customer.email}</Text>
          <Text style={styles.customer_fax}></Text>

          {/* Contarctor Details */}
          <Text style={styles.contractor_name}>{installerDetails.name}</Text>
          <Text style={styles.contractor_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.contractor_sea_reg_num}>
            {installerDetails.sea_reg_num.substring(0, 15)}
            <br />
            {installerDetails.sea_reg_num.substring(15)}
          </Text>
          <Text style={styles.contractor_contact}>
            {installerDetails.phone_1}
          </Text>
          <Text style={styles.contractor_email}>{installerDetails.email}</Text>
          <Text style={styles.contractor_fax}>N/A</Text>

          {/* Description of the installation */}
          <Text style={styles.package}>{systemCapacity}</Text>
          <Text style={styles.continuation_sheet_num}></Text>

          {/* Inspection and Testing */}

          <Text style={styles.inspection_1_ref}>See attached</Text>
          <Text style={styles.inspection_1_val}>+/-5%</Text>

          <Text style={styles.inspection_2_ref}>See attached</Text>
          <Text style={styles.inspection_2_val}></Text>

          <Text style={styles.inspection_3_ref}>See attached</Text>
          <Text style={styles.inspection_3_val}>220%</Text>

          <Text style={styles.inspection_4_ref}>See attached</Text>
          <Text style={styles.inspection_4_val}></Text>

          <Text style={styles.inspection_5_ref}>See attached</Text>
          <Text style={styles.inspection_5_val}>180s</Text>

          <Text style={styles.inspection_6_ref}>See attached</Text>
          <Text style={styles.inspection_6_val}>0.5s</Text>

          <Text style={styles.inspection_7_ref}>See attached</Text>
          <Text style={styles.inspection_7_val}>{'<0.5%'}</Text>

          <Text style={styles.inspection_8_ref}>See attached</Text>
          <Text style={styles.inspection_8_val}>{'<5%'}</Text>

          <Text style={styles.inspection_9_ref}>See attached</Text>
          <Text style={styles.inspection_9_val}>{'<4%'}</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />
          <Text style={styles.inspection_10_ref}>See attached</Text>
          <Text style={styles.inspection_10_val}>{'<2%'}</Text>

          <Text style={styles.inspection_11_ref}>See attached</Text>
          <Text style={styles.inspection_11_val}>{'<1.5%'}</Text>

          <Text style={styles.inspection_12_ref}>See attached</Text>
          <Text style={styles.inspection_12_val}>{'<0.6%'}</Text>

          <Text style={styles.inspection_13_ref}>See attached</Text>
          <Text style={styles.inspection_13_val}>{'<0.3%'}</Text>

          <Text style={styles.inspection_14_ref}>See attached</Text>
          <Text style={styles.inspection_14_val}>{'<1%'}</Text>

          <Text style={styles.inspection_15_ref}>See attached</Text>
          <Text style={styles.inspection_15_val}>{'<0.5%'}</Text>

          <Text style={styles.inspection_16_ref}>See attached</Text>
          <Text style={styles.inspection_16_val}>{'<0.375%'}</Text>

          <Text style={styles.inspection_17_ref}>See attached</Text>
          <Text style={styles.inspection_17_val}>{'<0.15%'}</Text>

          <Text style={styles.inspection_18_ref}>See attached</Text>
          <Text style={styles.inspection_18_val}>{'<0.075%'}</Text>

          {/* deviations */}

          <Text style={styles.deviations_inverter}>N/A</Text>
          <Text style={styles.deviations_panel}>N/A</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.view_3}>
          <Image src={img3} style={styles.image} />
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.view_4}>
          <Image src={img4} style={styles.image} />
          <View style={styles.item_inspected_1}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_2}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_3}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_4}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_5}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_6}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_7}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_8}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_9}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_10}>
            <Checkmark />
          </View>

          <View style={styles.item_inspected_11}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_12}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_13}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_14}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_15}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_16}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_17}>
            <Checkmark />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CEBAnnexure6;
