import React from 'react';
import { Project } from '../../store/types/ProjectTypes';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import Calibri from '../../fonts/calibri.ttf';
import CalibriBold from '../../fonts/calibri-bold.ttf';

import logo from '../../images/logo.png';
import { User } from '../../store/types/UserTypes';

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: Calibri,
    },
    {
      src: CalibriBold,
      fontWeight: 'bold',
    },
  ],
});

type Props = {
  project: Project;
  currentUser: User;
};

const BOQ = ({ project, currentUser }: Props) => {
  if (!project) {
    return <Text>Loading project data...</Text>;
  }

  const systemName = `${project.project_inverters[0].inverter.brand} ${project.project_inverters[0].inverter.model} ${project.project_inverters[0].inverter.rated_power_output}W`;

  const inverters = [
    `${project.project_inverters[0].inverter.brand} ${project.project_inverters[0].inverter.model} ${project.project_inverters[0].inverter.rated_power_output}W`,
    `${project.project_inverters.length}`,
  ];

  const panels = [
    `${project.project_panels[0].panel.brand} ${project.project_panels[0].panel.model} ${project.project_panels[0].panel.peak_power}W`,
    `${project.project_panels.length}`,
  ];

  const accessories = {
    Hardware_Accessories:
      project.hardware_accessories
        ?.filter((hw: any) => hw.quantity > 0)
        .map((hw: { accessory: { name: any }; quantity: any }) => [hw.accessory.name?.trim(), `${hw.quantity}`]) || [],
    Electrical_Accessories:
      project.electrical_accessories
        ?.filter((el: { quantity: number }) => el.quantity > 0)
        .map((el: { accessory: { name: any }; quantity: any }) => [el.accessory.name?.trim(), `${el.quantity}`]) || [],
    Cables:
      project?.cables
        ?.filter((cable: { quantity: number }) => cable.quantity > 0)
        .map((cable: { cable: { name: any }; quantity: any }) => [cable.cable.name?.trim(), `${cable.quantity}`]) || [],
    Railings:
      project?.railings
        ?.filter((railing: { utilization: number }) => railing.utilization > 0)
        .map((railing: { railing: { name: any }; utilization: any }) => [railing.railing.name?.trim(), `${railing.utilization}`]) || [],
  };

  const customerName = `${project.customer?.title}. ${project.customer?.first_name} ${project.customer?.last_name}` || 'Customer Name';

  const projectDate = project?.date?.split('T')[0];

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          <View style={styles.header}>
            <Image src={logo} style={styles.logo} />
            <Text style={styles.mainTitle}>{systemName} - BOQ</Text>
          </View>

          <View style={styles.customerTable}>
            <View style={styles.customerTableRow}>
              <Text>
                <Text style={{ fontWeight: 'demibold' }}>Customer Name:-</Text> {customerName}
              </Text>
            </View>
            <View style={styles.customerTableRow}>
              <Text>
                <Text style={{ fontWeight: 'demibold' }}>GP Invoice Number :-</Text> {}
              </Text>
            </View>
            <View style={styles.customerTableRow}>
              <Text>
                <Text style={{ fontWeight: 'demibold' }}>Date:-</Text> {projectDate}
              </Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.colDescription}>
                <Text style={styles.tableHeader}>Item Description</Text>
              </View>
              <View style={styles.colQuantity}>
                <Text style={styles.tableHeader}>Quantity</Text>
              </View>
            </View>
            <View style={styles.section}>
              <Text style={[styles.accessoryHeader, styles.tableRow]}>Inverter</Text>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.colDescription}>
                <Text style={styles.tableCell}>{inverters[0]}</Text>
              </View>
              <View style={styles.colQuantity}>
                <Text style={styles.tableCell}>{inverters[1]}</Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={[styles.accessoryHeader, styles.tableRow]}>PV Modules</Text>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.colDescription}>
                <Text style={styles.tableCell}>{panels[0]}</Text>
              </View>
              <View style={styles.colQuantity}>
                <Text style={styles.tableCell}>{panels[1]}</Text>
              </View>
            </View>

            {Object.entries(accessories).map(([accessoryName, items], index) => (
              <View key={index} style={styles.section}>
                <Text style={[styles.accessoryHeader, styles.tableRow]}>{accessoryName}</Text>
                {items.map((item: any, itemIndex: number) => (
                  <View key={itemIndex} style={styles.tableRow} wrap={false}>
                    <View style={styles.colDescription}>
                      <Text style={styles.tableCell}>{item[0]}</Text>
                    </View>
                    <View style={styles.colQuantity}>
                      <Text style={styles.tableCell}>{item[1]}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </View>
          <View style={styles.signatureBox}>
            <View style={[styles.signatureSectionLeft]}>
              <Text style={{ textAlign: 'left' }}>……………………………………………</Text>
              <Text style={{ textAlign: 'left' }}>Prepared by:- {currentUser?.username}</Text>
              <Text style={{ textAlign: 'left' }}>Date:- {new Date()?.toISOString()?.split('T')[0]}</Text>
            </View>
            <View style={[styles.signatureSectionRight]}>
              <Text style={{ textAlign: 'right' }}>……………………………………………</Text>
              <Text style={{ textAlign: 'right' }}>Checked By</Text>
            </View>
          </View>
        </View>
        {/* Page Number */}
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 40,
    paddingRight: 40,
    fontFamily: 'Calibri',
  },

  header: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
  },
  logo: {
    width: 100,
    marginRight: 10,
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },

  customerTable: {
    display: 'flex',
    marginTop: 20,
    borderTop: '1px solid gray',
    borderLeft: '1px solid gray',
    borderRight: '1px solid gray',
  },

  customerTableRow: {
    paddingTop: 3,
    paddingBottom: 2,
    paddingHorizontal: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
  },

  section: {
    marginTop: 0,
  },

  accessoryHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 3,
  },

  table: {
    display: 'flex',
    marginTop: 20,
    border: '1px solid gray',
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid gray',
  },
  colDescription: {
    width: '80%',
    borderRightWidth: 1,
    borderRightColor: 'gray',
  },
  colQuantity: {
    width: '20%',
    borderRightColor: 'gray',
    textAlign: 'center',
  },
  tableHeader: {
    fontSize: 13,
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 3,
    fontWeight: 'bold',
    backgroundColor: '#81BFDA',
  },
  tableCell: {
    fontSize: 11,
    paddingTop: 4,
    paddingBottom: 1,
    paddingLeft: 5,
  },
  signatureBox: {
    width: '100%',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureSection: {
    flex: 1,
  },

  signatureSectionLeft: {},

  signatureSectionRight: {},

  signatureText: {
    marginBottom: 3,
  },
  signatureLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#000',
    marginBottom: 5,
  },

  pageNumber: {
    position: 'absolute',
    right: '60px',
    top: '30px',
    fontSize: 9,
    color: 'grey',
  },
});

export default BOQ;
