const WidgetTitle = ({ title }: { title: string }) => {
    return (
        <div className="w-full flex justify-center">
            <div className="font-bold text-lg tracking-wide pb-2">
                {title}
            </div>
        </div>
    )
}

export default WidgetTitle;