import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Inverter } from '../../../store/types/InverterTypes';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearInverterCreate,
  clearInverterDetails,
  clearInverterUpdate,
  createInverter,
  deleteInverter,
  getInverterDetails,
  updateInverter,
} from '../../../store/InverterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Input, Select } from '../../../components/FormComponents';
import AccordionComponent from '../../Accordion';
import Breadcrumbs from '../../Breadcrumbs';
import { footerHeight, navbarHeight } from '../../../utils/constants';
import { Button } from '../../Buttons';
import LoaderOverlay from '../../LoaderOverlay';
import SuccessModal from '../../SuccessModal';
import { GiPowerGenerator } from 'react-icons/gi';
import ConfettiExplosion from 'react-confetti-explosion';
import { FaTrashAlt } from 'react-icons/fa';
import DeleteModal from '../../DeleteModal';
import { toast } from 'react-toastify';

const InverterSchema = yup.object<Partial<Record<keyof Inverter, yup.AnySchema>>>({
  id: yup.string().optional(),
  brand: yup.string().required('Brand required.'),
  model: yup.string().required('Model number required.'),
  origin_country: yup.string().required('Origin country required.'),
  rated_out_voltage_ac: yup.number().required('Rated output voltage required.'),
  rated_in_voltage_dc: yup.number().required('Rated input voltage required.'),
  rated_power_output: yup.number().required('Rated power output required.'),
  max_in_voltage_dc: yup.number().required('Maximum input voltage required.'),
  max_in_current_dc: yup.number().required('Maximum input current required.'),
  max_out_voltage_ac: yup.number().required('Maximum output voltage required.'),
  max_out_current_ac: yup.number().required('Maximum output current required.'),
  rated_power_frequency: yup.number().required('Rated power frequency required.'),
  max_efficiency: yup
    .number()
    .transform((value, originalValue) => (isNaN(value) ? parseFloat(originalValue) : value))
    .required('Maximum efficiency required.'),
  operating_temperature: yup.string().required('Operating temperature required.'),
  degree_of_protection: yup.string().required('Degree of protection required.'),
  install_strings: yup.number().required('Install strings required.'),
  ac_cables: yup.number().required('AC Cables required.'),
  phase: yup.string().required('Phase required.'),
  warranty: yup.number().required('Warranty required.'),
  test_reports: yup.string().required('Test reports required.'),
  cost: yup.number().required('Cost required.'),
  supported_standards: yup.string().required('Support standards required.'),
  start_up_voltage: yup.number().required('Start up voltage required.'),
  mppt_voltage_range: yup.string().required('MPPT voltage rage required.'),
  mppt_input_count: yup.number().required('MPPT input count required.'),
});

const AddEditInverter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const inverter = useSelector((state: RootState) => state.inverter.details);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editable, setEditable] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const methods = useForm<Partial<Inverter>>({
    resolver: yupResolver(InverterSchema),
    defaultValues: inverter.data ?? { brand: 'Solis' },
  });

  useEffect(() => {
    if (id) {
      dispatch(getInverterDetails({ id: id }));
    }
    return () => {
      dispatch(clearInverterDetails());
    };
  }, [id]);

  useEffect(() => {
    inverter.data &&
      methods.reset({
        id: inverter.data.id,
        brand: inverter.data.brand,
        model: inverter.data.model,
        origin_country: inverter.data.origin_country,
        rated_out_voltage_ac: inverter.data.rated_out_voltage_ac,
        rated_in_voltage_dc: inverter.data.rated_in_voltage_dc,
        rated_power_output: inverter.data.rated_power_output,
        max_in_voltage_dc: inverter.data.max_in_voltage_dc,
        max_in_current_dc: inverter.data.max_in_current_dc,
        max_out_voltage_ac: inverter.data.max_out_current_ac,
        max_out_current_ac: inverter.data.max_out_current_ac,
        rated_power_frequency: inverter.data.rated_power_frequency,
        max_efficiency: inverter.data.max_efficiency,
        operating_temperature: inverter.data.operating_temperature,
        degree_of_protection: inverter.data.degree_of_protection,
        install_strings: inverter.data.install_strings,
        ac_cables: inverter.data.ac_cables,
        phase: inverter.data.phase,
        warranty: inverter.data.warranty,
        test_reports: inverter.data.test_reports,
        cost: inverter.data.cost,
        supported_standards: inverter.data.supported_standards,
        start_up_voltage: inverter.data.start_up_voltage,
        mppt_voltage_range: inverter.data.mppt_voltage_range,
        mppt_input_count: inverter.data.mppt_input_count,
      });
  }, [inverter.data, id]);

  const inverterCreateState = useSelector((state: RootState) => state.inverter.create);
  const inverterUpdateState = useSelector((state: RootState) => state.inverter.update);
  const inverterDeleteState = useSelector((state: RootState) => state.inverter.delete);

  const onSave: SubmitHandler<Partial<Inverter>> = (data) => {
    if (id) {
      dispatch(updateInverter(data as Inverter));
    } else {
      dispatch(createInverter(data as Inverter));
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    if (inverterDeleteState.data && !inverterDeleteState.error) {
      navigate('/inverters');
    }
  }, [inverterDeleteState.data]);

  const deleteAction = async () => {
    dispatch(deleteInverter({ id: id }));
  };

  const cancelAction = () => {
    setDeleteModal(false);
  };

  return (
    <div className='w-full bg-[#ECECEC] overflow-y-scroll' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='flex justify-between items-center p-6 bg-white'>
        <Breadcrumbs links={[{ name: 'Inverters', link: '/inverters' }, { name: (id ? 'Edit' : 'Add') + ' Inverter' }]} />

        {id && (
          <button className='flex gap-2 items-center font-bold hover:text-[#FF802F] mr-4' type='button' onClick={() => setDeleteModal(true)}>
            <span className='text-sm'>Delete</span> <FaTrashAlt className='text-xl' />
          </button>
        )}
      </div>

      {id && (
        <div className='mx-auto md:w-[95%] mt-10 flex gap-5'>
          <Button
            type='button'
            colorType={inverter.data?.hardware_accessories_status === 'Incomplete' ? 'danger' : 'success'}
            text='Hardware Accessories'
            className='w-64 text-sm h-12'
            onClick={() => navigate(`/inverter/hardware-accessories/${id}`)}
          />
          <Button
            type='button'
            colorType={inverter.data?.electrical_accessories_status === 'Incomplete' ? 'danger' : 'success'}
            text='Electrical Accessories'
            className='w-64 text-sm h-12'
            onClick={() => navigate(`/inverter/electrical-accessories/${id}`)}
          />
          <Button
            type='button'
            colorType={inverter.data?.cables_status === 'Incomplete' ? 'danger' : 'success'}
            text='Cables'
            className='w-64 text-sm h-12'
            onClick={() => navigate(`/inverter/cables/${id}`)}
          />
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          deleteAction={deleteAction}
          cancelAction={cancelAction}
          type='Inverter'
          name={inverter?.data?.brand + ' ' + inverter?.data?.model}
        />
      )}

      {inverterCreateState?.pending && <LoaderOverlay />}
      {isSubmitted && !inverterCreateState.error && <ConfettiExplosion /> && (
        <SuccessModal
          reactIcon={<GiPowerGenerator />}
          title='Congratulations'
          subtitle='Inverter Added'
          message='Please add associated Electrical, Hardware and Cable information for this inverter'
          buttonText='Go to Inverter List'
          linkTo={`/inverters/`}
        />
      )}
      {isSubmitted && inverterCreateState.error && toast.error('Invalid Project Data. Please check the inputs.')}

      <div className='mx-auto md:w-[95%] my-10'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)} className='w-full p-10 shadow-lg rounded bg-white'>
            <AccordionComponent title='General'>
              <div className='grid grid-cols-3 gap-4 rounded'>
                <Input name='brand' type='text' label='Brand of Inverter' readOnly={!editable} />
                <Input name='model' type='text' label='Model No.' readOnly={!editable} />
                <Input name='origin_country' type='text' label='Country of Origin' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Technical Details'>
              <div className='grid grid-cols-3 gap-4'>
                <Input name='rated_out_voltage_ac' type='number' label='Rated Output Voltage (AC)' readOnly={!editable} />
                <Input name='rated_in_voltage_dc' type='number' label='Rated Input Voltage (DC)' readOnly={!editable} />
                <Input name='rated_power_output' type='number' label='Rated Power Output (W)' readOnly={!editable} />
                <Input name='max_in_voltage_dc' type='number' label='Max Input Voltage (DC)' readOnly={!editable} />
                <Input name='max_in_current_dc' type='number' label='Max Input Current (DC)' readOnly={!editable} />
                <Input name='max_out_voltage_ac' type='number' label='Max Output Voltage (AC)' readOnly={!editable} />
                <Input name='max_out_current_ac' type='number' label='Max Output Current (AC)' readOnly={!editable} />
                <Input name='rated_power_frequency' type='number' label='Rated Power Frequency' readOnly={!editable} />
                <Input name='max_efficiency' type='text' label='Max Efficiency' readOnly={!editable} />
                <Input name='operating_temperature' type='text' label='Operating Temperature' readOnly={!editable} />
                <Input name='degree_of_protection' type='text' label='Degree of Protection' readOnly={!editable} />
                <Input name='install_strings' type='number' label='Model Install String Number' readOnly={!editable} />
                <Input name='ac_cables' type='number' label='Recommended AC Cable' readOnly={!editable} />
                <Select
                  name='phase'
                  label='Inverter Phase'
                  options={[
                    { label: 'Single', value: 'Single' },
                    { label: 'Three', value: 'Three' },
                  ]}
                  readOnly={!editable}
                />
                <Input name='test_reports' type='text' label='Test Reports' readOnly={!editable} />
                <Input name='start_up_voltage' type='number' label='Brand Start-up Voltage' readOnly={!editable} />
                <Input name='mppt_voltage_range' type='text' label='MPPT Voltage Range (V)' readOnly={!editable} />
                <Input name='mppt_input_count' type='number' label='MPPT Input Count' readOnly={!editable} />
                <Input name='supported_standards' type='text' label='Supported Standards' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Financial & Warranty'>
              <div className='grid grid-cols-3 gap-4'>
                <Input name='warranty' type='text' label='Inverter Warranty' readOnly={!editable} />
                <Input name='cost' type='text' label='Inverter Cost' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <div className='w-full flex justify-end'>
              <Button text='Save' type='submit' colorType='success' className='w-64 h-12' />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddEditInverter;
