import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CustomSelect, Input } from '../CustomInput';
import { Customer } from '../../store/types/CustomerTypes';
import { ButtonOrange } from '../Buttons';

type Props = {
  editable: boolean;
  updateCustomer?: Partial<Customer> | Customer | null;
  customer: Customer | null;
  onInputChange: any;
  onFormSubmit: any;
};

const CustomerForm: React.FC<Props> = ({
  editable,
  updateCustomer,
  customer,
  onInputChange,
  onFormSubmit,
}) => {
  const titleOptions = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Ven.', value: 'Ven.' },
  ];

  const getTitle = (value: string) => {
    const title = titleOptions.find((item) => item.value === value);
    return title;
  };

  return (
    <form
      onSubmit={(e) => onFormSubmit(e)}
      className="w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white"
    >
      <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-5 ">
        <CustomSelect
          label="Title"
          disabled={!editable}
          options={titleOptions}
          value={getTitle(updateCustomer?.title ?? customer?.title ?? '')}
          onChange={(value) => {
            const fieldName = 'title';
            onInputChange({ fieldName, value: value.value });
          }}
        />
        <Input
          label="First Name"
          type="text"
          disabled={!editable}
          value={updateCustomer?.first_name ?? customer?.first_name ?? ''}
          onChange={(e) => {
            const fieldName = 'first_name';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="Last Name"
          type="text"
          disabled={!editable}
          value={updateCustomer?.last_name ?? customer?.last_name ?? ''}
          onChange={(e) => {
            const fieldName = 'last_name';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-5">
        <Input
          label="Email"
          type="text"
          disabled={!editable}
          value={updateCustomer?.email ?? customer?.email ?? ''}
          onChange={(e) => {
            const fieldName = 'email';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="Phone"
          type="text"
          disabled={!editable}
          value={updateCustomer?.phone ?? customer?.phone ?? ''}
          onChange={(e) => {
            const fieldName = 'phone';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="NIC"
          type="text"
          disabled={!editable}
          value={updateCustomer?.nic ?? customer?.nic ?? ''}
          onChange={(e) => {
            const fieldName = 'nic';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-5">
        <Input
          label="House No."
          type="text"
          disabled={!editable}
          value={updateCustomer?.house_no ?? customer?.house_no ?? ''}
          onChange={(e) => {
            const fieldName = 'house_no';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="Street"
          type="text"
          disabled={!editable}
          value={updateCustomer?.street ?? customer?.street ?? ''}
          onChange={(e) => {
            const fieldName = 'street';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
        <Input
          label="City"
          type="text"
          disabled={!editable}
          value={updateCustomer?.city ?? customer?.city ?? ''}
          onChange={(e) => {
            const fieldName = 'city';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
      </div>
      {editable && <ButtonOrange type="submit">SAVE</ButtonOrange>}
    </form>
  );
};

export default CustomerForm;
