import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, EngineerFee } from './types/EngineerFeeTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllEngineerFees = createAsyncThunk('engineerFee/get', async (): Promise<any> => {
  const res = await axios.get(`${api}/engineer-fee`);
  return res.data;
});

export const createEngineerFee = createAsyncThunk('engineerFee/create', async (data: EngineerFee): Promise<any> => {
  const res = await axios.post(`${api}/engineer-fee`, data);
  toast.success('Engineer Fee Added successfully');
  return res.data;
});

export const updateEngineerFee = createAsyncThunk('engineerFee/update', async (data: EngineerFee): Promise<any> => {
  const res = await axios.put(`${api}/engineer-fee/${data.id}`, data);
  toast.success('Engineer Fee Updated successfully');
  return res.data;
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
};

const engineerFeeSlice = createSlice({
  name: 'EngineerFee',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL Engineer Fees
    builder.addCase(getAllEngineerFees.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllEngineerFees.rejected, (state) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
    builder.addCase(getAllEngineerFees.pending, (state) => {
      state.list.pending = true;
      state.list.error = false;
    });

    // CREATE Engineer Fee
    builder.addCase(createEngineerFee.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createEngineerFee.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(createEngineerFee.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });

    // UPDATE Engineer Fee
    builder.addCase(updateEngineerFee.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.pending = false;
      state.update.error = false;
    });
    builder.addCase(updateEngineerFee.rejected, (state) => {
      state.update.pending = false;
      state.update.error = true;
    });
    builder.addCase(updateEngineerFee.pending, (state) => {
      state.update.pending = true;
      state.update.error = false;
    });
  },
});

export default engineerFeeSlice.reducer;
