import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../store/CustomerSlice';
import type { RootState, AppDispatch } from '../../store';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { getInverterList } from '../../store/InverterSlice';
import { getPanelList } from '../../store/PanelSlice';
import { getTariffList } from '../../store/TariffSlice';
import { getAllUtilities } from '../../store/UtilitySlice';
import { createProject } from '../../store/ProjectsSlice';
import Breadcrumbs from '../Breadcrumbs';
import ConfettiExplosion from 'react-confetti-explosion';
import SuccessModal from '../SuccessModal';
import { FaProjectDiagram } from 'react-icons/fa';
import { getCircuitList } from '../../store/CircuitSlice';
import { getAllUsers } from '../../store/UserSlice';
import ProjectForm from './ProjectForm';
import LoaderOverlay from '../LoaderOverlay';
import { toast } from 'react-toastify';

const NewProject = () => {
  const dispatch: AppDispatch = useDispatch();

  const projectCreateState = useSelector((state: RootState) => state.project.create);

  const [project, setProject] = useState<any>({
    date: new Date()?.toISOString()?.split('T')[0],
    tarifftype_id: '',
    customer_id: '',
    address: '',
    street: '',
    city: '',
    phone: '',
    email: '',
    monthly_avg: 0,
    account_no: '',
    utility_id: '',
    utility_branch: '',
    roof_type: 'asbestos',
    circuit_id: '',
    assigned_user: '',
    solar_scheme: '',
    discount: 0,
    discount_type: 'PERCENTAGE',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [inverterList, setInverterList] = useState<any[]>([]);

  const [panelList, setPanelList] = useState<any[]>([]);

  // PV Module Configuration
  const [panel, setPanel] = useState<any>(null);

  // Set project fields
  const handleInputChange = ({ fieldName, value }: any) => {
    setProject({
      ...project,
      [fieldName]: value,
    });
  };

  const submitProject = async (e: any) => {
    e.preventDefault();

    const panels: any[] = [];

    panelList.forEach((panel: any) => {
      for (let i = 0; i < panel?.quantity; i++) {
        const { quantity, ...panelData } = panel;
        panels.push(panelData);
      }
    });

    const payload = {
      date: project.date,
      customer_id: project.customer_id,
      panels: panels,
      inverters: inverterList,
      coordinates: {
        lat: project.lat,
        lon: project.lon,
      },
      address: [project.address, project.street, project.city],
      roof_type: project.roof_type,
      utility_id: project.utility_id,
      monthly_avg: project.monthly_avg,
      utility_branch: project.utility_branch,
      tarifftype_id: project.tarifftype_id,
      phase: project.phase,
      phone: project.phone,
      email: project.email,
      account_no: project.account_no,
      circuit_id: project.circuit_id,
      assigned_user: project.assigned_user,
      solar_scheme: project.solar_scheme,
      discount: project.discount,
      discount_type: project.discount_type,
    };

    await dispatch(createProject(payload));
    setIsSubmitted(true);

    setProject(null);
  };

  useEffect(() => {
    dispatch(getAllCustomers({}));
    dispatch(getInverterList({}));
    dispatch(getPanelList({}));
    dispatch(getTariffList({}));
    dispatch(getAllUtilities({}));
    dispatch(getCircuitList({}));
    dispatch(getAllUsers());
  }, [dispatch]);

  const links = [
    {
      link: '/projects',
      name: 'Projects',
    },
    {
      link: '',
      name: 'New Project',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      {projectCreateState.pending && <LoaderOverlay />}
      {isSubmitted && !projectCreateState.error && <ConfettiExplosion /> && (
        <SuccessModal
          reactIcon={<FaProjectDiagram />}
          title='Congratulations'
          subtitle='Project Created Successfully!'
          buttonText='Go to Project List'
          linkTo='/projects'
        />
      )}
      {isSubmitted && projectCreateState.error && toast.error('Error creating project!')}
      <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>

      <ProjectForm
        project={project}
        handleInputChange={handleInputChange}
        editable={true}
        setProject={setProject}
        onFormSubmit={submitProject}
        inverterList={inverterList}
        setInverterList={setInverterList}
        panel={panel}
        setPanel={setPanel}
        setPanelList={setPanelList}
        panelList={panelList}
      />
    </div>
  );
};

export default NewProject;
