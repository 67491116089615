import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/leco-annex2-iv/1.png';
import img2 from '../../images/documents/leco-annex2-iv/2.png';
import img3 from '../../images/documents/leco-annex2-iv/3.png';
import img4 from '../../images/documents/leco-annex2-iv/4.png';
import installerDetails from '../../config/installerDetails';

const LECOAnnex2 = ({ project }: any) => {
  const systemCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  const commissioningDate = new Date(project?.created_at)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  const installationDate = new Date(project?.actual_installation_date)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />

          {/* Basic System Informaation */}
          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.contact_no}>{project?.customer.phone}</Text>
          <Text style={styles.address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.email}>{project?.customer.email}</Text>
          <Text style={styles.system_power}>{systemCapacity}</Text>
          <Text style={styles.identification_ref}>{project?.id}</Text>
          <Text style={styles.installation_date}>
            {project?.installationDate}
          </Text>
          <Text style={styles.commissioning_date}>{commissioningDate}</Text>

          <>
            <Text style={styles.inverter}>
              {project?.project_inverters[0]?.inverter.brand}
            </Text>
            <Text style={styles.manufacturer}>
              {project?.project_inverters[0]?.inverter.brand}
            </Text>
            <Text style={styles.model}>
              {project?.project_inverters[0]?.inverter.model}
            </Text>
          </>

          <Text style={styles.quantity}>
            {project?.project_inverters.length}
          </Text>

          <Text style={styles.pv_modules}>
            {project?.project_panels[0]?.panel.brand}
          </Text>
          <Text style={styles.pv_manufacturer}>
            {project?.project_panels[0]?.panel.brand}
          </Text>
          <Text style={styles.pv_model}>
            {project?.project_panels[0]?.panel.model}
          </Text>
          <Text style={styles.pv_quantity}>{project?.no_of_panels}</Text>

          {/* System Designer Info */}

          <Text style={styles.designer_company}>{installerDetails.name}</Text>
          <Text style={styles.designer_contact_person}>
            {installerDetails.contact_person}
          </Text>
          <Text style={styles.designer_postal_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.designer_phone}>
            {installerDetails.phone_1}/ {installerDetails.phone_2}
          </Text>
          <Text style={styles.designer_email}>{installerDetails.email}</Text>

          <Text style={styles.installer_company}>{installerDetails.name}</Text>
          <Text style={styles.installer_contact_person}>
            {installerDetails.contact_person}
          </Text>
          <Text style={styles.installer_postal_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.installer_phone}>
            {installerDetails.phone_1}/ {installerDetails.phone_2}
          </Text>
          <Text style={styles.installer_email}>{installerDetails.email}</Text>
        </View>
      </Page>

      {/* page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />
        </View>
      </Page>

      {/* page 3 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_3}>
          <Image src={img3} style={styles.image} />

          {/* Array Specs */}
          <Text style={styles.array_specs_1}>
            {project?.project_panels[0]?.panel.brand}
          </Text>
          <Text style={styles.array_specs_2}>
            {project?.project_panels[0]?.panel.cell_type}
          </Text>
          <Text style={styles.array_specs_3}>{project?.no_of_panels} Nos.</Text>

          {project?.project_inverters.map((inv: any, idx: number) => (
            <Text style={styles.array_specs_4}>
              {inv.inverter.install_strings} Nos.
            </Text>
          ))}

          <Text style={styles.array_specs_5}>
            {project?.no_of_panels / 2} X{' '}
            {project?.project_inverters.map((inv: any, idx: number) => (
              <Text style={styles.array_specs_4}>
                {inv.inverter.install_strings}
              </Text>
            ))}{' '}
            Nos.
          </Text>

          {/* PV String */}
          <Text style={styles.pv_string_1}>
            {project?.circuit?.dc_cable_type}
          </Text>
          <Text style={styles.pv_string_2}>
            {project?.circuit?.dc_cable_size} mm
          </Text>
          <Text style={styles.pv_string_3}>
            {project?.circuit?.dc_ocp_type}
          </Text>
          <Text style={styles.pv_string_4}>
            {project?.circuit?.dc_ocp_voltage_current_rating}
          </Text>
          <Text style={styles.pv_string_5}>N/A</Text>

          {/* Electrical Details */}
          <Text style={styles.electrical_details_1}>
            {project?.circuit?.ac_cable_type}
          </Text>
          <Text style={styles.electrical_details_2}>
            {project?.circuit?.ac_cable_size} mm
          </Text>
          <Text style={styles.electrical_details_3}>N/A</Text>
          <Text style={styles.electrical_details_4}>
            {project?.circuit?.dc_switch_type}
          </Text>
          <Text style={styles.electrical_details_5}>
            {project?.circuit?.dc_switch_voltage_current_rating}
          </Text>
          <Text style={styles.electrical_details_6}>
            {project?.circuit?.dc_ocp_type}
          </Text>
          <Text style={styles.electrical_details_7}>
            {project?.circuit?.dc_ocp_voltage_current_rating}
          </Text>
          <Text style={styles.electrical_details_8}>N/A</Text>
          <Text style={styles.electrical_details_9}>N/A</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.view_4}>
          <Image src={img4} style={styles.image} />

          {/* AC system */}
          <Text style={styles.ac_system_1}>
            {project?.circuit?.ac_isolator_type}
          </Text>
          <Text style={styles.ac_system_2}>
            {project?.circuit?.ac_isolator_voltage_current_rating}
          </Text>
          <Text style={styles.ac_system_3}>{project?.circuit?.ocp_type}</Text>
          <Text style={styles.ac_system_4}>
            {project?.circuit?.ocp_voltage_current_rating}
          </Text>
          <Text style={styles.ac_system_5}>{project?.circuit?.rcd_type}</Text>
          <Text style={styles.ac_system_6}>
            {project?.circuit?.rcd_current_rating}
          </Text>

          {/* Earthing And Protection */}
          <Text style={styles.earthing_protection_1}>
            {project?.circuit?.earthing_type}
          </Text>
          <Text style={styles.earthing_protection_2}>
            {project?.circuit?.earthing_size} mm2
          </Text>
          <Text style={styles.earthing_protection_3}>N/A</Text>
          <Text style={styles.earthing_protection_4}>
            {project?.circuit?.dc_spd_type}, See attached
          </Text>
          <Text style={styles.earthing_protection_5}>
            {project?.circuit?.ac_spd_type}, See attached
          </Text>

          {/* Inverter */}
          <Text style={styles.inv_1}>
            {project?.project_inverters[0]?.inverter.brand}
          </Text>
          <Text style={styles.inv_2}>
            {project?.project_inverters[0]?.inverter.model}
          </Text>
          <Text style={styles.inv_3}>
            {(
              project?.project_inverters[0]?.inverter.rated_power_output / 1000
            ).toFixed(1)}
            kW
          </Text>
          <Text style={styles.inv_4}>
            {project?.project_inverters.length} Nos.
          </Text>

          {/* Main Circuit Breaker */}
          <Text style={styles.main_cb_1}>{project?.circuit?.mcb_type}</Text>
          <Text style={styles.main_cb_2}>
            {project?.circuit?.mcb_voltage_current_rating}
          </Text>
          <Text style={styles.main_cb_3}>
            {project?.circuit?.mcb_short_circuit_current}
          </Text>
          <Text style={styles.main_cb_4}>{project?.circuit?.mcb_ip_class}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default LECOAnnex2;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  // Basic System Info

  customer_name: {
    position: 'absolute',
    top: 213,
    left: 55,
    textTransform: 'capitalize',
  },

  contact_no: {
    position: 'absolute',
    top: 213,
    left: 265,
  },

  address: {
    position: 'absolute',
    top: 243,
    left: 55,
    width: 200,
    flex: 1,
    textTransform: 'capitalize',
  },
  email: {
    position: 'absolute',
    top: 243,
    left: 265,
  },

  system_power: {
    position: 'absolute',
    top: 283,
    left: 55,
  },
  identification_ref: {
    position: 'absolute',
    top: 283,
    left: 265,
  },

  installation_date: {
    position: 'absolute',
    top: 313,
    left: 55,
  },
  commissioning_date: {
    position: 'absolute',
    top: 313,
    left: 265,
  },

  inverter: {
    position: 'absolute',
    top: 328,
    left: 130,
    textTransform: 'capitalize',
  },
  manufacturer: {
    position: 'absolute',
    top: 338,
    left: 130,
    textTransform: 'capitalize',
  },

  model: {
    position: 'absolute',
    top: 348,
    left: 130,
  },
  quantity: {
    position: 'absolute',
    top: 358,
    left: 130,
  },

  pv_modules: {
    position: 'absolute',
    top: 328,
    left: 340,
    textTransform: 'capitalize',
  },
  pv_manufacturer: {
    position: 'absolute',
    top: 338,
    left: 340,
    textTransform: 'capitalize',
  },

  pv_model: {
    position: 'absolute',
    top: 348,
    left: 340,
  },
  pv_quantity: {
    position: 'absolute',
    top: 358,
    left: 340,
  },

  // System Designer Info

  designer_company: {
    position: 'absolute',
    top: 403,
    left: 55,
    textTransform: 'capitalize',
  },
  designer_contact_person: {
    position: 'absolute',
    top: 403,
    left: 265,
    textTransform: 'capitalize',
  },

  designer_postal_address: {
    position: 'absolute',
    top: 438,
    left: 55,
    width: 200,
    flex: 1,
    textTransform: 'capitalize',
  },
  designer_phone: {
    position: 'absolute',
    top: 435,
    left: 265,
  },

  designer_email: {
    position: 'absolute',
    top: 446,
    left: 265,
  },

  // System Installer Info

  installer_company: {
    position: 'absolute',
    top: 498,
    left: 55,
    textTransform: 'capitalize',
  },
  installer_contact_person: {
    position: 'absolute',
    top: 498,
    left: 265,
    textTransform: 'capitalize',
  },

  installer_postal_address: {
    position: 'absolute',
    top: 531,
    left: 55,
    width: 200,
    flex: 1,
    textTransform: 'capitalize',
  },
  installer_phone: {
    position: 'absolute',
    top: 528,
    left: 265,
  },

  installer_email: {
    position: 'absolute',
    top: 538,
    left: 265,
  },

  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },
  view_3: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  // Array specs

  array_specs_1: {
    position: 'absolute',
    top: 125,
    left: 310,
  },

  array_specs_2: {
    position: 'absolute',
    top: 147,
    left: 310,
  },
  array_specs_3: {
    position: 'absolute',
    top: 169,
    left: 310,
  },

  array_specs_4: {
    position: 'absolute',
    top: 191,
    left: 310,
  },
  array_specs_5: {
    position: 'absolute',
    top: 213,
    left: 310,
  },

  // PV String

  pv_string_1: {
    position: 'absolute',
    top: 250,
    left: 310,
  },

  pv_string_2: {
    position: 'absolute',
    top: 270,
    left: 310,
  },
  pv_string_3: {
    position: 'absolute',
    top: 298,
    left: 310,
  },

  pv_string_4: {
    position: 'absolute',
    top: 328,
    left: 310,
  },
  pv_string_5: {
    position: 'absolute',
    top: 353,
    left: 310,
  },

  // Array Electrical Details

  electrical_details_1: {
    position: 'absolute',
    top: 400,
    left: 310,
  },

  electrical_details_2: {
    position: 'absolute',
    top: 420,
    left: 310,
  },
  electrical_details_3: {
    position: 'absolute',
    top: 440,
    left: 310,
  },

  electrical_details_4: {
    position: 'absolute',
    top: 460,
    left: 310,
  },
  electrical_details_5: {
    position: 'absolute',
    top: 490,
    left: 310,
  },
  electrical_details_6: {
    position: 'absolute',
    top: 525,
    left: 310,
  },
  electrical_details_7: {
    position: 'absolute',
    top: 555,
    left: 310,
  },
  electrical_details_8: {
    position: 'absolute',
    top: 580,
    left: 310,
  },
  electrical_details_9: {
    position: 'absolute',
    top: 610,
    left: 310,
  },

  view_4: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  // AC System

  ac_system_1: {
    position: 'absolute',
    top: 160,
    left: 310,
  },

  ac_system_2: {
    position: 'absolute',
    top: 180,
    left: 310,
  },
  ac_system_3: {
    position: 'absolute',
    top: 207,
    left: 310,
  },
  ac_system_4: {
    position: 'absolute',
    top: 240,
    left: 310,
  },
  ac_system_5: {
    position: 'absolute',
    top: 265,
    left: 310,
  },

  ac_system_6: {
    position: 'absolute',
    top: 283,
    left: 310,
  },

  // Earthing and Protection

  earthing_protection_1: {
    position: 'absolute',
    top: 322,
    left: 310,
  },

  earthing_protection_2: {
    position: 'absolute',
    top: 345,
    left: 310,
  },
  earthing_protection_3: {
    position: 'absolute',
    top: 368,
    left: 310,
  },

  earthing_protection_4: {
    position: 'absolute',
    top: 393,
    left: 310,
  },

  earthing_protection_5: {
    position: 'absolute',
    top: 425,
    left: 310,
  },

  // Inverter
  inv_1: {
    position: 'absolute',
    top: 487,
    left: 310,
  },

  inv_2: {
    position: 'absolute',
    top: 508,
    left: 310,
  },
  inv_3: {
    position: 'absolute',
    top: 530,
    left: 310,
  },
  inv_4: {
    position: 'absolute',
    top: 547,
    left: 310,
  },

  // Main Circuit Breaker
  main_cb_1: {
    position: 'absolute',
    top: 585,
    left: 310,
  },

  main_cb_2: {
    position: 'absolute',
    top: 610,
    left: 310,
  },
  main_cb_3: {
    position: 'absolute',
    top: 630,
    left: 310,
  },
  main_cb_4: {
    position: 'absolute',
    top: 650,
    left: 310,
  },
});
