import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { Paper } from '@mui/material';

import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid';

import Breadcrumbs from '../../Breadcrumbs';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { InstallationCost } from '../../../store/types/InstallationCostTypes';
import { getAllInstallationCosts, updateInstallationCost } from '../../../store/InstallationCostSlice';
import LoadingSkeleton from '../../LoadingSkeleton';

export default function InstallationCostsTable() {
  const dispatch: AppDispatch = useDispatch();

  const installationCostList = useSelector((state: RootState) => state.installationCost.list);

  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = React.useState(installationCostList.data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [sortedData, setSortedData] = useState<InstallationCost[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    dispatch(getAllInstallationCosts({}));
  }, [dispatch]);

  useEffect(() => {
    setRows(installationCostList.data);
  }, [installationCostList.data]);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (data: GridRowModel) => {
    const updatedData = {
      id: data.id,
      min: Number(data.min),
      max: Number(data.max),
      tile_cost: Number(data.tile_cost),
      asbestos_cost: Number(data.asbestos_cost),
    };
    await dispatch(updateInstallationCost(updatedData));

    return updatedData;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'min',
      headerName: 'Min System Capacity (kW)',
      editable: true,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      type: 'number',
    },
    {
      field: 'max',
      headerName: 'Max System Capacity (kW)',
      editable: true,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      type: 'number',
    },
    {
      field: 'tile_cost',
      headerName: 'Tile Roof (LKR/kW)',
      editable: true,
      sortable: false,
      filterable: false,
      type: 'number',
      align: 'right',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'asbestos_cost',
      headerName: 'Asbestos Roof (LKR/kW)',
      editable: true,
      filterable: false,
      sortable: false,
      type: 'number',
      align: 'right',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [<GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />];
      },
    },
  ];

  useEffect(() => {
    // Sorting logic based on updated_at
    const sorted = [...installationCostList.data].sort((a, b) => {
      const dateA: any = a.updated_at ? new Date(a.updated_at) : null;
      const dateB: any = b.updated_at ? new Date(b.updated_at) : null;

      if (dateA && dateB) {
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      // Handle the case where either date is undefined
      return 0;
    });

    setSortedData(sorted);
  }, [sortOrder, searchTerm, installationCostList]);

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '',
      name: 'Installation Cost',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white relative'>
        <Breadcrumbs links={links} />
        <Link to='/financials/add-installation-cost' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>Add Installation Cost</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>

      <Paper className='w-full md:w-[95%] h-full border rounded p-5 md:p-10 md:my-10 shadow-lg bg-white overflow-y-auto relative'>
        {installationCostList.data.length > 0 && (
          <DataGrid
            processRowUpdate={processRowUpdate}
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnSelector
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            className='font-bold'
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              fontSize: '12px',
              fontWeight: 'bold',

              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#CBE6FA',
              },

              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'flex',
              },

              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '14px',
                marginX: 'auto',
              },
            }}
          />
        )}
        {installationCostList.pending && <LoadingSkeleton numColumns={1} />}
        {!installationCostList.error && !installationCostList.pending && installationCostList.data.length === 0 && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2  text-red-400 font-semibold'>Data Not Available!</div>
        )}

        {!installationCostList.pending && installationCostList.error && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2  text-red-400 font-semibold'>Error occurred!</div>
        )}
      </Paper>
    </div>
  );
}
