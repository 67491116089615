import React, { useEffect, useState } from 'react';
import { Modal, Paper } from '@mui/material';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '../../../store/types/PanelTypes';
import { getPanelList } from '../../../store/PanelSlice';
import { CustomSelect } from '../../CustomInput';
import { ButtonCustom } from '../../Buttons';

import { CgClose } from 'react-icons/cg';

type Props = {
  mode: 'EDIT' | 'ADD';
  confirmAction: () => void;
  selectedPanel: any;
  setSelectedPanel: any;
  closeModal: any;
};

const PvModuleSelection: React.FC<Props> = ({ confirmAction, selectedPanel, setSelectedPanel, closeModal, mode }) => {
  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius: 5,
    paddingY: 8,
    paddingX: 5,
  };

  const panels = useSelector((state: RootState) => state.panel.list.data);
  const panelOptions = panels.map((panel) => ({
    label: panel.model,
    value: panel,
  }));

  const [selectedAccessory, setSelectedAccessory] = useState<'railing'>('railing');

  const dispatch: AppDispatch = useDispatch();

  const setPanelData = (panel: Panel | undefined) => {
    const railings = panel?.railings?.map((railing) => ({
      id: railing.railing.id,
      utilization: railing.utilization,
      name: railing.railing.name,
      cost: railing.railing.cost,
    }));

    setSelectedPanel((prev: any) => ({
      id: panel?.id,
      brand: panel?.brand,
      model: panel?.model,
      railings: railings,
      cost: panel?.cost,
      quantity: prev?.quantity,
    }));
  };

  const accessories: { id: string; utilization: number; name: string }[] = selectedPanel?.railings;

  useEffect(() => {
    dispatch(getPanelList({}));
  }, [dispatch]);

  const changeAccessoryQuantity = (id: string, utilization: number, type: string) => {
    setSelectedPanel((prev: any) => ({
      ...selectedPanel,
      railings: prev.railings.map((item: any) => (item.id === id ? { ...item, utilization: utilization } : item)),
    }));
  };

  const handleClose = () => {
    setSelectedPanel(null);
    closeModal();
  };

  return (
    <Modal open={true} style={modalStyle} onClose={closeModal}>
      <Paper sx={[paperStyle, selectedPanel !== null && { height: '90%' }]}>
        <button onClick={handleClose} className='absolute top-5 right-5 text-3xl text-gray-500 hover:scale-90'>
          <CgClose />
        </button>
        <div className='flex w-full gap-3 justify-between items-center'>
          <h2 className='font-semibold text-lg text-orange-500'>
            {mode === 'ADD' && selectedPanel === null ? 'Select an PV module' : 'Configure PV module options'}
          </h2>
          {mode === 'ADD' && (
            <CustomSelect onChange={(e: { value: Panel | undefined }) => setPanelData(e?.value)} options={panelOptions} value={undefined} />
          )}
          <input
            onChange={(e) =>
              setSelectedPanel({
                ...selectedPanel,
                quantity: Number(e.target.value),
              })
            }
            value={selectedPanel?.quantity}
            type='number'
            placeholder='Quantity'
            className='border rounded px-3 py-2 outline-orange-400'
            min={1}
          />
        </div>
        {selectedPanel !== null && (
          <div className='my-5 flex flex-col h-full'>
            <div>
              <button onClick={() => setSelectedAccessory('railing')} className='p-2  border shadow-none border-b-0 bg-orange-50'>
                Railings & Accessories
              </button>
            </div>
            <div className='overflow-y-auto h-4/5 pr-6 py-10 bg-orange-50'>
              {accessories?.map((accessory) => (
                <div className='flex w-full justify-between p-3 border-b' key={accessory.id}>
                  <p>{accessory?.name}</p>
                  <input
                    type='number'
                    className='p-2 w-20'
                    value={accessory.utilization}
                    onChange={(e) => {
                      changeAccessoryQuantity(accessory.id, Number(e.target.value), selectedAccessory);
                    }}
                  />
                </div>
              ))}
            </div>

            <ButtonCustom
              onClick={() => confirmAction()}
              customStyles='bg-green-600 mt-5 mb-16'
              disabled={selectedPanel === null || isNaN(selectedPanel?.quantity) || selectedPanel.quantity < 1 || !selectedPanel.id}
            >
              Save
            </ButtonCustom>
          </div>
        )}
      </Paper>
    </Modal>
  );
};

export default PvModuleSelection;
