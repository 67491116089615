import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getTasksByUser } from '../../store/TaskSlice';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import WidgetTitle from './WidgetTitle';

const ActiveTasks = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const taskList = useSelector((state: RootState) => state.task.list);

  useEffect(() => {
    dispatch(getTasksByUser());
  }, [dispatch]);

  return (
    <div className="rounded p-8">
      <WidgetTitle title='Upcoming Tasks' />

      <div className='w-[100%] h-[350px]'>
        {taskList.pending ? (
          <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
        ) : taskList.data.length ? (
          <div className='divide-y'>
            <table className='divide-y'>
              <thead>
                <tr className="text-left">
                  <th className='pr-10 pb-2'>Status</th>
                  <th className='pr-10 pb-2'>Due Date</th>
                  <th className='pr-10 pb-2'>Completed</th>
                  <th className='pr-10 pb-2'>Location</th>
                </tr>
              </thead>
              <tbody className="divide-y text-sm">
                {taskList.data.map((task: any) => (
                  <tr className='cursor-pointer hover:bg-gray-100' onClick={() => navigate(`/projects/${task.project_id}`)}>
                    <td className="py-2">
                      {task?.task?.slice(0, 1).toUpperCase() + task?.task?.slice(1)}
                    </td>
                    <td>{task?.due_date?.split('T')[0]}</td>
                    <td>{task?.completed_date?.split('T')[0] ?? 'TBD'}</td>
                    <td>{task?.project?.address[2]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='flex justify-center items-center w-[100%] h-[100%] font-semibold text-slate-600'>
            No upcoming tasks
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveTasks;
