import React, { useState } from 'react';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { Utility } from '../../store/types/UtilityTypes';
import { createUtility } from '../../store/UtilitySlice';
import UtilityForm from './UtilityForm';
import Breadcrumbs from '../Breadcrumbs';
import LoaderOverlay from '../LoaderOverlay';

type Props = {};

const NewUtility: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const utilityCreateState = useSelector(
    (state: RootState) => state.utility.create
  );

  const [utility, setUtility] = useState<Utility>({
    name: '',
    cost: '',
  });

  const handleInputChange = ({ fieldName, value }: any) => {
    setUtility({
      ...utility,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(createUtility(utility));

    if (!utilityCreateState.error) {
      navigate('/utilities');
    }
  };

  const links = [
    {
      link: '/utilities',
      name: 'Utilities',
    },
    {
      link: '',
      name: 'New Utilities',
    },
  ];

  return (
    <div
      className="w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]"
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className="flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white">
        <Breadcrumbs links={links} />
      </div>
      {utilityCreateState?.pending && <LoaderOverlay />}
      <UtilityForm
        utility={utility}
        onInputChange={handleInputChange}
        onFormSubmit={handleFormSubmit}
        editable={true}
      />
    </div>
  );
};

export default NewUtility;
