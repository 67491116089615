import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState, Customer } from './types/CustomerTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllCustomers = createAsyncThunk(
  'customers/get',
  async (filters: any): Promise<any> => {
    const response = await axios.get(`${api}/customers`);

    return response.data;
  }
);

export const getOneCustomer = createAsyncThunk(
  'customers/getOne',
  async (customerId: string | undefined): Promise<any> => {
    const response = await axios.get(`${api}/customers/${customerId}`);

    return response.data;
  }
);

export const createCustomer = createAsyncThunk(
  'customers/create',
  async (data: Customer): Promise<any> => {
    const response = await axios.post(`${api}/customers`, data);
    toast.success('New Customer Added successfully!');
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/update',
  async (data: any, thunk): Promise<any> => {
    try {
      const response = await axios.put(`${api}/customers/${data.id}`, data);

      toast.success('Customer Updated successfully!');

      return response.data;
    } catch (err: any) {
      console.error('Error updating customer:', err);
      return thunk.rejectWithValue(err.response?.data || 'An error occurred');
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customers/delete',
  async (customerId: any | undefined, thunk) => {
    try {
      const response = await axios.delete(`${api}/customers/${customerId.id}`);
      toast.success('Customer Deleted successfully');
      return response.data;
    } catch (err: any) {
      console.error('Error Deleting customer:', err);
      return thunk.rejectWithValue(err.response?.data || 'An error occurred');
    }
  }
);

const initialState: InitialState = {
  list: {
    pending: false,
    error: false,
    data: [],
  },
  details: {
    data: null,
    error: false,
    pending: false,
  },
  create: {
    pending: false,
    error: false,
    data: null,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
  delete: {
    data: null,
    error: false,
    pending: false,
  },
};

const CustomerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL CUSTOMERS
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllCustomers.pending, (state) => {
      state.list.pending = true;
      state.list.error = false;
    });
    builder.addCase(getAllCustomers.rejected, (state) => {
      state.list.error = true;
      state.list.pending = false;
    });

    // GET ONE CUSTOMER
    builder.addCase(getOneCustomer.pending, (state) => {
      state.details.pending = true;
      state.details.error = false;
    });
    builder.addCase(getOneCustomer.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.pending = false;
      state.details.error = false;
    });
    builder.addCase(getOneCustomer.rejected, (state) => {
      state.details.data = null;
      state.details.pending = false;
      state.details.error = true;
    });
    // CREATE CUSTOMERS
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createCustomer.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });
    builder.addCase(createCustomer.rejected, (state) => {
      state.create.error = true;
      state.create.pending = false;
    });

    // UPDATE CUSTOMER
    builder.addCase(updateCustomer.pending, (state) => {
      state.update.pending = true;
      state.update.error = false;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.details.data = action.payload.data;
      state.update.pending = false;
      state.update.error = false;
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.update.data = null;
      state.update.pending = false;
      state.update.error = true;
    });

    // DELETE CUSTOMER
    builder.addCase(deleteCustomer.pending, (state) => {
      state.delete.pending = true;
      state.delete.error = false;
    });
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      state.delete.data = action.payload.data;
      state.delete.pending = false;
      state.delete.error = false;
    });
    builder.addCase(deleteCustomer.rejected, (state) => {
      state.delete.data = null;
      state.delete.pending = false;
      state.delete.error = true;
    });
  },
});

export default CustomerSlice.reducer;
