import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { formatNumber } from '../../../utils/auxilliaryFuntions';

import Calibri from '../../../fonts/calibri.ttf';
import CalibriBold from '../../../fonts/calibri-bold.ttf';

import logo from '../../../images/logo.png';
import { Invoice as IInvoice } from '../../../store/types/ProjectTypes';

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: Calibri,
    },
    {
      src: CalibriBold,
    },
  ],
});

interface InvoiceProps {
  invoice: IInvoice;
  customer: any;
}

const Invoice = ({ invoice, customer }: InvoiceProps) => {
  const props = {
    document: 'invoice',
    data: {
      senderAddress: 'No 06, Ballahenamulla Road,',
      senderCity: 'Colombo 06, SRI LANKA.',
      senderTel: 'Tel: +94 775008888 / +94 705008888',
    },
  };

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.header} fixed>
          <View style={{ display: 'flex' }}>
            <Image src={logo} style={styles.logo} />
            <View>
              <Text style={styles.title}>Invoice</Text>
            </View>
          </View>

          <View style={styles.sender}>
            <Text>{props.data.senderAddress}</Text>
            <Text>{props.data.senderCity}</Text>
            <Text>{props.data.senderTel}</Text>
            <Text>info@sunterra.lk / www.sunterra.com</Text>
          </View>
        </View>

        <View style={styles.customerInfo}>
          <View
            style={{
              width: '58%',
              borderRight: '1px',
              borderBottom: '1px',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                borderBottom: 1,
                paddingTop: 2,
                paddingLeft: 2,
              }}
            >
              Bill To:
            </Text>

            <View
              style={{
                paddingTop: 2,
                paddingLeft: 2,
                textTransform: 'capitalize',
              }}
            >
              <Text>
                {customer?.title} {customer?.first_name} {customer?.last_name}
              </Text>
              <Text>
                {customer?.house_no}, {customer?.street}, {customer?.city}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: '42%',
              display: 'flex',
            }}
          >
            <View style={styles.invoiceData}>
              <Text>Date</Text>
            </View>
            <View style={styles.invoiceData}>
              <Text>Invoice No:</Text>
            </View>

            <View style={styles.invoiceData}>
              <Text>Customer Location:</Text>
            </View>
          </View>

          <View
            style={{
              width: '17%',
            }}
          >
            <Text style={styles.invoiceData}>{invoice.created_at?.split('T')[0]}</Text>
            <Text style={styles.invoiceData}>{invoice.invoice_number}</Text>

            <Text style={styles.invoiceData}>{customer?.city}</Text>
          </View>
        </View>

        {/* Main Part */}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell, styles.col1]}>Serial</Text>
            <Text style={[styles.headerCell, styles.col2]}>Description</Text>
            <Text style={[styles.headerCell, styles.col3]}>Qty.</Text>
            <Text style={[styles.headerCell, styles.col4]}>Unit Price (LKR)</Text>
            <Text style={[styles.headerCell, styles.col5, { borderRight: 0 }]}>Amount (LKR)</Text>
          </View>

          {invoice.items?.map((item, idx: number) => {
            return (
              <View style={styles.tableRow} wrap={false} key={idx}>
                <Text style={[styles.tableCell, styles.col1]}>{idx + 1}</Text>
                <Text style={[styles.tableCell, styles.col2, { textAlign: 'left' }]}>{item.description}</Text>

                <Text style={[styles.tableCell, styles.col3]}>{item?.quantity ? item?.quantity : '-'}</Text>
                <Text style={[styles.tableCell, styles.col4]}>{item?.price ? formatNumber(item.price) : '-'}</Text>

                <Text style={[styles.tableCell, styles.col5]}>{formatNumber(item.total)}</Text>
              </View>
            );
          })}

          {invoice.items.length > 0 && (
            <View style={styles.tableRow} wrap={false}>
              <Text
                style={[
                  styles.tableCell,
                  styles.col5,
                  {
                    textAlign: 'right',
                    width: '75%',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '10',
                  },
                ]}
              >
                Total
              </Text>
              <Text style={[styles.tableCell, styles.col5, { borderLeft: 0, fontSize: '10' }]}>{formatNumber(invoice.total)}</Text>
            </View>
          )}
        </View>

        {/* Conditions & Notes */}
        <View style={styles.notes}>
          <View wrap={false}>
            {/* Warranty */}
            <Text style={styles.h2}>Terms and Conditions</Text>
            <Text style={styles.h3}>Warranty Covers:</Text>
            <Text>* Phono Solar Mono Module - Manufacturer's 12 years warranty</Text>
            <Text style={styles.notesText}>* Solis OnGrid Inverter - 10 years warranty for manufacturing defects</Text>
            <Text style={styles.notesText}>* Performance Warranty - 25 years Warranty</Text>
            <Text style={styles.notesText}>* Invoice / Warranty Certificate must be produced for warranty claims</Text>
            <Text style={styles.notesText}>* For warranty claims, contact Sunterra (Pvt) Ltd on 077 500 8888 / 070 500 8888</Text>
            <Text style={[styles.h2, { marginTop: 5 }]}>Warranty does not cover:</Text>
            <Text style={styles.notesText}>* Failure to adhering to the recommended Servicing and Maintenance;</Text>
            <Text style={styles.notesText}>
              * Repair, modification or movement of the Product by Customer or anyone other than SunTerra or its authorized representative, or
              incorrect attachment to other products not provided by SunTerra;
            </Text>
            <Text style={styles.notesText}>
              * Damage resulting from power surges and acts of nature, including but not limited to storms, lightning, overvoltage, fire, flood,
              pests, or other events outside of SunTerra's control;
            </Text>
            <Text style={styles.notesText}>
              * Damage resulting from abuse, misuse, negligence, accident, action of third parties, improper or noncompliant use or operation,
              including inadequate ventilation and circulation, improper maintenance, or storage, excessive pollution, dirt or dust intrusion into the
              Product.
            </Text>
            <Text style={styles.notesText}>
              "* Abnormal or unintended use; Normal wear and tear; Damage resulting from failure to properly maintain the Products, or from
              unauthorized repairs or repair attempts; Temporary loss of power or production (including any rebate, refund, or other benefit
              associated therewith);"
            </Text>{' '}
            <Text style={styles.notesText}>
              * Consumable components of any Product, including but not limited to fuses, filters, fans, and batteries; Aspects of any Product not
              affecting its function, including but not limited to cosmetic aspects;
            </Text>
            <Text style={styles.notesText}>
              * Any extension of the standard warranty must be specifically agreed to in writing by SunTerra and shall be at an additional cost to
              Customer.
            </Text>
          </View>

          {/* Payment Instructions */}
          <View wrap={false}>
            <Text style={[styles.h2, { marginTop: 10 }]}>Payment Instructions:</Text>

            <Text style={styles.notesText}>* All cheques should crossed and made payable to - Sunterra (Pvt) Ltd</Text>
            <Text style={styles.notesText}>* All cash payments must be directly made to below mentioned Sunterra bank accounts</Text>
            <Text style={styles.notesText}>* Forward scanned copy of direct bank deposit slip to: accountant@sunterra.lk</Text>
          </View>

          {/* Banking Info */}
          <View wrap={false}>
            <Text style={[styles.h2, { marginTop: 10 }]}>Banking Information</Text>

            <Text style={styles.notesText}>* Sunterra (Pvt) Ltd. - Commercial Bank of Ceylon PLC - Foreign Branch - 1000467183</Text>
          </View>
        </View>

        <Text style={styles.footer} fixed>
          This is a system generated invoice. Signature is not required.
        </Text>
      </Page>
    </Document>
  );
};

export default Invoice;

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    marginHorizontal: 30,
    fontSize: 12,
    fontFamily: 'Calibri',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    margin: '20px 0',
    justifyContent: 'space-between',
    width: '90%',
  },

  logo: { width: '100px', marginBottom: '15px' },

  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: -30,
  },

  sender: {
    textAlign: 'right',
    fontSize: 10,
  },

  customerInfo: {
    border: 1,
    borderRight: 0,
    borderBottom: 0,
    width: '90%',
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
  },

  invoiceData: {
    borderBottom: 1,
    paddingLeft: 2,
    paddingTop: 2,
    borderRight: 1,
    textTransform: 'capitalize',
  },

  row: {
    flexDirection: 'row',
    padding: 2,
    border: 1,
    marginTop: -1,
  },
  cell1: {
    paddingRight: 10,
    fontSize: 9,
  },
  cell2: {
    paddingLeft: 65,
    marginLeft: 'auto',
  },

  h2: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 5,
    fontSize: 11,
    textTransform: 'capitalize',
  },

  h3: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 10,
    textTransform: 'capitalize',
  },

  notes: {
    marginTop: 15,
    fontSize: 8,
  },
  notesText: {
    maxWidth: '90%',
  },

  table: {
    marginTop: 15,
    width: '90%',
    borderRight: 1,
    borderRightColor: 'black',
  },
  col1: {
    width: '7%',
    textAlign: 'center',
  },
  col2: {
    width: '50%',
    textAlign: 'center',
  },
  col3: {
    width: '9%',
    textAlign: 'center',
  },
  col4: {
    width: '16%',
    textAlign: 'center',
  },
  col5: {
    width: '18%',
    textAlign: 'right',
  },
  tableHeader: {
    flexDirection: 'row',
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: '#FFB534',
    fontSize: 11,
  },
  headerCell: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: 'black',
    borderRightColor: '#FFB534',
    paddingVertical: 2,
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: 11,
    overflow: 'hidden',
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 0.5,
    borderTop: 1,
    marginTop: -1,
  },
  tableCell: {
    borderLeft: 1,
    borderColor: 'black',
    padding: 3,
    fontSize: 10,
    textOverflow: 'ellipsis',
  },
  total: {
    marginLeft: 'auto',
    color: 'black',
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    marginTop: 15,
    left: '27%',
    fontSize: 8,
  },
  serialPage: {
    backgroundColor: 'white',
    paddingHorizontal: 30,
    paddingVertical: 30,
    fontSize: 10,
  },
  serialTitle: {
    fontWeight: 800,
    fontSize: 12,
    marginTop: 15,
    marginBottom: 5,
  },
  serialTable: {
    width: '100%',
  },
  serialRow: {
    flexDirection: 'row',
    borderBottom: 1,
    padding: 5,
  },
  serialCell1: {
    width: '30%',
  },
  serialCell2: {
    width: '70%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
});
