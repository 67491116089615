import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../utils/constants';
import { links } from '../data/routes';

const NavBar: React.FC = () => {
  const location = useLocation();

  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    if (location.pathname === '/') {
      window.location.href = '/dashboard';
    }
  }, [location.pathname]);

  const isParentActive = (link: any) => {
    if (location.pathname.includes(link.path)) return true;
    if (link.sub) {
      return link.sub.some((sublink: any) => location.pathname.includes(sublink.path));
    }
    return false;
  };

  return (
    <div
      className='w-64 shadow-lg py-1 z-10 hidden md:block'
      style={{
        height: `calc(100vh - ${navbarHeight + footerHeight}px)`,
        overflowY: 'auto',
      }}
    >
      <ul>
        {links.map((link: any, idx: Number) => {
          const parentActive = isParentActive(link);
          return (
            <li className='w-full' key={idx.toString()}>
              {link.sub ? (
                <div>
                  <button
                    className={`flex items-center gap-4 py-4 px-4 font-semibold text-sm w-full ${parentActive && 'bg-gray-100 '} hover:bg-gray-50`}
                    onClick={() => {
                      if (expanded === link.path.split('/')[1]) {
                        setExpanded('');
                      } else {
                        setExpanded(link.path.split('/')[1]);
                      }
                    }}
                  >
                    <span
                      className={`
                   ${parentActive ? 'animate-bounce text-orange-400' : 'text-gray-200'}  `}
                      style={{
                        animationTimingFunction: 'ease-out',
                        animationDirection: 'forwards',
                        animationIterationCount: '1.5',
                        animationDuration: '0.6s',
                        animationComposition: 'additive',
                      }}
                    >
                      {link.icon}
                    </span>
                    {link.name}
                  </button>
                  {expanded === link.path.split('/')[1] && (
                    <ul className='flex flex-col transition-transform duration-200 ease-in-out transform'>
                      {link.sub.map((link: any, idx: number) => (
                        <Link
                          key={idx}
                          className={`pl-10 flex items-center gap-4 py-4 px-4 font-semibold w-full text-xs ${
                            location.pathname.split('/')[2] === link.path.split('/')[2] && 'bg-gray-100'
                          } hover:bg-gray-50`}
                          to={link.path}
                        >
                          <span
                            className={`
                   ${location.pathname.includes(link.path) ? 'animate-bounce text-orange-400' : 'text-gray-200'}  `}
                            style={{
                              animationTimingFunction: 'ease-out',
                              animationDirection: 'forwards',
                              animationIterationCount: '1.5',
                              animationDuration: '0.6s',
                              animationComposition: 'additive',
                            }}
                          >
                            {link.icon}
                          </span>
                          {link.name}
                        </Link>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <Link
                  className={`flex items-center gap-4 py-4 px-4 font-semibold text-sm w-full ${parentActive && 'bg-gray-100 '} hover:bg-gray-50`}
                  to={link.path}
                >
                  <span
                    className={`
                   ${parentActive ? 'animate-bounce text-orange-400' : 'text-gray-200'}  `}
                    style={{
                      animationTimingFunction: 'ease-out',
                      animationDirection: 'forwards',
                      animationIterationCount: '1.5',
                      animationDuration: '0.6s',
                      animationComposition: 'additive',
                    }}
                  >
                    {link.icon}
                  </span>
                  {link.name}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavBar;
