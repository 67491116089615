const projectRefNumGen = ({ project }: any) => {
  const proposalDate = new Date(project?.created_at);

  const customerCity = project?.customer.city;

  const formatDate = (date: any) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  const formatCity = (city: any) => {
    if (!city) return '';
    const cityCode = city.substring(0, 3).toUpperCase();
    // const cityNumber = city.substring(3).padStart(2, '0');
    return cityCode;
  };

  const refNumber = proposalDate
    ? `${formatDate(proposalDate).replace(/\./g, '')}${formatCity(
        customerCity
      )}`
    : '';

  return refNumber;
};

export default projectRefNumGen;
