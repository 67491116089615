import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';
import Breadcrumbs from '../Breadcrumbs';
import { footerHeight, navbarHeight } from '../../utils/constants';
import { getOneUtility } from '../../store/UtilitySlice';

const ViewUtility = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams<{ id: any }>();

  const utilityId = params.id || '';

  useEffect(() => {
    const fetchUtility = async () => {
      await dispatch(getOneUtility(utilityId));
    };

    fetchUtility();
  }, [dispatch, utilityId]);

  const utility = useSelector((state: RootState) => state.utility.details.data);

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/utilities',
      name: 'Utilities',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>
    </div>
  );
};

export default ViewUtility;
