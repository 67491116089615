import { useState } from "react";
import MonthlyRevenue from "./MonthlyRevenue";
import YearlySales from "./YearlySales";
import { CustomSelect, ReactSelectProps } from "../CustomInput";

const Sales = () => {
    const salesOptions: ReactSelectProps[] = [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Yearly', value: 'yearly' },
    ];

    const currentYear = new Date().getFullYear();
    const yearOptions: ReactSelectProps[] = Array.from({ length: 5 }, (_, index) => ({
        label: (currentYear - index).toString(),
        value: currentYear - index,
    }));

    const [year, setYear] = useState<number>(yearOptions[0].value);
    const [saleType, setSaleType] = useState(salesOptions[0].value);

    const getValueFromOptions = (
        options: ReactSelectProps[],
        value: string | number
    ) => {
        const year = options.find((item) => item.value === value);
        return year;
    };

    return (
        <div>
            <div className="flex gap-3 justify-center px-8 pt-1">
                <CustomSelect
                    options={salesOptions}
                    value={getValueFromOptions(salesOptions, saleType)}
                    onChange={(value) => {
                        setSaleType(value.value);
                    }}
                    width='w-full'
                />
                {saleType === salesOptions[0].value && (
                    <CustomSelect
                        options={yearOptions}
                        placeholder="Select Year"
                        value={getValueFromOptions(yearOptions, year)}
                        onChange={(value) => {
                            setYear(value.value);
                        }}
                        width='w-full'
                    />
                )}
            </div>
            {saleType === salesOptions[0].value ? (
                <MonthlyRevenue year={year} />
            ) : (
                <YearlySales />
            )}
        </div>
    )
}

export default Sales;