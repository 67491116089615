import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdSearch } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import Breadcrumbs from '../Breadcrumbs';
import { getTariffList } from '../../store/TariffSlice';
import { Tariff } from '../../store/types/TariffTypes';
import Loader from '../Loader';
import LoadingSkeleton from '../LoadingSkeleton';

const TariffTypes = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const tariffListState = useSelector((state: RootState) => state.tariff.list);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortedData, setSortedData] = useState<Tariff[]>([]);

  useEffect(() => {
    const fetchAndSetProjectsList = async () => {
      dispatch(getTariffList({}));
    };

    fetchAndSetProjectsList();
  }, []);

  useEffect(() => {
    // Sorting logic based on updated_at
    const sorted = [...tariffListState.data].sort((a, b) => {
      const dateA = a.updated_at ? new Date(a.updated_at) : null;
      const dateB = b.updated_at ? new Date(b.updated_at) : null;

      if (dateA && dateB) {
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      // Handle the case where either date is undefined
      return 0;
    });

    // Filtering based on search term
    const filtered = sorted.filter((tariff) => tariff.name.toLowerCase().includes(searchTerm.toLowerCase()));

    setSortedData(filtered);
  }, [tariffListState.data, sortOrder, searchTerm]);

  return (
    <div className='w-full flex flex-col items-center bg-[#ECECEC] h-[100vh]' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Tariff Types',
            },
          ]}
        />

        <Link to='/tariff-types/add' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Tariff Type</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>
      <Paper
        sx={{
          marginX: 'auto',
          width: { xs: '100%', md: '95%' },
          height: '100%',
          overflowY: 'auto',
          marginY: { xs: '10px', md: '40px' },
          paddingX: { xs: '10px', md: '40px' },
          paddingY: { xs: '10px', md: '30px' },
        }}
      >
        <div className='flex justify-between'></div>
        <div className='flex gap-2 mb-4 justify-between items-end'>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            aria-label='Search Projects'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />
          {/* Show number of items */}
          <p className='text-xs text-gray-500'>
            Viewing {tariffListState.data.length} item
            {tariffListState.data.length === 1 ? '' : 's'}
          </p>
        </div>
        <TableContainer
          sx={{
            marginX: 'auto',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-center text-sm font-bold'>Name</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Return Rate (LKR)</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((tariff, index) => (
                <TableRow
                  key={index}
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  onClick={() => {
                    navigate(`/tariff-types/${tariff.id}`);
                  }}
                >
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500 capitalize'>{tariff.name}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{tariff.return_rate}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='my-1'>
          {tariffListState.pending && <LoadingSkeleton numColumns={1} />}
          {!tariffListState.error && !tariffListState.pending && tariffListState.data.length === 0 && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
          )}
          {!tariffListState.pending && tariffListState.error && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default TariffTypes;
