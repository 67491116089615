import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, TransportCost } from './types/TransportCostTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllTransportCosts = createAsyncThunk('transportCost/get', async (filter: any): Promise<any> => {
  const res = await axios.get(`${api}/transport-cost`);

  return res.data;
});

export const createTransportCost = createAsyncThunk('transportCost/create', async (data: TransportCost): Promise<any> => {
  const res = await axios.post(`${api}/transport-cost`, data);
  toast.success('Transport Cost Added successfully');
  return res.data;
});

export const updateTransportCost = createAsyncThunk('installationCost/update', async (data: TransportCost): Promise<any> => {
  const res = await axios.put(`${api}/transport-cost/${data.id}`, data);
  toast.success('Transport Cost Updated successfully');
  return res.data;
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
};

const TransportCostSlice = createSlice({
  name: 'TransportCost',
  initialState: initialState,
  reducers: {},

  // GET ALL Transport Costs
  extraReducers: (builder) => {
    builder.addCase(getAllTransportCosts.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllTransportCosts.rejected, (state) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
    builder.addCase(getAllTransportCosts.pending, (state, action) => {
      state.list.pending = true;
      state.list.error = false;
    });

    // CREATE Transport Cost
    builder.addCase(createTransportCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createTransportCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(createTransportCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });

    // UPATE Transport Cost
    builder.addCase(updateTransportCost.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(updateTransportCost.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
    });
    builder.addCase(updateTransportCost.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });
  },
});

export default TransportCostSlice.reducer;
