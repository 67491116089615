import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../../store';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';

import AccordionComponent from '../Accordion';

import InverterSelection from './Devices/InverterSelection';
import PanelSelection from './Devices/PanelSelection';

import { ButtonOrange } from '../Buttons';
import { CustomSelect, Input } from '../CustomInput';
import { Project } from '../../store/types/ProjectTypes';
import { toast } from 'react-toastify';

type Props = {
  editable: boolean;
  updateCustomer?: Partial<Project> | Project | null;
  project: Project | null;
  setProject: Project | null;
  handleInputChange: any;
  onFormSubmit: any;
  inverterList: any;
  setInverterList: any;
  panel: any;
  setPanel: any;
  setPanelList: any;
  panelList: any;
};

const ProjectForm: React.FC<Props> = ({
  editable,
  project,
  setProject,
  handleInputChange,
  onFormSubmit,
  inverterList,
  setInverterList,
  panel,
  setPanel,
  setPanelList,
  panelList,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectedInverter, setSelectedInverter] = useState<any>(null);
  const [inverterModal, setInverterModal] = useState(false);
  const [inverterEditModal, setInverterEditModal] = useState(false);
  const openInverterModal = () => setInverterModal(true);
  const closeInverterModal = () => {
    setInverterModal(false);
    setSelectedInverter(null);
  };
  const openEditInverterModal = () => setInverterEditModal(true);
  const closeEditInverterModal = () => setInverterEditModal(false);

  // Inverter Configuration
  const pushInverterList = (): void => {
    setInverterList([...inverterList, selectedInverter]);
    setInverterModal(false);
    setSelectedInverter(null);
  };

  const replaceInverterInList = () => {
    setInverterList(inverterList.map((inverter: any, idx: number) => (idx === selectedInverter.index ? selectedInverter : inverter)));
    setInverterEditModal(false);
    setSelectedInverter(null);
  };

  const removeInverter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    setInverterList(inverterList.map((inverter: any, idx: number) => (idx === index ? null : inverter)).filter(Boolean));
  };

  // PV Module Configuration
  const [panelModal, setPanelModal] = useState(false);
  const [panelEditModal, setPanelEditModal] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState<any>(null);

  const openPanelModal = () => setPanelModal(true);
  const closePanelModal = () => setPanelModal(false);
  const openPanelEditModal = () => setPanelEditModal(true);
  const closePanelEditModal = () => setPanelEditModal(false);
  const pushPanelList = (): void => {
    setPanelList([...panelList, selectedPanel]);
    setPanelModal(false);
    setSelectedPanel(null);
  };

  const replacePanelInList = () => {
    setPanelList(panelList.map((panel: any, idx: number) => (idx === selectedPanel.index ? selectedPanel : panel)));
    setPanelEditModal(false);
    setSelectedPanel(null);
  };

  const removePanel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    setPanelList(panelList.map((panel: any, idx: number) => (idx === index ? null : panel)).filter(Boolean));
  };

  const customerList = useSelector((state: RootState) => state.customer.list.data);
  const tariffList = useSelector((state: RootState) => state?.tariff?.list?.data);
  const utilityList = useSelector((state: RootState) => state.utility.list.data);
  const circuitList = useSelector((state: RootState) => state.circuit.list.data);
  const userList = useSelector((state: RootState) => state.user.list.data);
  const customerOptions = customerList.map((customer) => ({
    label: `${customer.first_name} ${customer.last_name}`,
    value: customer,
  }));
  const tariffOptions = tariffList?.map((tariff) => ({
    label: tariff.name,
    value: tariff.id,
  }));
  const utilityOptions = utilityList.map((utlity) => ({
    label: utlity.name,
    value: utlity.id,
  }));
  const circuitOptions = circuitList.map((circuit, idx) => ({
    label: `Spec-${idx + 1}`,
    value: circuit.id,
  }));
  const userOptions = userList.map((user) => ({
    label: user.username,
    value: user.id,
  }));
  const roofOptions = [
    { label: 'Asbestos', value: 'asbestos' },
    { label: 'Tile', value: 'tile' },
    { label: 'Amano', value: 'amano' },
    { label: 'Combination', value: 'combination' },
  ];

  const phaserOptions = [
    { label: 'Single Phase 15A', value: 'Single Phase 15A' },
    { label: 'Single Phase 30A', value: 'Single Phase 30A' },
    { label: 'Three Phase 30A', value: 'Three Phase 30A' },
    { label: 'Three Phase 60A', value: 'Three Phase 60A' },
  ];

  const solarSchemeOptions = [
    { label: 'Net Metering', value: 'Net Metering' },
    { label: 'Net Accounting', value: 'Net Accounting' },
    { label: 'Net Plus', value: 'Net Plus' },
  ];

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (inverterList.length === 0) {
      toast.warning('Please select an Inverter.');
      return;
    }

    if (panelList.length === 0) {
      toast.warning('Please select a Panel.');
      return;
    }
    onFormSubmit(e);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit} className='w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'>
        <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-5 '>
          <CustomSelect
            label='Assigned User'
            options={userOptions}
            value={undefined}
            required={true}
            onChange={(e: any) => {
              handleInputChange({
                fieldName: 'assigned_user',
                value: e?.value,
              });
            }}
          />

          {/* <Input
            label='Date:'
            type='date'
            value={undefined}
            onChange={(e: any) => {
              handleInputChange({
                fieldName: 'date',
                value: e?.target.value,
              });
            }}
          /> */}

          <CustomSelect
            label='Project Type'
            options={tariffOptions}
            required={true}
            onChange={(e: any) => {
              handleInputChange({
                fieldName: 'tarifftype_id',
                value: e?.value,
              });
            }}
            value={undefined}
          />
        </div>
        {/* =============================== Customer Details =============================== */}

        <div className='py-5'>
          <AccordionComponent
            title='Customer Details'
            children={
              <>
                <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5'>
                  <CustomSelect
                    label='Customer'
                    options={customerOptions}
                    required={true}
                    onChange={(e: any) => {
                      setSelectedCustomer(e?.value || '');
                      setProject({
                        ...project,
                        customer_id: e?.value.id || '',
                        address: e?.value.house_no || '',
                        street: e?.value.street || '',
                        city: e?.value.city || '',
                        phone: e?.value.phone || '',
                        email: e?.value.email || '',
                        nic: e?.value.nic || '',
                      });
                    }}
                    value={undefined}
                  />

                  <Input
                    label='NIC No.:'
                    type='text'
                    value={`${selectedCustomer?.nic || ''}`}
                    disabled
                    onChange={function (e: any): void {
                      throw new Error('Function not implemented.');
                    }}
                  />

                  <Input
                    type='text'
                    disabled
                    label='Customer Name:'
                    value={`${selectedCustomer?.first_name || ''} ${selectedCustomer?.last_name || ''}`}
                    onChange={function (e: any): void {
                      throw new Error('Function not implemented.');
                    }}
                  />
                </div>
                <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5'>
                  <Input
                    label='Phone Number:'
                    type='text'
                    disabled
                    value={project?.phone ?? ''}
                    onChange={function (e: any): void {
                      throw new Error('Function not implemented.');
                    }}
                  />

                  <Input
                    label='Email:'
                    type='text'
                    disabled
                    value={project?.email ?? ''}
                    onChange={function (e: any): void {
                      throw new Error('Function not implemented.');
                    }}
                  />

                  <div className='w-full md:w-1/3 items-center gap-2 flex flex-row mb-3'>
                    <Input
                      label='Latitude:'
                      type='text'
                      placeholder='6.886896319'
                      value={undefined}
                      onChange={(e) => {
                        handleInputChange({
                          fieldName: 'lat',
                          value: e.target.value,
                        });
                      }}
                      width='w-1/2'
                    />
                    <Input
                      label='Longitude:'
                      type='text'
                      placeholder='79.918649632'
                      value={undefined}
                      onChange={(e) => {
                        handleInputChange({
                          fieldName: 'lon',
                          value: e.target.value,
                        });
                      }}
                      width='w-1/2'
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5'>
                  <Input
                    label='Address:'
                    type={''}
                    value={project?.address ?? ''}
                    onChange={(e) =>
                      handleInputChange({
                        fieldName: 'address',
                        value: e.target.value,
                      })
                    }
                    placeholder='Adrress Line 1'
                  />

                  <Input
                    label='Street:'
                    type='text'
                    value={project?.street ?? ''}
                    placeholder='Adrress Line 2'
                    onChange={(e) =>
                      handleInputChange({
                        fieldName: 'street',
                        value: e.target.value,
                      })
                    }
                  />

                  <Input
                    label='City:'
                    type='text'
                    placeholder='Adrress Line 3'
                    value={project?.city ?? ''}
                    onChange={(e) =>
                      handleInputChange({
                        fieldName: 'city',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            }
          />
        </div>

        {/* =============================== Project Details =============================== */}

        <div className='py-5'>
          <AccordionComponent
            title='Project Details'
            children={
              <>
                <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5'>
                  <Input
                    label='Electricity A/C Number:'
                    type='text'
                    value={undefined}
                    onChange={(e) => {
                      handleInputChange({
                        fieldName: 'account_no',
                        value: e.target.value,
                      });
                    }}
                  />

                  <CustomSelect
                    label='Utility'
                    required={true}
                    options={utilityOptions}
                    onChange={(e: any) => {
                      handleInputChange({
                        fieldName: 'utility_id',
                        value: e?.value,
                      });
                    }}
                    value={undefined}
                  />

                  <Input
                    label='Average Monthly Consumption:'
                    type='number'
                    value={undefined}
                    onChange={(e) => {
                      handleInputChange({
                        fieldName: 'monthly_avg',
                        value: Number(e.target.value),
                      });
                    }}
                    units='kWh'
                  />
                </div>

                <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5'>
                  <CustomSelect
                    label='Roof Type'
                    required={true}
                    options={roofOptions}
                    onChange={(e: any) => {
                      handleInputChange({
                        fieldName: 'roof_type',
                        value: e?.value,
                      });
                    }}
                    value={undefined}
                  />

                  <CustomSelect
                    label='Utility Supply Rating'
                    required={true}
                    options={phaserOptions}
                    onChange={(e: any) => {
                      handleInputChange({
                        fieldName: 'phase',
                        value: e.value,
                      });
                    }}
                    value={undefined}
                  />

                  <Input
                    label='Utility Branch:'
                    type='text'
                    value={undefined}
                    onChange={(e) => {
                      handleInputChange({
                        fieldName: 'utility_branch',
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* =============================== System Specifications =============================== */}

        <div className='py-5'>
          <AccordionComponent
            title='System Specifications'
            children={
              <>
                <div className='w-full flex flex-col mb-3'>
                  <label className='my-1 text-sm text-gray-500 font-semibold'>Inverters: </label>
                  <div className='h-[48px] border rounded flex items-center overflow-x-auto overflow-y-hidden gap-5 inverter-list'>
                    <button
                      type='button'
                      onClick={(e) => openInverterModal()}
                      className='flex gap-2 items-center justify-center w-1/4 px-3 py-3 rounded font-semibold text-white bg-gray-500 hover:bg-gray-200 hover:text-orange-500 duration-300 z-10'
                    >
                      Add Inverter
                      <MdAddCircle className='text-2xl' />
                    </button>
                    {inverterList.map((inverter: any, idx: number) => (
                      <div className='flex border rounded w-max items-center mx-1 py-1 px-2 bg-gray-200' key={idx}>
                        <p>{inverter.model}</p>
                        <div className='flex items-center ml-3'>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedInverter({
                                ...inverterList[idx],
                                index: idx,
                              });
                              openEditInverterModal();
                            }}
                            className='p-1 hover:text-orange-500 duration-300'
                          >
                            <FaEdit />
                          </button>
                          <button onClick={(e) => removeInverter(e, idx)} className='p-1 hover:text-orange-500 duration-300'>
                            <FaTrash />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className='w-full flex flex-col mb-3'>
                  <label className='mb-1 text-sm text-gray-500 font-semibold'>PV Module Model: </label>
                  <div className='h-[48px] gap-5 border rounded flex items-center'>
                    <button
                      type='button'
                      onClick={(e) => openPanelModal()}
                      className='flex gap-2 items-center justify-center w-1/4 px-3 py-3 rounded font-semibold text-white bg-gray-500 hover:bg-gray-200 hover:text-orange-500 duration-300 z-10 whitespace-nowrap'
                    >
                      Select PV Module
                      <MdAddCircle className='text-2xl' />
                    </button>
                    {panelList.map((panel: any, idx: number) => (
                      <div className='flex border rounded w-max items-center mx-1 py-1 px-2 bg-gray-200' key={idx}>
                        <p>{panel.model}</p>
                        <div className='flex items-center ml-3'>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedPanel({
                                ...panelList[idx],
                                index: idx,
                              });
                              openPanelEditModal();
                            }}
                            className='p-1 hover:text-orange-500 duration-300'
                          >
                            <FaEdit />
                          </button>
                          <button onClick={(e) => removePanel(e, idx)} className='p-1 hover:text-orange-500 duration-300'>
                            <FaTrash />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full gap-2 md:gap-3 lg:gap-5'>
                  <CustomSelect
                    label='Circuit Specification'
                    options={circuitOptions}
                    required={true}
                    onChange={(e: any) => {
                      handleInputChange({
                        fieldName: 'circuit_id',
                        value: e?.value,
                      });
                    }}
                    value={undefined}
                  />

                  <CustomSelect
                    label='Solar Scheme'
                    required={true}
                    options={solarSchemeOptions}
                    onChange={(e: any) => {
                      handleInputChange({
                        fieldName: 'solar_scheme',
                        value: e.value,
                      });
                    }}
                    value={undefined}
                  />
                </div>
              </>
            }
          />
        </div>
        <ButtonOrange type='submit' disabled={''}>
          SUBMIT
        </ButtonOrange>
      </form>
      {inverterModal && (
        <InverterSelection
          inverterList={inverterList}
          setInverterList={setInverterList}
          confirmAction={pushInverterList}
          mode='ADD'
          selectedInverter={selectedInverter}
          setSelectedInverter={setSelectedInverter}
          closeModal={closeInverterModal}
        />
      )}
      {inverterEditModal && (
        <InverterSelection
          inverterList={inverterList}
          setInverterList={setInverterList}
          confirmAction={replaceInverterInList}
          mode='EDIT'
          selectedInverter={selectedInverter}
          setSelectedInverter={setSelectedInverter}
          closeModal={closeEditInverterModal}
        />
      )}
      {panelModal && (
        <PanelSelection
          closeModal={closePanelModal}
          mode='ADD'
          selectedPanel={selectedPanel}
          setSelectedPanel={setSelectedPanel}
          confirmAction={pushPanelList}
        />
      )}
      {panelEditModal && (
        <PanelSelection
          closeModal={closePanelEditModal}
          mode='EDIT'
          selectedPanel={selectedPanel}
          setSelectedPanel={setSelectedPanel}
          confirmAction={replacePanelInList}
        />
      )}
    </>
  );
};

export default ProjectForm;
