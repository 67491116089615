import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';

import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { createServiceRequest, getProjectDetails } from '../../../store/ProjectsSlice';

type Props = {
  cancelAction: any;
};

const ServiceRequestModal: React.FC<Props> = ({ cancelAction }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();

  const [service, setService] = useState({
    cost: 0,
    requested_date: '',
  });

  const projectId = params.id;

  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '30px 40px',
  };

  const submitServiceRequest = async () => {
    const payload = {
      project_id: projectId,
      cost: service.cost,
      requested_date: new Date(service.requested_date),
    };

    await dispatch(createServiceRequest(payload));
    await dispatch(getProjectDetails({ id: projectId }));
    cancelAction();
  };

  return (
    <Modal open={true} style={modalStyle}>
      <Paper sx={paperStyle} className='relative'>
        <div className='w-full mb-2'>
          <label className='font-semibold text-gray-500'>Date : </label>
          <input
            type='date'
            className='border py-3 text-sm px-5 rounded w-full'
            onChange={(e) => setService({ ...service, requested_date: e.target.value })}
          />
        </div>
        <div className='w-full'>
          <label className='font-semibold text-gray-500'>Cost : </label>
          <input
            type='number'
            className='border py-3 text-sm px-2 rounded w-full'
            onChange={(e) => setService({ ...service, cost: Number(e.target.value) })}
          />
        </div>
        <div className='flex my-2 gap-2 justify-between items-center'>
          <button className='px-10 py-3 bg-gray-200 text-black text-xs font-bold capitalize rounded whitespace-nowrap' onClick={() => cancelAction()}>
            Cancel
          </button>
          <button
            className='px-10 py-3 bg-[#398100] text-white text-xs font-bold capitalize rounded whitespace-nowrap'
            onClick={() => submitServiceRequest()}
          >
            Save
          </button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ServiceRequestModal;
