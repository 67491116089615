import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import OpenSans from '../../fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../../fonts/OpenSans-Semibold.ttf';
import OpenSansBold from '../../fonts/OpenSans-Bold.ttf';
import installerDetails from '../../config/installerDetails';

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans,
    },
    {
      src: OpenSansSemiBold,
    },
    {
      src: OpenSansBold,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 11,
    fontFamily: 'OpenSans',
  },

  p: {
    fontSize: 11,
    marginTop: 10,
    marginBottom: 10,
  },

  letter: {
    paddingLeft: 70,
    paddingRight: 30,
    paddingVertical: 30,
    marginHorizontal: 'auto',
    fontSize: 12,
  },

  receiver: {
    marginTop: 25,
    marginBottom: 25,
    textTransform: 'capitalize',
  },
});

const ClearanceLetterCEB = ({ project }: any) => {
  const date = new Date(project?.created_at)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  const inverterCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  const panelCapacity = (
    project?.project_panels.length *
    (project?.project_panels[0].panel.peak_power / 1000)
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={[styles.page, { position: 'relative' }]}>
        <View>
          <View style={styles.letter}>
            <View style={styles.receiver}>
              <Text>{date}</Text>
              <Text>Area Engineer,</Text>
              <Text>CEB,</Text>
              <Text>{project?.customer.city}</Text>
            </View>

            <Text>Dear Sir/Madam,</Text>
            <Text
              style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold' }}
            >
              Subject: Request letter for {inverterCapacity}
              kW solar system installation.
            </Text>
            <Text style={styles.p}>
              We have received a request from the customer detailed below for a
              rooftop solar installation. Inverter Capacity – {inverterCapacity}
              kW and Panel Capacity – {panelCapacity}
              kW under the Net Accounting Scheme.
            </Text>

            <View style={{ marginVertical: 10 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Customer Name</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {project?.customer.first_name} {project?.customer.last_name}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Address</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {project?.customer.house_no}, {project?.customer.street},{' '}
                  {project?.customer.city}.
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Ceb Account No.</Text>
                <Text style={{ width: '70%' }}>: {project?.account_no}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Mobile Number</Text>
                <Text style={{ width: '70%' }}>
                  : {project?.customer.phone}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Solar Contractor</Text>
                <Text style={{ width: '70%' }}>: {installerDetails.name}.</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Solar Contact No.</Text>
                <Text style={{ width: '70%' }}>
                  : {installerDetails.phone_1}/ {installerDetails.phone_2}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>E-Mail</Text>
                <Text style={{ width: '70%' }}>: {installerDetails.email}</Text>
              </View>
            </View>
            <Text style={styles.p}>
              We kindly seek your initial clearance at your earliest, to proceed
              with the installation and meet the Project deadlines.
            </Text>
            <Text style={styles.p}>
              Your assistance in this respect is highly appreciated.
            </Text>

            <Text style={{ marginTop: 10 }}>Thank you,</Text>
            <Text>Yours faithfully,</Text>

            <View style={{ marginTop: 40 }}>
              <Text>…………………………………………</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Customer Name</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {project?.customer.first_name} {project?.customer.last_name}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Customer Address</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {project?.customer.house_no}, {project?.customer.street},{' '}
                  {project?.customer.city}.
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 40 }}>
              <Text>…………………………………………</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Solar Service Provider</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {installerDetails.name}.
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Name</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {installerDetails.contact_person}.
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ width: '30%' }}>Designation</Text>
                <Text style={{ width: '70%', textTransform: 'capitalize' }}>
                  : {installerDetails.designation}.
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row', marginTop: 20 }}>
              <Text style={{ width: '30%' }}>SEA Registration No.</Text>
              <Text style={{ width: '70%' }}>
                : {installerDetails.sea_reg_num}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ClearanceLetterCEB;
