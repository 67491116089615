import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/SalesTypes';
const api = process.env.REACT_APP_API;

export const getYearlySales = createAsyncThunk(
    'sales/get/yearly',
    async () => {
        const response = await axios.get(`${api}/sales/yearly`, {
            headers: { Authorization: localStorage.getItem('token') }
        });

        return response.data;
    }
);

export const getmonthlySales = createAsyncThunk(
    'sales/get/monthly',
    async (year: number | undefined) => {
        const response = await axios.get(`${api}/sales/monthly/${year}`, {
            headers: { Authorization: localStorage.getItem('token') }
        });

        return response.data;
    }
);

const initialState: InitialState = {
    yearlyList: {
        data: { years: [], sales: [] },
        pending: false,
        error: false,
    },
    monthlyList: {
        data: [],
        pending: false,
        error: false,
    },
};

const SaleSlice = createSlice({
    name: 'sales',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getYearlySales.fulfilled, (state, action) => {
            state.yearlyList.data = action.payload.data;
            state.yearlyList.pending = false;
            state.yearlyList.error = false;
        });
        builder.addCase(getYearlySales.pending, (state, action) => {
            state.yearlyList.pending = true;
            state.yearlyList.error = false;
            state.yearlyList.data = { years: [], sales: [] };
        });
        builder.addCase(getYearlySales.rejected, (state, action) => {
            state.yearlyList.pending = false;
            state.yearlyList.error = true;
            state.yearlyList.data = { years: [], sales: [] };
        });
        builder.addCase(getmonthlySales.fulfilled, (state, action) => {
            state.monthlyList.data = action.payload.data;
            state.monthlyList.pending = false;
            state.monthlyList.error = false;
        });
        builder.addCase(getmonthlySales.pending, (state, action) => {
            state.monthlyList.pending = true;
            state.monthlyList.error = false;
            state.monthlyList.data = [];
        });
        builder.addCase(getmonthlySales.rejected, (state, action) => {
            state.monthlyList.pending = false;
            state.monthlyList.error = true;
            state.monthlyList.data = [];
        });
    },
});

export default SaleSlice.reducer;