import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/CableTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getCableList = createAsyncThunk(
  'cable/list',
  async (payload: any, thunk) => {
    try {
      const res = await axios.get(api + '/cables');

      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.dat);
    }
  }
);

export const createCable = createAsyncThunk(
  'cable/create',
  async (payload: any, thunk) => {
    try {
      const res = await axios.post(api + '/cables', payload);
      toast.success('Cable Cost Created Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateCable = createAsyncThunk(
  'cable/update',
  async (payload: any, thunk) => {
    try {
      const res = await axios.put(api + '/cables/' + payload.id, payload);
      toast.success('Cable Cost Updated Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
};

const CableSlice = createSlice({
  name: 'Cable',
  initialState: initialState,
  reducers: {
    clearCableList: (state) => {
      state.list.data = [];
    },
  },
  extraReducers: (builder) => {
    // List case
    builder.addCase(getCableList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getCableList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getCableList.pending, (state, action) => {
      state.list.data = [];
      state.list.error = false;
      state.list.pending = true;
    });
    // Create case
    builder.addCase(createCable.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
    });
    builder.addCase(createCable.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
    });
    builder.addCase(createCable.pending, (state, action) => {
      state.create.data = null;
      state.create.error = false;
      state.create.pending = true;
    });
    //Update case
    builder.addCase(updateCable.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
    });
    builder.addCase(updateCable.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updateCable.pending, (state, action) => {
      state.update.data = null;
      state.update.error = false;
      state.update.pending = true;
    });
  },
});

export const { clearCableList } = CableSlice.actions;

export default CableSlice.reducer;
