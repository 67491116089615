import { FaSpinner } from 'react-icons/fa';

export const ButtonOrange = ({ disabled, onClick, children, customStyles, width = 'w-full md:w-1/4', py = 'py-3' }: any) => {
  return (
    <button
      type='submit'
      className={`${customStyles} ${width} ${py} rounded text-white font-bold text-sm shadow-lg hover:shadow-md duration-100  ml-auto mt-auto bg-orange-500 hover:bg-orange-600`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const ButtonCustom = ({
  disabled,
  onClick,
  children,
  customStyles = 'bg-green-600',
  customIcon,
  iconStyles,
  py = 'py-3',
  px = 'px-10',
  isLoading,
}: any) => {
  return (
    <button
      className={`flex items-center justify-center gap-2  ${py} ${px} ${customStyles} hover:bg-green-700 shadow-lg hover:shadow-md duration-100 text-white text-sm font-bold capitalize rounded whitespace-nowrap disabled:cursor-not-allowed`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      <span className={`${iconStyles}`}>
        {customIcon} {isLoading && <FaSpinner className='animate-spin' />}
      </span>
    </button>
  );
};

type ButtonProps = {
  text: string;
  colorType: 'primary' | 'secondary' | 'success' | 'danger';
  type: 'submit' | 'button';
  onClick?: (e?: any) => any;
  className?: string;
};

export const Button: React.FC<ButtonProps> = ({ text, colorType, className, onClick }) => {
  let color: string;
  switch (colorType) {
    case 'primary':
      color = 'bg-orange-600';
      break;
    case 'secondary':
      color = 'bg-gray-100';
      break;
    case 'success':
      color = 'bg-green-600';
      break;
    case 'danger':
      color = 'bg-red-700';
      break;
  }

  return (
    <button onClick={onClick} className={`${color} ${className} px-3 py-2 rounded w-full text-white font-semibold `}>
      {text}
    </button>
  );
};
