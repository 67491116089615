import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../store';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getmonthlySales } from '../../store/SaleSlice';
import { currencyFormatter } from '../../utils/numberFormat';
import Skeleton from '@mui/material/Skeleton';
import WidgetTitle from './WidgetTitle';

const MonthlyRevenue = ({ year }: { year: number }) => {
  const dispatch: AppDispatch = useDispatch();
  const salesList = useSelector((state: RootState) => state.sale.monthlyList);

  useEffect(() => {
    dispatch(getmonthlySales(year));
  }, [dispatch, year]);

  useEffect(() => {
    if (salesList.data && salesList.data.length) {
      setChartOptions(prevOptions => ({
        ...prevOptions,
        series: [
          {
            name: 'Yealy sales',
            data: salesList.data
          },
        ],
      }))
    };
  }, [salesList]);

  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      type: 'line',
      zoom: { enabled: false }
    },
    stroke: { curve: 'smooth' },
    series: [],
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    yaxis: { min: 0 },
    dataLabels: {
      enabled: true,
      offsetY: -10,
      offsetX: -4,
      formatter: function (val: number, opt) {
        return currencyFormatter(val, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    markers: {
      size: 4,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    }
  });

  return (
    <div className="rounded p-8 pt-1">
      <WidgetTitle title='Monthly Sales' />

      <div className='w-[100%] h-[350px]'>
        {salesList.pending ? (
          <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
        ) : salesList.data && (
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            width={'100%'}
            height={'100%'}
          />
        )}
      </div>
    </div>
  );
};

export default MonthlyRevenue;
