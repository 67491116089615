import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Input } from '../../../components/FormComponents';
import AccordionComponent from '../../Accordion';
import Breadcrumbs from '../../Breadcrumbs';
import { footerHeight, navbarHeight } from '../../../utils/constants';
import { Button } from '../../Buttons';
import { Panel } from '../../../store/types/PanelTypes';
import { clearPanelDetails, createPanel, getPanelDetails, updatePanel } from '../../../store/PanelSlice';
import SuccessModal from '../../SuccessModal';
import LoaderOverlay from '../../LoaderOverlay';
import ConfettiExplosion from 'react-confetti-explosion';
import { FaSolarPanel } from 'react-icons/fa';
import { toast } from 'react-toastify';

const PanelSchema = yup.object<Partial<Record<keyof Panel, yup.AnySchema>>>({
  id: yup.string().optional(),
  brand: yup.string().required('Brand is requried.').default('Phono'),
  model: yup.string().required('Model is required.').default('').default(''),
  origin_country: yup.string().required('Origin country is required.').default(''),
  peak_power: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Peak power must be greater than zero.')
    .default(0)
    .required('Peak power is required.'),
  efficiency: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Efficiency must be greater than zero.')
    .default(0)
    .required('Efficiency is required')
    .default(0),
  open_circuit_voltage: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Open circuit voltage must be greater than zero.')
    .default(0)
    .required('Open circuit voltage is required')
    .default(0),
  rated_voltage: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Rated voltage must be greater than zero.')
    .default(0)
    .required('Rated voltage is required')
    .default(0),
  short_circuit_current: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Short circuit current must be greater than zero.')
    .default(0)
    .required('Short circuit current is required')
    .default(0),
  rated_current: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Rated current must be greater than zero.')
    .default(0)
    .required('Rated current is required')
    .default(0),
  cell_type: yup.string().required('Cell type is required').default(''),
  support_standards: yup.string().required('Support standards is required').default(''),
  test_reports: yup.string().required('Test reports is required').default(''),
  avg_daily_production: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Average daily production must be greater than zero.')
    .default(0)
    .required('Average daily production is required')
    .default(0),
  first_year_production: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'First year production must be greater than zero.')
    .default(0)
    .required('First year production is required')
    .default(0),
  forecast_20yr: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Forecasted production must be greater than zero.')
    .default(0)
    .required('Forecasted production is required')
    .default(0),
  earning_20yr: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Earnings must be greater than zero.')
    .default(0)
    .required('Earnings is required')
    .default(0),
  warranty: yup
    .number()
    .integer()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Warranty must be greater than zero.')
    .default(0)
    .required('Warranty is required')
    .default(0),
  performance_guarantee: yup
    .number()
    .integer()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Performance guarantee must be greater than zero.')
    .default(0)
    .required('Performance guarantee is required')
    .default(0),
  cost: yup
    .number()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, 'Cost be greater than zero.')
    .default(0)
    .required('Cost is required')
    .default(0),
});

const AddEditPanel = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const panel = useSelector((state: RootState) => state.panel?.details);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editable, setEditable] = useState(true);

  const methods = useForm<Partial<Panel>>({
    resolver: yupResolver(PanelSchema),
    defaultValues: panel.data || PanelSchema.cast({}),
  });

  useEffect(() => {
    if (id) {
      dispatch(getPanelDetails({ id: id }));
    }
    return () => {
      dispatch(clearPanelDetails());
    };
  }, [id]);

  useEffect(() => {
    panel.data &&
      methods.reset({
        id: panel.data.id,
        brand: panel.data.brand,
        model: panel.data.model,
        origin_country: panel.data.origin_country,
        peak_power: panel.data.peak_power,
        efficiency: panel.data.efficiency,
        open_circuit_voltage: panel.data.open_circuit_voltage,
        rated_voltage: panel.data.rated_voltage,
        short_circuit_current: panel.data.short_circuit_current,
        rated_current: panel.data.rated_current,
        cell_type: panel.data.cell_type,
        support_standards: panel.data.support_standards,
        test_reports: panel.data.test_reports,
        avg_daily_production: panel.data.avg_daily_production,
        first_year_production: panel.data.first_year_production,
        forecast_20yr: panel.data.forecast_20yr,
        earning_20yr: panel.data.earning_20yr,
        warranty: panel.data.warranty,
        performance_guarantee: panel.data.performance_guarantee,
        cost: panel.data.cost,
      });
  }, [panel.data, id]);

  const panelCreateState = useSelector((state: RootState) => state.panel.create);
  const panelUpdateState = useSelector((state: RootState) => state.panel.update);

  const onSave: SubmitHandler<Partial<Panel>> = (data) => {
    if (id) {
      dispatch(updatePanel(data as Panel));

      if (!panelUpdateState.error) {
        navigate('/panels');
      }
    } else {
      dispatch(createPanel(data as Panel));
      setIsSubmitted(true);
    }
  };

  return (
    <div className='w-full bg-[#ECECEC] overflow-y-scroll' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='p-6 bg-white'>
        <Breadcrumbs links={[{ name: 'PV Modules', link: '/panels' }, { name: (id ? 'Edit' : 'Add') + ' PV Module' }]} />
      </div>
      {id && (
        <div className='mx-auto md:w-[95%] mt-10 flex gap-5'>
          <Button
            type='button'
            colorType={panel.data?.railings_status === 'Incomplete' ? 'danger' : 'success'}
            text='Railings & Accessories'
            className='w-64 text-sm h-12'
            onClick={() => navigate(`/panels/railings/${id}`)}
          />
        </div>
      )}
      {panelCreateState?.pending && <LoaderOverlay />}
      {isSubmitted && !panelCreateState.error && <ConfettiExplosion /> && (
        <SuccessModal
          reactIcon={<FaSolarPanel />}
          title='Congratulations'
          subtitle='PV Module Added'
          message='Please add associated Railings & Accessories information for this PV Module'
          buttonText='Go to Panel List'
          linkTo='/panels'
        />
      )}
      {isSubmitted && panelCreateState.error && toast.error('Invalid Project Data. Please check the inputs.')}

      <div className='mx-auto md:w-[95%] my-10'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)} className='w-full p-10 shadow-lg rounded bg-white'>
            <AccordionComponent title='General'>
              <div className='grid grid-cols-3 gap-4 rounded'>
                <Input name='brand' type='text' label='Brand of PV Module' readOnly={!editable} />
                <Input name='model' type='text' label='Model No.' readOnly={!editable} />
                <Input name='origin_country' type='text' label='Country of Origin' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Technical Details'>
              <div className='grid grid-cols-3 gap-4'>
                <Input name='peak_power' type='number' label='Peak Power' readOnly={!editable} />
                <Input name='efficiency' type='number' label='Efficiency' readOnly={!editable} />
                <Input name='open_circuit_voltage' type='number' label='Open Circuit Voltage' readOnly={!editable} />
                <Input name='rated_voltage' type='number' label='Rated Voltage' readOnly={!editable} />
                <Input name='short_circuit_current' type='number' label='Short Circuit Current' readOnly={!editable} />
                <Input name='rated_current' type='number' label='Rated Current' readOnly={!editable} />
                <Input name='cell_type' type='text' label='PV Cell Type' readOnly={!editable} />
                <Input name='support_standards' type='text' label='Support Standards' readOnly={!editable} />
                <Input name='test_reports' type='text' label='Test Reports' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Performance Details'>
              <div className='grid grid-cols-3 gap-4'>
                <Input name='avg_daily_production' type='number' label='Average Daily Production' readOnly={!editable} />
                <Input name='first_year_production' type='number' label='First Year Unit Production' readOnly={!editable} />
                <Input name='forecast_20yr' type='number' label='Forecasted 20 Year Production' readOnly={!editable} />
                <Input name='earning_20yr' type='number' label='Forecasted Earnings' readOnly={!editable} />
                <Input name='performance_guarantee' type='number' label='Performance Guarantee' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Financial & Warranty'>
              <div className='grid grid-cols-3 gap-4'>
                <Input name='warranty' type='number' label='PV Module Warranty' readOnly={!editable} />
                <Input name='cost' type='number' label='PV Module Cost' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <div className='w-full flex justify-end'>
              <Button text='Save' type='submit' colorType='success' className='w-64 h-12' />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddEditPanel;
