import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { InitialState, Panel } from './types/PanelTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const createPanel = createAsyncThunk('panel/create', async (payload: Panel, thunk) => {
  try {
    const res = await axios.post(api + '/panels', payload);

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getPanelList = createAsyncThunk('panel/list', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/panels');

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getPanelDetails = createAsyncThunk('panel/details', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/panels/' + payload.id);

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updatePanel = createAsyncThunk('panel/udpate', async (payload: Panel, thunk) => {
  try {
    const res = await axios.put(api + '/panels/' + payload.id, payload);
    toast.success('Panel Updated Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const deletePanel = createAsyncThunk('panel/delete', async (payload: any, thunk) => {
  try {
    const res = await axios.delete(api + '/panels/' + payload.id);
    toast.success('Panel Deleted Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getPanelRailings = createAsyncThunk('panel/railings/get', async (payload: any, thunk) => {
  try {
    const res = await axios.get(api + '/panels/railings/' + payload.id);
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updatePanelRailing = createAsyncThunk('panel/railings/update', async (payload: any, thunk) => {
  try {
    const res = await axios.put(api + '/panels/railings/' + payload.id, payload);
    toast.success('Railings Updated Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  details: {
    data: null,
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    pending: false,
    error: false,
  },
  delete: {
    data: null,
    pending: false,
    error: false,
  },
  railingData: {
    data: [],
    pending: false,
    error: false,
  },
};

const PanelSlice = createSlice({
  name: 'PV Module',
  initialState: initialState,
  reducers: {
    clearPanelDetails: (state) => {
      state.details.data = null;
    },
    clearPanelCreate: (state) => {
      state.create.data = null;
    },
    clearPanelUpdate: (state) => {
      state.update.data = null;
    },
    clearPanelList: (state) => {
      state.list.data = [];
    },
    clearPanelDelete: (state) => {
      state.delete.data = null;
    },
  },
  extraReducers: (builder) => {
    // Create Case
    builder.addCase(createPanel.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
    });
    builder.addCase(createPanel.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
    });
    builder.addCase(createPanel.pending, (state, action) => {
      state.create.pending = true;
      state.create.error = false;
    });
    // List Case
    builder.addCase(getPanelList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getPanelList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getPanelList.pending, (state, action) => {
      state.list.error = false;
      state.list.pending = true;
    });
    // Details Case
    builder.addCase(getPanelDetails.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.error = false;
      state.details.pending = false;
    });
    builder.addCase(getPanelDetails.rejected, (state, action) => {
      state.details.data = null;
      state.details.error = true;
      state.details.pending = false;
    });
    builder.addCase(getPanelDetails.pending, (state, action) => {
      state.details.error = false;
      state.details.pending = true;
    });
    // Update Case
    builder.addCase(updatePanel.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
      state.details.data = action.payload.data;
    });
    builder.addCase(updatePanel.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updatePanel.pending, (state, action) => {
      state.update.error = false;
      state.update.pending = true;
    });
    // Delete Case
    builder.addCase(deletePanel.fulfilled, (state, action) => {
      state.delete.data = action.payload.data;
      state.delete.error = false;
      state.delete.pending = false;
      state.details.data = action.payload.data;
    });
    builder.addCase(deletePanel.rejected, (state, action) => {
      state.delete.data = null;
      state.delete.error = true;
      state.delete.pending = false;
    });
    builder.addCase(deletePanel.pending, (state, action) => {
      state.delete.error = false;
      state.delete.pending = true;
    });
    builder.addCase(getPanelRailings.fulfilled, (state, action) => {
      state.railingData.data = action.payload.data;
      state.railingData.error = false;
      state.railingData.pending = false;
    });
    builder.addCase(getPanelRailings.pending, (state, action) => {
      state.railingData.data = [];
      state.railingData.error = false;
      state.railingData.pending = true;
    });
    builder.addCase(getPanelRailings.rejected, (state, action) => {
      state.railingData.data = [];
      state.railingData.error = true;
      state.railingData.pending = false;
    });
  },
});

export const { clearPanelCreate, clearPanelDetails, clearPanelDelete, clearPanelUpdate } = PanelSlice.actions;

export default PanelSlice.reducer;
