import React, { useEffect, useState } from 'react';
import { Modal, Paper } from '@mui/material';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getInverterList } from '../../../store/InverterSlice';
import { Inverter } from '../../../store/types/InverterTypes';
import { ButtonCustom } from '../../Buttons';
import { CustomSelect } from '../../CustomInput';

import { CgClose } from 'react-icons/cg';

type Props = {
  inverterList?: any;
  setInverterList?: any;
  mode: 'EDIT' | 'ADD';
  editInverter?: any;
  confirmAction: () => void;
  inverter?: any;
  selectedInverter?: any;
  setSelectedInverter?: any;
  closeModal: any;
};

const InverterSelection: React.FC<Props> = ({ confirmAction, selectedInverter, setSelectedInverter, closeModal, mode }) => {
  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    backgroundColor: 'white',
    boxShadow: 24,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 5,
    paddingX: 5,
  };

  const inverters = useSelector((state: RootState) => state.inverter.list.data);
  const inverterOptions = inverters.map((inverter) => ({
    label: inverter.model,
    value: inverter,
  }));

  const [selectedAccessory, setselectedAccessory] = useState<'electrical' | 'hardware' | 'cable'>('electrical');

  const dispatch: AppDispatch = useDispatch();

  const setInverterData = (inverter: Inverter | undefined) => {
    const hardwareAccessories = inverter?.hardware_accessories?.map((accessory) => ({
      id: accessory.accessory.id,
      quantity: accessory.quantity,
      name: accessory.accessory.name,
      cost: accessory.accessory.cost,
    }));
    const electricalAccessories = inverter?.electrical_accessories?.map((accessory) => ({
      id: accessory.accessory.id,
      quantity: accessory.quantity,
      name: accessory.accessory.name,
      cost: accessory.accessory.cost,
    }));
    const cables = inverter?.cables?.map((cable) => ({
      id: cable.cable.id,
      quantity: cable.quantity,
      name: cable.cable.name,
      cost: cable.cable.cost,
    }));

    setSelectedInverter({
      id: inverter?.id,
      brand: inverter?.brand,
      model: inverter?.model,
      capacity: inverter?.rated_power_output,
      hardware_accessories: hardwareAccessories,
      electrical_accessories: electricalAccessories,
      cables: cables,
      cost: inverter?.cost,
    });
  };

  const accessories: { id: string; quantity: number; name: string }[] =
    selectedAccessory === 'electrical'
      ? selectedInverter?.electrical_accessories
      : selectedAccessory === 'hardware'
      ? selectedInverter?.hardware_accessories
      : selectedInverter?.cables;

  useEffect(() => {
    dispatch(getInverterList({}));
  }, [dispatch]);

  const changeAccessoryQuantity = (id: string, quantity: number, type: string) => {
    switch (type) {
      case 'hardware':
        setSelectedInverter((prev: any) => ({
          ...selectedInverter,
          hardware_accessories: prev.hardware_accessories.map((item: any) => (item.id === id ? { ...item, quantity: quantity } : item)),
        }));
        break;
      case 'electrical':
        setSelectedInverter((prev: any) => ({
          ...selectedInverter,
          electrical_accessories: prev.electrical_accessories.map((item: any) => (item.id === id ? { ...item, quantity: quantity } : item)),
        }));
        break;
      case 'cable':
        setSelectedInverter((prev: any) => ({
          ...selectedInverter,
          cables: prev.cables.map((item: any) => (item.id === id ? { ...item, quantity: quantity } : item)),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Modal open={true} style={modalStyle} onClose={closeModal}>
      <Paper sx={[paperStyle, selectedInverter !== null && { height: '90%' }]}>
        <button onClick={() => closeModal()} className='absolute top-5 right-5 text-3xl text-gray-500 hover:scale-90'>
          <CgClose />
        </button>
        <div className='flex w-full justify-between items-center'>
          <h2 className='font-semibold text-lg text-orange-500'>
            {mode === 'ADD' && selectedInverter === null ? 'Select an Inverter' : 'Configure Inverter Accessories'}
          </h2>
          {mode === 'ADD' && <CustomSelect onChange={(e) => setInverterData(e?.value)} options={inverterOptions} value={undefined} />}
        </div>
        {selectedInverter !== null && (
          <div className='my-5 flex flex-col h-full'>
            <div className=''>
              <button
                disabled={selectedInverter === null}
                onClick={() => setselectedAccessory('electrical')}
                className={`p-2 shadow-lg border w-auto ${selectedAccessory === 'electrical' && 'shadow-none border-b-0 bg-orange-50'}`}
              >
                Electrical Accessories
              </button>
              <button
                disabled={selectedInverter === null}
                onClick={() => setselectedAccessory('hardware')}
                className={`p-2 shadow-lg border ${selectedAccessory === 'hardware' && 'shadow-none border-b-0 bg-orange-50'}`}
              >
                Hardware Accessories
              </button>
              <button
                disabled={selectedInverter === null}
                onClick={() => setselectedAccessory('cable')}
                className={`p-2 shadow-lg border ${selectedAccessory === 'cable' && 'shadow-none border-b-0 bg-orange-50'}`}
              >
                Cables
              </button>
            </div>
            <div className='overflow-y-auto h-4/5 pr-6 py-10 bg-orange-50'>
              {accessories?.map((accessory) => (
                <div className='flex w-full justify-between p-3 border-b' key={accessory.id}>
                  <p>{accessory.name}</p>
                  <input
                    type='text'
                    className='p-2 w-20'
                    value={accessory.quantity}
                    onChange={(e) => {
                      changeAccessoryQuantity(accessory.id, Number(e.target.value), selectedAccessory);
                    }}
                  />
                </div>
              ))}
            </div>

            <ButtonCustom onClick={() => confirmAction()} customStyles='bg-green-600 mt-5 mb-16' disabled={selectedInverter === null}>
              Save
            </ButtonCustom>
          </div>
        )}
      </Paper>
    </Modal>
  );
};

export default InverterSelection;
