import React from 'react';
import { Modal, Paper } from '@mui/material';
import { CgClose } from 'react-icons/cg';

import { Input } from '../../CustomInput';
import { ButtonOrange } from '../../Buttons';

type Props = {
  handleChange: any;
  handleSubmit: any;
  closeModal: () => void;
  accessory: { name: string; cost: number | string };
  title: string;
};

const NewAccessoryModal: React.FC<Props> = ({
  handleChange,
  handleSubmit,
  accessory,
  closeModal,
  title,
}) => {
  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'white',
    boxShadow: 24,
    p: 6,
    borderRadius: 4,
  };

  return (
    <Modal open={true} style={modalStyle}>
      <div>
        <button
          onClick={closeModal}
          className="absolute top-10 right-16 text-4xl text-white"
        >
          <CgClose />
        </button>
        <Paper sx={paperStyle} className="relative">
          <div className="flex flex-col">
            <h3 className="mb-6 font-bold text-lg">{title}</h3>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <Input
                label="Name"
                type="text"
                value={accessory?.name ?? ''}
                onChange={(e) => {
                  const field = 'name';
                  const value = e.target.value;
                  handleChange({ field, value });
                }}
                width="w-full"
              />

              <Input
                label="Cost:"
                type="number"
                value={accessory.cost}
                onChange={(e) => {
                  const field = 'cost';
                  const value = Number(e.target.value);
                  handleChange({ field, value });
                }}
                width="w-full"
                units="LKR"
              />

              <ButtonOrange type="submit" width="w-full">
                SAVE
              </ButtonOrange>
            </form>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default NewAccessoryModal;
