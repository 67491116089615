import { Button as MUIBtn, Modal, Tooltip } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '../../Buttons';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { createReceipt } from '../../../store/ProjectsSlice';
import { FaFilePdf, FaSpinner } from 'react-icons/fa';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Receipt from './Receipt';
import { Receipt as IReceipt, Invoice as IInvoice, Project } from '../../../store/types/ProjectTypes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Select, Input } from '../../../components/FormComponents';

type ReceiptListProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  receipts: IReceipt[];
  invoices: IInvoice[];
  project: Project;
};

const ReceiptListModal: React.FC<ReceiptListProps> = ({ open = true, setOpen, receipts, invoices, project }) => {
  const ReceiptSchema = yup.object<Partial<Record<keyof IReceipt, yup.AnySchema>>>({
    payment_type: yup.string().required('Select a payment type.'),
    payment_ref: yup.string(),
    amount: yup.number().required('Amount is required.'),
    invoice_number: yup.string().required('Select an Invoice.'),
    project_id: yup.string().required('Invalid project ID.'),
    description: yup.string().required('Description is required.'),
  });

  const methods = useForm<Partial<IReceipt>>({
    resolver: yupResolver(ReceiptSchema),
    defaultValues: {
      project_id: project?.id,
    },
  });

  const onSave: SubmitHandler<Partial<IReceipt>> = (data) => {
    dispatch(createReceipt(data));
  };

  const dispatch: AppDispatch = useDispatch();

  const paymentTypes = [
    { label: 'Card', value: 'Card' },
    { label: 'Cash', value: 'Cash' },
    { label: 'Bank Transfer', value: 'Bank Transfer' },
  ];

  return (
    <Modal open={open} className='flex items-center justify-center' onClose={() => setOpen(false)}>
      <div className='bg-white px-8 py-5 rounded-lg w-1/2'>
        <FormProvider {...methods}>
          <form className='grid grid-cols-3 gap-2' onSubmit={methods.handleSubmit(onSave)}>
            <Select name='invoice_number' label='Invoice' options={invoices?.map((i) => ({ label: i.invoice_number, value: i.invoice_number }))} />
            <Select name='payment_type' label='Payment Type' options={paymentTypes} />
            <Input name='amount' label='Amount' type='number' />
            <div className='col-span-1'>
              <Input name='description' label='Description' type='text' />
            </div>
            <Input name='payment_ref' label='Payment Reference' type='text' />
            <div className='pt-7'>
              <Button type='submit' colorType='success' text='New Receipt' />
            </div>
          </form>
        </FormProvider>
        {receipts && receipts.length > 0 && (
          <table className='divide-y my-2 w-full bg-orange-50'>
            <thead className='divide-x text-gray-500'>
              <tr>
                <th className='p-2'>Receipt No</th>
                <th className='p-2'>Invoice No</th>
                <th className='p-2'>Date Created</th>
                <th className='p-2'>Download</th>
              </tr>
            </thead>
            <tbody>
              {receipts.map((receipt, idx) => (
                <tr className='divide-x' key={receipt.id}>
                  <td className='p-2 text-center'>{receipt.receipt_number}</td>
                  <td className='p-2 text-center'>{receipt.invoice_number}</td>
                  <td className='p-2 text-center'>{receipt.created_at?.split('T')[0]}</td>
                  <td className='p-2 text-center'>
                    <PDFDownloadLink document={<Receipt project={project} receipt={receipt} receiptIdx={idx} />} fileName={`Receipt${idx + 1}`}>
                      {({ loading }) => (
                        <Tooltip title='Invoice'>
                          <MUIBtn variant='contained' size='small' startIcon={loading ? <FaSpinner /> : <FaFilePdf />} disabled={loading}>
                            Receipt
                          </MUIBtn>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default ReceiptListModal;
