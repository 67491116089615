import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOneUser, updateUser, deleteUser } from '../../store/UserSlice';
import type { RootState, AppDispatch } from '../../store';
import { User } from '../../store/types/UserTypes';
import Breadcrumbs from '../Breadcrumbs';
import { footerHeight, navbarHeight } from '../../utils/constants';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import DeleteModal from '../DeleteModal';
import { toast } from 'react-toastify';
import { CustomSelect, Input } from '../CustomInput';
import { ButtonOrange } from '../Buttons';

const ViewUser = () => {
  const [editable, setEditable] = useState(false);
  const [newUserData, setNewUserData] = useState<Partial<User> | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const params = useParams<{ id: any }>();

  const userId = params.id;

  useEffect(() => {
    const fetchUser = async () => {
      await dispatch(getOneUser(userId));
    };
    fetchUser();
  }, [dispatch, userId]);

  const user = useSelector((state: RootState) => state.user.details.data);

  const userDeleteState = useSelector((state: RootState) => state.user.delete);

  const handleInputChange = (data: { fieldName: string; value: string }) => {
    setNewUserData((prevData) => ({
      ...prevData,
      [data.fieldName]: data.value,
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editable) {
      return;
    }
    if (newUserData?.password !== newUserData?.password_confirm) {
      toast.error('Passwords do not match.');
      return;
    }

    const { password_confirm, ...updateData } = newUserData || {};

    try {
      await dispatch(updateUser({ id: userId, ...updateData }));
      toast.success('User Updated Successfully!');
      setEditable(false);
    } catch (error) {
      toast.error('Failed to update user.');
    }
  };

  const toggleEditable = () => {
    setEditable(!editable);
    if (user && !editable) {
      setNewUserData({
        username: '',
        email: user.email,
        type: user.type,
        password: '',
      });
    } else {
      setNewUserData(null);
    }
  };

  const deleteAction = async () => {
    if (user) {
      await dispatch(deleteUser(user.id));
      setDeleteModal(false);
      navigate('/users');
    }
  };

  const cancelAction = () => {
    setDeleteModal(false);
  };

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/users',
      name: 'Users',
    },
  ];

  if (user) {
    links.push({
      link: '',
      name: user?.username,
    });
  }

  const userOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'User', value: 'user' },
  ];

  const getUserType = (value: string) => {
    const userType = userOptions.find((item) => item.value === value);
    return userType;
  };

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />

        <div className='flex'>
          {editable && (
            <button className='flex gap-2 items-center font-bold hover:text-[#FF802F] mr-4' type='button' onClick={() => setDeleteModal(true)}>
              <span className='text-sm'>Delete</span> <FaTrashAlt className='text-xl' />
            </button>
          )}
          <button type='button' className='' onClick={() => toggleEditable()}>
            {editable ? (
              <div className='flex gap-2 items-center font-bold hover:text-[#FF802F]'>
                <span className='text-sm'>Cancel</span> <AiOutlineCloseSquare className='text-xl' />
              </div>
            ) : (
              <div className='flex gap-2 items-center font-bold hover:text-[#FF802F]'>
                <span className='text-sm'>Edit</span> <FaRegEdit className='text-xl' />
              </div>
            )}
          </button>
        </div>
      </div>
      <form
        onSubmit={(e) => handleFormSubmit(e)}
        className='w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'
      >
        <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3'>
          <Input
            label='User Name'
            type='text'
            disabled={!editable}
            value={newUserData?.username ?? user?.username ?? ''}
            onChange={(e) => {
              const fieldName = 'username';
              const value = e.target.value;
              handleInputChange({ fieldName, value });
            }}
          />

          <Input
            label='Email'
            type='email'
            disabled
            value={newUserData?.email ?? user?.email ?? ''}
            onChange={(e) => {
              const fieldName = 'email';
              const value = e.target.value;
              handleInputChange({ fieldName, value });
            }}
          />

          <CustomSelect
            label='Type'
            disabled
            options={userOptions}
            value={getUserType(newUserData?.type ?? user?.type ?? '')}
            onChange={(value) => {
              const fieldName = 'type';
              handleInputChange({ fieldName, value: value.value });
            }}
          />
        </div>
        <div className='flex flex-col md:flex-row  w-full gap-2 md:gap-3'>
          <Input
            label='Password'
            type='password'
            disabled={!editable}
            value={newUserData?.password ?? ''}
            onChange={(e) => {
              const fieldName = 'password';
              const value = e.target.value;
              handleInputChange({ fieldName, value });
            }}
          />

          <Input
            label='Re-enter Password'
            type='password'
            disabled={!editable}
            value={newUserData?.password_confirm ?? ''}
            onChange={(e) => {
              const fieldName = 'password_confirm';
              const value = e.target.value;
              handleInputChange({ fieldName, value });
            }}
          />
        </div>
        {editable && <ButtonOrange type='submit'>SAVE</ButtonOrange>}
      </form>
      {deleteModal && <DeleteModal deleteAction={deleteAction} cancelAction={cancelAction} type='User' name={user?.username} />}
    </div>
  );
};

export default ViewUser;
