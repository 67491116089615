import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import Calibri from '../../../fonts/calibri.ttf';
import CalibriBold from '../../../fonts/calibri-bold.ttf';

import logo from '../../../images/logo.png';
import { formatNumber } from '../../../utils/auxilliaryFuntions';

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: Calibri,
    },
    {
      src: CalibriBold,
    },
  ],
});

interface ReceiptProps {
  project: any;
  receipt: any;
  receiptIdx: number;
}

const Receipt = ({ project, receipt, receiptIdx }: ReceiptProps) => {
  const systemCapacity = project.project_inverters
    .map((inverter: any) => inverter.inverter.rated_power_output)
    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue / 1000, 0);

  const firstDescription = `${systemCapacity}kW Solar System total payment`;
  const totalPrice = project?.project_finance.price;

  const receiptItems: {
    description: string;
    amount: number;
  }[] = [];

  let balanceAmount = totalPrice;

  project.receipt.slice(0, receiptIdx + 1).forEach((receipt: any) => {
    receiptItems.push({
      description: receipt.description,
      amount: receipt.amount,
    });
    balanceAmount -= receipt.amount;
  });

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.mainView}>
          <View style={styles.header} fixed>
            <Image src={logo} style={styles.logo} />
            <Text style={styles.title}>Receipt</Text>
          </View>
          <View style={styles.addressSection}>
            <View style={styles.companyInfo}>
              <Text style={{ fontWeight: 'bold' }}>Company Address :</Text>
              <Text>Sunterra (Pvt) Ltd</Text>
              <Text>No 188, Buthgamua Rd,</Text>
              <Text>Rajagiriya, Sri Lanka.</Text>
              <Text>Tel: +94 775008888 / +94 705008888</Text>
              <Text>info@sunterra.lk</Text>
              <Text>www.sunterra.com</Text>
            </View>

            <View style={styles.customerInfo}>
              <Text style={{ fontWeight: 'bold' }}>Customer Address :</Text>
              <Text>
                {project?.customer.title} {project?.customer.first_name} {project?.customer.last_name}
              </Text>
              <Text>
                {project?.customer.house_no}, {project?.customer.street},
              </Text>
              <Text>{project?.customer.city}.</Text>
              <Text>{project?.customer.phone}</Text>
            </View>
          </View>

          <View style={styles.receiptInfo}>
            <View style={{ width: '50%' }}>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Receipt Date</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.created_at.split('T')[0]}</Text>
                </View>
              </View>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Invoice Date</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.created_at.split('T')[0]}</Text>
                </View>
              </View>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Payment Method</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.payment_type}</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '50%' }}>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Receipt No.</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.receipt_number}</Text>
                </View>
              </View>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Invoice No.</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.invoice_number}</Text>
                </View>
              </View>
              <View style={styles.receiptInfo_item}>
                <Text style={styles.receiptInfo_item_text}>Payment Reference</Text>
                <View style={styles.receiptInfo_item_data}>
                  <Text style={styles.receiptInfo_item_data_text}>{receipt.payment_ref || '-'}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Table */}

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.headerCell, styles.col1]}>Serial No.</Text>
              <Text style={[styles.headerCell, styles.col2]}>System Capacity</Text>
              <Text style={[styles.headerCell, styles.col3, { textAlign: 'center' }]}>Description</Text>
              <Text style={[styles.headerCell, styles.col4, { borderRight: 0 }]}>Amount (LKR)</Text>
            </View>

            <View style={styles.tableRow} wrap={false}>
              <Text style={[styles.tableCell, styles.col1]}>{1}</Text>
              <Text style={[styles.tableCell, styles.col2]}>{`${systemCapacity} kW`}</Text>
              <Text style={[styles.tableCell, styles.col3]}>{firstDescription}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{formatNumber(totalPrice)}</Text>
            </View>
            {receiptItems.map((item) => (
              <View style={styles.tableRow} wrap={false}>
                <Text style={[styles.tableCell, styles.col1]}></Text>
                <Text style={[styles.tableCell, styles.col2]}></Text>
                <Text style={[styles.tableCell, styles.col3]}>{item.description}</Text>
                <Text style={[styles.tableCell, styles.col4]}>{formatNumber(item.amount)}</Text>
              </View>
            ))}

            <View style={styles.lastRow} wrap={false}>
              <Text style={[styles.noBorderCell, styles.col1]}></Text>
              <Text style={[styles.noBorderCell, styles.col2]}></Text>
              <Text style={[styles.headerCell, styles.col3, { backgroundColor: '#FFB534', borderBottom: 1, padding: 6 }]}>Balance Amount</Text>
              <Text style={[styles.headerCell, styles.col4, { backgroundColor: '#FFB534', borderBottom: 1, padding: 6 }]}>
                {formatNumber(balanceAmount)}
              </Text>
            </View>
          </View>

          <View style={{ marginLeft: 2 }}>
            <Text>Thank you for your payment.</Text>
            <Text>If any queries, please contact our Sales Manager (070 500 8888/077 500 8888)</Text>
            <Text>or Email us on info@sunterra.lk</Text>
          </View>

          <View style={{ flexDirection: 'row', marginVertical: 20 }}>
            <View style={{ width: '50%' }}></View>
            <View style={{ width: '50%' }}>
              <View style={{ width: '50%', borderBottom: '1px solid orange' }}></View>
              <Text style={{ width: '50%', textAlign: 'center' }}>Received By</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Receipt;

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontSize: 9,
    fontFamily: 'Calibri',
  },

  mainView: {
    marginVertical: 30,
    marginLeft: 50,
    marginRight: 30,
    border: '1.5px solid orange',
  },

  header: {
    paddingTop: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'relative',
    justifyContent: 'space-between',
    borderBottom: '1.5px solid orange',
  },

  logo: { width: '120px' },

  title: {
    fontSize: 36,
    fontWeight: 'bold',
    width: '50%',
  },

  addressSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'relative',
    marginVertical: 15,
    paddingHorizontal: 2,
  },

  companyInfo: { width: '50%' },

  customerInfo: {
    width: '50%',
  },

  receiptInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'relative',
    justifyContent: 'flex-start',
  },

  receiptInfo_item: {
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 2,
  },

  receiptInfo_item_text: { fontWeight: 'bold', width: '40%' },

  receiptInfo_item_data: {
    width: 100,
    border: '1px solid orange',
  },

  receiptInfo_item_data_text: {
    textAlign: 'center',
    marginTop: 2,
  },

  // TABLE
  table: {
    width: '100%',
    marginBottom: 20,
  },
  col1: {
    width: '10%',
    textAlign: 'center',
  },
  col2: {
    width: '20%',
    textAlign: 'center',
  },
  col3: {
    width: '50%',
    textAlign: 'left',
  },
  col4: {
    width: '20%',
    textAlign: 'right',
  },

  tableHeader: {
    flexDirection: 'row',
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: '#FFB534',
    fontSize: 11,
  },
  headerCell: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: 'black',
    borderRightColor: '#FFB534',
    paddingVertical: 3,
    margin: 'auto',
    fontWeight: 'bold',
    fontSize: 11,
    overflow: 'hidden',
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    borderTop: 1,
    marginTop: -1,
  },
  tableCell: {
    borderLeft: 1,
    borderColor: 'black',
    padding: 6,
    fontSize: 11,
    textOverflow: 'ellipsis',
  },
  lastRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  noBorderCell: {
    borderLeft: 0,
    borderBottom: 0,
    padding: 6,
    fontSize: 11,
    textOverflow: 'ellipsis',
  },
  total: {
    marginLeft: 'auto',
    color: 'black',
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 11,
    display: 'flex',
    flexDirection: 'row',
  },
});
