import React, { useState } from 'react';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import CustomerForm from './CustomerForm';
import { Customer } from '../../store/types/CustomerTypes';
import Breadcrumbs from '../Breadcrumbs';
import { createCustomer } from '../../store/CustomerSlice';
import LoaderOverlay from '../LoaderOverlay';

type Props = {};

const NewCustomer: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const customerCreateState = useSelector(
    (state: RootState) => state.customer.create
  );

  const [customer, setCustomer] = useState<Customer>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    house_no: '',
    street: '',
    city: '',
    title: 'Mr.',
    nic: '',
  });

  const handleInputChange = ({ fieldName, value }: any) => {
    setCustomer({
      ...customer,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(createCustomer(customer));

    if (!customerCreateState.error) {
      navigate('/customers');
    }
  };

  const links = [
    {
      link: '/customers',
      name: 'Customers',
    },
    {
      link: '',
      name: 'New Customer',
    },
  ];

  return (
    <div
      className="w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]"
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className="flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white">
        <Breadcrumbs links={links} />
      </div>
      {customerCreateState?.pending && <LoaderOverlay />}
      <CustomerForm
        customer={customer}
        onInputChange={handleInputChange}
        onFormSubmit={handleFormSubmit}
        editable={true}
      />
    </div>
  );
};

export default NewCustomer;
