import React from 'react';
import { Input } from '../../CustomInput';
import { InstallationCost } from '../../../store/types/InstallationCostTypes';
import { ButtonOrange } from '../../Buttons';

type Props = {
  editable: boolean;
  installationCost: InstallationCost | null;
  onInputChange: any;
  onFormSubmit: any;
};

const InstallationCostForm: React.FC<Props> = ({
  editable,
  installationCost,
  onInputChange,
  onFormSubmit,
}) => {
  return (
    <form
      onSubmit={(e) => onFormSubmit(e)}
      className="w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white"
    >
      <div className="flex flex-col md:flex-row justify-start w-full gap-2 md:gap-3 lg:gap-12">
        <Input
          label="Min System Capacity"
          type="number"
          disabled={!editable}
          value={installationCost?.min ?? ''}
          onChange={(e) => {
            const fieldName = 'min';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width="w-1/2"
          units="kW"
        />

        <Input
          label="Max System Capacity"
          type="number"
          disabled={!editable}
          value={installationCost?.max ?? ''}
          onChange={(e) => {
            const fieldName = 'max';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width="w-1/2"
          units="kW"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-start w-full gap-2 md:gap-3 lg:gap-12">
        <Input
          label="Tile Roof"
          type="number"
          disabled={!editable}
          value={installationCost?.tile_cost ?? ''}
          onChange={(e) => {
            const fieldName = 'tile_cost';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width="w-1/2"
          units="LKR/kW"
        />

        <Input
          label="Asbestos Roof"
          type="number"
          disabled={!editable}
          value={installationCost?.asbestos_cost ?? ''}
          onChange={(e) => {
            const fieldName = 'asbestos_cost';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width="w-1/2"
          units="LKR/kW"
        />
      </div>
      {editable && (
        <ButtonOrange type="submit" customStyles="mb-4">
          SAVE
        </ButtonOrange>
      )}
    </form>
  );
};

export default InstallationCostForm;
