import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Skeleton } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface MapProps {
    locations: { lat: string, lon: string }[];
    mapContainerStyle: CSSProperties;
    zoom: number | undefined;
    mapTypeId: string | undefined;
    center?: { lat: number, lng: number };
}

const Map: FC<MapProps> = ({
    locations,
    mapContainerStyle,
    zoom,
    mapTypeId,
    center
}) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
    });

    if (loadError) {
        return (
            <div className={`w-[${mapContainerStyle.width}] h-[${mapContainerStyle.height}]`}>
                <p>Error loading maps</p>
            </div>
        );
    }

    if (!isLoaded) {
        return (
            <Skeleton
                variant='rectangular'
                width={mapContainerStyle.width}
                height={mapContainerStyle.width} />
        );
    }

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center
                ? center
                : locations.length > 0
                    ? { lat: parseFloat(locations[0].lat), lng: parseFloat(locations[0].lon) }
                    : { lat: 0, lng: 0 }
            }
            zoom={zoom}
            mapTypeId={mapTypeId}
        >
            {locations.map((location, index) => (
                <Marker
                    key={index}
                    position={{
                        lat: parseFloat(location.lat),
                        lng: parseFloat(location.lon),
                    }}
                />
            ))}
        </GoogleMap>
    );
};

export default Map;