import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getOneCustomer, updateCustomer, deleteCustomer } from '../../store/CustomerSlice';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';
import { Customer } from '../../store/types/CustomerTypes';
import Breadcrumbs from '../Breadcrumbs';
import CustomerForm from './CustomerForm';
import { footerHeight, navbarHeight } from '../../utils/constants';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import DeleteModal from '../DeleteModal';
import { toast } from 'react-toastify';
import { Project } from '../../store/types/ProjectTypes';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ViewCustomer = () => {
  const [editable, setEditable] = useState(false);
  const [newCustomerData, setNewCustomerData] = useState<Customer | Partial<Customer> | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const customerId = params.id;

  useEffect(() => {
    const fetchCustomer = async () => {
      await dispatch(getOneCustomer(customerId));
    };

    fetchCustomer();
  }, [dispatch, customerId]);

  const customer = useSelector((state: RootState) => state.customer.details.data);

  const customerUpdateState = useSelector((state: RootState) => state.customer.update);

  const customerDeleteState = useSelector((state: RootState) => state.customer.delete);

  const projects = useSelector((state: RootState) => state.project?.list.data);

  const customerProject = projects.filter((project: Project) => project.customer_id === customerId);

  const handleInputChange = (data: { fieldName: string; value: string }) => {
    setNewCustomerData({
      ...newCustomerData,
      [data.fieldName]: data.value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!editable) {
      return;
    }
    await dispatch(updateCustomer(newCustomerData as Customer));
    if (!customerUpdateState.error) {
      setNewCustomerData(null);
      toast.success(customerUpdateState.message);
      setEditable(false);
      navigate('/customers');
    }
    toast.error(customerUpdateState.message);
  };

  const toggleEditable = () => {
    setEditable(!editable);
    setNewCustomerData({ ...newCustomerData, id: customerId });
  };

  useEffect(() => {
    if (customerDeleteState.data && !customerDeleteState.error) {
      navigate('/customers');
    }
  }, [customerDeleteState.data, customerDeleteState.error, navigate]);

  const deleteAction = async () => {
    dispatch(deleteCustomer({ id: customerId }));
  };

  const cancelAction = () => {
    setDeleteModal(false);
  };

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/customers',
      name: 'Customers',
    },
  ];

  if (customer) {
    links.push({
      link: '',
      name: customer?.first_name + ' ' + customer?.last_name,
    });
  }

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />

        <div className='flex'>
          {editable && (
            <button className='flex gap-2 items-center font-bold hover:text-[#FF802F] mr-4' type='button' onClick={() => setDeleteModal(true)}>
              <span className='text-sm'>Delete</span> <FaTrashAlt className='text-xl' />
            </button>
          )}
          <button type='button' className='' onClick={() => toggleEditable()}>
            {editable ? (
              <div className='flex gap-2 items-center font-bold hover:text-[#FF802F]'>
                <span className='text-sm'>Cancel</span> <AiOutlineCloseSquare className='text-xl' />
              </div>
            ) : (
              <div className='flex gap-2 items-center font-bold hover:text-[#FF802F]'>
                <span className='text-sm'>Edit</span> <FaRegEdit className='text-xl' />
              </div>
            )}
          </button>
        </div>
      </div>

      <CustomerForm
        onFormSubmit={handleFormSubmit}
        onInputChange={handleInputChange}
        customer={customer}
        updateCustomer={newCustomerData}
        editable={editable}
      />
      <div className='w-full md:w-[95%] border rounded p-5 md:p-10 md:mb-10 shadow-lg flex flex-col relative bg-white'>
        <p className='text-sm font-bold text-gray-700'>Customer Projects</p>
        <TableContainer
          sx={{
            marginX: 'auto',
            marginTop: '20px',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-left text-sm font-bold'>Started Date</p>
                </TableCell>
                <TableCell>
                  <p className='text-left text-sm font-bold'>Location</p>
                </TableCell>
                <TableCell>
                  <p className='text-left text-sm font-bold'>Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerProject.map((project: Project, index) => (
                <TableRow key={index} onClick={() => navigate(`/projects/${project.id}`)}>
                  <TableCell>
                    <p>{project.created_at?.split('T')[0]}</p>
                  </TableCell>
                  <TableCell>
                    <p>{`${project.address[0]}, ${project.address[1]}, ${project.address[2]}`}</p>
                  </TableCell>
                  <TableCell>
                    <p>{project.status}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {deleteModal && (
        <DeleteModal
          deleteAction={deleteAction}
          cancelAction={cancelAction}
          type='Customer'
          name={`${customer?.first_name} ${customer?.last_name}`}
        />
      )}
    </div>
  );
};

export default ViewCustomer;
