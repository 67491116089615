import { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { footerHeight, navbarHeight } from '../../../utils/constants';
import AccessorySelection from './AccessorySelection';
import { Inverter } from '../../../store/types/InverterTypes';
import { useParams } from 'react-router-dom';
import InverterSelection from './InverterSelection';
import PvModuleSelection from './PanelSelection';
import {
  addInverter,
  addPanel,
  getProjectDetails,
  removeInverter,
  removePanel,
  updateProjectCable,
  updateProjectElectricalAccessory,
  updateProjectHardwareAccessory,
  updateProjectRailing,
  createRefund,
  deleteRefund,
} from '../../../store/ProjectsSlice';

import { CgClose } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { ItemType } from '../../../utils/enums';
import { Refund, RefundDeleteInput } from '../../../store/types/RefundTypes';
import { ProjectTasks } from '../../../utils/enums';


const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other} className='w-full'>
      {value === index && (
        <Box p={3}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ProjectDevices = () => {
  const params = useParams();
  const projectId = params.id as string;
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [newInverterModal, setNewInverterModal] = useState(false);
  const [newInverter, setNewInverter] = useState(null);
  const [newPanelModal, setNewPanelModal] = useState(false);
  const [newRefundModal, setNewRefundModal] = useState(false);
  const [newPanel, setNewPanel] = useState(null);
  const [currentTab, setCurrentTab] = useState<'inverter' | 'panel' | 'hardware' | 'electrical' | 'cable' | 'railing' | 'returns'>('inverter');
  const [accessoryUpdateData, setAccessoryUpdateData] = useState<{
    accessory_id: string;
    projectId: string;
    quantity: number;
    additional_quantity: string | number | readonly string[] | undefined;
  }>({
    accessory_id: '',
    quantity: 0,
    additional_quantity: 0,
    projectId: projectId as string,
  });

  const [editingAccessory, setEditingAccessory] = useState(false);

  const project = useSelector((state: RootState) => state.project.details.data);

  const quantityEditLimitation = [ProjectTasks.Installation, ProjectTasks.Complete, ProjectTasks.Handover].includes(project?.project_task[0].task);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getProjectDetails({ id: projectId }));
  }, [dispatch, projectId]);

  const submitNewInverter = async () => {
    if (newInverter) {
      const inverter = newInverter as Partial<Inverter>;
      await dispatch(
        addInverter({
          inverterData: {
            id: inverter.id,
            hardware_accessories: inverter.hardware_accessories,
            electrical_accessories: inverter.electrical_accessories,
            cables: inverter.cables,
            cost: inverter.cost,
          },
          params: projectId,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          setNewInverterModal(false);
          setNewInverter(null);
        })
        .catch((err) => {
          toast.error(err.message || 'Error');
        });
    }
    dispatch(getProjectDetails({ id: projectId }));
  };
  const submitNewPanel = async () => {
    if (newPanel) {
      const panel = newPanel as any;
      await dispatch(
        addPanel({
          panelData: {
            id: panel.id,
            quantity: panel.quantity,
            cost: panel.cost,
            railings: panel.railings,
          },
          params: projectId,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          setNewPanelModal(false);
          setNewPanel(null);
        })
        .catch((err) => {
          toast.error(err.message || 'Error');
        });
    }
    dispatch(getProjectDetails({ id: projectId }));
  };

  const submitRefund = async (accessory: any, returnQty: number) => {
    const { project_id, type, name, cost } = accessory;
    // Normalize accessory id
    const accessory_id = type === ItemType.Cable ? accessory.cable_id : type === ItemType.Railing ? accessory.railing_id : accessory.accessory_id;

    const refundData: Refund = {
      accessory_id,
      project_id,
      quantity: returnQty,
      type,
      name,
      cost,
    };
    await dispatch(createRefund(refundData));
    dispatch(getProjectDetails({ id: projectId }));
    setNewRefundModal(false);
  };

  const submitRemoveInverter = async (inverterId: string) => {
    await dispatch(removeInverter({ inverter_id: inverterId, project_id: projectId }));
    dispatch(getProjectDetails({ id: projectId }));
  };

  const submitRemovePanel = async (panelId: string) => {
    await dispatch(removePanel({ panel_id: panelId, project_id: projectId }));
    dispatch(getProjectDetails({ id: projectId }));
  };

  const submitRemoveRefund = async (refund: Refund) => {
    const { accessory_id, project_id, quantity, type, id } = refund;

    await dispatch(deleteRefund({ id, project_id, returnedQty: quantity, accessory_id, type }));
    dispatch(getProjectDetails({ id: projectId }));
  };

  const updateAccessorySubmit = async () => {
    switch (currentTab) {
      case 'hardware':
        dispatch(updateProjectHardwareAccessory(accessoryUpdateData));
        break;
      case 'electrical':
        dispatch(updateProjectElectricalAccessory(accessoryUpdateData));
        break;
      case 'cable':
        dispatch(updateProjectCable(accessoryUpdateData));
        break;
      case 'railing':
        dispatch(
          updateProjectRailing({
            accessory_id: accessoryUpdateData.accessory_id,
            utilization: accessoryUpdateData.quantity,
            additional_quantity: accessoryUpdateData.additional_quantity,
            projectId: accessoryUpdateData.projectId,
          })
        );
        break;
    }
    dispatch(getProjectDetails({ id: projectId }));
  };

  return (
    <div className='w-full flex flex-col items-center' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <Tabs value={value} onChange={handleChange} aria-label='tabs'>
        <Tab label='Inverters' id='tab-0' onClick={() => setCurrentTab('inverter')} />
        <Tab label='PV Modules' id='tab-1' onClick={() => setCurrentTab('panel')} />
        <Tab label='Hardware Accessories' id='tab-2' onClick={() => setCurrentTab('hardware')} />
        <Tab label='Electrical Accessories' id='tab-3' onClick={() => setCurrentTab('electrical')} />
        <Tab label='Cables' id='tab-4' onClick={() => setCurrentTab('cable')} />
        <Tab label='Railings' id='tab-4' onClick={() => setCurrentTab('railing')} />
        <Tab label='Returns' id='tab-4' onClick={() => setCurrentTab('returns')} />
      </Tabs>

      {/* Inverters */}
      <TabPanel value={value} index={0}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Brand</th>
                <th className='p-2'>Model</th>
                <th className='p-2'>Capacity</th>
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.project_inverters.sort().map((inverter: any) => (
                <tr key={inverter.id}>
                  <td className='px-2 py-3'>{inverter.inverter.brand}</td>
                  <td className='px-2 py-3'>{inverter.inverter.model}</td>
                  <td className='px-2 py-3'>{inverter.inverter.rated_power_output}</td>
                  <td className='px-2 py-3'>
                    <button onClick={() => submitRemoveInverter(inverter.id)}>
                      <CgClose />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => setNewInverterModal(true)} className='bg-green-600 text-white font-semibold rounded py-2 px-4 float-right'>
            Add Inverter
          </button>
        </div>
      </TabPanel>

      {/* Panels */}
      <TabPanel value={value} index={1}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Brand</th>
                <th className='p-2'>Model</th>
                <th className='p-2'>Avg Daily Production</th>
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.project_panels.map((panel: any) => (
                <tr key={panel.id}>
                  <td className='px-2 py-3'>{panel.panel.brand}</td>
                  <td className='px-2 py-3'>{panel.panel.model}</td>
                  <td className='px-2 py-3'>{panel.panel.avg_daily_production}</td>
                  <td className='px-2 py-3'>
                    <button onClick={() => submitRemovePanel(panel.id)}>
                      <CgClose />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button onClick={() => setNewPanelModal(true)} className='bg-green-600 text-white font-semibold rounded py-2 px-4 float-right'>
            Add Panel
          </button>
        </div>
      </TabPanel>

      {/* Hardware Accessories */}
      <TabPanel value={value} index={2}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Quantity</th>
                {quantityEditLimitation && <th className='p-2'>Additional Quantity</th>}
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.hardware_accessories
                ?.slice()
                ?.sort((a: { accessory: { name: string } }, b: { accessory: { name: any } }) => a.accessory.name.localeCompare(b.accessory.name))
                .map((accessory: any) => (
                  <tr key={accessory.accessory_id}>
                    <td className='px-2 py-3'>{accessory.accessory.name}</td>
                    {editingAccessory ? (
                      <>
                        <td>
                          <input
                            disabled={quantityEditLimitation}
                            type='number'
                            onBlur={() => {
                              updateAccessorySubmit();
                              setEditingAccessory(false);
                            }}
                            className='px-3 py-2 border rounded w-24'
                            value={accessoryUpdateData?.accessory_id === accessory.accessory_id ? accessoryUpdateData.quantity : accessory.quantity}
                            onChange={(e) =>
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                quantity: Number(e.target.value),
                              })
                            }
                          />
                        </td>
                        {quantityEditLimitation && (
                          <td>
                            <input
                              disabled={!quantityEditLimitation}
                              type='number'
                              onBlur={() => {
                                updateAccessorySubmit();
                                setEditingAccessory(false);
                              }}
                              className='px-3 py-2 border rounded w-24'
                              value={
                                accessoryUpdateData?.accessory_id === accessory.accessory_id
                                  ? accessoryUpdateData.additional_quantity
                                  : accessory.additional_quantity
                              }
                              onChange={(e) =>
                                setAccessoryUpdateData({
                                  ...accessoryUpdateData,
                                  additional_quantity: Number(e.target.value),
                                })
                              }
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td
                          className='px-2 py-3 w-64'
                          onClick={() => {
                            setAccessoryUpdateData({
                              ...accessoryUpdateData,
                              projectId: projectId as string,
                              accessory_id: accessory.accessory_id,
                              quantity: accessory.quantity,
                            });
                            setEditingAccessory(true);
                          }}
                        >
                          {accessory.quantity}
                        </td>
                        {quantityEditLimitation && (
                          <td
                            className='px-2 py-3 w-64'
                            onClick={() => {
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                projectId: projectId as string,
                                accessory_id: accessory.accessory_id,
                                quantity: accessory.quantity,
                                additional_quantity: accessory.additional_quantity,
                              });
                              setEditingAccessory(true);
                            }}
                          >
                            {accessory.additional_quantity}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </TabPanel>

      {/* Electrical Accessories */}
      <TabPanel value={value} index={3}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Quantity</th>
                {quantityEditLimitation && <th className='p-2'>Additional Quantity</th>}
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.electrical_accessories
                ?.slice()
                ?.sort((a: { accessory: { name: string } }, b: { accessory: { name: any } }) => a.accessory.name.localeCompare(b.accessory.name))
                .map((accessory: any) => (
                  <tr key={accessory.accessory_id}>
                    <td className='px-2 py-3'>{accessory.accessory.name}</td>
                    {editingAccessory ? (
                      <>
                        <td>
                          <input
                            disabled={quantityEditLimitation}
                            type='number'
                            onBlur={() => {
                              updateAccessorySubmit();
                              setEditingAccessory(false);
                            }}
                            className='px-3 py-2 border rounded w-24'
                            value={accessoryUpdateData?.accessory_id === accessory.accessory_id ? accessoryUpdateData.quantity : accessory.quantity}
                            onChange={(e) =>
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                quantity: Number(e.target.value),
                              })
                            }
                          />
                        </td>
                        {quantityEditLimitation && (
                          <td>
                            <input
                              disabled={!quantityEditLimitation}
                              type='number'
                              onBlur={() => {
                                updateAccessorySubmit();
                                setEditingAccessory(false);
                              }}
                              className='px-3 py-2 border rounded w-24'
                              value={
                                accessoryUpdateData?.accessory_id === accessory.accessory_id
                                  ? accessoryUpdateData.additional_quantity
                                  : accessory.additional_quantity
                              }
                              onChange={(e) =>
                                setAccessoryUpdateData({
                                  ...accessoryUpdateData,
                                  additional_quantity: Number(e.target.value),
                                })
                              }
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td
                          className='px-2 py-3 w-64'
                          onClick={() => {
                            setAccessoryUpdateData({
                              ...accessoryUpdateData,
                              projectId: projectId as string,
                              accessory_id: accessory.accessory_id,
                              quantity: accessory.quantity,
                              additional_quantity: accessory.additional_quantity,
                            });
                            setEditingAccessory(true);
                          }}
                        >
                          {accessory.quantity}
                        </td>
                        {quantityEditLimitation && (
                          <td
                            className='px-2 py-3 w-64'
                            onClick={() => {
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                projectId: projectId as string,
                                accessory_id: accessory.accessory_id,
                                quantity: accessory.quantity,
                                additional_quantity: accessory.additional_quantity,
                              });
                              setEditingAccessory(true);
                            }}
                          >
                            {accessory.additional_quantity}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </TabPanel>

      {/* Cables */}
      <TabPanel value={value} index={4}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Quantity</th>
                {quantityEditLimitation && <th className='p-2'>Additional Quantity</th>}
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.cables
                ?.slice()
                ?.sort((a: { cable: { name: string } }, b: { cable: { name: any } }) => a.cable.name.localeCompare(b.cable.name))
                .map((accessory: any) => (
                  <tr key={accessory.cable_id}>
                    <td className='px-2 py-3'>{accessory.cable.name}</td>
                    {editingAccessory ? (
                      <>
                        <td>
                          <input
                            disabled={quantityEditLimitation}
                            type='number'
                            onBlur={() => {
                              updateAccessorySubmit();
                              setEditingAccessory(false);
                            }}
                            className='px-3 py-2 border rounded w-24'
                            value={accessoryUpdateData?.accessory_id === accessory.cable_id ? accessoryUpdateData.quantity : accessory.quantity}
                            onChange={(e) =>
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                quantity: Number(e.target.value),
                              })
                            }
                          />
                        </td>
                        {quantityEditLimitation && (
                          <td>
                            <input
                              disabled={!quantityEditLimitation}
                              type='number'
                              onBlur={() => {
                                updateAccessorySubmit();
                                setEditingAccessory(false);
                              }}
                              className='px-3 py-2 border rounded w-24'
                              value={
                                accessoryUpdateData?.accessory_id === accessory.cable_id
                                  ? accessoryUpdateData.additional_quantity
                                  : accessory.additional_quantity
                              }
                              onChange={(e) =>
                                setAccessoryUpdateData({
                                  ...accessoryUpdateData,
                                  additional_quantity: Number(e.target.value),
                                })
                              }
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td
                          className='px-2 py-3 w-64'
                          onClick={() => {
                            setAccessoryUpdateData({
                              ...accessoryUpdateData,
                              projectId: projectId as string,
                              accessory_id: accessory.cable_id,
                              quantity: accessory.quantity,
                              additional_quantity: accessory.additional_quantity,
                            });
                            setEditingAccessory(true);
                          }}
                        >
                          {accessory.quantity}
                        </td>
                        {quantityEditLimitation && (
                          <td
                            className='px-2 py-3 w-64'
                            onClick={() => {
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                projectId: projectId as string,
                                accessory_id: accessory.cable_id,
                                quantity: accessory.quantity,
                                additional_quantity: accessory.additional_quantity,
                              });
                              setEditingAccessory(true);
                            }}
                          >
                            {accessory.additional_quantity}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </TabPanel>

      {/* Railings */}
      <TabPanel value={value} index={5}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Quantity</th>
                {quantityEditLimitation && <th className='p-2'>Additional Quantity</th>}
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.railings
                ?.slice()
                ?.sort((a: { railing: { name: string } }, b: { railing: { name: any } }) => a.railing.name.localeCompare(b.railing.name))
                .map((railing: any) => (
                  <tr key={railing.railing_id}>
                    <td className='px-2 py-3'>{railing.railing.name}</td>
                    {editingAccessory ? (
                      <>
                        <td>
                          <input
                            disabled={quantityEditLimitation}
                            type='number'
                            onBlur={() => {
                              updateAccessorySubmit();
                              setEditingAccessory(false);
                            }}
                            className='px-3 py-2 border rounded w-24'
                            value={accessoryUpdateData?.accessory_id === railing.railing_id ? accessoryUpdateData.quantity : railing.utilization}
                            onChange={(e) =>
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                quantity: Number(e.target.value),
                              })
                            }
                          />
                        </td>
                        {quantityEditLimitation && (
                          <td>
                            <input
                              disabled={!quantityEditLimitation}
                              type='number'
                              onBlur={() => {
                                updateAccessorySubmit();
                                setEditingAccessory(false);
                              }}
                              className='px-3 py-2 border rounded w-24'
                              value={
                                accessoryUpdateData?.accessory_id === railing.railing_id
                                  ? accessoryUpdateData.additional_quantity
                                  : railing.additional_quantity
                              }
                              onChange={(e) =>
                                setAccessoryUpdateData({
                                  ...accessoryUpdateData,
                                  additional_quantity: Number(e.target.value),
                                })
                              }
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td
                          className='px-2 py-3 w-64'
                          onClick={() => {
                            setAccessoryUpdateData({
                              ...accessoryUpdateData,
                              projectId: projectId as string,
                              accessory_id: railing.railing_id,
                              quantity: railing.utilization,
                              additional_quantity: railing.additional_quantity,
                            });
                            setEditingAccessory(true);
                          }}
                        >
                          {railing.utilization}
                        </td>
                        {quantityEditLimitation && (
                          <td
                            className='px-2 py-3 w-64'
                            onClick={() => {
                              setAccessoryUpdateData({
                                ...accessoryUpdateData,
                                projectId: projectId as string,
                                accessory_id: railing.railing_id,
                                quantity: railing.utilization,
                                additional_quantity: railing.additional_quantity,
                              });
                              setEditingAccessory(true);
                            }}
                          >
                            {railing.additional_quantity}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </TabPanel>

      {/* Refunds */}
      <TabPanel value={value} index={6}>
        <div
          style={{
            maxHeight: 'calc(100vh - 240px)',
            overflowY: 'auto',
          }}
        >
          <table className='w-full divide-y'>
            <thead>
              <tr className='text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Type</th>
                <th className='p-2'>Cost</th>
                <th className='p-2'>Quantity</th>
              </tr>
            </thead>
            <tbody className='divide-y'>
              {project?.refunds.map((refund: Refund) => (
                <tr>
                  <td className='px-2 py-3'>{refund.name}</td>
                  <td className='px-2 py-3'>{refund.type}</td>
                  <td className='px-2 py-3'>{refund.cost}</td>
                  <td className='px-2 py-3' align='center'>
                    {refund.quantity}
                  </td>
                  <td className='px-2 py-3'>
                    <button onClick={() => submitRemoveRefund(refund)}>
                      <CgClose />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => setNewRefundModal(true)} className='bg-green-600 text-white font-semibold rounded py-2 px-4 float-right'>
            Add Item to Return
          </button>
        </div>
      </TabPanel>
      {newInverterModal && (
        <InverterSelection
          confirmAction={() => submitNewInverter()}
          closeModal={() => {
            setNewInverterModal(false);
            setNewInverter(null);
          }}
          mode='ADD'
          setSelectedInverter={setNewInverter}
          selectedInverter={newInverter}
        />
      )}
      {newPanelModal && (
        <PvModuleSelection
          confirmAction={() => submitNewPanel()}
          closeModal={() => {
            setNewPanelModal(false);
            setNewPanel(null);
          }}
          mode='ADD'
          setSelectedPanel={setNewPanel}
          selectedPanel={newPanel}
        />
      )}
      {newRefundModal && (
        <AccessorySelection
          confirmAction={submitRefund}
          closeModal={() => setNewRefundModal(false)}
          accessories={[
            ...project?.hardware_accessories
              .filter((ha: any) => ha.quantity > 0)
              .map((ha: any) => ({
                ...ha,
                type: ItemType.HardwareAccessory,
                name: ha.accessory.name,
              })),
            ...project?.electrical_accessories
              .filter((ea: any) => ea.quantity > 0)
              .map((ea: any) => ({
                ...ea,
                type: ItemType.ElectricalAccessory,
                name: ea.accessory.name,
              })),
            ...project?.cables
              .filter((c: any) => c.quantity > 0)
              .map((c: any) => ({
                ...c,
                type: ItemType.Cable,
                name: c.cable.name,
              })),
            ...project?.railings
              .filter((r: any) => r.utilization > 0)
              .map((r: any) => ({
                ...r,
                type: ItemType.Railing,
                name: r.railing.name,
              })),
          ]}
        />
      )}
    </div>
  );
};

export default ProjectDevices;
