import React from 'react';
import { PiSpinnerGapThin } from 'react-icons/pi';

const LoaderOverlay = () => {
  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center'>
      <div className='text-center'>
        <PiSpinnerGapThin className='animate-spin text-9xl text-orange-500' />
      </div>
    </div>
  );
};

export default LoaderOverlay;
