import React, { useState } from 'react';
import { Circuit } from '../../../store/types/CircuitTypes';
import { Input } from '../../CustomInput';
import { ButtonOrange } from '../../Buttons';

type Props = {
  onFormSubmit: any;
  onInputChange: any;
  spec: Circuit | null;
  editable: boolean;
  updateSpec?: Circuit | Partial<Circuit> | null;
};

const CircuitForm: React.FC<Props> = ({
  editable,
  onFormSubmit,
  onInputChange,
  spec,
  updateSpec,
}) => {
  return (
    <form
      onSubmit={onFormSubmit}
      className="w-full md:w-[95%] flex justify-center flex-col md:my-10 gap-10 bg-[#ECECEC] mx-auto"
    >
      <div className="h-full border rounded-lg p-5 md:p-10 shadow-lg flex flex-col relative bg-white">
        {/* =========================== */}
        {/* <div className="flex flex-col md:flex-row justify-between w-full">
          <div className="w-full md:w-1/3 flex flex-col mb-3 pr-3">
            <label className="my-1 text-sm text-gray-500 font-bold">
              Circuit Name:{' '}
            </label>
            <input
              type="text"
              className="border py-3 text-sm px-2 rounded"
              defaultValue={selectedSpec && selectedSpec.circuitName}
              disabled={!editable}
              value={updateSpec?.dc_cable_type ?? spec?.dc_cable_type ?? ''}
              onChange={(e) => {
                const fieldName = 'circuitName';
                const value = e.target.value;
                onInputChange({fieldName, value});
              }}
            />
          </div>
        </div> */}

        {/* ==============Row 1============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            label="Make & Type of DC Cable:"
            type="text"
            disabled={!editable}
            value={updateSpec?.dc_cable_type ?? spec?.dc_cable_type ?? ''}
            onChange={(e) => {
              const fieldName = 'dc_cable_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="Shanghai Yuanling DC Single PV Cable"
          />

          <Input
            label="DC Cable Size:"
            type="text"
            disabled={!editable}
            value={updateSpec?.dc_cable_size ?? spec?.dc_cable_size ?? ''}
            onChange={(e) => {
              const fieldName = 'dc_cable_size';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="mm"
            placeholder="4"
          />

          <Input
            label="DC Cable Rated Voltage:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.dc_cable_rated_voltage ??
              spec?.dc_cable_rated_voltage ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'dc_cable_rated_voltage';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="V"
            placeholder="1500"
          />
        </div>

        {/* ==============Row 2============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            label="DC Cable Standards:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.dc_cable_standards ?? spec?.dc_cable_standards ?? ''
            }
            onChange={(e) => {
              const fieldName = 'dc_cable_standards';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="TUV Rheinland"
          />
          <Input
            label="Make & Type of AC Cable:"
            type="text"
            disabled={!editable}
            value={updateSpec?.ac_cable_type ?? spec?.ac_cable_type ?? ''}
            onChange={(e) => {
              const fieldName = 'ac_cable_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="ACL"
          />

          <Input
            label="AC Cable Size:"
            type="text"
            disabled={!editable}
            value={updateSpec?.ac_cable_size ?? spec?.ac_cable_size ?? ''}
            onChange={(e) => {
              const fieldName = 'ac_cable_size';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="mm"
            placeholder="6"
          />
        </div>

        {/* ==============Row 3============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            label="AC Cable Rated Voltage:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.ac_cable_rated_voltage ??
              spec?.ac_cable_rated_voltage ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'ac_cable_rated_voltage';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="V"
            placeholder="450/750"
          />

          <Input
            label="AC Cable Standards:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.ac_cable_standards ?? spec?.ac_cable_standards ?? ''
            }
            onChange={(e) => {
              const fieldName = 'ac_cable_standards';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="BSEN50525-2-31/SLS 1504-2-31"
          />

          <Input
            label="Grounding Cable Rated Voltage:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.grounding_cable_rated_voltage ??
              spec?.grounding_cable_rated_voltage ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'grounding_cable_rated_voltage';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="V"
            placeholder="450/750"
          />
        </div>

        {/* ==============Row 4============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            label="Grounding Cable Standards:"
            type="text"
            disabled={!editable}
            value={
              updateSpec?.grounding_cable_standards ??
              spec?.grounding_cable_standards ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'grounding_cable_standards';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="BSEN50525-2-31/SLS 1504-2-31"
          />
          <Input
            label="Make & Type of DC Switch:"
            type="text"
            disabled={!editable}
            value={updateSpec?.dc_switch_type ?? spec?.dc_switch_type ?? ''}
            onChange={(e) => {
              const fieldName = 'dc_switch_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="SUNTREE - SISO"
          />

          <Input
            type="text"
            label="DC Switch - Voltage & Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.dc_switch_voltage_current_rating ??
              spec?.dc_switch_voltage_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'dc_switch_voltage_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="600V / 1500V DC / 32A"
          />
        </div>

        {/* ==============Row 5============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="Make & Type of AC Isolator:"
            disabled={!editable}
            value={updateSpec?.ac_isolator_type ?? spec?.ac_isolator_type ?? ''}
            onChange={(e) => {
              const fieldName = 'ac_isolator_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="ORANGE - Din Rail"
          />

          <Input
            type="text"
            label="AC Isolator Voltage & Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.ac_isolator_voltage_current_rating ??
              spec?.ac_isolator_voltage_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'ac_isolator_voltage_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="388V / 32A"
          />

          <Input
            type="text"
            label="Make & Type of DC Overcurrent Protection:"
            disabled={!editable}
            value={updateSpec?.dc_ocp_type ?? spec?.dc_ocp_type ?? ''}
            onChange={(e) => {
              const fieldName = 'dc_ocp_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="SUNTREE - SUP4 - PV - Type II"
          />
        </div>

        {/* ==============Row 6============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="DC Overcurrent Protection - Voltage & Current Rating"
            disabled={!editable}
            value={
              updateSpec?.dc_ocp_voltage_current_rating ??
              spec?.dc_ocp_voltage_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'dc_ocp_voltage_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="1000V / 30kA"
          />

          <Input
            type="text"
            label="Make & Type of AC Overcurrent Protection:"
            disabled={!editable}
            value={updateSpec?.ocp_type ?? spec?.ocp_type ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="SUNTREE - SUP1/SUP2 - Type II"
          />

          <Input
            type="text"
            label="AC Overcurrent Protection - Voltage & Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.ocp_voltage_current_rating ??
              spec?.ocp_voltage_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'ocp_voltage_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="350V / 20 - 30kA"
          />
        </div>

        {/* ==============Row 7============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="AC Overcurrent Protection - Uc:"
            disabled={!editable}
            value={updateSpec?.ocp_uc ?? spec?.ocp_uc ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_uc';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="V"
            placeholder="385"
          />

          <Input
            type="text"
            label="AC Overcurrent Protection - Umax:"
            disabled={!editable}
            value={updateSpec?.ocp_umax ?? spec?.ocp_umax ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_umax';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kV"
            placeholder="1.6"
          />

          <Input
            type="text"
            label="AC Overcurrent Protection - In:"
            disabled={!editable}
            value={updateSpec?.ocp_in ?? spec?.ocp_in ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_in';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="20"
          />
        </div>

        {/* ==============Row 8============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="AC Overcurrent Protection - Imax (kA):"
            disabled={!editable}
            value={updateSpec?.ocp_imax ?? spec?.ocp_imax ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_imax';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="40"
          />

          <Input
            type="text"
            label="Make & Type of RCD:"
            disabled={!editable}
            value={updateSpec?.rcd_type ?? spec?.rcd_type ?? ''}
            onChange={(e) => {
              const fieldName = 'rcd_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="ORANGE"
          />

          <Input
            type="text"
            label="RCD Rating:"
            disabled={!editable}
            value={
              updateSpec?.rcd_current_rating ?? spec?.rcd_current_rating ?? ''
            }
            onChange={(e) => {
              const fieldName = 'rcd_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="32A / 20 - 30kA"
          />
        </div>

        {/* ==============Row 9============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="Make & Type of Earthing:"
            disabled={!editable}
            value={updateSpec?.earthing_type ?? spec?.earthing_type ?? ''}
            onChange={(e) => {
              const fieldName = 'earthing_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="Copper Rod"
          />

          <Input
            type="text"
            label="Size of Earth Bonding:"
            disabled={!editable}
            value={updateSpec?.earthing_size ?? spec?.earthing_size ?? ''}
            onChange={(e) => {
              const fieldName = 'earthing_size';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="mm"
            placeholder="16"
          />

          <Input
            type="text"
            label="Make & Type of DC SPD:"
            disabled={!editable}
            value={updateSpec?.dc_spd_type ?? spec?.dc_spd_type ?? ''}
            onChange={(e) => {
              const fieldName = 'dc_spd_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="SUNTREE"
          />
        </div>

        {/* ==============Row 10============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="Make & Type of AC SPD:"
            disabled={!editable}
            value={updateSpec?.ac_spd_type ?? spec?.ac_spd_type ?? ''}
            onChange={(e) => {
              const fieldName = 'ac_spd_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="SUNTREE"
          />

          <Input
            type="text"
            label="Make & Model of MCB:"
            disabled={!editable}
            value={updateSpec?.mcb_type ?? spec?.mcb_type ?? ''}
            onChange={(e) => {
              const fieldName = 'mcb_type';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="ORANGE"
          />

          <Input
            type="text"
            label="MCB Voltage & Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.mcb_voltage_current_rating ??
              spec?.mcb_voltage_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'mcb_voltage_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="385V / 32A"
          />
        </div>
        {/* ==============Row 11============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="MCB Short Circuit Current:"
            disabled={!editable}
            value={
              updateSpec?.mcb_short_circuit_current ??
              spec?.mcb_short_circuit_current ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'mcb_short_circuit_current';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="20 - 30"
          />

          <Input
            type="text"
            label="MCB IP Class:"
            disabled={!editable}
            value={updateSpec?.mcb_ip_class ?? spec?.mcb_ip_class ?? ''}
            onChange={(e) => {
              const fieldName = 'mcb_ip_class';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            placeholder="IP64"
          />

          <Input
            type="text"
            label="Over Current Protective Device (DC Bus) - Icn:"
            disabled={!editable}
            value={updateSpec?.ocp_dc_icn ?? spec?.ocp_dc_icn ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_dc_icn';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="20"
          />
        </div>

        {/* ==============Row 12============= */}
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="Over Current Protective Device (DC Bus) - Iu:"
            disabled={!editable}
            value={updateSpec?.ocp_dc_iu ?? spec?.ocp_dc_iu ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_dc_iu';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="40"
          />

          <Input
            type="text"
            label="Over Current Protective Device (DC Bus) -Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.ocp_dc_current_rating ??
              spec?.ocp_dc_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'ocp_dc_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="A"
            placeholder="32"
          />

          <Input
            type="text"
            label="Over Current Protective Device (AC Bus) - Icn:"
            disabled={!editable}
            value={updateSpec?.ocp_ac_icn ?? spec?.ocp_ac_icn ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_ac_icn';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="30"
          />
        </div>

        {/* ==============Row 13============= */}
        <div className="flex flex-col md:flex-row w-full gap-2 md:gap-3 lg:gap-5">
          <Input
            type="text"
            label="Over Current Protective Device (AC Bus) - Iu:"
            disabled={!editable}
            value={updateSpec?.ocp_ac_iu ?? spec?.ocp_ac_iu ?? ''}
            onChange={(e) => {
              const fieldName = 'ocp_ac_iu';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="kA"
            placeholder="40"
          />

          <Input
            type="text"
            label="Over Current Protective Device (AC Bus) -Current Rating:"
            disabled={!editable}
            value={
              updateSpec?.ocp_ac_current_rating ??
              spec?.ocp_ac_current_rating ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'ocp_ac_current_rating';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="A"
            placeholder="32"
          />

          <Input
            type="text"
            label="Grounding Cable Cross-Section (mm)"
            disabled={!editable}
            value={
              updateSpec?.grounding_cable_cross ??
              spec?.grounding_cable_cross ??
              ''
            }
            onChange={(e) => {
              const fieldName = 'grounding_cable_cross';
              const value = e.target.value;
              onInputChange({ fieldName, value });
            }}
            units="mm"
            placeholder="6"
          />
        </div>
      </div>
      {editable && <ButtonOrange type="submit">SAVE</ButtonOrange>}
    </form>
  );
};

export default CircuitForm;
