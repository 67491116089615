import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './CustomerSlice';
import projectReducer from './ProjectsSlice';
import userReducer from './UserSlice';
import dashboardReducer from './DashboardSlice';
import inverterReducer from './InverterSlice';
import panelReducer from './PanelSlice';
import electricalAccessoryReducer from './ElectricalAccessorySlice';
import hardwareAccessoryReducer from './HardwareAccessorySlice';
import cableReducer from './CableSlice';
import railingReducer from './RailingSlice';
import tariffReducer from './TariffSlice';
import utilityReducer from './UtilitySlice';
import CircuitReducer from './CircuitSlice';
import TaskReducer from './TaskSlice';
import InstallationCostReducer from './InstallationCostSlice';
import TransportCostReducer from './TransportCostSlice';
import EngineerFeeReducer from './EngineerFeeSlice';
import ServiceCostReducer from './ServiceCostSlice';
import SaleReducer from './SaleSlice';

const store = configureStore({
  reducer: {
    customer: customerReducer,
    project: projectReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    inverter: inverterReducer,
    panel: panelReducer,
    electricalAccessory: electricalAccessoryReducer,
    hardwareAccessory: hardwareAccessoryReducer,
    cable: cableReducer,
    railing: railingReducer,
    tariff: tariffReducer,
    utility: utilityReducer,
    circuit: CircuitReducer,
    task: TaskReducer,
    installationCost: InstallationCostReducer,
    transportCost: TransportCostReducer,
    engineerFee: EngineerFeeReducer,
    serviceCost: ServiceCostReducer,
    sale: SaleReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
