import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Login from './components/User/Login';
import './App.css';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authorization = localStorage.getItem('token');
    !authorization && navigate('/login');
  }, [navigate]);

  return (
    <div className="relative">
      <ToastContainer />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
    </div>
  );
};

export default App;
