import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/ceb-annexure-5/1.png';
import img2 from '../../images/documents/ceb-annexure-5/2.png';
import img3 from '../../images/documents/ceb-annexure-5/3.png';
import installerDetails from '../../config/installerDetails';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 10,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  customer_name: {
    position: 'absolute',
    top: '115px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
  },

  customer_address: {
    position: 'absolute',
    top: '157px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
  },

  customer_electricity_acc_num: {
    position: 'absolute',
    top: '115px',
    left: '480px',
    width: '80px',
    wordWrap: 'break-word',
  },

  utility: {
    position: 'absolute',
    top: '127px',
    left: '480px',
  },

  customer_contact: {
    position: 'absolute',
    top: '155px',
    left: '480px',
    wordWrap: 'break-word',
  },

  customer_email: {
    position: 'absolute',
    top: '165px',
    left: '480px',
    wordWrap: 'break-word',
  },

  customer_fax: {
    position: 'absolute',
    top: '177px',
    left: '480px',
    wordWrap: 'break-word',
  },

  contractor_name: {
    position: 'absolute',
    top: '215px',
    left: '200px',
    width: '160px',
    wordWrap: 'break-word',
  },

  contractor_address: {
    position: 'absolute',
    top: '235px',
    left: '200px',
    width: '180px',
    wordWrap: 'break-word',
  },

  contractor_sea_reg_num: {
    position: 'absolute',
    top: '207px',
    left: '475px',
    width: '100px',
    fontSize: '7px',
    wordBreak: 'break-word',
  },

  contractor_contact: {
    position: 'absolute',
    top: '230px',
    left: '480px',
    width: '80px',
  },

  contractor_email: {
    position: 'absolute',
    top: '240px',
    left: '480px',
    width: '80px',
    wordWrap: 'break-word',
  },

  contractor_fax: {
    position: 'absolute',
    top: '252px',
    left: '480px',
    width: '80px',
    wordWrap: 'break-word',
  },

  package: {
    position: 'absolute',
    top: '302px',
    left: '40px',
  },

  continuation_sheet_num: {
    position: 'absolute',
    top: '325px',
    left: '485px',
  },

  brand: {
    position: 'absolute',
    top: '398px',
    left: '200px',
  },
  origin_country: {
    position: 'absolute',
    top: '398px',
    left: '460px',
  },
  num_of_inverters: {
    position: 'absolute',
    top: '413px',
    left: '200px',
  },
  model_and_serial_num: {
    position: 'absolute',
    top: '413px',
    left: '460px',
  },

  test_reports: {
    position: 'absolute',
    top: '435px',
    left: '200px',
  },

  output_voltage: {
    position: 'absolute',
    top: '450px',
    left: '245px',
    fontSize: 7,
  },

  input_voltage: {
    position: 'absolute',
    top: '460px',
    left: '245px',
    fontSize: 7,
  },

  power_output: {
    position: 'absolute',
    top: '455px',
    left: '460px',
  },

  max_input_voltage: {
    position: 'absolute',
    top: '473px',
    left: '190px',
  },

  max_input_current: {
    position: 'absolute',
    top: '473px',
    left: '460px',
  },

  max_output_voltage: {
    position: 'absolute',
    top: '492px',
    left: '190px',
  },

  max_output_current: {
    position: 'absolute',
    top: '492px',
    left: '460px',
  },

  power_frequency: {
    position: 'absolute',
    top: '510px',
    left: '190px',
  },

  max_efficiency: {
    position: 'absolute',
    top: '510px',
    left: '460px',
  },

  temperature_range: {
    position: 'absolute',
    top: '528px',
    left: '190px',
  },

  degree_of_protection: {
    position: 'absolute',
    top: '528px',
    left: '460px',
  },

  support_standards: {
    position: 'absolute',
    top: '547px',
    left: '190px',
  },

  panel_brand: {
    position: 'absolute',
    top: '582px',
    left: '190px',
  },
  panel_origin_country: {
    position: 'absolute',
    top: '597px',
    left: '190px',
  },
  panel_test_reports: {
    position: 'absolute',
    top: '615px',
    left: '200px',
  },
  panel_model_and_serial_num: {
    position: 'absolute',
    top: '637px',
    left: '190px',
  },

  panel_peak_power: {
    position: 'absolute',
    top: '632px',
    left: '460px',
    fontSize: '10px',
  },
  num_of_panels: {
    position: 'absolute',
    top: '645px',
    left: '460px',
    fontSize: '10px',
  },
  panel_efficiency: {
    position: 'absolute',
    top: '661px',
    left: '190px',
  },
  pv_cell_type: {
    position: 'absolute',
    top: '661px',
    left: '460px',
  },
  panel_oc_voltage: {
    position: 'absolute',
    top: '681px',
    left: '190px',
  },
  panel_rated_voltage: {
    position: 'absolute',
    top: '681px',
    left: '460px',
  },

  panel_sc_current: {
    position: 'absolute',
    top: '703px',
    left: '190px',
  },
  panel_rated_current: {
    position: 'absolute',
    top: '703px',
    left: '460px',
  },
  panel_support_standards: {
    position: 'absolute',
    top: '728px',
    left: '190px',
  },

  // 2nd page

  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  earthing_conductor_material: {
    position: 'absolute',
    top: '50px',
    left: '200px',
  },

  earthing_conductor_csa: {
    position: 'absolute',
    top: '50px',
    left: '290px',
  },
  earthing_conductor_connection: {
    position: 'absolute',
    top: '47px',
    left: '530px',
  },

  // page 3

  view_3: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  item_inspected_1: {
    position: 'absolute',
    top: '200px',
    left: '500px',
  },

  item_inspected_2: {
    position: 'absolute',
    top: '230px',
    left: '500px',
  },
  item_inspected_3: {
    position: 'absolute',
    top: '260px',
    left: '500px',
  },
  item_inspected_4: {
    position: 'absolute',
    top: '285px',
    left: '500px',
  },
  item_inspected_5: {
    position: 'absolute',
    top: '315px',
    left: '500px',
  },

  item_inspected_6: {
    position: 'absolute',
    top: '355px',
    left: '500px',
  },
  item_inspected_7: {
    position: 'absolute',
    top: '400px',
    left: '500px',
  },
  item_inspected_8: {
    position: 'absolute',
    top: '435px',
    left: '500px',
  },

  item_inspected_9: {
    position: 'absolute',
    top: '465px',
    left: '500px',
  },

  item_inspected_10: {
    position: 'absolute',
    top: '495px',
    left: '500px',
  },
  item_inspected_11: {
    position: 'absolute',
    top: '525px',
    left: '500px',
  },
  item_inspected_12: {
    position: 'absolute',
    top: '565px',
    left: '500px',
  },
  item_inspected_13: {
    position: 'absolute',
    top: '590px',
    left: '500px',
  },
  item_inspected_14: {
    position: 'absolute',
    top: '615px',
    left: '500px',
  },
  item_inspected_15: {
    position: 'absolute',
    top: '645px',
    left: '500px',
  },

  item_inspected_16: {
    position: 'absolute',
    top: '675px',
    left: '500px',
  },
  item_inspected_17: {
    position: 'absolute',
    top: '702px',
    left: '500px',
  },

  checkmark: {
    position: 'absolute',
    width: 8,
    height: 15,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 0,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    transform: 'rotate(45deg)',
  },
});

const Checkmark = () => (
  <View>
    <div style={styles.checkmark}></div>
  </View>
);

const CEBAnnexure5 = ({ project }: any) => {
  const systemCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />

          {/* Customer Details */}
          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.customer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.customer_electricity_acc_num}>
            {project?.account_no}
          </Text>
          <Text style={styles.utility}>{project?.utility.name}</Text>
          <Text style={styles.customer_contact}>{project?.customer.phone}</Text>
          <Text style={styles.customer_email}>{project?.customer.email}</Text>
          <Text style={styles.customer_fax}></Text>

          {/* Contarctor Details */}
          <Text style={styles.contractor_name}>{installerDetails.name}</Text>
          <Text style={styles.contractor_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.contractor_sea_reg_num}>
            {installerDetails.sea_reg_num.substring(0, 15)}
            <br />
            {installerDetails.sea_reg_num.substring(15)}
          </Text>
          <Text style={styles.contractor_contact}>
            {installerDetails.phone_1}
          </Text>
          <Text style={styles.contractor_email}>{installerDetails.email}</Text>
          <Text style={styles.contractor_fax}>N/A</Text>

          {/* Description of the installation */}
          <Text style={styles.package}>{systemCapacity}</Text>
          <Text style={styles.continuation_sheet_num}></Text>

          {/* Inverter Details */}

          <View>
            <Text style={styles.brand}>
              {project?.project_inverters[0]?.inverter?.brand}
            </Text>
            <Text style={styles.origin_country}>
              {project?.project_inverters[0]?.inverter.origin_country}
            </Text>
            <Text style={styles.num_of_inverters}>
              {project?.project_inverters?.length}
            </Text>
            <Text style={styles.model_and_serial_num}>
              {project?.project_inverters[0]?.inverter.model}
            </Text>
            <Text style={styles.test_reports}>
              {project?.project_inverters[0]?.inverter.test_reports}
            </Text>
            <Text style={styles.output_voltage}>
              {project?.project_inverters[0]?.inverter.rated_out_voltage_ac}
            </Text>
            <Text style={styles.input_voltage}>
              {project?.project_inverters[0]?.inverter.rated_in_voltage_dc}
            </Text>
            <Text style={styles.power_output}>
              {project?.project_inverters[0]?.inverter.rated_power_output}
            </Text>
            <Text style={styles.max_input_voltage}>
              {project?.project_inverters[0]?.inverter.max_in_voltage_dc}
            </Text>
            <Text style={styles.max_input_current}>
              {project?.project_inverters[0]?.inverter.max_in_current_dc}
            </Text>
            <Text style={styles.max_output_voltage}>
              {project?.project_inverters[0]?.inverter.max_out_voltage_ac}
            </Text>
            <Text style={styles.max_output_current}>
              {project?.project_inverters[0]?.inverter.max_out_current_ac}
            </Text>
            <Text style={styles.power_frequency}>
              {project?.project_inverters[0]?.inverter.rated_power_frequency}
            </Text>
            <Text style={styles.max_efficiency}>
              {project?.project_inverters[0]?.inverter.max_efficiency}
            </Text>
            <Text style={styles.temperature_range}>
              {project?.project_inverters[0]?.inverter.operating_temperature} C
            </Text>
            <Text style={styles.degree_of_protection}>
              {project?.project_inverters[0]?.inverter.degree_of_protection}
            </Text>
            <Text style={styles.support_standards}>
              {project?.project_inverters[0]?.inverter.supported_standards}
            </Text>
          </View>

          {/* Panel Details */}

          <Text style={styles.panel_brand}>
            {project?.project_panels[0]?.panel.brand}
          </Text>
          <Text style={styles.panel_origin_country}>
            {project?.project_panels[0]?.panel.origin_country}
          </Text>
          <Text style={styles.panel_test_reports}>
            {project?.project_panels[0]?.panel.test_reports}
          </Text>
          <Text style={styles.panel_model_and_serial_num}>
            {project?.project_panels[0]?.panel.model}
          </Text>

          <Text style={styles.panel_peak_power}>
            {project?.project_panels[0]?.panel.peak_power}
          </Text>
          <Text style={styles.num_of_panels}>{project?.no_of_panels}</Text>
          <Text style={styles.panel_efficiency}>
            {project?.project_panels[0]?.panel.efficiency}
          </Text>
          <Text style={styles.pv_cell_type}>
            {project?.project_panels[0]?.panel.cell_type}
          </Text>
          <Text style={styles.panel_oc_voltage}>
            {project?.project_panels[0]?.panel.open_circuit_voltage}
          </Text>
          <Text style={styles.panel_rated_voltage}>
            {project?.project_panels[0]?.panel.rated_voltage}
          </Text>
          <Text style={styles.panel_sc_current}>
            {project?.project_panels[0]?.panel.short_circuit_current}
          </Text>
          <Text style={styles.panel_rated_current}>
            {project?.project_panels[0]?.panel.rated_current}
          </Text>
          <Text style={styles.panel_support_standards}>
            {project?.project_panels[0]?.panel.support_standards}
          </Text>
        </View>
      </Page>

      {/* page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />
          {/* protective conductors */}
          <Text style={styles.earthing_conductor_material}>
            {project?.circuit?.earthing_type}
          </Text>
          <Text style={styles.earthing_conductor_csa}>
            {project?.circuit?.earthing_size}
          </Text>
          <View style={styles.earthing_conductor_connection}>
            <Checkmark />
          </View>
        </View>
      </Page>

      {/* page 3 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_3}>
          <Image src={img3} style={styles.image} />

          <View style={styles.item_inspected_1}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_2}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_3}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_4}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_5}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_6}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_7}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_8}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_9}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_10}>
            <Checkmark />
          </View>

          <View style={styles.item_inspected_11}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_12}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_13}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_14}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_15}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_16}>
            <Checkmark />
          </View>
          <View style={styles.item_inspected_17}>
            <Checkmark />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CEBAnnexure5;
