import React from 'react';
import Select from 'react-select';

interface InputProps {
  label?: string;
  type: string;
  disabled?: boolean;
  value?: any;
  onChange?: (e: any) => void;
  placeholder?: string;
  units?: string;
  customStyles?: string;
  width?: string;
  name?: string;
}

export interface ReactSelectProps {
  label: string;
  value: any;
}

interface SelectProps {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  options: ReactSelectProps[];
  onChange: (e: any) => void;
  value: any;
  customStyles?: string;
  width?: string;
  required?: boolean;
  defaultValue?: any;
}

export const Input = ({
  label,
  type,
  disabled,
  value,
  onChange,
  placeholder,
  customStyles,
  units,
  width = 'w-1/3',
  name,
}: InputProps) => (
  <div className={`w-full md:${width} ${customStyles} flex flex-col mb-3`}>
    <label className="my-1 text-xs text-gray-500 font-bold">
      {label}
      <div className="flex items-end gap-2 relative">
        <input
          name={name}
          placeholder={placeholder}
          type={type}
          required
          className="border py-3 text-sm px-2 rounded w-full mt-2 outline-orange-400 disabled:cursor-not-allowed cursor-text"
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
        <span className="absolute right-6 top-5 text-sm font-medium">
          {units}
        </span>
      </div>
    </label>
  </div>
);

export const CustomSelect = ({
  disabled,
  label,
  placeholder,
  options,
  customStyles = '',
  width = 'w-full md:w-1/3',
  onChange,
  required = false,
  value,
  defaultValue,
}: SelectProps) => (
  <div className={`${width} flex flex-col mb-3`}>
    <label className="my-1 text-xs text-gray-500 font-bold">
      {label}
      <Select
        styles={{
          // @ts-ignore
          control: (base, state) => ({
            ...base,
            paddingTop: 4,
            paddingBottom: 5,
            outlineColor: state.menuIsOpen ? '#FF802F' : 'grey',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: 'grey',
            primary25: '#FF802F',
            primary: '#FF802F',
          },
        })}
        value={value}
        required={required}
        isDisabled={disabled}
        autoComplete={false}
        className={`${customStyles} text-sm rounded w-full mt-2 outline-orange-400 disabled:cursor-not-allowed cursor-pointer`}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </label>
  </div>
);
