import React from 'react';
import TaskCard from './TaskCard';
import { useDroppable } from '@dnd-kit/core';
import { ColumnType, TaskType } from '../../../store/types/KanbanTypes';

type ColumnProps = {
  column: ColumnType;
  tasks: TaskType[];
};

const Column = ({ column, tasks }: ColumnProps) => {
  const { setNodeRef } = useDroppable({
    id: column.id,
  });

  return (
    <div className='flex-none flex-col w-80'>
      <div className={`py-3 px-3 rounded-md shadow-custom-shadow mb-4`} style={{ backgroundColor: column.bgColor, opacity: 0.7 }}>
        <div className='flex items-center justify-between'>
          <h2 className='font-semibold'>{column.title}</h2> <p>{tasks?.length}</p>
        </div>
      </div>
      <div>
        <div ref={setNodeRef} className='h-screen flex flex-col flex-1 rounded-md bg-[#ffff] p-3 gap-3 shadow-custom-shadow'>
          {tasks.map((task: any) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Column;
