import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Billing, Tariff } from '../../store/types/TariffTypes';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '../FormComponents';
import { footerHeight, navbarHeight } from '../../utils/constants';
import Breadcrumbs from '../Breadcrumbs';
import { Button } from '../Buttons';
import { createTariff, updateTariff, clearTariffDetails, getOneTariff } from '../../store/TariffSlice';
import { AppDispatch, RootState } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const BillingSchema = yup.object<Partial<Record<keyof Billing, yup.AnySchema>>>({
  id: yup.string().optional(),
  min: yup.number().required('Minimum value is required').min(0, 'Minimum value must be at least 0'),
  max: yup.number().required('Maximum value is required').moreThan(yup.ref('min'), 'Maximum value must be greater than minimum value'),
  fixed_charge: yup.number().required('Fixed charge is required').min(1, 'Fixed charge must be greater than 0.'),
  rate: yup.number().required('Rate is required').min(1, 'Rate must be greater than 0'),
});

const TariffSchema = yup.object<Partial<Record<keyof Tariff, yup.AnySchema>>>({
  id: yup.string().optional(),
  name: yup.string().required(),
  return_rate: yup.number().required(),
  billing: yup
    .array()
    .of(
      BillingSchema.test('is-greater-than-previous', 'Min and Max must be greater than the previous item', function (value, context) {
        const { path, parent } = context;
        const index = parseInt(path.split('[')[1].split(']')[0], 10);
        if (index > 0) {
          const previousItem = parent[index - 1];
          if (previousItem) {
            return (value as Billing).min > previousItem.max;
          }
        }
        return true;
      })
    )
    .required(),
});

const AddEditTariff = () => {
  const params = useParams<{ id: string }>();
  const tariffId = params.id;
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const tariffDetails = useSelector((state: RootState) => state.tariff.details);
  const tariffUpdateState = useSelector((state: RootState) => state.tariff.update);
  const tariffCreateState = useSelector((state: RootState) => state.tariff.create);

  const [editable, setEditable] = useState(true);

  const methods = useForm<Partial<Tariff>>({
    resolver: yupResolver(TariffSchema),
    defaultValues: {
      name: '',
      return_rate: 0,
      billing: [
        {
          min: 0,
          max: 90,
          rate: 0,
          fixed_charge: 0,
        },
      ],
    },
  });

  useEffect(() => {
    if (tariffId) {
      dispatch(getOneTariff(tariffId));
    }
    return () => {
      dispatch(clearTariffDetails());
    };
  }, [dispatch, tariffId]);

  useEffect(() => {
    tariffDetails.data &&
      methods.reset({
        id: tariffDetails.data.id,
        name: tariffDetails.data.name,
        return_rate: tariffDetails.data.return_rate,
        billing: tariffDetails.data.billing,
      });
  }, [methods, tariffDetails.data, tariffId]);

  const tariffState = useWatch(methods);

  const onSave: SubmitHandler<Partial<Tariff>> = async (data) => {
    if (tariffId) {
      await dispatch(updateTariff(data as Tariff));
      if (!tariffUpdateState.error) {
        // navigate('/tariff-types');
      }
    } else {
      await dispatch(createTariff(data as Tariff));
      if (!tariffCreateState.error) {
        navigate('/tariff-types');
      }
    }
  };

  const addNewRange = () => {
    const currentBillingData = tariffState?.billing as Billing[];
    const idx = tariffState?.billing!.length - 1;
    methods.setValue('billing', [
      ...currentBillingData,
      { min: currentBillingData[idx].max + 1, max: currentBillingData[idx].max + 60, fixed_charge: 0, rate: 0 },
    ]);
  };

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <div className='flex items-center'>
          <Breadcrumbs links={[{ name: 'Tariff', link: '/tariff-types' }, { name: (tariffId ? 'Edit' : 'Add') + ' Tariff' }]} />
        </div>
      </div>
      <div className='mt-20 w-5/6'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)} className='w-full p-10 shadow-lg rounded bg-white  grid grid-cols-4 gap-2'>
            <div className='col-span-5 flex justify-end'>
              <div className='w-1/5'>
                <Button
                  colorType='primary'
                  text='Add Range'
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    addNewRange();
                  }}
                />
              </div>
            </div>
            <div className='col-span-2'>
              <Input label='Name' name='name' type='text' readOnly={!editable} />
            </div>
            <div className='col-span-2'>
              <Input label='Return Rate' name='return_rate' type='number' readOnly={!editable} />
            </div>
            {tariffState.billing?.map((biling, idx) => (
              <div className='col-span-4 grid grid-cols-4 gap-2 items-end mt-2' key={idx}>
                <div className='col-span-4'>
                  <h3 className='font-semibold'>Billing Bracket {idx + 1}</h3>
                </div>
                <div className='col-span-1'>
                  <Input label='Min' name={`billing[${idx}].min`} type='text' readOnly={!editable} />
                </div>
                <div className='col-span-1'>
                  <Input label='Max' name={`billing[${idx}].max`} type='text' readOnly={!editable} />
                </div>
                <div className='col-span-1'>
                  <Input label='Rate' name={`billing[${idx}].rate`} type='text' readOnly={!editable} />
                </div>
                <div className='col-span-1'>
                  <Input label='Fixed Charge' name={`billing[${idx}].fixed_charge`} type='text' readOnly={!editable} />
                </div>
              </div>
            ))}
            <div className='col-span-5 flex justify-end mt-4'>
              <div className='w-1/5'>
                <Button colorType='success' text='Save' type='submit' />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddEditTariff;
