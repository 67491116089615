import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, Tariff } from './types/TariffTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getTariffList = createAsyncThunk('tariff/list', async (filters: any) => {
  const res = await axios.get(`${api}/tariff-types`);

  return res.data;
});

export const createTariff = createAsyncThunk('tariff/create', async (data: Tariff): Promise<any> => {
  const response = await axios.post(`${api}/tariff-types`, data);
  toast.success('Tariff Type Added successfully');
  return response.data;
});

export const getOneTariff = createAsyncThunk('tariff/get-one', async (tariffId: string | undefined): Promise<any> => {
  const response = await axios.get(`${api}/tariff-types/${tariffId}`);

  return response.data;
});

export const updateTariff = createAsyncThunk('tariff/update', async (data: any | undefined): Promise<any> => {
  const response = await axios.put(`${api}/tariff-types/${data.id}`);

  return response.data;
});

export const deleteTariff = createAsyncThunk('tariffs/delete', async (tariffId: string | undefined) => {
  const response = await axios.delete(`${api}/tariff-types/${tariffId}`);

  return response.data;
});

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  details: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
};

const TariffSlice = createSlice({
  name: 'Tariff',
  initialState: initialState,
  reducers: {
    clearTariffDetails: (state) => {
      state.details.data = null;
    },
    clearTariffCreate: (state) => {
      state.create.data = null;
    },
  },

  // GET ALL TARIFF TYPES
  extraReducers: (builder) => {
    builder.addCase(getTariffList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getTariffList.rejected, (state) => {
      state.list.error = true;
      state.list.pending = false;
      state.list.data = [];
    });
    builder.addCase(getTariffList.pending, (state) => {
      state.list.error = false;
      state.list.pending = true;
      state.list.data = [];
    });

    // CREATE NEW TARIFF TYPE
    builder.addCase(createTariff.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createTariff.rejected, (state) => {
      state.create.error = true;
      state.create.pending = false;
      state.create.data = null;
    });
    builder.addCase(createTariff.pending, (state) => {
      state.create.error = false;
      state.create.pending = true;
      state.create.data = null;
    });

    // GET ONE TARIFF TYPE
    builder.addCase(getOneTariff.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.pending = false;
      state.details.error = false;
    });
    builder.addCase(getOneTariff.rejected, (state) => {
      state.details.error = true;
      state.details.pending = false;
      state.details.data = null;
    });
    builder.addCase(getOneTariff.pending, (state) => {
      state.details.error = false;
      state.details.pending = true;
      state.details.data = null;
    });

    // UPDATE TARIFF TYPE
    builder.addCase(updateTariff.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.pending = false;
      state.update.error = false;
    });
    builder.addCase(updateTariff.rejected, (state) => {
      state.update.error = true;
      state.update.pending = false;
      state.update.data = null;
    });
    builder.addCase(updateTariff.pending, (state) => {
      state.update.error = false;
      state.update.pending = true;
      state.update.data = null;
    });
  },
});

export const { clearTariffCreate, clearTariffDetails } = TariffSlice.actions;

export default TariffSlice.reducer;
