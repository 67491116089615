import { useDraggable } from '@dnd-kit/core';
import { TaskType } from '../../../store/types/KanbanTypes';
import { IoPerson } from 'react-icons/io5';

type TaskCardProps = {
  task: TaskType;
};

const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: task.id,
  });

  const style = transform
    ? {
        transform: `translate(${transform.x}px, ${transform.y}px)`,
      }
    : undefined;
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
      className='flex items-start gap-4 cursor-grab rounded-md bg-blue-200/60 px-4 py-3 shadow-sm hover:shadow-md hover:bg-blue-200'
    >
      <div className='flex justify-center items-center w-10 h-10 bg-white rounded-full text-xl'>
        <IoPerson className='' />
      </div>
      <div>
        <h3 className='font-medium text-base mb-1'>{task.customerName}</h3>
        <p className='text-gray-500 text-xs'>
          File ID: <span className='font-medium'>{task.fileId}</span>
        </p>
        <p className='text-orange-900 text-xs'>
          Assigned To: <span className='font-medium'>{task.assignedTo}</span>
        </p>
      </div>
    </div>
  );
};

export default TaskCard;
