import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { Input } from '../../components/FormComponents';
import AccordionComponent from '../Accordion';
import Breadcrumbs from '../Breadcrumbs';
import { footerHeight, navbarHeight } from '../../utils/constants';
import { Button } from '../Buttons';
import { createProject } from '../../store/ProjectsSlice';
import { Project } from '../../store/types/ProjectTypes';

const ProjectSchema = yup.object<Partial<Record<keyof Project, yup.AnySchema>>>({
  id: yup.string().optional(),
  user: yup.string().required('User is requried.').default('xx'),
  date: yup
    .date()
    .required('Date is required.')
    .default(() => new Date()),
});

const AddProject = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [editable, setEditable] = useState(true);

  const methods = useForm<Partial<Project>>({
    resolver: yupResolver(ProjectSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (id) {
    }
    return () => {};
  }, [id]);

  const onSave: SubmitHandler<Partial<Project>> = (data) => {
    dispatch(createProject(data as Project));
  };

  return (
    <div className='w-full bg-gray-100 overflow-y-scroll' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='p-6 bg-white'>
        <Breadcrumbs links={[{ name: 'Projects', link: '/projects' }, { name: 'New Project' }]} />
      </div>
      <div className='m-10'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)} className='w-full p-10 shadow-lg rounded bg-white'>
            <AccordionComponent title='General'>
              <div className='grid grid-cols-3 gap-4 rounded'>
                <Input name='user' type='text' label='Assigned User' readOnly={!editable} />
                <Input name='date' type='date' label='Date' readOnly={!editable} />
              </div>
            </AccordionComponent>
            <AccordionComponent title='Customer Details'>
              <div className='grid grid-cols-3 gap-4'></div>
            </AccordionComponent>
            <AccordionComponent title='Project Details Details'>
              <div className='grid grid-cols-3 gap-4'></div>
            </AccordionComponent>
            <AccordionComponent title='System Specifications'>
              <div className='grid grid-cols-3 gap-4'></div>
            </AccordionComponent>
            <div className='w-full flex justify-end'>
              <Button text='Save' type='submit' colorType='success' className='w-64 h-12' />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddProject;
