import React, { useState } from 'react';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { ServiceCost } from '../../../store/types/ServiceCostTypes';
import { createServiceCost } from '../../../store/ServiceCostSlice';
import ServiceCostForm from './ServiceCostForm';
import Breadcrumbs from '../../Breadcrumbs';

type Props = {};

const AddServiceCost: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const createServiceCostState = useSelector(
    (state: RootState) => state.serviceCost.create
  );

  const [cost, setCost] = useState<ServiceCost>({
    min: '',
    max: '',
    cost: 0,
    free_3yr: 0,
  });

  const handleInputChange = ({ fieldName, value }: any) => {
    setCost({
      ...cost,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(createServiceCost(cost));

    if (!createServiceCostState.error) {
      navigate('/financials/service-cost');
    }
  };

  const links = [
    {
      link: '/financials/service-cost',
      name: 'Financials',
    },
    {
      link: '',
      name: 'Add Service Cost',
    },
  ];

  return (
    <div
      className="w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC]"
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className="flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white">
        <Breadcrumbs links={links} />
      </div>
      <ServiceCostForm
        serviceCost={cost}
        onInputChange={handleInputChange}
        onFormSubmit={handleFormSubmit}
        editable={true}
      />
    </div>
  );
};

export default AddServiceCost;
