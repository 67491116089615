import React from 'react';
import { Input } from '../../CustomInput';
import { EngineerFee } from '../../../store/types/EngineerFeeTypes';
import { ButtonOrange } from '../../Buttons';

type Props = {
  editable: boolean;
  engineerFee: EngineerFee | null;
  onInputChange: (input: { fieldName: string; value: number }) => void;
  onFormSubmit: (e: React.FormEvent) => void;
};

const EngineerFeeForm: React.FC<Props> = ({ editable, engineerFee, onInputChange, onFormSubmit }) => {
  return (
    <form
      onSubmit={(e) => onFormSubmit(e)}
      className='w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'
    >
      <div className='flex flex-col md:flex-row justify-start w-full gap-2 md:gap-3 lg:gap-12'>
        <Input
          label='Min System Capacity'
          type='number'
          disabled={!editable}
          value={engineerFee?.min ?? ''}
          onChange={(e) => {
            const fieldName = 'min';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width='w-1/2'
          units='kW'
        />

        <Input
          label='Max System Capacity'
          type='number'
          disabled={!editable}
          value={engineerFee?.max ?? ''}
          onChange={(e) => {
            const fieldName = 'max';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width='w-1/2'
          units='kW'
        />
      </div>
      <div className='flex flex-col md:flex-row justify-start w-full gap-2 md:gap-3 lg:gap-12'>
        <Input
          label='Engineer Fee'
          type='number'
          disabled={!editable}
          value={engineerFee?.fee ?? ''}
          onChange={(e) => {
            const fieldName = 'fee';
            const value = Number(e.target.value);
            onInputChange({ fieldName, value });
          }}
          width='w-1/2'
          units='LKR/kW'
        />
      </div>
      {editable && (
        <ButtonOrange type='submit' customStyles='mb-4'>
          SAVE
        </ButtonOrange>
      )}
    </form>
  );
};

export default EngineerFeeForm;
