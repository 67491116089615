import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/leco-app-v2/1.png';
import img2 from '../../images/documents/leco-app-v2/2.png';
import installerDetails from '../../config/installerDetails';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 12,
  },
  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  scheme_1: {
    position: 'absolute',
    top: 170,
    left: 215,
  },

  scheme_2: {
    position: 'absolute',
    top: 170,
    left: 345,
  },

  scheme_3: {
    position: 'absolute',
    top: 170,
    left: 470,
  },

  consumer_acc_num: {
    position: 'absolute',
    top: '230px',
    left: '223px',
    fontSize: 18,
    letterSpacing: 15,
  },

  consumer_name: {
    position: 'absolute',
    top: '255px',
    left: '223px',
    textTransform: 'capitalize',
  },

  consumer_NID: {
    position: 'absolute',
    top: '277px',
    left: '223px',
  },

  consumer_address: {
    position: 'absolute',
    top: '300px',
    left: '223px',
    textTransform: 'capitalize',
  },
  single_phase: {
    position: 'absolute',
    top: '340px',
    left: '300px',
  },

  three_phase: {
    position: 'absolute',
    top: '340px',
    left: '408px',
  },

  current: {
    position: 'absolute',
    top: '340px',
    left: '498px',
  },

  installer_phone_1: {
    position: 'absolute',
    top: '383px',
    left: '210px',
    letterSpacing: '15.8px',
  },

  installer_phone_2: {
    position: 'absolute',
    top: '402px',
    left: '210px',
    letterSpacing: '15.8px',
  },

  installer_email: {
    position: 'absolute',
    top: '390px',
    left: '480px',
  },

  bank_name: {
    position: 'absolute',
    top: '448px',
    left: '128px',
  },

  bank_acc_num: {
    position: 'absolute',
    top: '448px',
    left: '310px',
  },

  bank_branch: {
    position: 'absolute',
    top: '448px',
    left: '470px',
  },

  total_panel_capacity: {
    position: 'absolute',
    top: '510px',
    left: '238px',
  },

  inverter_capacity: {
    position: 'absolute',
    top: '510px',
    left: '508px',
  },

  signing_person: {
    position: 'absolute',
    top: '685px',
    left: '265px',
    textTransform: 'capitalize',
  },

  // page 2
  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  installer_name: {
    position: 'absolute',
    top: '135px',
    left: '145px',
    textTransform: 'capitalize',
  },

  installer_address: {
    position: 'absolute',
    top: '175px',
    left: '145px',
    width: '400px',
    wordWrap: true,
    textTransform: 'capitalize',
  },

  contact_person: {
    position: 'absolute',
    top: '250px',
    left: '145px',
    textTransform: 'capitalize',
  },

  phone_fixed: {
    position: 'absolute',
    top: '280px',
    left: '190px',
  },

  phone_mobile: {
    position: 'absolute',
    top: '310px',
    left: '190px',
  },

  fax: {
    position: 'absolute',
    top: '340px',
    left: '145px',
  },

  email: {
    position: 'absolute',
    top: '365px',
    left: '145px',
  },

  installer_name_last: {
    position: 'absolute',
    top: 420,
    left: 40,
    width: 80,
    wordBreak: 'break-all',
    textTransform: 'capitalize',
  },

  checkmark: {
    position: 'absolute',
    width: 8,
    height: 15,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 0,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    transform: 'rotate(45deg)',
  },
});

const Checkmark = () => (
  <View>
    <div style={styles.checkmark}></div>
  </View>
);

const LECOAppV2 = ({ project }: any) => {
  const phase = project?.phase.split(' ', 2).join('');

  const phaseCurrent = project?.phase.split(' ')[2];

  const inverterCapacity = (
    project?.project_inverters.length *
    (project?.project_inverters[0].inverter.rated_power_output / 1000)
  ).toFixed(1);

  const panelCapacity = (
    project?.project_panels.length *
    (project?.project_panels[0].panel.peak_power / 1000)
  ).toFixed(1);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.image} />

          {project?.solar_scheme === 'Net Metering' ? (
            <View style={styles.scheme_1}>
              <Checkmark />
            </View>
          ) : project?.solar_scheme === 'Net Accounting' ? (
            <View style={styles.scheme_2}>
              <Checkmark />
            </View>
          ) : project?.solar_scheme === 'Net Accounting' ? (
            <View style={styles.scheme_3}>
              <Checkmark />
            </View>
          ) : (
            ''
          )}

          <Text style={styles.consumer_acc_num}>{project?.account_no}</Text>
          <Text style={styles.consumer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.consumer_NID}>{project?.customer.nic}</Text>
          <Text style={styles.consumer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}.
          </Text>
          <Text style={styles.single_phase}>
            {phase === 'SinglePhase' ? 1 : '-'}
          </Text>
          <Text style={styles.three_phase}>
            {phase === 'ThreePhase' ? 3 : '-'}
          </Text>
          <Text style={styles.current}>{phaseCurrent}</Text>

          <Text style={styles.bank_name}></Text>
          <Text style={styles.bank_acc_num}></Text>
          <Text style={styles.bank_branch}></Text>

          <Text style={styles.total_panel_capacity}>{panelCapacity}</Text>
          <Text style={styles.inverter_capacity}>{inverterCapacity}</Text>

          <Text style={styles.installer_phone_1}>
            {installerDetails.phone_1}
          </Text>
          <Text style={styles.installer_phone_2}>
            {installerDetails.phone_2}
          </Text>
          <Text style={styles.installer_email}>{installerDetails.email}</Text>

          <Text style={styles.signing_person}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
        </View>
      </Page>

      {/* Page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />

          <Text style={styles.installer_name}>{installerDetails.name}</Text>
          <Text style={styles.installer_address}>
            {installerDetails.address}
          </Text>
          <Text style={styles.contact_person}>
            {installerDetails.contact_person}
          </Text>
          <Text style={styles.phone_fixed}>{installerDetails.phone_1}</Text>
          <Text style={styles.phone_mobile}>{installerDetails.phone_2}</Text>
          <Text style={styles.fax}>N/A</Text>
          <Text style={styles.email}>{installerDetails.email}</Text>

          <Text style={styles.installer_name_last}>
            {installerDetails.name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default LECOAppV2;
