import { FaBolt, FaBuilding, FaDollarSign, FaHome, FaProjectDiagram, FaSolarPanel, FaTruck, FaUser, FaUserFriends, FaWrench } from 'react-icons/fa';
import { GiPowerGenerator } from 'react-icons/gi';
import { PiCircuitryFill } from 'react-icons/pi';
import { LuUtilityPole } from 'react-icons/lu';
import { TbBracketsContain } from 'react-icons/tb';
import { LuCable } from 'react-icons/lu';
import { SiOpensourcehardware } from 'react-icons/si';
import { MdEngineering } from 'react-icons/md';
import { MdOutlineAnalytics } from 'react-icons/md';
import { FaFunnelDollar } from 'react-icons/fa';
import { FaBarsProgress } from 'react-icons/fa6';

export const links = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <FaHome />,
    sub: [
      {
        name: 'Analytics',
        path: '/dashboard/analytics',
        icon: <MdOutlineAnalytics />,
      },
      {
        name: 'Sales Pipeline',
        path: '/dashboard/sales-pipeline',
        icon: <FaFunnelDollar />,
      },
      {
        name: 'Project Status',
        path: '/dashboard/project-status',
        icon: <FaBarsProgress />,
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    icon: <FaUserFriends />,
  },
  {
    name: 'Projects',
    path: '/projects',
    icon: <FaProjectDiagram />,
  },
  {
    name: 'Customers',
    path: '/customers',
    icon: <FaUser />,
  },
  {
    name: 'Inverters',
    path: '/inverters',
    icon: <GiPowerGenerator />,
  },
  {
    name: 'PV Modules',
    path: '/panels',
    icon: <FaSolarPanel />,
  },
  {
    name: 'Circuit Specifications',
    path: '/products/circuit-specifications',
    icon: <PiCircuitryFill />,
  },
  {
    name: 'Utilities',
    path: '/utilities',
    icon: <LuUtilityPole />,
  },
  {
    name: 'Tariff Types',
    path: '/tariff-types',
    icon: <FaBuilding />,
  },
  {
    name: 'Financials',
    path: '/financials',
    icon: <FaDollarSign />,
    sub: [
      {
        name: 'Hardware Acc',
        path: '/financials/hardware-accessories',
        icon: <SiOpensourcehardware />,
      },
      {
        name: 'Electrical Acc',
        path: '/financials/electrical-accessories',
        icon: <FaBolt />,
      },
      {
        name: 'Cables',
        path: '/financials/cables',
        icon: <LuCable />,
      },
      {
        name: 'Railings & Acc',
        path: '/financials/railings',
        icon: <TbBracketsContain />,
      },
      {
        name: 'Installation',
        path: '/financials/installation-cost',
        icon: <FaWrench />,
      },
      {
        name: 'Transport',
        path: '/financials/transport-cost',
        icon: <FaTruck />,
      },
      {
        name: 'Engineer Fee',
        path: '/financials/engineer-fee',
        icon: <MdEngineering />,
      },
      {
        name: 'Service',
        path: '/financials/service-cost',
        icon: <FaWrench />,
      },
    ],
  },
];
