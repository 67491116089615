import googleMapsApi from '../../config/googleMapsApi';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';

import OpenSans from '../../fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../../fonts/OpenSans-Semibold.ttf';
import OpenSansBold from '../../fonts/OpenSans-Bold.ttf';
import OpenSansSemiBoldItalic from '../../fonts/OpenSans-SemiboldItalic.ttf';

import headBanner from '../../images/documents/solar-proposal/head-banner.png';

import img1 from '../../images/documents/solar-proposal/1.png';

// Page 2
import page2Header from '../../images/documents/solar-proposal/page2-header.png';
import page2Bottom from '../../images/documents/solar-proposal/page2-bottom.png';

// Page 3
import streetView from '../../images/documents/solar-proposal/location-street-view.png';
import roofDimensions from '../../images/documents/solar-proposal/estimated-roof-dimensions.png';

// Page 4
import page4 from '../../images/documents/solar-proposal/page4.png';

// Page 5

import page5Bottom from '../../images/documents/solar-proposal/page5-bottom.png';

// Page 6
import page6 from '../../images/documents/solar-proposal/page6.png';

// Page 7
import page7 from '../../images/documents/solar-proposal/page7.png';

// Page 8
import page8 from '../../images/documents/solar-proposal/page8.png';

// Page 9
import page9 from '../../images/documents/solar-proposal/page9.png';

// Page 10
import page10 from '../../images/documents/solar-proposal/page10.png';

// Page 11
import page11 from '../../images/documents/solar-proposal/page11.png';
import projectRefNumGen from '../Projects/projectRefNumGen';
import installerDetails from '../../config/installerDetails';
import { DiscountType } from '../../utils/enums';

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSans,
    },
    {
      src: OpenSansSemiBold,
    },
    {
      src: OpenSansBold,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 11,
  },

  page_view: {
    marginLeft: 90,
    marginRight: 50,
    fontFamily: 'OpenSans',
  },

  checkmark: {
    position: 'absolute',
    top: 2,
    right: 10,
    width: 4,
    height: 10,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    transform: 'rotate(45deg)',
  },

  view_1: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
    fontSize: 9,
    color: 'black',
    zIndex: -1,
  },

  header: { width: '100%' },

  footer: {
    position: 'absolute',
    bottom: 25,
    left: 90,
    right: 70,
    objectFit: 'cover',
    zIndex: 0,
    borderTop: '0.7px solid #f88619',
    fontSize: 7.5,
    lineHeight: 1.2,
    color: '#000',
  },

  h1: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#f88619',
    marginTop: 2,
    marginBottom: 10,
  },

  h2: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#f88619',
    marginBottom: 5,
  },

  h3: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#f88619',
    marginBottom: 5,
  },

  p: {
    fontSize: 11,
    marginTop: 5,
    marginBottom: 7,
  },

  bold: {
    fontSize: 11,
    fontWeight: 'bold',
  },

  page_image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  customer_name: {
    position: 'absolute',
    bottom: '105px',
    left: '115px',
  },

  customer_phone: {
    position: 'absolute',
    bottom: '93px',
    left: '115px',
  },

  customer_email: {
    position: 'absolute',
    bottom: '81px',
    left: '115px',
  },

  contractor_name: {
    position: 'absolute',
    bottom: '105px',
    left: '385px',
  },

  contractor_address: {
    position: 'absolute',
    bottom: '93px',
    left: '395px',
  },

  contractor_contact: {
    position: 'absolute',
    bottom: '81px',
    left: '425px',
  },

  // page 2
  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
    fontSize: 11,
  },

  page2_letter: {
    width: '85%',
    marginHorizontal: 'auto',
    fontSize: 11,
  },

  page2_bottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  //  page 3

  project_location_details: {
    width: '85%',
  },

  // Page 4
  page_4_p: {
    position: 'absolute',
    top: 86,
    left: 92,
    width: '74.9%',
    fontSize: 11.7,
    fontWeight: 'demibold',
    textAlign: 'justify',
  },

  // Page 5
  page5_bottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  table2: {
    marginTop: 5,
    marginBottom: 5,
    width: '95%',
    border: '1px solid #f88619',
    borderBottom: 'none',
  },

  table2_col1: {
    width: '50%',
    borderRight: '1px solid #f88619',
    padding: 2,
    paddingLeft: 3,
  },

  table2_col2: {
    width: '50%',
    borderRight: '1px solid #f88619',
    padding: 2,
    paddingLeft: 3,
  },

  table2_Row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f88619',
  },

  table3: {
    marginTop: 5,
    marginBottom: 5,
    width: '95%',
    border: '1px solid #f88619',
    borderBottom: 'none',
  },

  table3_col1: {
    width: '50%',
    borderRight: '1px solid #f88619',
    padding: 2,
    paddingLeft: 3,
  },

  table3_col2: {
    width: '50%',
    borderRight: '1px solid #f88619',
    padding: 2,
    paddingLeft: 3,
  },

  table3_Row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f88619',
  },

  table4: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
    width: '95%',
  },

  table4_col1: {
    width: '33%',
    borderRight: '3px solid #f88619',
    padding: 5,
  },

  table4_col2: {
    width: '33%',
    padding: 5,
    borderRight: '3px solid #f88619',
  },

  table4_col3: {
    width: '33%',
    padding: 5,
  },

  // Page 11

  project_cost: {
    position: 'absolute',
    top: 221,
    left: 330,
    zIndex: 0,
    fontSize: 11,
  },

  special_discount: {
    position: 'absolute',
    top: 238,
    left: 330,
    fontSize: 11,
  },

  total_cost: {
    position: 'absolute',
    top: 254,
    left: 330,
    fontSize: 11,
  },
  // Page 13

  page13: {
    marginLeft: 40,
    marginRight: 20,
    marginTop: 25,
    marginBottom: 45,
    height: '90%',
    border: '1px solid black',
    boxSizing: 'border-box',
    fontFamily: 'OpenSans',
  },

  page13_letter: {
    width: '85%',
    marginHorizontal: 'auto',
    fontSize: 11,
  },

  page13_receiver: {
    marginTop: 25,
    marginBottom: 5,
    marginLeft: '60%',
  },
});

const proposalData = {
  ref_num: '20231030COL01',
  proposal_date: '30 October 2023',

  contractor: {
    name: 'Sunterra (Pvt) Ltd',
    address: 'No 188, Buthgamuwa Raod, Rajagiriya.',
    contact: '070 500 8888 / 077 500 8888',
    project_manager: 'Clive Shadrach',
  },
};

const SolarProposal = ({ project }: any) => {
  const inverterCapacity = (project?.project_inverters.length * (project?.project_inverters[0].inverter.rated_power_output / 1000)).toFixed(1);

  const apiKey: any = process.env.REACT_APP_GOOGLE_MAPS_API;
  const latitude = project?.coordinates.lat;
  const longitude = project?.coordinates.lon;

  const fetchStaticMap = async () => {
    const params = new URLSearchParams({
      center: `${latitude},${longitude}`,
      zoom: '20',
      size: '300x180',
      key: apiKey,
    });

    try {
      const response = await googleMapsApi.get(`/staticmap?${params}&maptype=satellite`);
      return response.request.responseURL;
    } catch (error) {
      console.error('Error fetching static map:', error);
      return null;
    }
  };

  const fetchStreetView = async () => {
    const params = new URLSearchParams({
      location: `${latitude},${longitude}`,
      size: '300x180',
      key: apiKey,
    });

    try {
      const response = await googleMapsApi.get(`/streetview?${params}`);
      return response.request.responseURL;
    } catch (error) {
      console.error('Error fetching street view:', error);
      return null;
    }
  };

  const ref_num = projectRefNumGen({ project });

  const PageFooter = ({ pageNumber }: any) => (
    <View style={styles.footer}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View>
          <Text>
            Solar Project Proposal for {project?.customer.first_name} {project?.customer.last_name}.
          </Text>
          <Text>
            Installation located at {project?.customer.house_no}, {project?.customer.street}, {project?.customer.city}.
          </Text>
          <Text>Project Reference No: {ref_num}</Text>
        </View>
        <Text>- Page {pageNumber} -</Text>
      </View>
    </View>
  );

  const proposalDate = new Date(project?.created_at)
    .toLocaleString('en-GB', {
      timeZone: 'UTC',
    })
    .split(',')[0];

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={img1} style={styles.page_image} />

          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.customer_phone}>{project?.customer.phone}</Text>
          <Text style={styles.customer_email}>{project?.customer.email}</Text>

          <Text style={styles.contractor_name}>{installerDetails.name}</Text>
          <Text style={styles.contractor_address}>{installerDetails.address}</Text>
          <Text style={styles.contractor_contact}>
            {installerDetails.phone_1}/ {installerDetails.phone_2}
          </Text>
        </View>
      </Page>

      {/* Page 2 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Image src={page2Header} />
        </View>

        <View style={styles.page_view}>
          <Text style={{ marginTop: 30, fontWeight: 'bold' }}>{proposalDate}</Text>
          <View style={{ marginTop: 20 }}>
            <Text>
              {project?.customer.first_name} {project?.customer.last_name},
            </Text>
            <Text>{project?.customer.house_no},</Text>
            <Text>{project?.customer.street},</Text>
            <Text>{project?.customer.city}</Text>
          </View>
          <Text style={{ marginTop: 30, marginBottom: 10, fontWeight: 'bold' }}>Subject: Solar System Installation Proposal</Text>
          <Text style={styles.p}>
            {project?.customer.first_name} {project?.customer.last_name}.
          </Text>
          <Text style={styles.p}>
            Thank you for choosing us, Sunterra (Pvt) Ltd, to provide a solar installation proposal for your {project?.project_type.name} solar energy
            system. We, as a government authorized installer, have helped homeowners and businesses harness solar energy to power their needs and also
            make money while doing so.
          </Text>
          <Text style={styles.p}>
            As the cost per unit of energy from power grids continues to rise each year, many consumers are seeking long-term alternatives to reduce
            their energy bills. Solar energy is a great alternative to drawing power from the electric grid and saves consumers substantially while
            also protecting and helping preserve the environment.
          </Text>
          <Text style={styles.p}>
            In this proposal, we have included details regarding the expected impact that a solar system will have on your grid energy use and overall
            energy cost savings. You’ll also find information showing when you’re projected to “break even” on the system given your current and/or
            projected energy use, and what your savings beyond that point will be.
          </Text>
          <Text style={styles.p}>
            Should you have any questions, comments, or concerns regarding this proposed solar system, feel free to contact any of our representatives
            or the undersigned.
          </Text>
          <Text style={styles.p}>Best Regards,</Text>

          <View style={{ marginTop: 40 }}>
            <Text>……………………………</Text>
            <Text>{installerDetails.contact_person}</Text>
            <Text>Project Manager</Text>
          </View>
        </View>

        <View style={styles.page2_bottom}>
          <Image src={page2Bottom} />
        </View>
      </Page>

      {/* Page 3 */}
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Image src={headBanner} />
        </View>

        <View style={styles.page_view}>
          <View>
            <Text style={styles.h1}>1. Project Background</Text>

            <Text style={styles.h2}>1.1. Project Description</Text>

            <Text style={styles.p}>
              The services of Sunterra (Pvt) Ltd are being considered for the design, installation and commissioning of a solar system at premises
              owned by {project?.customer.first_name} {project?.customer.last_name}.
            </Text>

            <Text style={styles.h2}>1.2. Project Location Details</Text>

            <Text style={styles.bold}>Location on Street View</Text>

            {/* <Image style={styles.project_location_details} src={streetView} /> */}
            {/* <Image
              src={`https://maps.googleapis.com/maps/api/staticmap?center=20,-10&zoom=1&maptype=satellite&markers=color:red%7C35.405625,-97.517396%7C6.898605516923754,79.92403073340883&key=AIzaSyBNh8UYkd59-EKvWvCa9PLp_zEyMH9U9d0`}
              style={styles.project_location_details}
            /> */}

            <Image src={fetchStreetView()} style={styles.project_location_details} />

            <Text style={[styles.bold, { marginTop: 10 }]}>Estimated Roof Dimensions</Text>

            <Image src={fetchStaticMap()} style={styles.project_location_details} />
            {/* <Image
              style={styles.project_location_details}
              src={roofDimensions}
            /> */}
          </View>
        </View>
        <PageFooter pageNumber='3' />
      </Page>

      {/* Page 4 */}
      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page4} style={styles.page_image} />
          <Text style={styles.page_4_p}>
            Our company will provide the engineering services to design, produce drawings, and specifications that meet or exceed the requirements of
            {project?.customer.first_name} {project?.customer.last_name}. The company is responsible for all work of their sub
            consultants/subcontractors to meet the requirements of the Solar System Installation proposal.
          </Text>
          <PageFooter pageNumber='4' />
        </View>
      </Page>

      {/* Page 5 */}
      <Page size='A4' style={[styles.page, { position: 'relative' }]}>
        <View style={styles.header} fixed>
          <Image src={headBanner} />
        </View>
        <View style={styles.page_view}>
          <View>
            <Text style={[styles.p, { fontSize: 10 }]}>
              [1] – The Initial Clearance is obtained from {project?.utility.name} based on numerous variables related to your{' '}
              {project?.project_type?.name} connection. {project?.utility.name} could advice for the meter to be moved or ask for other changes to be
              made. Such work can be completed by Client or could request for Sunterra (Pvt) Ltd to carryout. Extra work in such cases will bear an
              additional cost for which the company will notify in writing.
            </Text>

            <Text style={[styles.p, { fontSize: 10 }]}>
              [2] – {project?.customer.title} {project?.customer.first_name} will be required to visit the {project?.utility.name},{' '}
              {project?.utility_branch} branch to sign the solar agreement once the system is installed and the agreement is made ready.
            </Text>
          </View>

          <View style={{ marginTop: 5 }}>
            <Text style={styles.h1}>3. Project Assumptions</Text>

            <Text style={styles.p}>
              We have taken into consideration the following assumptions in proposing this {project?.project_type.name} solar system.
            </Text>

            <View style={styles.table2}>
              <View style={styles.table2_Row} wrap={false}>
                <Text style={[styles.table2_col1]}>Electricity Account Number</Text>
                <Text style={[styles.table2_col2]}>{project?.account_no}</Text>
              </View>
              <View style={styles.table2_Row} wrap={false}>
                <Text style={[styles.table2_col1]}>{project?.utility.name} Meter Supply Rating</Text>
                <Text style={[styles.table2_col2]}>{project?.phase}</Text>
              </View>
              <View style={styles.table2_Row} wrap={false}>
                <Text style={[styles.table2_col1]}>Average Monthly Unit Consumption</Text>
                <Text style={[styles.table2_col2]}>{project?.monthly_units} Units</Text>
              </View>
              <View style={styles.table2_Row} wrap={false}>
                <Text style={[styles.table2_col1]}>Average Monthly Electricity Bill</Text>
                <Text style={[styles.table2_col2]}>Rs. {project?.monthly_value.toLocaleString()}/-</Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 5 }}>
            <Text style={styles.h1}>4. Proposed System Details</Text>

            <Text style={styles.p}>
              The table below covers the details of the specific Solar System layout that we’re recommending for your premises.
            </Text>

            <View style={styles.table3}>
              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>System Size</Text>

                <Text style={[styles.table3_col2]}>{inverterCapacity} kW</Text>
              </View>

              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>Monthly Energy Production (est.)</Text>

                <Text style={[styles.table3_col2]}>{project?.project_production?.est_monthly_production} Units</Text>
              </View>

              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>Solar Panel Brand</Text>

                <Text style={[styles.table3_col2]}>{project?.project_panels[0]?.panel.brand}</Text>
              </View>

              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>Panel Model – Panel Nos.</Text>
                <Text style={[styles.table3_col2]}>
                  {project?.project_panels[0]?.panel.model} - {project?.project_panels.length}Nos
                </Text>
              </View>

              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>Inverter Brand</Text>

                <Text style={[styles.table3_col2]}>{project?.project_inverters[0].inverter?.brand}</Text>
              </View>

              <View style={styles.table3_Row} wrap={false}>
                <Text style={[styles.table3_col1]}>Inverter Model – Inverter Nos.</Text>
                <Text style={[styles.table3_col2]}>
                  <Text style={[styles.table3_col2]}>{project?.project_inverters[0].inverter?.model}</Text>- {project?.project_inverters.length}Nos
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.page5_bottom}>
          <Image src={page5Bottom} />
        </View>
        <PageFooter pageNumber='5' />
      </Page>

      {/* Page 6 */}
      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page6} style={styles.page_image} />
          <PageFooter pageNumber='6' />
        </View>
      </Page>

      {/* Page 7 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page7} style={styles.page_image} />
          <PageFooter pageNumber='7' />
        </View>
      </Page>

      {/* Page 8 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page8} style={styles.page_image} />
          <PageFooter pageNumber='8' />
        </View>
      </Page>

      {/* Page 9 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page9} style={styles.page_image} />
          <PageFooter pageNumber='9' />
        </View>
      </Page>

      {/* Page 10 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page10} style={styles.page_image} />
          <PageFooter pageNumber='10' />
        </View>
      </Page>

      {/* Page 11 */}

      <Page size='A4' style={styles.page}>
        <View style={styles.view_1}>
          <Image src={page11} style={styles.page_image} />

          <Text style={styles.project_cost}>Rs. {(project?.project_finance?.total * 1.15).toLocaleString()}/-</Text>
          {project?.project_finance?.discount_type === DiscountType.AMOUNT ? (
            <Text style={styles.special_discount}>Rs. {project?.project_finance?.discount.toLocaleString()}/-</Text>
          ) : (
            <Text style={styles.special_discount}>{project?.project_finance?.discount.toLocaleString()}%</Text>
          )}
          <Text style={styles.total_cost}>Rs. {project?.project_finance?.price.toLocaleString()}/-</Text>
          <PageFooter pageNumber='11' />
        </View>
      </Page>

      {/* Page 12 */}
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Image src={headBanner} />
        </View>
        <View style={styles.page_view}>
          <View>
            <Text style={styles.p}>
              Upon completion of the 3 -year period, the Customer may wish to appoint any authorized service agents or may request Sunterra to service
              and maintain the system at rates applicable at that time.
            </Text>
            <Text style={styles.h1}>8. Financial Benefits</Text>
            <Text style={[styles.p, { marginTop: '-10px' }]}>
              The table below details the expected financial benefits in terms of savings and earnings through your Solar System on tthe three solar
              schemes offered by {project?.utility.name}.
            </Text>

            <View>
              <Text style={{ textDecoration: 'underline', marginTop: 5 }}>Net Metering:</Text>
              <View style={{ marginLeft: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>Monthly Savings on Net Metering</Text>
                  <Text style={{ width: '50%' }}>: Rs. {project?.project_production?.saving_net_metering.toLocaleString()}/-</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>Estimated Excess Production</Text>
                  <Text style={{ width: '50%' }}>: {project?.project_production?.excess_units} Units</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>ROI on Net Metering</Text>
                  <Text style={{ width: '50%' }}>: {project?.project_production?.roi_net_metering}%</Text>
                </View>
              </View>
            </View>

            <View>
              <Text style={{ textDecoration: 'underline', marginTop: 10 }}>Net Accounting:</Text>
              <View style={{ marginLeft: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>Monthly Savings on Net Accounting</Text>
                  <Text style={{ width: '50%' }}>: Rs. {project?.project_production?.saving_net_accounting.toLocaleString()}/-</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>Monthly Earnings on Excess Production</Text>
                  <Text style={{ width: '50%' }}>: Rs.{project?.project_production?.saving_net_accounting.toLocaleString()}/-</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>ROI on Net Accounting</Text>
                  <Text style={{ width: '50%' }}>: {project?.project_production?.roi_net_accounting}%</Text>
                </View>
              </View>
            </View>

            <View>
              <Text style={{ textDecoration: 'underline', marginTop: 10 }}>Net Plus:</Text>
              <View style={{ marginLeft: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>Monthly Earnings on Net Plus</Text>
                  <Text style={{ width: '50%' }}>: {project?.project_production?.earning_net_plus.toLocaleString()}/-</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '50%' }}>ROI on Net Plus</Text>
                  <Text style={{ width: '50%' }}>: {project?.project_production?.roi_net_plus}%</Text>
                </View>
              </View>
            </View>

            <Text style={styles.p}>
              Considering your current consumption of {project?.monthly_units} units and above-mentioned financial savings and/or earnings, we propose
              to select the {project?.solar_scheme} scheme to achieve the following financial benefits:
            </Text>

            <View style={{ marginHorizontal: 30, fontWeight: 'bold' }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ color: '#f88619', width: '50%' }}>“Break-Even” time in years</Text>
                <Text style={{ width: '50%' }}>: {(project?.project_production?.break_even_months / 12).toFixed(2)} Years</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ color: '#f88619', width: '50%' }}>“Break-Even” time in months</Text>
                <Text style={{ width: '50%' }}>: {project?.project_production?.break_even_months} Months</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ color: '#f88619', width: '50%' }}>Return on Investment (ROI)</Text>
                <Text style={{ width: '50%' }}>
                  :{' '}
                  {project?.solar_scheme === 'Net Metering'
                    ? project?.project_production?.roi_net_metering.toFixed(2)
                    : project?.solar_scheme === 'Net Accounting'
                    ? project?.project_production?.roi_net_accounting.toFixed(2)
                    : project?.project_production?.roi_net_plus.toFixed(2)}
                  %
                </Text>
              </View>
            </View>

            <View style={{}}>
              <Text
                style={{
                  color: 'grey',
                  marginVertical: 10,
                  marginHorizontal: 'auto',
                }}
              >
                …………………………………
              </Text>
              <Text
                style={{
                  color: 'grey',
                  marginVertical: 10,
                  marginHorizontal: 'auto',
                }}
              >
                “The Earth is what we all have in common.”
              </Text>

              <Text
                style={{
                  color: 'grey',
                  marginVertical: 10,
                  fontSize: 8,
                  marginHorizontal: 'auto',
                }}
              >
                - Wendell Berr
              </Text>
            </View>
          </View>
        </View>
        <PageFooter pageNumber='12' />
      </Page>

      {/* Page 13 */}
      <Page size='A4' style={[styles.page, { position: 'relative' }]}>
        <View style={styles.page13}>
          <View
            style={{
              fontSize: 9,
              textAlign: 'center',
              width: '85%',
              marginHorizontal: 'auto',
              marginTop: 10,
            }}
          >
            <Text style={{ fontWeight: 'semibold' }}>PROJECT CONFIRMATION INSTRUCTIONS</Text>
            <Text style={{}}>
              To confirm the Solar proposal submitted by SunTerra and lock in the special price offered, kindly fill out the fields in block capital
              letters, scan/take an image from your camera phone and Email or WhatsApp to one of our representatives of SunTerra.
            </Text>
            <Text>……………………………………………………………………………………………………………</Text>
          </View>

          <View style={styles.page13_letter}>
            <View style={styles.page13_receiver}>
              <Text>Date: ………/………/20………</Text>
              <Text>
                {project?.customer.first_name} {project?.customer.last_name},
              </Text>
              <Text>{project?.customer.house_no}</Text>
              <Text>{project?.customer.street}</Text>
              <Text>{project?.customer.city}</Text>
            </View>

            <View>
              <Text>Sunterra Private Limited</Text>
              <Text>No. 188, Buthgamuwa Road,</Text>
              <Text>Rajagiriya.</Text>
              <Text>Colombo 8.</Text>
            </View>

            <Text style={{ fontWeight: 'semibold', marginVertical: 20 }}>Subject: Project Confirmation</Text>

            <Text>
              I/We,………………………………………………………………………………………… (Full Name), hereby accept and confirm the Solar Proposal of Project Reference Number
              {ref_num} with the following terms and conditions mentioned below;
            </Text>

            <View style={{ marginVertical: 20 }}>
              <Text style={{ fontWeight: 'semibold', textDecoration: 'underline' }}>Solar System Summary:</Text>

              <View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>System size</Text>
                  <Text style={{ width: '60%' }}>: {inverterCapacity}KW</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>Solar Panel Brand</Text>
                  <Text style={{ width: '60%' }}>: {project?.project_panels[0]?.panel.brand}</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>Panel Model – Panel Nos.</Text>
                  <Text style={{ width: '60%' }}>
                    : {project?.project_panels[0]?.panel.model} – {project?.project_panels.length}Nos.
                  </Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>Inverter Brand</Text>
                  <Text style={{ width: '60%' }}>: {project?.project_inverters[0]?.inverter.brand}</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>Inverter Model – Inverter Nos.</Text>
                  <Text style={{ width: '60%' }}>
                    : {project?.project_inverters[0]?.inverter.brand} – {project?.project_inverters.length} Nos
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ width: '40%' }}>Project Duration</Text>
                  <Text style={{ width: '60%' }}>: 4 Weeks (confirmation to commissioning)</Text>
                </View>
              </View>
            </View>

            <View style={{ marginBottom: 20 }}>
              <Text style={{ fontWeight: 'semibold', textDecoration: 'underline' }}>Solar System Summary:</Text>

              <Text>System Cost - {project?.project_finance?.price.toLocaleString()}/-</Text>
              <Text>Payment Schedule –</Text>

              <View style={{ marginLeft: 20 }}>
                <Text>* Initial Payment: 80%</Text>
                <Text>* After Installation: 20%</Text>
              </View>
            </View>

            <Text>
              I/We, hereby also confirm to provide the following documents for the necessary administration and coordination work with the CEB:
            </Text>
            <View style={{ marginLeft: 20 }}>
              <Text>* NIC or Passport copy</Text>
              <Text>* Duly signed solar application form</Text>
              <Text>* Copy of electricity bill</Text>
              <Text>* For Net Accounting / Net Plus – Copy of passbook or bank statement.</Text>
            </View>

            <Text style={{ marginTop: 30 }}>………………………………………</Text>
            <Text style={{ fontWeight: 'semibold' }}>
              {project?.customer.first_name} {project?.customer.last_name}.
            </Text>
          </View>
        </View>
        <PageFooter pageNumber='13' />
      </Page>
    </Document>
  );
};

export default SolarProposal;
