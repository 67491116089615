import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState, Utility } from './types/UtilityTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getAllUtilities = createAsyncThunk(
  'utility/get',
  async (filter: any): Promise<any> => {
    const res = await axios.get(`${api}/utilities`);

    return res.data;
  }
);

export const createUtility = createAsyncThunk(
  'utility/create',
  async (data: Utility, thunk): Promise<any> => {
    try {
      const res = await axios.post(`${api}/utilities`, data);
      toast.success('Utility Added Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getOneUtility = createAsyncThunk(
  'utility/get-one',
  async (utilityId: Utility | undefined): Promise<any> => {
    const res = await axios.post(`${api}/utilities/${utilityId}`);

    return res;
  }
);

export const updateUtility = createAsyncThunk(
  'utility/update',
  async (data: Utility, thunk): Promise<any> => {
    try {
      const res = await axios.put(`${api}/utilities/${data.id}`, data);
      toast.success('Utility Updated Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteUtility = createAsyncThunk(
  'utility/delete',
  async (utilityId: Number | String | undefined, thunk): Promise<any> => {
    try {
      const res = await axios.put(`${api}/utilities/${utilityId}`, {
        status: 'Deleted',
      });
      toast.success('Utility Deleted Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
  create: {
    data: null,
    pending: false,
    error: false,
  },
  details: {
    data: null,
    pending: false,
    error: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
  delete: {
    data: null,
    error: false,
    pending: false,
  },
};

const UtilitySlice = createSlice({
  name: 'Utility',
  initialState: initialState,
  reducers: {},

  // GET ALL UTILITIES
  extraReducers: (builder) => {
    builder.addCase(getAllUtilities.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getAllUtilities.rejected, (state) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
    builder.addCase(getAllUtilities.pending, (state, action) => {
      state.list.pending = true;
      state.list.error = false;
    });

    // CREATE UTILITY
    builder.addCase(createUtility.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.pending = false;
      state.create.error = false;
    });
    builder.addCase(createUtility.rejected, (state) => {
      state.create.pending = false;
      state.create.error = true;
      state.create.data = null;
    });
    builder.addCase(createUtility.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
      state.create.data = null;
    });

    // GET ONE UTILITY
    builder.addCase(getOneUtility.pending, (state) => {
      state.details.pending = true;
      state.details.error = false;
      state.details.data = null;
    });
    builder.addCase(getOneUtility.fulfilled, (state, action) => {
      state.details.data = action.payload.data;
      state.details.pending = false;
      state.details.error = false;
    });
    builder.addCase(getOneUtility.rejected, (state) => {
      state.details.data = null;
      state.details.pending = false;
      state.details.error = true;
    });

    //Update Utility
    builder.addCase(updateUtility.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
    });
    builder.addCase(updateUtility.rejected, (state) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updateUtility.pending, (state) => {
      state.update.data = null;
      state.update.error = false;
      state.update.pending = true;
    });
  },
});

export default UtilitySlice.reducer;
