import React, { useState } from 'react';
import Column from './Column';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { ColumnType, TaskType } from '../../../store/types/KanbanTypes';
import { footerHeight, navbarHeight } from '../../../utils/constants';
import Breadcrumbs from '../../Breadcrumbs';

const COLUMN: ColumnType[] = [
  {
    id: 'NEW_ENQUIRIES',
    title: 'New Enquiries',
    bgColor: '#D4F6FF',
    fontColor: '',
  },
  {
    id: 'SITE_VISIT',
    title: 'Site Visit',
    bgColor: '#E1EACD',
    fontColor: '',
  },
  {
    id: 'COSTING',
    title: 'Costing',
    bgColor: '#E6BAA3',
    fontColor: '',
  },
  {
    id: 'PROPOSAL',
    title: 'Proposal',
    bgColor: '#CDC1FF',
    fontColor: '',
  },
  {
    id: 'ORDER_FORM',
    title: 'Order Form',
    bgColor: '#FFEDED',
    fontColor: '',
  },
];

const INITIAL_TASKS: TaskType[] = [
  { id: '1', customerName: 'John Doe', fileId: 'F12345', assignedTo: 'Thilini', status: 'NEW_ENQUIRIES' },
  { id: '2', customerName: 'Jane Smith', fileId: 'F54321', assignedTo: 'Sumith', status: 'SITE_VISIT' },
  { id: '3', customerName: 'Mark Johnson', fileId: 'F67890', assignedTo: 'Damith', status: 'COSTING' },
  { id: '4', customerName: 'Emily Davis', fileId: 'F09876', assignedTo: 'Thilini', status: 'PROPOSAL' },
  { id: '5', customerName: 'Jackson Liyanage', fileId: 'F67891', assignedTo: 'Damith', status: 'PROPOSAL' },
  { id: '6', customerName: 'Jane Almeda', fileId: 'F54325', assignedTo: 'Sumith', status: 'NEW_ENQUIRIES' },
];
const SalesPipeline = () => {
  const [tasks, setTasks] = useState(INITIAL_TASKS);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || !active.id || !over.id) return;

    const taskId = active.id as string;
    const newStatus = over.id as string;

    setTasks((prevTasks) => prevTasks.map((task) => (task.id === taskId ? { ...task, status: newStatus } : task)));
  };

  return (
    <div
      className='w-full flex flex-col items-center bg-[#F7F7F7]/70 overflow-y-scroll'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Sales Pipeline',
            },
          ]}
        />
      </div>
      <div className='flex justify-start w-full px-6 py-6 gap-5 overflow-x-auto'>
        <DndContext onDragEnd={handleDragEnd}>
          {COLUMN.map((column) => (
            <Column key={column.id} column={column} tasks={tasks.filter((task) => task.status === column.id)} />
          ))}
        </DndContext>
      </div>
    </div>
  );
};

export default SalesPipeline;
