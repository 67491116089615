import { Modal, Paper } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProjectStatus } from '../../../store/ProjectsSlice';
import { Input, Select } from '../../FormComponents';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Button } from '../../Buttons';
import { Project } from '../../../store/types/ProjectTypes';
import { taskList } from '../../../utils/enums';

import { FaArrowRight } from 'react-icons/fa';

const modalStyle = {
  display: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
};

const paperStyle = {
  position: 'absolute',
  width: 'auto',
  top: '50vh',
  left: '50vw',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
};

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  project: Project;
};

interface StatusUpdatePayload {
  user_id: string;
  due_date: string;
  project_id: string;
  task_id: string;
}

const ProjectStatusSchema = yup.object<Partial<Record<keyof StatusUpdatePayload, yup.AnySchema>>>({
  due_date: yup.string().required('Date is required.'),
  user_id: yup.string().required('Please select a user.'),
  project_id: yup.string().required('Invalid project ID'),
  task_id: yup.string().required('Invalid task ID'),
});

const StatusUpdate: React.FC<Props> = ({ showModal, setShowModal, project }) => {
  const dispatch: AppDispatch = useDispatch();
  const users = useSelector((state: RootState) => state.user.list.data);

  const methods = useForm<Partial<StatusUpdatePayload>>({
    resolver: yupResolver(ProjectStatusSchema),
    defaultValues: {
      due_date: '',
      user_id: '',
      project_id: project?.id,
      task_id: project?.project_task[0]?.id,
    },
  });

  const taskIndex = taskList.findIndex((t) => t === project?.status);

  const onSave: SubmitHandler<Partial<StatusUpdatePayload>> = (data) => {
    dispatch(updateProjectStatus(data));
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)} style={modalStyle}>
      <div>
        <Paper sx={paperStyle} className='relative'>
          <FormProvider {...methods}>
            <h3 className='font-semibold mb-5'>Update Project Status</h3>
            <form onSubmit={methods.handleSubmit(onSave)} className='w-96 flex flex-col items-center gap-2'>
              <div className='flex w-full items-center gap-3 font-medium text-lg'>
                <p>{taskList[taskIndex]}</p>
                <FaArrowRight />
                <p>{taskList[taskIndex + 1]}</p>
              </div>
              <Input name='due_date' label='Due Date' type='date' />
              <Select name='user_id' label='User' options={users.map((u) => ({ label: u.username, value: u?.id as string }))} />
              <Button colorType='success' type='submit' text='Save' />
            </form>
          </FormProvider>
        </Paper>
      </div>
    </Modal>
  );
};

export default StatusUpdate;
