import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState } from './types/TaskTypes';
const api = process.env.REACT_APP_API;

export const getTasksByUser = createAsyncThunk(
  'tasks/get/by-user',
  async () => {
    const response = await axios.get(`${api}/tasks/by-user`, {
      headers: { Authorization: localStorage.getItem('token') }
    });

    return response.data;
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    pending: false,
    error: false,
  },
};

const TaskSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTasksByUser.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.pending = false;
      state.list.error = false;
    });
    builder.addCase(getTasksByUser.pending, (state, action) => {
      state.list.data = [];
      state.list.pending = true;
      state.list.error = false;
    });
    builder.addCase(getTasksByUser.rejected, (state, action) => {
      state.list.data = [];
      state.list.pending = false;
      state.list.error = true;
    });
  },
});

export default TaskSlice.reducer;
