import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { Link, useNavigate } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { MdAddCircle, MdSearch } from 'react-icons/md';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../store/CustomerSlice';
import { Customer } from '../../store/types/CustomerTypes';
import Breadcrumbs from '../Breadcrumbs';
import Loader from '../Loader';
import LoadingSkeleton from '../LoadingSkeleton';

function Customers() {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortedData, setSortedData] = useState<Customer[]>([]);

  const customerListState = useSelector((state: RootState) => state.customer.list);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const fetchCustomerList = async () => {
    await dispatch(getAllCustomers({}));
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    // Sorting logic based on updated_at
    const sorted = [...customerListState.data].sort((a, b) => {
      const dateA = a.updated_at ? new Date(a.updated_at) : null;
      const dateB = b.updated_at ? new Date(b.updated_at) : null;

      if (dateA && dateB) {
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      // Handle the case where either date is undefined
      return 0;
    });

    // Filtering based on search term
    const filtered = sorted.filter(
      (customer) => customer.status === 'Active' && `${customer.first_name} ${customer.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSortedData(filtered);
  }, [customerListState.data, sortOrder, searchTerm]);

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] h-[100vh]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Customers',
            },
          ]}
        />

        <Link to='/customers/new-customer' className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Customer</p>
          <MdAddCircle className='text-2xl' />
        </Link>
      </div>

      <Paper
        sx={{
          marginX: 'auto',
          width: { xs: '100%', md: '95%' },
          height: '100%',
          overflow: 'auto',
          marginY: { xs: '10px', md: '40px' },
          paddingX: { xs: '10px', md: '40px' },
          paddingY: { xs: '10px', md: '30px' },
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            height: '80%',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#C7C8CC',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <div className='flex gap-2 mb-4 justify-between items-end'>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />

          {/* Show number of items */}
          <p className='text-xs text-gray-500'>
            Viewing {sortedData.length} item{sortedData.length === 1 ? '' : 's'}
          </p>
        </div>

        <TableContainer
          sx={{
            marginX: 'auto',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-center text-sm font-bold'>Name</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Email</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Phone</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Location</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((customer, index) => (
                <TableRow
                  key={index}
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  onClick={() => {
                    navigate(`/customers/view-customer/${customer.id}`);
                  }}
                >
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500 capitalize'>{`${customer.first_name} ${customer.last_name}`}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{customer.email}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{customer.phone}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500 capitalize'>{customer.city}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='my-1'>
          {customerListState.pending && <LoadingSkeleton numColumns={1} />}
          {!customerListState.error && !customerListState.pending && sortedData.length === 0 && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
          )}
          {!customerListState.pending && customerListState.error && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default Customers;
