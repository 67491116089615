import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/ProjectTypes';
import { toast } from 'react-toastify';
import { Refund, RefundDeleteInput } from './types/RefundTypes';

const api = process.env.REACT_APP_API;

export const createProject = createAsyncThunk('project/create', async (project: any, thunk) => {
  try {
    const res = await axios.post(`${api}/projects`, project);

    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(true);
  }
});

export const getProjectsList = createAsyncThunk('project/list', async (filter: any) => {
  const res = await axios.get(`${api}/projects`);

  return res.data;
});

export const getOngoingProjectsList = createAsyncThunk('project/ongoing/list', async () => {
  const res = await axios.get(`${api}/projects/ongoing`, {
    headers: { Authorization: localStorage.getItem('token') },
  });

  return res.data;
});

export const getProjectDetails = createAsyncThunk('project/details', async (payload: any) => {
  const res = await axios.get(`${api}/projects/${payload.id}`);

  return res.data;
});

export const addInverter = createAsyncThunk('project/add-inverter', async (payload: { inverterData: any; params: any }) => {
  try {
    const res = await axios.put(`${api}/projects/add-inverter/${payload.params}`, payload.inverterData);

    return res.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Unknown error');
  }
});

export const addPanel = createAsyncThunk('project/add-panel', async (payload: { panelData: any; params: any }) => {
  try {
    const res = await axios.put(`${api}/projects/add-panel/${payload.params}`, payload.panelData);
    return res.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Unknown error');
  }
});

export const removeInverter = createAsyncThunk('project/remove-inverter', async (payload: any) => {
  const res = await axios.put(`${api}/projects/remove-inverter/${payload.project_id}`, { inverter_id: payload.inverter_id });

  return res.data;
});

export const removePanel = createAsyncThunk('project/remove-panel', async (payload: any) => {
  const res = await axios.put(`${api}/projects/remove-panel/${payload.project_id}`, { panel_id: payload.panel_id });

  return res.data;
});

export const createServiceRequest = createAsyncThunk('project/service-request/create', async (payload: any) => {
  const res = await axios.post(`${api}/services`, payload);

  return res.data;
});

export const updateProjectStatus = createAsyncThunk('project/update-status', async (payload: any) => {
  const res = await axios.put(`${api}/projects/update-status/${payload.project_id}`, payload);

  return res.data;
});

export const updateDiscount = createAsyncThunk('project/update-discount', async (payload: any) => {
  try {
    const res = await axios.put(`${api}/projects/update-discount/${payload.project_id}`, {
      discount: payload.discount,
      discount_type: payload.discount_type,
    });

    return res.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || 'Unknown error');
  }
});

export const updateProjectHardwareAccessory = createAsyncThunk('project/hardware-accessory/update', async (payload: any) => {
  const { projectId, ...body } = payload;
  const res = await axios.put(`${api}/projects/hardware-accessories/${projectId}`, body);

  return res.data;
});

export const updateProjectElectricalAccessory = createAsyncThunk('project/electrical-accessory/update', async (payload: any) => {
  const { projectId, ...body } = payload;
  const res = await axios.put(`${api}/projects/electrical-accessories/${projectId}`, body);

  return res.data;
});

export const updateProjectCable = createAsyncThunk('project/cable/update', async (payload: any) => {
  const { projectId, ...body } = payload;
  const res = await axios.put(`${api}/projects/cables/${projectId}`, body);

  return res.data;
});

export const updateProjectRailing = createAsyncThunk('project/railing/update', async (payload: any) => {
  const { projectId, ...body } = payload;
  const res = await axios.put(`${api}/projects/railings/${projectId}`, body);

  return res.data;
});

export const createQuotation = createAsyncThunk('project/quotation/create', async (payload: any) => {
  const res = await axios.post(`${api}/quotations`, payload);

  return res.data;
});

export const createInvoice = createAsyncThunk('project/invoice/create', async (payload: any) => {
  const res = await axios.post(`${api}/invoices`, payload);

  return res.data;
});

export const createReceipt = createAsyncThunk('project/receipt/create', async (payload: any) => {
  const res = await axios.post(`${api}/receipts`, payload);

  return res.data;
});

export const createRefund = createAsyncThunk('refund/create', async (data: Refund, thunk): Promise<any> => {
  try {
    const res = await axios.post(`${api}/refunds`, data);

    toast.success('Return Item Added Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

export const deleteRefund = createAsyncThunk('refund/delete', async (payload: RefundDeleteInput, thunk): Promise<any> => {
  try {
    const { id, ...body } = payload;
    const res = await axios.put(`${api}/refunds/${payload.id}`, body);

    toast.success('Return Item Removed Successfully!');
    return res.data;
  } catch (err: any) {
    toast.error(err.response.data.message);
    return thunk.rejectWithValue(err.response.data);
  }
});

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  ongoingList: {
    data: [],
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
  details: {
    data: null,
    error: false,
    pending: false,
  },
};

const ProjectSlice = createSlice({
  name: 'Project',
  initialState: initialState,
  reducers: {
    updateInstallationImage: (state, action) => {
      state.details.data.installation_images = action.payload;
    },
    updateProjectImage: (state, action) => {
      state.details.data.project_images = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Create Project
    builder.addCase(createProject.pending, (state) => {
      state.create.pending = true;
      state.create.error = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.create.pending = false;
      state.create.error = false;
      state.create.data = action.payload.data;
    });
    builder.addCase(createProject.rejected, (state) => {
      state.create.error = true;
      state.create.pending = false;
    });

    // Get all projects
    builder.addCase(getProjectsList.pending, (state) => {
      state.list.pending = true;
      state.list.error = false;
    });
    builder.addCase(getProjectsList.fulfilled, (state, action) => {
      state.list.pending = false;
      state.list.error = false;
      state.list.data = action.payload.data;
    });
    builder.addCase(getProjectsList.rejected, (state) => {
      state.list.error = true;
      state.list.pending = false;
    });

    // Get ongoing projects
    builder.addCase(getOngoingProjectsList.rejected, (state) => {
      state.ongoingList.error = false;
      state.ongoingList.pending = false;
      state.ongoingList.data = [];
    });
    builder.addCase(getOngoingProjectsList.pending, (state) => {
      state.ongoingList.pending = true;
      state.ongoingList.error = false;
      state.ongoingList.data = [];
    });
    builder.addCase(getOngoingProjectsList.fulfilled, (state, action) => {
      state.ongoingList.pending = false;
      state.ongoingList.error = false;
      state.ongoingList.data = action.payload.data;
    });

    // Get project details
    builder.addCase(getProjectDetails.rejected, (state) => {
      state.details.error = false;
      state.details.pending = false;
    });
    builder.addCase(getProjectDetails.pending, (state) => {
      state.details.pending = true;
      state.details.error = false;
    });
    builder.addCase(getProjectDetails.fulfilled, (state, action) => {
      state.details.pending = false;
      state.details.error = false;
      state.details.data = action.payload.data;
    });

    builder.addCase(updateProjectStatus.fulfilled, (state, action) => {
      state.details.data.status = action.payload.data.new_task.task;
    });

    builder.addCase(updateDiscount.fulfilled, (state, action) => {
      state.details.data.project_finance = action.payload.data;
    });

    builder.addCase(createQuotation.fulfilled, (state, action) => {
      state.details.data.quotation = [...state.details.data.quotation, action.payload.data];
    });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.details.data.invoice = [...state.details.data.invoice, action.payload.data];
    });
    builder.addCase(createReceipt.fulfilled, (state, action) => {
      state.details.data.receipt = [...state.details.data.receipt, action.payload.data];
    });
  },
});

export const { updateInstallationImage, updateProjectImage } = ProjectSlice.actions;
export default ProjectSlice.reducer;
