import { ChangeEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select as MUISelect, MenuItem, FormControl } from '@mui/material';

interface InputProps {
  name: string;
  type: 'text' | 'number' | 'date';
  onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  unit?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name: props.name,
    control: control,
    defaultValue: props.type === 'number' ? undefined : '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = props.type === 'text' ? e.target.value?.trim() : e.target.value;
    field.onChange(value);
    props.onChange?.(e);
  };
  return (
    <div className='relative flex flex-col gap-2 w-full'>
      <label className='text-sm font-medium' htmlFor={props.name}>
        {props.label}
      </label>
      <input
        id={props.name}
        {...field}
        type={props.type}
        disabled={Boolean(props.disabled)}
        className={`px-3 py-2 border rounded w-full focus:border-none ${errors[props.name] ? 'border-red-500' : 'border-gray-200'}`}
        onChange={handleChange}
        placeholder={props.placeholder}
        readOnly={Boolean(props.readOnly)}
      />
      {errors[props.name] && <span className='text-red-500 text-sm'>{errors[props.name]?.message as string}</span>}
      <span className='font-semibold absolute top-1/2 right-3'>{props.unit}</span>
    </div>
  );
};

interface SelectProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => any;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  name: string;
  label: string;
  options: SelectOption[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => any;
  disabled?: boolean;
  placeholder?: string;
}

export const Select: React.FC<SelectProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    name: props.name,
    control: control,
    defaultValue: '',
  });

  const handleChange = (e: any) => {
    field.onChange(e);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <FormControl fullWidth variant='standard' error={Boolean(errors[props.name])}>
      <label className='text-sm font-medium' htmlFor={props.name}>
        {props.label}
      </label>
      <MUISelect
        id={props.name}
        {...field}
        onChange={handleChange}
        label={props.label}
        disabled={props.disabled}
        className={`border rounded -translate-y-2 p-1 ${errors[props.name] ? 'border-red-500' : 'border-gray-200'}`}
      >
        {props.placeholder && (
          <MenuItem value='' disabled>
            {props.placeholder}
          </MenuItem>
        )}
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      {errors[props.name] && <span className='text-red-500 text-sm'>{errors[props.name]?.message as string}</span>}
    </FormControl>
  );
};
