import React from 'react';

function LoadingSkeleton({ numColumns }: any) {
  const columns = Array.from({ length: numColumns }, (_, index) => index);

  return (
    <div role="status" className="w-full mx-auto animate-pulse">
      <div className="overflow-x-hidden bg-white">
        <div className="bg-gray-50 divide-gray-100">
          {Array.from({ length: 6 }, (_, rowIndex) => (
            <div key={rowIndex} className="p-1 flex items-center space-x-1">
              {columns.map((columnIndex) => (
                <div
                  key={columnIndex}
                  className="h-10 bg-gray-200 rounded flex-grow"
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LoadingSkeleton;
