import React from 'react';
import { CustomSelect, Input } from '../CustomInput';
import { User } from '../../store/types/UserTypes';
import { ButtonOrange } from '../Buttons';

type Props = {
  editable: boolean;
  user: User | null;
  onInputChange: any;
  onFormSubmit: any;
};

const UserForm: React.FC<Props> = ({ editable, user, onInputChange, onFormSubmit }) => {
  const userOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'User', value: 'user' },
  ];

  const getUserType = (value: string) => {
    const userType = userOptions.find((item) => item.value === value);
    return userType;
  };

  return (
    <form
      onSubmit={(e) => onFormSubmit(e)}
      className='w-full md:w-[95%] border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'
    >
      <div className='flex flex-col md:flex-row justify-between w-full gap-2 md:gap-3'>
        <Input
          label='User Name'
          type='text'
          disabled={!editable}
          value={user?.username ?? ''}
          onChange={(e) => {
            const fieldName = 'username';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />

        <Input
          label='Email'
          type='email'
          disabled={!editable}
          value={user?.email ?? ''}
          onChange={(e) => {
            const fieldName = 'email';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />

        <CustomSelect
          label='Type'
          disabled={!editable}
          options={userOptions}
          value={getUserType(user?.userType ?? '')}
          onChange={(value) => {
            const fieldName = 'type';
            onInputChange({ fieldName, value: value.value });
          }}
        />
      </div>
      <div className='flex flex-col md:flex-row  w-full gap-2 md:gap-3'>
        <Input
          label='Password'
          type='password'
          disabled={!editable}
          value={user?.password ?? ''}
          onChange={(e) => {
            const fieldName = 'password';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />

        <Input
          label='Re-enter Password'
          type='password'
          disabled={!editable}
          value={user?.password_confirm ?? ''}
          onChange={(e) => {
            const fieldName = 'password_confirm';
            const value = e.target.value;
            onInputChange({ fieldName, value });
          }}
        />
      </div>
      {editable && <ButtonOrange type='submit'>SAVE</ButtonOrange>}
    </form>
  );
};

export default UserForm;
