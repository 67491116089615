import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdSearch } from 'react-icons/md';

import { Link, useNavigate } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { MdAddCircle } from 'react-icons/md';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getInverterList } from '../../../store/InverterSlice';
import Breadcrumbs from '../../Breadcrumbs';

import { Inverter } from '../../../store/types/InverterTypes';
import Loader from '../../Loader';
import LoadingSkeleton from '../../LoadingSkeleton';

function Inverters() {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortedData, setSortedData] = useState<Inverter[]>([]);

  const inverterListState = useSelector((state: RootState) => state.inverter.list);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const fetchInverterList = async () => {
    await dispatch(getInverterList({}));
  };

  useEffect(() => {
    fetchInverterList();
  }, []);

  useEffect(() => {
    // Sorting logic based on capacity
    const sorted = [...inverterListState.data].sort((a, b) => {
      const capacityA = Number(a.rated_power_output) || 0;
      const capacityB = Number(b.rated_power_output) || 0;

      return sortOrder === 'asc' ? capacityB - capacityA : capacityA - capacityB;
    });

    // Filtering based on search term
    const filtered = sorted.filter((inv) =>
      `${inv.brand} ${inv.model} ${inv.rated_power_output} ${inv.phase} ${inv.status}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSortedData(filtered);
  }, [inverterListState.data, sortOrder, searchTerm]);

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] h-[100vh]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Inverters',
            },
          ]}
        />

        <button onClick={() => navigate('/inverters/add')} className='flex gap-2 items-center font-bold hover:text-orange-500'>
          <p className='text-sm'>New Inverter</p>
          <MdAddCircle className='text-2xl' />
        </button>
      </div>

      <Paper
        sx={{
          marginX: 'auto',
          width: { xs: '100%', md: '95%' },
          height: '100vh',
          overflow: 'auto',
          marginY: { xs: '10px', md: '40px' },
          paddingX: { xs: '10px', md: '40px' },
          paddingY: { xs: '10px', md: '30px' },
        }}
      >
        <div className='flex gap-2 mb-4 justify-between items-end'>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MdSearch />
                </InputAdornment>
              ),
            }}
          />

          {/* Show number of items */}
          <p className='text-xs text-gray-500'>
            Viewing {sortedData.length} item{sortedData.length === 1 ? '' : 's'}
          </p>
        </div>

        <TableContainer
          sx={{
            marginX: 'auto',
          }}
        >
          <Table aria-label='sticky table' sx={{ paddingX: '40px' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: '#CBE6FA',
                }}
              >
                <TableCell>
                  <p className='text-center text-sm font-bold'>Brand</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Model</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Capacity</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Phase</p>
                </TableCell>
                <TableCell>
                  <p className='text-center text-sm font-bold'>Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((inv, index) => (
                <TableRow
                  key={index}
                  sx={{
                    fontSize: '12px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F3F3F3',
                    },
                  }}
                  onClick={() => {
                    navigate(`/inverters/${inv.id}`);
                  }}
                >
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{inv.brand}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{inv.model}</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{inv.rated_power_output} W</p>
                  </TableCell>
                  <TableCell>
                    <p className='text-center text-xs font-bold text-gray-500'>{inv.phase}</p>
                  </TableCell>
                  <TableCell>
                    <p className={`text-center text-xs font-bold ${inv.status === 'Inactive' ? 'text-[#A90000]' : 'text-[#0A4A18]'}`}>{inv.status}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='my-1 transition-all duration-200'>
          {inverterListState.pending && <LoadingSkeleton numColumns={1} />}
          {!inverterListState.error && !inverterListState.pending && sortedData.length === 0 && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Data Not Available!</div>
          )}
          {!inverterListState.pending && inverterListState.error && (
            <div className='my-12 flex justify-center items-center text-red-400 font-semibold'>Error occurred!</div>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default Inverters;
