import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getInverterElectricalAccessories, updateInverterElectricalAccessory } from '../../../store/InverterSlice';
import Breadcrumbs from '../../Breadcrumbs';
import { Button, InputAdornment, TextField } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import LoadingSkeleton from '../../LoadingSkeleton';
import { MdSearch } from 'react-icons/md';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, accessoryName: '', perPanelReq: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Add New Accessory
      </Button>
    </GridToolbarContainer>
  );
}

const InverterElectricalAccessories = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const inverterId = params.id;

  const inverterData = useSelector((state: RootState) => state.inverter.details.data);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [searchTerm, setSearchTerm] = useState('');

  const electricalAccessoryData = useSelector((state: RootState) => state.inverter.electricalAccessoryData);

  // Fetch Inverter Electrical accesssories
  useEffect(() => {
    dispatch(getInverterElectricalAccessories({ id: inverterId }));
  }, [dispatch, inverterId]);

  function createRows(accessoryData: [any]) {
    if (!accessoryData) {
      return [];
    }
    const rows = accessoryData.map((acc) => {
      return {
        id: acc.id,
        accessory: acc.accessory.name,
        quantity: acc.quantity,
      };
    });

    return rows;
  }

  // Handle search input change
  useEffect(() => {
    if (searchTerm === '') {
      setRows(createRows(electricalAccessoryData.data));
    } else {
      const filteredRows = electricalAccessoryData?.data?.filter((acc: { accessory: { name: string } }) =>
        acc.accessory.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setRows(createRows(filteredRows));
    }
  }, [searchTerm, electricalAccessoryData.data]);

  // Initialise rows
  useEffect(() => {
    electricalAccessoryData.data && setRows(createRows(electricalAccessoryData.data));
  }, [electricalAccessoryData]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (updatedAccessory: GridRowModel) => {
    const updatedAccessoryData = {
      id: updatedAccessory.id,
      quantity: updatedAccessory.quantity,
    };

    const res = await dispatch(updateInverterElectricalAccessory(updatedAccessoryData)).unwrap();

    const updatedRow = { ...updatedAccessory, isNew: false };
    if (res.data) {
      setRows(rows.map((row) => (row.id === updatedAccessory.id ? updatedRow : row)));
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'accessory',
      headerName: 'Electrical Accessory Name',
      flex: 3,
      editable: false,
      filterable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      editable: true,
      align: 'right',
      flex: 1,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [<GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />];
      },
    },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // if the submission successfull
    setSubmitSuccess(true);
    // react-confetty
  };

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/inverters',
      name: 'Inverters',
    },
    {
      link: '',
      name: 'Electrical Accessories',
    },
  ];

  if (inverterData) {
    const panelLink = {
      name: `${inverterData?.brand} ${inverterData?.model} ${inverterData?.rated_power_output}W`,
      link: `/inverters/${inverterId}`,
    };
    links.splice(1, 0, panelLink);
  }

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll bg-[#ECECEC] pb-5'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex flex-col md:flex-row items-center justify-between w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>

      <form onSubmit={handleSubmit} className='w-full md:w-[95%]'>
        <div className='w-full border rounded p-5 md:p-10 md:my-10 shadow-lg flex flex-col relative bg-white'>
          <div className='flex gap-2 mb-4 justify-between items-end'>
            <TextField
              label='Search'
              variant='outlined'
              size='small'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />

            {/* Show number of items */}
            <p className='text-xs text-gray-500'>
              Viewing {rows.length} item{rows.length === 1 ? '' : 's'}
            </p>
          </div>
          {electricalAccessoryData.pending ? (
            <LoadingSkeleton numColumns={1} />
          ) : electricalAccessoryData.error ? (
            <div>Error fetching Electrical Accessories</div>
          ) : (
            electricalAccessoryData.data && (
              <DataGrid
                processRowUpdate={processRowUpdate}
                rows={rows}
                columns={columns}
                hideFooter
                disableColumnSelector
                editMode='row'
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  toolbar: { setRowModesModel },
                }}
                className='font-bold'
                sx={{
                  width: '100%',
                  display: 'flex',
                  fontSize: '12px',
                  fontWeight: 'bold',

                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#CBE6FA',
                  },

                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'flex',
                    justifyContent: '',
                  },

                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    marginX: 'auto',
                  },
                }}
              />
            )
          )}
        </div>
        {/*
        <button
          type='submit'
          className={`py-3 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl duration-100 w-full md:w-1/5 ml-auto mt-auto bg-[#FF802F] hover:bg-[#ff7a45] float-right`}
        >
          SUBMIT
        </button> */}
      </form>
    </div>
  );
};

export default InverterElectricalAccessories;
