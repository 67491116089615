import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/HardwareAccessoryTypes';
import { toast } from 'react-toastify';

const api = process.env.REACT_APP_API;

export const getHardwareAccessoryList = createAsyncThunk(
  'hardware-accessories/list',
  async (payload: any, thunk) => {
    try {
      const res = await axios.get(api + '/hardware-accessories');

      return res.data;
    } catch (err: any) {
      return thunk.rejectWithValue(err.response.dat);
    }
  }
);

export const createHardwareAccessory = createAsyncThunk(
  'hardware-accessory/create',
  async (payload: any, thunk) => {
    try {
      const res = await axios.post(api + '/hardware-accessories', payload);
      toast.success('Hardware Cost Created Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateHardwareAccessory = createAsyncThunk(
  'hardware-accessory/update',
  async (payload: any, thunk) => {
    try {
      const res = await axios.put(
        api + '/hardware-accessories/' + payload.id,
        payload
      );
      toast.success('Hardware Cost Updated Successfully!');
      return res.data;
    } catch (err: any) {
      toast.error(err.response.data.message);
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

const initialState: InitialState = {
  list: {
    data: [],
    error: false,
    pending: false,
  },
  create: {
    data: null,
    error: false,
    pending: false,
  },
  update: {
    data: null,
    error: false,
    pending: false,
  },
};

const HardwareAccessorySlice = createSlice({
  name: 'HardwareAccessory',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // List case
    builder.addCase(getHardwareAccessoryList.fulfilled, (state, action) => {
      state.list.data = action.payload.data;
      state.list.error = false;
      state.list.pending = false;
    });
    builder.addCase(getHardwareAccessoryList.rejected, (state, action) => {
      state.list.data = [];
      state.list.error = true;
      state.list.pending = false;
    });
    builder.addCase(getHardwareAccessoryList.pending, (state, action) => {
      state.list.data = [];
      state.list.error = false;
      state.list.pending = true;
    });
    // Create case
    builder.addCase(createHardwareAccessory.fulfilled, (state, action) => {
      state.create.data = action.payload.data;
      state.create.error = false;
      state.create.pending = false;
    });
    builder.addCase(createHardwareAccessory.rejected, (state, action) => {
      state.create.data = null;
      state.create.error = true;
      state.create.pending = false;
    });
    builder.addCase(createHardwareAccessory.pending, (state, action) => {
      state.create.data = null;
      state.create.error = false;
      state.create.pending = true;
    });
    //Update case
    builder.addCase(updateHardwareAccessory.fulfilled, (state, action) => {
      state.update.data = action.payload.data;
      state.update.error = false;
      state.update.pending = false;
    });
    builder.addCase(updateHardwareAccessory.rejected, (state, action) => {
      state.update.data = null;
      state.update.error = true;
      state.update.pending = false;
    });
    builder.addCase(updateHardwareAccessory.pending, (state, action) => {
      state.update.data = null;
      state.update.error = false;
      state.update.pending = true;
    });
  },
});

export default HardwareAccessorySlice.reducer;
