import React, { useState } from 'react';

import { navbarHeight, footerHeight } from '../../../utils/constants';

import CircuitForm from './CircuitForm';
import { createCircuit } from '../../../store/CircuitSlice';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../Breadcrumbs';
import ConfettiExplosion from 'react-confetti-explosion';
import SuccessModal from '../../SuccessModal';
import { PiCircuitryFill } from 'react-icons/pi';
import LoaderOverlay from '../../LoaderOverlay';

const filters = {
  search: '',
  page: 0,
  limit: 100,
};

const CreateCircuitSpecifications = () => {
  const dispatch: AppDispatch = useDispatch();

  const circuitCreateState = useSelector((state: RootState) => state.circuit.create);

  const [specs, setSpecs] = useState({
    dc_cable_type: 'Shanghai Yuanling DC Single PV Cable',
    dc_cable_size: '4',
    dc_cable_rated_voltage: '1500',
    dc_cable_standards: 'TUV Rheinland',
    ac_cable_type: 'ACL',
    ac_cable_size: '6',
    ac_cable_rated_voltage: '450/750V',
    ac_cable_standards: 'BSEN50525-2-31/SLS 1504-2-31',
    grounding_cable_standards: 'BSEN50525-2-31/SLS 1504-2-31',
    dc_ocp_type: 'SUNTREE - SUP4 - PV - Type II',
    dc_ocp_voltage_current_rating: '1000V / 30kA',
    dc_switch_type: 'SUNTREE - SISO',
    dc_switch_voltage_current_rating: '600V / 1500V DC / 32A',
    ac_isolator_type: 'ORANGE - Din Rail',
    ac_isolator_voltage_current_rating: '388V / 32A',
    ocp_type: 'SUNTREE - SUP1/SUP2 - Type II',
    ocp_voltage_current_rating: '350V / 20 - 30kA',
    ocp_uc: '385',
    ocp_umax: '1.6',
    ocp_in: '20',
    ocp_imax: '40',
    rcd_type: 'ORANGE',
    rcd_current_rating: '32A / 20 - 30kA',
    earthing_type: 'Copper Rod',
    earthing_size: '16',
    dc_spd_type: 'SUNTREE',
    ac_spd_type: 'SUNTREE',
    mcb_type: 'ORANGE',
    mcb_voltage_current_rating: '385V / 32A',
    mcb_short_circuit_current: '20 - 30kA',
    mcb_ip_class: 'IP64',
    ocp_dc_icn: '20',
    ocp_dc_iu: '40',
    ocp_dc_current_rating: '32',
    ocp_ac_icn: '30',
    ocp_ac_iu: '40',
    ocp_ac_current_rating: '32',
    grounding_cable_rated_voltage: '450/750V',
    grounding_cable_cross: '6',
  });

  // const [specs, setSpecs] = useState({
  //   dc_cable_type: '',
  //   dc_cable_size: '',
  //   dc_cable_rated_voltage: '',
  //   dc_cable_standards: '',
  //   ac_cable_type: '',
  //   ac_cable_size: '',
  //   ac_cable_rated_voltage: '',
  //   ac_cable_standards: '',
  //   grounding_cable_rated_voltage: '',
  //   grounding_cable_cross: '',
  //   grounding_cable_standards: '',
  //   dc_ocp_type: '',
  //   dc_ocp_voltage_current_rating: '',
  //   dc_switch_type: '',
  //   dc_switch_voltage_current_rating: '',
  //   ac_isolator_type: '',
  //   ac_isolator_voltage_current_rating: '',
  //   ocp_type: '',
  //   ocp_voltage_current_rating: '',
  //   ocp_uc: '',
  //   ocp_umax: '',
  //   ocp_in: '',
  //   ocp_imax: '',
  //   rcd_type: '',
  //   rcd_current_rating: '',
  //   earthing_type: '',
  //   earthing_size: '',
  //   dc_spd_type: '',
  //   ac_spd_type: '',
  //   mcb_type: '',
  //   mcb_voltage_current_rating: '',
  //   mcb_short_circuit_current: '',
  //   mcb_ip_class: '',
  //   ocp_dc_icn: '',
  //   ocp_dc_iu: '',
  //   ocp_dc_current_rating: '',
  //   ocp_ac_icn: '',
  //   ocp_ac_iu: '',
  //   ocp_ac_current_rating: '',
  // });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const editable = true;

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(createCircuit(specs));
    });

    setIsSubmitted(true);
  };

  const handleInputChange = ({ fieldName, value }: any) => {
    setSpecs({
      ...specs,
      [fieldName]: value,
    });
  };

  // Handle Links for Breadcrumbs
  const links = [
    {
      link: '/products/circuit-specifications',
      name: 'Circuit Specifications',
    },
    {
      link: '',
      name: 'Create Circuit Specifications',
    },
  ];

  return (
    <div className='w-full flex flex-col items-center overflow-y-scroll' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <Breadcrumbs links={links} />
      </div>

      {circuitCreateState?.pending && <LoaderOverlay />}
      {isSubmitted && !circuitCreateState.error && <ConfettiExplosion /> && (
        <SuccessModal
          reactIcon={<PiCircuitryFill />}
          title='Congratulations'
          subtitle='Circuit Specification Added'
          buttonText='Go to Circuit List'
          linkTo='/products/circuit-specifications'
        />
      )}

      <div className='w-full bg-[#ECECEC]'>
        <CircuitForm spec={specs} editable={editable} onFormSubmit={handleFormSubmit} onInputChange={handleInputChange} />
      </div>
    </div>
  );
};

export default CreateCircuitSpecifications;
