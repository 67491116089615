export const navbarHeight = 120;
export const footerHeight = 30;

export const projecProgress = [
    {
        task: 'Consultation',
        percent: 0
    },
    {
        task: 'Proposal',
        percent: 15
    },
    {
        task: 'Clearance',
        percent: 30
    },
    {
        task: 'Invoice',
        percent: 45
    },
    {
        task: 'Installation',
        percent: 60
    },
    {
        task: 'Handover',
        percent: 90
    },
    {
        task: 'Complete',
        percent: 100
    },
];