import React, { useState } from 'react';
import { navbarHeight, footerHeight } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { EngineerFee } from '../../../store/types/EngineerFeeTypes';
import { createEngineerFee } from '../../../store/EngineerFeeSlice';
import EngineerFeeForm from './EngineerFeeForm';
import Breadcrumbs from '../../Breadcrumbs';

type Props = {};

const AddEngineerFee: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const createEngineerFeeState = useSelector((state: RootState) => state.engineerFee.create);

  const [fee, setFee] = useState<EngineerFee>({
    min: '',
    max: '',
    fee: 0,
  });

  const handleInputChange = ({ fieldName, value }: any) => {
    setFee({
      ...fee,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    await dispatch(createEngineerFee(fee));

    if (!createEngineerFeeState.error) {
      navigate('/financials/engineer-fee');
    }
  };

  const links = [
    {
      link: '/financials/engineer-fee',
      name: 'Financials',
    },
    {
      link: '',
      name: 'Add Engineer Fee',
    },
  ];

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-auto bg-[#ECECEC]'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs links={links} />
      </div>
      <EngineerFeeForm engineerFee={fee} onInputChange={handleInputChange} onFormSubmit={handleFormSubmit} editable={true} />
    </div>
  );
};

export default AddEngineerFee;
