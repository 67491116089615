import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import img1 from '../../images/documents/leco-annex-4/1.png';
import img2 from '../../images/documents/leco-annex-4/2.png';
import installerDetails from '../../config/installerDetails';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '100%',
    orientation: 'portrait',
    fontSize: 9,
  },
  view: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 0,
  },

  // project details

  bank_name: {
    position: 'absolute',
    top: 165,
    left: 200,
  },

  project_description: {
    position: 'absolute',
    top: 187,
    left: 200,
  },
  gps_cordinates: {
    position: 'absolute',
    top: 209,
    left: 200,
  },

  site_address: {
    position: 'absolute',
    top: 230,
    left: 200,
    width: 300,
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },

  inspection_date: {
    position: 'absolute',
    top: 270,
    left: 200,
  },

  customer_name: {
    position: 'absolute',
    top: 315,
    left: 190,
    textTransform: 'capitalize',
  },

  customer_address: {
    position: 'absolute',
    top: 337,
    left: 190,
    width: 300,
    wordWrap: 'break-word',
    textTransform: 'capitalize',
  },
  mobile_num: {
    position: 'absolute',
    top: 359,
    left: 190,
  },

  land_num: {
    position: 'absolute',
    top: 381,
    left: 190,
    width: 300,
    wordWrap: 'break-word',
  },
  customer_email: {
    position: 'absolute',
    top: 403,
    left: 190,
  },

  power_rating: {
    position: 'absolute',
    top: 423,
    left: 190,
    width: 300,
    wordWrap: 'break-word',
  },

  service_provider_name: {
    position: 'absolute',
    top: 478,
    left: 190,
    textTransform: 'capitalize',
  },

  adb_reg_num: {
    position: 'absolute',
    top: 500,
    left: 190,
  },
  contact_details: {
    position: 'absolute',
    top: 522,
    left: 190,
  },
  proposed_capacity: {
    position: 'absolute',
    top: 552,
    left: 190,
  },

  // page 2
  view_2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: 'white',
  },
});

const LECOAnnex4 = ({ project }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          <Image src={img1} style={styles.image} />
          {/* project details */}
          <Text style={styles.bank_name}></Text>
          <Text style={styles.project_description}>
            Grid Tied Solar Power System
          </Text>
          <Text style={styles.gps_cordinates}>
            Lat: {project?.coordinates.lat}, Lon: {project?.coordinates.lon}
          </Text>
          <Text style={styles.site_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.inspection_date}>{project?.inspection_date}</Text>

          {/* Customer details */}
          <Text style={styles.customer_name}>
            {project?.customer.first_name} {project?.customer.last_name}
          </Text>
          <Text style={styles.customer_address}>
            {project?.customer.house_no}, {project?.customer.street},{' '}
            {project?.customer.city}
          </Text>
          <Text style={styles.mobile_num}>{project?.customer.phone}</Text>
          <Text style={styles.land_num}></Text>
          <Text style={styles.customer_email}>{project?.customer.email}</Text>
          <Text style={styles.power_rating}>{project?.phase}</Text>

          {/* Service Provider details */}
          <Text style={styles.service_provider_name}>
            {installerDetails.name}
          </Text>
          <Text style={styles.adb_reg_num}>{installerDetails.sea_reg_num}</Text>
          <Text style={styles.contact_details}>
            {installerDetails.phone_1}/ {installerDetails.phone_2}
          </Text>
          <Text style={styles.proposed_capacity}>
            {(
              project?.project_inverters.length *
              (project?.project_inverters[0].inverter.rated_power_output / 1000)
            ).toFixed(1)}{' '}
            DC
          </Text>
        </View>
      </Page>

      {/* Page 2 */}

      <Page size="A4" style={styles.page}>
        <View style={styles.view_2}>
          <Image src={img2} style={styles.image} />
        </View>
      </Page>
    </Document>
  );
};

export default LECOAnnex4;
