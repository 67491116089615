import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';
import ConfettiExplosion from 'react-confetti-explosion';

type Props = {
  reactIcon?: any;
  title?: any;
  subtitle?: any;
  message?: any;
  buttonText?: any;
  onButtonClick?: any;
  linkTo?: any;
  product?: any;
};

const SuccessModal: React.FC<Props> = ({ reactIcon, title, subtitle, message, buttonText, onButtonClick, linkTo, product }) => {
  const navigate = useNavigate();

  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    backgroundColor: 'white',
    boxShadow: 24,
    p: 6,
    borderRadius: 5,
  };

  const handleButtonClick = () => {
    navigate(linkTo, { state: { product } });
  };

  return (
    <Modal open={true} style={modalStyle}>
      <div>
        <ConfettiExplosion className='absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
        <Paper sx={paperStyle} className='relative'>
          <div className='text-center'>
            <div className='text-5xl flex justify-center mx-auto mb-5'>{reactIcon}</div>
            <h1 className='font-bold text-4xl mb-3'>{title}</h1>
            <h2 className='text-xl font-bold mb-3'>{subtitle}</h2>
            <p className='w-[60%] mx-auto mb-3 text-xs'>{message}</p>
            <button
              type='button'
              className={`mt-3 py-3 w-2/3 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl duration-100 ml-auto  bg-[#FF802F] hover:bg-[#ff7a45] whitespace-nowrap`}
              onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default SuccessModal;
