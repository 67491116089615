import { Modal, Paper } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { Project } from '../../../store/types/ProjectTypes';

type Props = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  project: Project;
};

const TaskList: React.FC<Props> = ({ showModal, setShowModal, project }) => {
  const modalStyle = {
    display: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const paperStyle = {
    position: 'absolute',
    width: 'auto',
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)} style={modalStyle}>
      <div>
        <Paper sx={paperStyle} className='relative'>
          <h2 className='font-bold text-xl mb-4'>Task List</h2>
          <div className='md:w-auto max-w-[600px] flex flex-col sm:flex-row gap-5 overflow-y-auto flex-wrap justify-center'>
            {project?.project_task
              .filter((task: any) => task.task !== 'complete')
              .reverse()
              .map((task: any) => (
                <div className='w-72 border rounded p-4 bg-[#fffdfb] border-orange-400' key={task.id}>
                  <div className='flex justify-between w-full'>
                    <p className='font-medium text-gray-600'>Task: </p>
                    <p className='font-semibold'>{task?.task?.slice(0, 1).toUpperCase() + task?.task?.slice(1)}</p>
                  </div>
                  <div className='flex justify-between w-full'>
                    <p className='font-medium text-gray-600'>User: </p>
                    <p className='font-semibold'>{task?.user?.username}</p>
                  </div>
                  <div className='flex justify-between w-full'>
                    <p className='font-medium text-gray-600'>Due Date: </p>
                    <p className='font-semibold'>{task?.due_date.split('T')[0]}</p>
                  </div>
                  <div className='flex justify-between w-full'>
                    <p className='font-medium text-gray-600'>Completed: </p>
                    <p className={`font-bold  ${task?.completed_date ? 'text-green-600' : 'text-red-600'}`}>{task?.completed_date ? 'Yes' : 'No'}</p>
                  </div>
                  <div className='flex justify-between w-full'>
                    <p className='font-medium text-gray-600'>Completed Date: </p>
                    <p className='font-semibold'>{task?.completed_date?.split('T')[0] ?? 'TBD'}</p>
                  </div>
                </div>
              ))}
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default TaskList;
