import { footerHeight, navbarHeight } from '../../utils/constants';
import Breadcrumbs from '../Breadcrumbs';
import ActiveTasks from './ActiveTasks';
import OngoingProjects from './OngoingProjects';
import ProjectMap from './ProjectMap';
import Sales from './Sales';

const Dashboard = () => {
  return (
    <div className='w-full flex flex-col items-center bg-gray-50 h-[100vh]' style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}>
      <div className='justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm bg-white'>
        <Breadcrumbs
          links={[
            {
              link: '',
              name: 'Dashboard',
            },
          ]}
        />
      </div>
      <div className='grid grid-cols-2 2xl:grid-cols-3 w-full px-10 py-6 gap-10 overflow-scroll bg-[#E7E7E7]/70'>
        <div className='shadow-lg rounded-md col-span-1 bg-white'>
          <Sales />
        </div>
        <div className='shadow-lg rounded-md col-span-1 bg-white'>
          <OngoingProjects />
        </div>
        <div className='shadow-lg rounded-md col-span-1 bg-white'>
          <ActiveTasks />
        </div>
        <div className='shadow-lg rounded-md col-span-1 bg-white'>
          <ProjectMap />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
